import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useAppPetitionListSelector } from "src/_common/hooks/selectors/petition/petitionSelector";
import { CRYPTO_SECRET_KEY, getSignedTime, URLS } from "src/_config";
import { useAppPetitionApi } from "src/_common/hooks/api/petition/appPetitionApiHook";
import { useAppPetitionDetailsSelector, } from "src/_common/hooks/selectors/petition/petitionSelector";
import Footer from "../../common/Footer";
import RightStickyBtn from "../../common/RightStickyBtn";
// import GotoStartPetition from "../common/GotoStartPetition"
// import RightPanelButtons from "../common/RightPanelButtons";
// import SignPetition from "../sign-petition/SignPetition";
// import ShareModal from "../common/ShareModal";
import { useToaster } from "src/_common/hooks/common/appToasterHook";
import parse from 'html-react-parser'
// import NavTabs from "../my-petition-dashboard/NavTabs";
import { useAppUserDetailsSelector } from "src/_common/hooks/selectors/user/userSelector";
import NavbarMain from "src/components/common/navbar/NavbarMain";
import { useAppFundraisingApi } from "src/_common/hooks/api/fundraising/appFundraisingApiHook";
import { useAppFundraisingCharityListSelector, useAppFundraisingDetailsSelector } from "src/_common/hooks/selectors/fundraising/fundraisingSelector";
import ShareModal from "src/components/common/ShareModal";
import RightDetailsComponent from "./RightDetailsComponent";
import ShareDonateButtons from "../common/ShareAndDonateButtons";
import MetaDecorator from "src/components/common/MetaDecorator";
import { useAuthStatus } from "src/_common/hooks/authHook";
import NavTabs from "../my-fundraising-dashboard/NavTabs";

const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);
const queryString = require("query-string");

const PetitionDetails = () => {

  const {slug} = useParams<any>()
  // const id = cryptr.decrypt(fundraisingId.id)
  // const slug = fundraisingId.slug
  // console.log({ id })
  const fundraisingApi = useAppFundraisingApi()
  const userDetails = useAppUserDetailsSelector()
  const isAuth = useAuthStatus()

  const history = useHistory()
  const toast = useToaster()

  const [showShareModal, setShowShareModal] = useState<boolean>(false)
  const [charityDetail, setCharityDetail] = useState<any>([])
  const [updatesList, setUpdatesList] = useState<any>([])

  const fundraisingDetails = useAppFundraisingDetailsSelector();
  const charityListSelector = useAppFundraisingCharityListSelector()?.data;

  const filterCharity = () => {
    // console.log(charityListSelector)
    const charityDetails = charityListSelector.filter((item: any) => {
      return item.id == fundraisingDetails?.details?.charity_id
    })
    // console.log(charityDetails)
    setCharityDetail(charityDetails);
  }

  const getFundraisingDetails = () => {
    const params = {
      slug,
    };
    fundraisingApi.callGetFundraisingDetails(
      params,
      (message: string, resp: any) => {
        if(resp.data.details == null){
          history.push(URLS.FUNDRAISING.BROWSE_FUNDRAISING)
        }
        else if (resp.data.details.fundraising_for == 3 && resp.data.details.charity_id != 0) {
          filterCharity()
        }
      },
      (message: string) => {
        // toast.error(message)
      }
    );
  };

  const getCharityList = () => {
    let params = {
      title: ""
    }
    fundraisingApi.callGetFundraisingCharityList(
      params,
      (message: string, resp: any) => {
        // console.log({ resp });
      },
      (message: string) => { }
    );
  }

  const openShareModal = () => {
    setShowShareModal(true)
  }

  const closeShareModal = () => {
    setShowShareModal(false)
  }

  // const increasePetitionViewCount = () => {
  //   const params = {
  //     petition_id: String(id),
  //   };
  //   fundraisingApi.callAddPetitionViewCount(
  //     params,
  //     (message: string, resp: any) => {
  //       console.log({ resp });
  //       // toast.success(message);
  //     },
  //     (message: string) => {
  //       // toast.error(message)
  //     }
  //   );
  // }

  const getUpdatesList = () => {
    let params = {
      // fundraiser_id: id
      slug
    }
    fundraisingApi.callGetUpdatesList(params,
      (message: string, resp: any) => {
        // console.log(resp.data.list.data)
        setUpdatesList(resp.data.list.data)
      }, (message: string) => {
      })
  }

  // const displayUpdateList = () => {
  //   const displayList = []
  //   for (let i = 0; i < numberOfRows; i++) {
  //     displayList.push(
  //       <div className="row">

  //       </div>
  //     )
  //   }
  // }

  useEffect(() => {
    getCharityList();
    getUpdatesList();
    // getFundraisingDetails();
    // increasePetitionViewCount()
  }, []);

  useEffect(() => {
    if (charityListSelector) {
      getFundraisingDetails()
    }
  }, [charityListSelector])

  // useEffect(() => {
  //   if (!fundraisingDetails?.details) {
  //     history.push(URLS.FUNDRAISING.BROWSE_FUNDRAISING)
  //   }
  // }, [fundraisingDetails])

  return (
    <>

      <MetaDecorator
        title={fundraisingDetails?.details?.title}
        description={fundraisingDetails?.details?.description}
        imageUrl={fundraisingDetails?.details?.fundraiser_image?.thumb}
      />

      <NavbarMain section={"fundraising"} />

      <RightStickyBtn />


      <section className="inner-page">
        <div className="container">
          {fundraisingDetails?.details && userDetails && userDetails?.id && userDetails?.id == fundraisingDetails?.details?.user_id &&
            <div className="custom-tab-wrap">
              <NavTabs
                page={"details"}
              // setPage={setPage}
              />
            </div>
          }
          {/* <!--------- CONTENT AREA START ----------> */}
          <div className="innerContentArea">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-8">
                  <div className="petition_bannerimg">
                    {fundraisingDetails?.details?.fundraiser_image?.id != 0 ?
                      <img src={fundraisingDetails?.details?.fundraiser_image?.original} />
                      :
                      fundraisingDetails?.details?.video_link ?
                        <iframe src={"https://www.youtube.com/embed/" + fundraisingDetails?.details?.video_link.substring(fundraisingDetails?.details?.video_link.indexOf("?v=") + 3)} width="100%" height="100%"></iframe>
                        : <img src="/images/no-image-large.jpg" />
                    }
                  </div>
                  <div className="petition_authorpicname">
                    <div className="d-flex">
                      <div className="petition_authorpic">
                        <img src={fundraisingDetails?.details?.user?.avatar?.thumb} />
                      </div>
                      <div className="petition_authorname">{fundraisingDetails?.details?.user?.full_name}</div>
                    </div>
                    <div className="post-date">
                      {/* Created <span>{getSignedTime(fundraisingDetails?.details?.date_created ? fundraisingDetails?.details?.date_created : new Date())}</span> */}
                      Created <span>{fundraisingDetails?.details?.date_created ? getSignedTime(fundraisingDetails?.details?.date_created) : getSignedTime(new Date())}</span>
                    </div>
                  </div>
                  <h3 className="section-title">{fundraisingDetails?.details?.title}</h3>
                  {isAuth ?
                    <div className="petition_detailstxt">
                      <p>

                        <React.Fragment>
                          <h4>Raise Type : {" "}
                            {fundraisingDetails?.details?.fundraising_for == 1 ?
                              "Crisis Relief" : null}
                            {fundraisingDetails?.details?.fundraising_for == 2 ?
                              "Community Initiatives" : null}
                            {fundraisingDetails?.details?.fundraising_for == 3 ?
                              "Event" : null}
                          </h4>
                          {fundraisingDetails?.details?.fundraising_for == 3 ?
                            <p>
                              {fundraisingDetails?.details?.event_option == "1" ? "Non Profit" : fundraisingDetails?.details?.event_option == "2" ? "Own Event" : ""}
                            </p>
                            : null
                          }
                        </React.Fragment>

                        {fundraisingDetails?.details?.fundraising_for == 3 && charityDetail.length > 0 ?
                          <>
                            <div className="m-charity-info inside-details">
                              <div className="m-charity-info-logo">
                                {charityDetail[0]?.id !== 0 ?
                                  <img
                                    src={charityDetail[0]?.image?.thumb}
                                    alt=""
                                  />
                                  : null
                                }
                              </div>
                              <div className="m-charity-text">
                                <h2>
                                  <span>{charityDetail[0]?.title}</span> <em>Society</em>{" "}
                                  <i className="fa fa-check-circle" />
                                </h2>
                                <div className="m-charity-info-details-wrapper">
                                  <div className="m-charity-detail">
                                    {charityDetail[0]?.category}
                                  </div>
                                  <div className="m-charity-detail">
                                    {charityDetail[0]?.state?.state_name}, OK
                                  </div>
                                  <div className="m-charity-detail">
                                    EIN: {charityDetail[0]?.ein_number}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                          : null}
                      </p>
                    </div>
                    :
                    null
                  }
                  <div className="petition_detailstxt">
                    {fundraisingDetails?.details?.description ?
                      <p>
                        {parse(fundraisingDetails?.details?.description)}
                      </p>
                      :
                      null
                    }
                  </div>
                  {/* {updatesList && updatesList.length ? */}
                  {/* <div className="two-btns-wrap">
                  <a className="btn btn-secondary sharebtn w-100" onClick={openShareModal}>
                    Share <span className="share_icon" />
                  </a>
                  <a className="btn btn-primary w-100 donatenowbtn">
                    Donate now <img className="fa" src="/images/rodeo_hover.svg" />
                  </a>
                </div> */}
                  <ShareDonateButtons className="two-btns-wrap" openShareModal={openShareModal} />
                </div>

                {fundraisingDetails && <RightDetailsComponent openShareModal={openShareModal}/>}
              </div>
              {updatesList && updatesList.length != 0 ?
                <div className="details-page-updates-wrap row mt-4">
                  <h4>Updates</h4>
                  {updatesList.map((item: any, index: any) => (
                    <div className="col-6 mt-2" key={index}>
                      <div className="updates-tab-content-profile d-flex">
                        <div className="fund-update-img">
                          <img src={item?.fundraiser_update_image?.thumb} alt="avatar" />
                        </div>
                        <div className="updates-profile-text">
                          <h4>
                            {item?.user?.full_name}
                            <span>{getSignedTime(item?.updated_date)}</span>
                          </h4>
                          <p><b style={{fontSize:"17px"}}>{item?.title}</b></p>
                          <p>{item?.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                :
                null
              }
            </div>
          </div>
        </div>
      </section>
      {/* <!--------- CONTENT AREA END ----------> */}

      <Footer />
      {showShareModal &&
        <ShareModal
          shouldShow={showShareModal}
          onClose={closeShareModal}
          slug={slug}
          section="fundraising"
        />
      }

    </>
  );
};

export default PetitionDetails;
