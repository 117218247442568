import moment from 'moment'
import TimeAgo from "javascript-time-ago";
// English.
import en from "javascript-time-ago/locale/en";
import { number } from 'prop-types';

TimeAgo.addLocale(en);
// Create formatter (English).
const timeAgo = new TimeAgo("en-US");

export const getSignedTime = (value: any) => {
  let localTime = moment.utc(value).local().format();
  let currentDate = new Date(Date.now());
  let givenDate = new Date(localTime);
  // let givenDate = new Date(value);
  // let dateDiff = currentDate.getTime() - givenDate.getTime();
  let dateDiff = currentDate.getTime() - givenDate.getTime();
  const time: any = timeAgo.format(
    new Date(Date.now()).valueOf() - dateDiff,
    "round"
  );
  // console.log({dateDiff, value, time})
  return time;
};

export const generateDaysOption = (days: number) => {
  return new Array(days).fill(1).map((x, i) => ({
    value: i + 1,
    label: String(i + 1)
  }))
}

export const generateYearsOption = (numYears: number, ignoreLastYears: number) => {
  return new Array(numYears).fill(1).map((y, i) => {
    let yt = moment().subtract((i + ignoreLastYears), 'year').format('YYYY')
    return {
      value: parseInt(yt),
      label: yt,
    }
  })
}

export const extractErrorMessage = (error: any, defaultMessage = 'Please try again') => {
  // console.log('error here', error)
  if (typeof error === 'string') {
    return error
  } else {
    if (error && error.response && error.response.message && error.response.message.length) {
      return error.response.message
    } else if (error && error.data && error.data.errors && Object.keys(error.data.errors).length) {
      return error.data.errors[Object.keys(error.data.errors)[0]];
    } else if (error && error.data && error.data.message) {
      return error.data.message
    } else if (error && error.message) {
      return error.message
    } else {
      return defaultMessage
    }
  }
}

export const getFirstLastStartLetter = (firstName: string, lastName: string) => {

  var fname = firstName ? firstName.charAt(0).toUpperCase() : null
  var lname = lastName ? lastName.charAt(0).toUpperCase() : null
  var data = fname+''+lname;
  return data
}

export const getSubDomain=()=>{
  var subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : null;
  return subdomain
}

export const generateLink = (link: string) => {
  return `${window.location.origin}${link}/`
}

export const getRandomName = (prefix: string = '', suffix: string = '') => {
  let random = `${new Date().getTime()}_${Math.ceil(Math.random() * 1000)}`
  return `${prefix}${random}${suffix}`
}

export const extractHtmlString = (str: string) => {
  return str.replace(/<[^>]+>/g, '')
}

export const trimTo = (str: string, num: number, appendDots: boolean = false) => {
  return str && str.length > num ? appendDots ? `${str.substr(0, num)}...` : str.substr(0, num) : str
}

export const getYearString = (month: number) => {
  if (!month) {
    return 'N/A'
  }
  let year = Math.floor(month / 12)
  let remMonth = month % 12

  let str = ''
  if (year) {
    str += `${year} ${year > 1 ? 'years' : 'year'}`
  }
  if (month) {
    if (str && str.length) {
      str += ' and '
    }
    str += `${remMonth} ${remMonth > 1 ? 'months' : 'month'}`
  }
  return str;
}

export const getFileFromDataUrl = (dataUrl: string) => {
  if (!dataUrl) {
    return null
  }
  let arr = dataUrl.split(',');
  let mime = null
  if (arr && arr.length) {
    let parts = arr[0].match(/:(.*?);/)
    mime = parts && parts.length ? parts[1] : null
  }
  if (!mime) {
    return null
  }
  let bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], getRandomName('image', `.${mime.split('/')[1]}`), { type: mime });
}

export const getSubdomain = () => {
  return window.location.host.split('.')[1] ? window.location.host.split('.')[0] : null;
}

export const getNameInitials = (name: string) => {
  if (!name) {
    return 'NA'
  }
  return name.split(' ').map(x => x.charAt(0)).slice(0, 2).join('')
}

export const formattedNumber = (number: number) => {
    // Return 100K, 1.2K, 45M, etc number format
    var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

    // what tier? (determines SI symbol)
    var tier = Math.log10(Math.abs(number)) / 3 | 0;

    // if zero, we don't need a suffix
    if(tier == 0) return number;

    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
}

export const toTitleCase = (str: string): string => {
  if (str) {
      return str.charAt(0).toUpperCase() + str.substr(1);
  }
  alert(str)
  return str;
}

export const toCustomTitleCase = (str: string): string => {
  if (str) {
    const regex = /([a-z0-9])([A-Z])+/g;
    let newStr: string = str.replace(regex, '$1 $2').split('_').join(' ').split('-').join(' ');
    return toTitleCase(newStr);
  }
  return str;
}

export const phNoFormat = (n: string): string => {
  var len=n.length
  var s=""
  for(let i=1;i<=11-len;i++) {
    s=s+"0"
  }
  s=s+n
  return s
}

export const extractNumbersFromString = (s: string) => {
  var len=s.length
  var tmp=""
  var c = '0123456789'
  for(let i=0;i<len;i++) {
    if(c.includes(s[i]))
    {
      tmp+=s[i]
    }
  }
  return tmp
}

export const extractContent = (s:any) => {
  var span = document.createElement('span');
  span.innerHTML = s;
  span.innerHTML = span.innerHTML.replace(/\&nbsp;/g, '');
  return span.textContent || span.innerText;
}

export const changeString = (str: string, len: number) => {
  if (str.length > 0 && str.length > len) {
    return str.substr(0, len) + "..."
  } else {
    return str
  }
}

export const addCommaToAmount = (val: string) => {
  let a = val.split(".")
  let b = ""
  if(a.length==2) {
    b=a[1]
  }
  if(a[0].length<4) {
    return val
  }
  else {
    let x = ""
    console.log(a[0].length)
    for(let i=a[0].length-1;i>=0;i-=3) {
      if((i-2)>0) {
        x=a[0].substring(i-2,i+1)+(i!=a[0].length-1 ? "," : "")+x
      }
      else {
        x=a[0].substring(0,i+1)+","+x
      }
    }
    return x+(b?"."+b:"")
  }
}

export const getRandomNumbers = (n:any, u:any) => {
  var arr = [];
  while(arr.length < n){
      var r = Math.floor(Math.random() * u) + 1;
      if(arr.indexOf(r) === -1) arr.push(r);
  }
  return arr
}

export const allowNumberOnly = (val: any,name:any,setValue:any) => { 
  let lastVal=val[val.length-1]
  if (val && isNaN(lastVal)) {
    setValue(name,val.substring(0,val.length-1))
  }
}