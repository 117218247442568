import { ACTIONS, SAGA_ACTIONS } from '../../../../_config'
import { ChangePasswordReq, LoginReq, RegisterReq, UpdateUserInfoReq, SocialLoginReq, ForgotPasswordReq, SetPasswordReq } from '../../../interfaces/ApiReqRes'
import { useApiCall } from '../../common/appApiCallHook'

export function useUserApi() {

  const callApi = useApiCall()
  
  const userLogin = (data: LoginReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGIN, data, onSuccess, onError);
  }

  const userRegister = (data: RegisterReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.REGISTER, data, onSuccess, onError);
  }

  const updateUserInfo = (data: UpdateUserInfoReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.UPDATE_USER_INFO, data, onSuccess, onError);
  }

  const changePassword = (data: ChangePasswordReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.CHANGE_PASSWORD, data, onSuccess, onError);
  }

  const deactivateAccount = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.DEACTIVATE_ACCOUNT, null, onSuccess, onError);
  }

  const profilePicUpload = (data: ChangePasswordReq,onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.PROFILE_PIC_UPLOAD, data, onSuccess, onError);
  }

  const profilePicDelete = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.PROFILE_PIC_DELETE, null, onSuccess, onError);
  }

  const socialLogin = (data: SocialLoginReq,onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.SOCIAL_LOGIN, data, onSuccess, onError);
  }

  const forgotPassword = (data: ForgotPasswordReq,onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.FORGOT_PASSWORD, data, onSuccess, onError);
  }

  const setPassword = (data: SetPasswordReq,onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.SET_PASSWORD, data, onSuccess, onError);
  }

  return {
    callUserLogin: userLogin,
    callUserRegister: userRegister,
    callUpdateUserInfo: updateUserInfo,
    callChangePassword: changePassword,
    callDeactivateAccount: deactivateAccount,
    callProfilePicUpload: profilePicUpload,
    callProfilePicDelete: profilePicDelete,
    callSocialLogin: socialLogin,
    callForgotPassword: forgotPassword,
    callSetPassword: setPassword,
  }
}