import React, { useEffect, useRef } from 'react'
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import FormTextInput from 'src/_common/components/form-elements/textinput/formTextInput'
import { useAppSurveyApi } from 'src/_common/hooks/api/survey/appSurveyApiHook'
import { useToaster } from 'src/_common/hooks/actions/common/appToasterHook';
import { useAppSurveyAction } from 'src/_common/hooks/actions/survey/appSurveyActionHook';
import SurveyBanner from './SurveyBanner';
import { useHistory, useParams } from 'react-router';
import { CRYPTO_SECRET_KEY, URLS } from 'src/_config';
import { useAppSurveyDetailsSelector } from 'src/_common/hooks/selectors/survey/surveySelector';
import { Link } from 'react-router-dom';
import FormTextAreaInput from 'src/_common/components/form-elements/textarea/textareaInput';
import { Editor } from '@tinymce/tinymce-react';

const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

interface SurveyOneProps {
    setPageChangeBtn: Function;
}

interface SurveyOneFormValues {
    title: string;
    survey_format: string;
    // description: string;
}

const surveyOneFormSchema = yup.object().shape({
    title: yup
        .string()
        .required("Survey Name is required"),
    survey_format: yup
        .string()
        .required("Survey Format is required"),
});

const SurveyOne = ({ setPageChangeBtn }: SurveyOneProps) => {

    const { register, control, setValue, handleSubmit, errors, reset } = useForm<SurveyOneFormValues>({
        resolver: yupResolver(surveyOneFormSchema),
        defaultValues: {
            title: '',
            survey_format: '',
            // description: ''
        },
    });


    const editorRef: any = useRef(null);

    const surveyApi = useAppSurveyApi()
    const surveyAction = useAppSurveyAction()
    const toast = useToaster()
    const surveyDetails = useAppSurveyDetailsSelector()
    const history = useHistory()

    const { slug } = useParams<any>()
    // const { id } = useParams<any>()
    // const surveyId = id ? cryptr.decrypt(id) : null

    const goBack = () => {
        history.goBack()
    }

    const onSubmit = (values: any) => {console.log({values})
        let params: any = {
            title: values.title,
            survey_format: values.survey_format,
            description: editorRef?.current ? editorRef?.current.getContent() : null
        }
        // console.log({params})
        if (slug && surveyDetails?.id) {
            params["survey_id"] = surveyDetails?.id
            surveyApi.callEditSurvey(
                params,
                (message: string, resp: any) => {
                    console.log({ resp })
                    // toast.success(message)
                    surveyAction.resetSurveyDetails()
                    setPageChangeBtn("survey_two")
                },
                (message: string) => {
                    toast.error(message)
                }
            )
        }
        else {
            surveyApi.callCreateSurvey(
                params,
                (message: string, resp: any) => {
                    console.log({ resp })
                    // toast.success(message)
                    surveyAction.resetSurveyDetails()
                    setPageChangeBtn("survey_two")
                },
                (message: string) => {
                    toast.error(message)
                }
            )
        }
    }

    const getSurveyDetails = () => {
        let params = {
            slug
        }
        surveyApi.callGetSurveyDetails(
            params,
            (message: string, resp: any) => {
                // console.log({resp})
            },
            (message: string) => {
                // toast.error(message)
            }
        )
    }

    // const onChange = (e: any, description: any) => {
    //     if (description) {
    //       let editorContent = description.getContent({ format: 'text' })
    //       if (editorContent.length <= MAX_LEN * 10) {
    //         changeStoryCount(editorContent)
    //         setErrorMessage('')
    //       }
    //       else {
    //         setStoryCount(0)
    //         setErrorMessage('Character limit exceeded')
    //       }
    //     }
    // }

    useEffect(() => {
        if (slug) {
            getSurveyDetails()
        }
        else {
            setValue("title", '')
            setValue("survey_format", '')
            // setValue("description", '')
        }
        surveyAction.createSurveyPage("survey_one")
    }, [slug])

    useEffect(() => {
        console.log({ surveyDetails })
        if (surveyDetails && slug) {
            if (surveyDetails.title) {
                setValue("title", surveyDetails.title)
            }
            if (surveyDetails.survey_format) {
                setValue("survey_format", String(surveyDetails.survey_format))
            }
            // if (surveyDetails.description) {
            //     setValue("description", String(surveyDetails.description))
            // }
        }
    }, [surveyDetails])

    console.log({errors})

    return (
        <>
            <SurveyBanner page={1} />

            <section className="create-survey-page">
                <div className="container">
                    <div className="create-survey-form-wrap">
                        <div className='heading-one-line'>
                            <h2 className="survey-title">START YOUR SURVEY</h2>
                            {/* <Link to={URLS.SURVEY.SURVEY_LANDING} className="create-survey-back-link">Back</Link> */}
                            {window.screen.width > 767 && <a onClick={goBack} className="create-survey-back-link" style={{ cursor: "pointer" }}>Back</a>}
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <div className="mb-3">
                                <label htmlFor="" className="form-label">
                                    Survey Name<span style={{ color: 'red' }}>*</span>
                                </label>
                                {/* <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Write your survey name"
                                /> */}
                                <Controller
                                    control={control}
                                    className="form-control"
                                    name="title"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <FormTextInput
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            type="text"
                                            error={errors.title}
                                            placeholder="Write your survey name"
                                        />
                                    )}
                                />
                                <div className="my-3">
                                    <label htmlFor="" className="form-label">
                                        Survey Description
                                    </label>
                                    {/* <Controller
                                        control={control}
                                        name="description"
                                        className="form-control"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <FormTextAreaInput
                                                name={name}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                                inputRef={ref}
                                                rows={4}
                                                type="text"
                                                error={errors.description}
                                                placeholder=""
                                            />
                                        )}
                                    /> */}
                                    <Editor
                                        apiKey="2utn329kvrgai26fg6qt532okdsdvy9m1lgfk4ss1jaytsu9"
                                        onInit={(evt: any, description: any) => {
                                            editorRef.current = description
                                            if (surveyDetails && surveyDetails.description) {
                                                description.setContent(surveyDetails.description)
                                            }
                                        }}
                                        initialValue={surveyDetails && surveyDetails.description ? surveyDetails.description : null}
                                        // onEditorChange={onChange}
                                        init={{
                                            height: 300,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar: 'undo redo | formatselect | ' +
                                                'bold italic underline | alignleft aligncenter ' +
                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                'removeformat | help',
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="mb-3 d-none">
                                <label htmlFor="" className="form-label">
                                    Survey Category
                                </label>
                                <select name="" id="" className="form-select">
                                    <option value="">Choose survey category</option>
                                </select>
                            </div>
                            <div className="choose-survey-format-wrap">
                                <h2>Choose a survey format<span style={{ color: 'red' }}>*</span></h2>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="create-survey-radio">
                                            <input type="radio" id="myRadio1" name={"survey_format"} ref={register} value="1" />
                                            <label htmlFor="myRadio1">
                                                <p>
                                                    <em>Classic</em>
                                                    <span>Show all questions on a page at once</span>
                                                </p>
                                                <img src="images/reader-outline.svg" alt="" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="create-survey-radio">
                                            <input type="radio" id="myRadio2" name={"survey_format"} ref={register} value="2" />
                                            <label htmlFor="myRadio2">
                                                <p>
                                                    <em>One question at a time</em>
                                                    <span>Automatically scroll to the next question</span>
                                                </p>
                                                <img src="images/albums-outline.svg" alt="" />
                                            </label>
                                        </div>
                                    </div>
                                    {errors && errors.survey_format && errors.survey_format.message ? <div className="invalid-feedback">{errors.survey_format.message}</div> : null}
                                </div>
                                <div className="create-survey-btn-wrap">
                                    {window.screen.width <= 767 && <a onClick={goBack} className="create-survey-back-link" style={{ cursor: "pointer" }}>Back</a>}
                                    <button className="btn btn-primary"
                                        type="submit"
                                    // onClick={() => setPageChangeBtn("survey_two")}
                                    >
                                        {slug ? "Update" : "Create"} Survey
                                        <img className="fa" src="/images/arrow-forward-outline.svg" />
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SurveyOne