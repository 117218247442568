import React from 'react'
import ForgotPasswordComponent from '../../components/login-signup/ForgotPassword'

export default function ForgotPassword() {

  return (
    <div>
      <ForgotPasswordComponent />
    </div>
  );
}
