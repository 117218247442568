import React from 'react';

const LazyStartFundraisingPage = React.lazy(() =>
  import(/* webpackChunkName: "StartFundraisingPage" */ './startfundraisingpage')
);

const StartFundraisingPage = (props: Record<string, any>) => (
  <React.Suspense fallback={<div className="loader"></div>}>
    <LazyStartFundraisingPage {...props} />
  </React.Suspense>
);

export default StartFundraisingPage