import React from 'react'
import EditMyFundraising from '../../../components/fundraising/my-causes/EditMyFundraising'

export default function MyFundraisingDashboardPage() {
  return (
    <React.Fragment>
       <EditMyFundraising />
    </React.Fragment>
  )
}
