import React, { useEffect } from "react";
import NavbarBlankMidSec from "./NavbarBlankMidSec";
import NavbarFundraisingMidSec from "./NavbarFundraisingMidSec";
import NavbarLeftLogo from "./NavbarLeftLogo";
import NavbarPetitionMidSec from "./NavbarPetitionMidSec";
import NavbarRightLoginButton from "./NavbarRightLoginButton";
import NavbarSearchBtn from "./NavbarSearchBtn";
import NavbarSurveyMidSec from "./NavbarSurveyMidSec";

interface NavbarMainProps {
  section: string;
}

const NavbarMain = ({ section }: NavbarMainProps) => {
  
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
    <>
      {section == "home" ?
        <nav className="navbar navbar-expand-lg homeNav">
          <div className="container-fluid">
            <NavbarLeftLogo section={section} />
            <div className="collapse navbar-collapse" id="navbarScroll">
              <form className="d-flex">
                <NavbarRightLoginButton />
              </form>
            </div>
          </div>
        </nav>
        :
        <nav className="navbar navbar-expand-lg petetionLanding">
          <div className="container-fluid">
            <NavbarLeftLogo section={section} />
            <div className="collapse navbar-collapse" id="navbarScroll">
              {section == "petition" && <NavbarPetitionMidSec />}
              {section == "fundraising" && <NavbarFundraisingMidSec />}
              {section == "login" && <NavbarBlankMidSec />}
              {section == "survey" && <NavbarSurveyMidSec />}
              <form className="d-flex">
                {section == "petition" || section == "fundraising"  || section == "survey" ?
                  <NavbarSearchBtn section={section}/>
                  :
                  <div className="nav_search">
                  </div>
                }
                <NavbarRightLoginButton />
              </form>
            </div>
          </div>
        </nav>
      }
    </>
  )
}

export default NavbarMain