import React, { useState } from "react";
// import { Link, useHistory } from "react-router-dom";
import { useUserApi } from "../../_common/hooks/api/user/appUserApiHook";
import * as Yup from "yup";
// import { URLS } from "../../_config";
import FormTextInput from "src/_common/components/form-elements/textinput/formTextInput";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToaster } from "../../_common/hooks/common/appToasterHook";
import NavbarMain from "../common/navbar/NavbarMain";
import RightStickyBtn from "../common/RightStickyBtn";
import Footer from "../common/Footer";
import { useHistory } from "react-router";
import { URLS } from "src/_config";
import MetaDecorator from "../common/MetaDecorator";

interface ForgotPasswordFormValues {
  email: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is invalid"),
});

const ForgotPassword = () => {
  const { control, errors, handleSubmit, setValue } = useForm<ForgotPasswordFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
    },
  });

  const toast = useToaster();
  const userApi = useUserApi();
  const history = useHistory();

  const onSubmit = (values: ForgotPasswordFormValues) => {
    const params = {
      email: values.email?.trim(),
    }
    userApi.callForgotPassword(
      params,
      (message:any)=>{
        setValue("email",'')
        toast.success(message)
        setTimeout(() => {
          history.push(URLS.USER.LOGIN)
        }, 3000);
      },
      (message:any,resp:any)=>{
        console.log(resp,message)
        toast.error(message)
      },
    )
  }

  return (
    <>

      <MetaDecorator
        title={"Forgot Password"}
        description={"Forgot Password"}
      />

      <NavbarMain section={"login"} />
      <RightStickyBtn />

      <section className="inner-page">
        <div className="container">
          <div className="login-signup-box mw-400">
            <h2 className="section-title">Forgot Password</h2>
            <div className="form-wrap">
              <div className="mb-3">
                <label className="form-label">Enter your registered email address<span style={{ color: 'red' }}>*</span></label>
                <Controller
                  control={control}
                  name="email"
                  render={({ onChange, onBlur, value, name, ref }) => (
                    <FormTextInput
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      inputRef={ref}
                      type="text"
                      error={errors.email}
                      placeholder="Email"
                    />
                  )}
                />
              </div>
              <div className="btn-wrap">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleSubmit(onSubmit)}
                >
                  Continue{" "}
                  <img
                    className="fa"
                    src={"/images/arrow-forward-outline.svg"}
                    alt="forward"
                  />
                </button>
              </div>      
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ForgotPassword;
