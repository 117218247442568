import { call, put } from 'redux-saga/effects';
import {ACTIONS, API_URL, STORAGE } from '../_config'
import { CallApi } from './api/callApi';

export function* login(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.LOGIN, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      localStorage.setItem(STORAGE, resp.data.data.token);
      yield put({
        type: ACTIONS.USER.LOGIN,
        payload: {
          user: resp.data.data.user,
          address: resp.data.data.address,
          token: resp.data.data.token,
        }
      })
      
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* register(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.REGISTER, data);console.log({resp})
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      // localStorage.setItem(STORAGE, resp.data.data.token);
      yield put({
        type: ACTIONS.USER.REGISTER,
        payload: {
          user: resp.data.data,
          // user: resp.data.data.user,
          // token: resp.data.data.token,
        }
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* updateUserInfo(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.UPDATE_USER_INFO, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.USER.ME,
        payload: {
          user: resp.data.data.user,
        }
      })
      yield put({
        type: ACTIONS.USER.ADDRESS,
        payload: {
          address: resp.data.data.address,
        }
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* changePassword(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.CHANGE_PASSWORD, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
      // console.log("try if")
    } else {
      // console.log("try else")
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    // console.log("catch",e)
    // action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    action && action.callbackError && action.callbackError(e);
  }
}

export function* deactivateAccount(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.DEACTIVATE_ACCOUNT, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* profilePicUpload(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.PROFILE_PIC_UPLOAD, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.USER.ME,
        payload: {
          user: resp.data.data.details.user,
        }
      })
      yield put({
        type: ACTIONS.USER.ADDRESS,
        payload: {
          address: resp.data.data.details.address,
        }
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* profilePicDelete(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.PROFILE_PIC_DELETE, null, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      console.log(resp.data.data)
      yield put({
        type: ACTIONS.USER.ME,
        payload: {
          user: resp.data.data,
        }
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* socialLogin(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.SOCIAL_LOGIN, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      localStorage.setItem(STORAGE, resp.data.data.token);
      yield put({
        type: ACTIONS.USER.LOGIN,
        payload: {
          user: resp.data.data.user,
          address: resp.data.data.address,
          token: resp.data.data.token,
        }
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* forgotPassword(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.FORGOT_PASSWORD, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    // action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    action && action.callbackError && action.callbackError(e);
  }
}

export function* setPassword(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.SET_PASSWORD, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}