import React from 'react'
import WordsOfSupport from 'src/components/fundraising/words-of-support/WordsOfSupport'

export default function WordOfSupportPage() {
  return (
    <React.Fragment>
       <WordsOfSupport />
    </React.Fragment>
  )
}
