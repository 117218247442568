import React from 'react'
import BankAccountFail from 'src/components/fundraising/bank-account/BankAccountFail'

export default function BankAccountFailPage() {
  return (
    <React.Fragment>
       <BankAccountFail />
    </React.Fragment>
  )
}
