import React from 'react'
import { Link } from 'react-router-dom'
import Footer from 'src/components/common/Footer'
import NavbarMain from 'src/components/common/navbar/NavbarMain'
import RightStickyBtn from 'src/components/common/RightStickyBtn'
import { useAppFundraisingDetailsSelector } from 'src/_common/hooks/selectors/fundraising/fundraisingSelector'
import { CRYPTO_SECRET_KEY } from 'src/_config'

const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

const BankAccountFail = () => {

    const fundraisingDetails = useAppFundraisingDetailsSelector();

    return (
        <>
            <NavbarMain section={"fundraising"} />

            <RightStickyBtn />

            <section className="inner-page">
                <div className="container">
                    {
                        fundraisingDetails && fundraisingDetails?.details?.slug ?
                        <Link to={`/bank-account/${fundraisingDetails?.details?.slug}`}> Go back to bank page</Link> : null
                    }
                    <h1 className="alert-danger alert">Sorry Payment Failed</h1>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default BankAccountFail