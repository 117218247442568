import React from 'react'
import FundraisingDetails from 'src/components/fundraising/fundraising-details/FundraisingDetails'

export default function FundraisingDetailsPage() {
  return (
    <React.Fragment>
       <FundraisingDetails/>
    </React.Fragment>
  )
}
