import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import Footer from "src/components/common/Footer";
import NavbarMain from "src/components/common/navbar/NavbarMain";
import RightStickyBtn from "src/components/common/RightStickyBtn";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import FormTextInput from "src/_common/components/form-elements/textinput/formTextInput";
import FormTextAreaInput from "src/_common/components/form-elements/textarea/textareaInput";
import CheckboxInput from "src/_common/components/form-elements/checkboxinput/checkboxInput";
import FormMaskedInput from "src/_common/components/form-elements/maskedInput/maskedInput";
import { useAppFundraisingApi } from "src/_common/hooks/api/fundraising/appFundraisingApiHook";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import { CRYPTO_SECRET_KEY } from "src/_config/site_statics";
import { useAppFundraisingDetailsSelector } from "src/_common/hooks/selectors/fundraising/fundraisingSelector";
import { allowNumberOnly } from "src/_config";
import HomePageModal from "../../home/HomePageModal";
import MetaDecorator from "src/components/common/MetaDecorator";
import LoginSignup from "src/components/login-signup/LoginSignup";
import { useAppUserAuthSelector, useAppUserDetailsSelector } from "src/_common/hooks/selectors/user/userSelector";

const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

interface MakeDonationFormValues {
  // fundraiser_id: string,
  anonymous_donation: boolean;
  amount: string;
  // card_id: string,
  card_name: string;
  card_number: string;
  card_exp: string;
  // card_exp_month: string,
  // card_exp_year: string,
  card_cvc: string;
  // save_card_info: string,
  // email: string;
  // country_id: string,
  zip_code: string;
  allowComment: string;
  comment: string;
}

const MakeDonationFormSchema = yup.object().shape({
  amount: yup.string()
    .required("Amount is required"),
  card_name: yup.string().required("Name on card is required"),
  card_number: yup.string().required("Card number is required"),
  card_exp: yup.string().required("Card expiry is required"),
  card_cvc: yup.string().required("CVV is required"),
  // email: yup
  //   .string()
  //   .email("Provide a valid email"),
  zip_code: yup.string().required("Postal code is required"),
  allowComment: yup.string(),
  // comment: yup.string()
  //   .when("allowComment", (key: any) => {
  //     console.log(key.value)
  //     if (key && key == '1')
  //       return yup.string().required("Comment is required")
  //   }),
});

const FundraisingDonate = () => {
  const { slug } = useParams<any>();
  // const fundraiser_id = cryptr.decrypt(id)
  const fundraisingDetails = useAppFundraisingDetailsSelector()
  const fundraisingApi = useAppFundraisingApi()
  const toast = useToaster()
  const history = useHistory()
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const [popupSetting, setPopupSetting] = useState<any>()
  const isAuth = useAppUserAuthSelector()
  const userDetails = useAppUserDetailsSelector()

  const { register, control, setValue, getValues, watch, handleSubmit, errors } =
    useForm<MakeDonationFormValues>({
      resolver: yupResolver(MakeDonationFormSchema),
      defaultValues: {
        anonymous_donation: false,
        amount: "",
        card_name: "",
        card_number: "",
        card_exp: "",
        card_cvc: "",
        // email: "",
        zip_code: "",
        allowComment: "",
        comment: "",
      },
    });

  const donationAmount = watch('amount')

  const handleKeyPress = (event: any) => {
    // console.log("keypreess", event)
    // return (event.charCode == 8 ||  event.charCode >= 48 && event.charCode <= 57)
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  }

  const openPopup = () => {
    setShowPopup(true)
  }

  const closePopup = () => {
    setShowPopup(false)
    history.push(`/fundraising/${slug}`)
  }

  const callMakeDonation = (params?: any) => {
    fundraisingApi.callMakeFundraisingDonation(params, (message: string, resp: any) => {
      // console.log({resp})
      // toast.success(message)
      let x = {
        show_popup_option: 3,
        time: 5,
        title: 'Success',
        description: message
      }
      setPopupSetting(x)
      openPopup()
      // history.push(`/fundraising-details/${id}`)
    }, (message: string) => {
      toast.error(message)
    });
  }

  const onSubmit = (values?: any) => {
    // console.log({ values });

    // const card_exp_month = (values.card_exp).split(" ")[0]
    // const card_exp_year = (values.card_exp).split(" ")[1]

    let formData = new FormData();
    formData.append("fundraiser_id", fundraisingDetails?.details?.id);
    formData.append("anonymous_donation", values.anonymous_donation ? '1' : '0');
    formData.append("amount", values.amount);
    if (values.amount == 0) {
      toast.error("Please enter a valid amount")
      return
    }
    // formData.append("category_id", values.category_id);
    formData.append("zip_code", values.zip_code);
    formData.append("card_name", values.card_name);
    formData.append("card_number", (values.card_number).split(" ").join(""));
    formData.append("card_exp_month", (values.card_exp).split("/")[0]);
    formData.append("card_exp_year", (values.card_exp).split("/")[1]);
    if (isNaN(values.card_exp.split("/")[0]) || isNaN(values.card_exp.split("/")[1])) {
      toast.error("Please enter valid card expiry date")
      return
    }
    formData.append("card_cvc", values.card_cvc);
    // formData.append("email", values.email);
    formData.append("comment", values.comment);

    //need to know what will be sent
    formData.append("card_id", '');
    formData.append("save_card_info", '0');
    formData.append("country_id", '231');

    callMakeDonation(formData)
  };

  const getFundraisingDetails = (slug: any) => {
    const params = {
      slug,
    };
    fundraisingApi.callGetFundraisingDetails(
      params,
      (message: string, resp: any) => {
        // console.log({ resp });
        if (resp.data.details.fundraising_for == 3 && resp.data.details.charity_id != 0) {
          // console.log("called")
        }
      },
      (message: string) => {
        // toast.error(message)
      }
    );
  };

  useEffect(() => {
    if (fundraisingDetails?.settings?.allow_suppoter) {
      setValue("allowComment", fundraisingDetails?.settings?.allow_suppoter)
    }
  }, [fundraisingDetails])

  useEffect(() => {
    if (slug) {
      getFundraisingDetails(slug)
    }
  }, [slug])

  return (
    <>

      <MetaDecorator
        title={fundraisingDetails?.details?.title + " : Donate"}
        description={"Donate to " + fundraisingDetails?.details?.title}
        imageUrl={fundraisingDetails?.details?.fundraiser_image?.thumb}
      />

      <NavbarMain section={"fundraising"} />

      <RightStickyBtn />

      <section className="inner-page">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="card-setup-panel">
                <div className="row">
                  <div className="col-sm-9">
                    <div className="card-setup-block card-setup-left">
                      <Link
                        className="btn btn-secondary"
                        to={`/fundraising/${slug}`}
                      >
                        <i className="fa fa-angle-left me-2" />
                        Return to fundraiser <span className="chat_icon" />
                      </Link>
                      <div className="d-flex mt-4 card-setup-profile-pan mb-4">
                        <div className="card-setup-avatar">
                          <img src={fundraisingDetails?.details?.fundraiser_image?.original} />
                        </div>
                        <div className="card-setup-profile-content">
                          <p>
                            You're supporting{" "}
                            <strong>
                              {fundraisingDetails?.details?.title}
                            </strong>
                          </p>
                        </div>
                      </div>
                      <div className="card-setup-donation-amt">
                        <h5>Enter your donation<span style={{ color: 'red' }}>*</span></h5>
                        <div className="card-setup-donation-amt-inner">
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <h5>$</h5>
                              <h6>USD</h6>
                            </div>
                            <div className="donation-amt-fld">
                              <Controller
                                control={control}
                                name="amount"
                                render={({
                                  onChange,
                                  onBlur,
                                  value,
                                  name,
                                  ref,
                                }) => (
                                  <FormTextInput
                                    name={name}
                                    onChange={e => {
                                      onChange(e)
                                      allowNumberOnly(e, name, setValue)
                                    }}
                                    onBlur={onBlur}
                                    // value={Math.abs(Number(value))}
                                    value={value}
                                    // maxLength={MAX_LEN}
                                    // min={0}
                                    // onKeyPress={(event: any) => handleKeyPress(event)}
                                    inputRef={ref}
                                    type="tel"
                                    // pattern=" 0+\.[0-9]*[1-9][0-9]*$"
                                    disabled={!isAuth}
                                    error={errors.amount}
                                    placeholder="0"
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <h6>
                          We protect your donation with the Rural America In Action
                          Giving Guarantee
                        </h6>
                      </div>
                      {!isAuth ?
                        <LoginSignup page="fundDonate"/>
                        :
                        <>
                          <i className="fa fa-info-circle" />
                          <span style={{marginLeft: "3px", fontWeight: "bold"}}>You are logged in with {userDetails?.full_name}</span>
                        </>
                      }
                      <div className="payment-method-pan-outer pt-4 pb-4">
                        <h3>Payment method</h3>
                        <div className="payment-method-pan">
                          {/* <div className="mb-3 d-none">
                            <Controller
                              control={control}
                              name="email"
                              className="form-control"
                              render={({
                                onChange,
                                onBlur,
                                value,
                                name,
                                ref,
                              }) => (
                                <FormTextInput
                                  name={name}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  value={value}
                                  inputRef={ref}
                                  type="text"
                                  error={errors.email}
                                  placeholder="Email Address*"
                                />
                              )}
                            />
                          </div> */}
                          <div className="row mb-3">
                            <div className="col-sm-6 card-no-fld">
                              <Controller
                                control={control}
                                name="card_number"
                                className="form-control"
                                render={({
                                  onChange,
                                  onBlur,
                                  value,
                                  name,
                                  ref,
                                }) => (
                                  <FormMaskedInput
                                    name={name}
                                    mask="9999 9999 9999 9999"
                                    maskPlaceholder="4242 4242 4242 4242"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    inputRef={ref}
                                    type="tel"
                                    error={errors.card_number}
                                    placeholder="Card Number*"
                                    disabled={!isAuth}
                                  />
                                )}
                              />
                            </div>
                            <div className="col-sm-3 card-year-fld">
                              <Controller
                                control={control}
                                name="card_exp"
                                className="form-control"
                                render={({
                                  onChange,
                                  onBlur,
                                  value,
                                  name,
                                  ref,
                                }) => (
                                  <FormMaskedInput
                                    name={name}
                                    mask="99/99"
                                    maskPlaceholder="MM/YY"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    inputRef={ref}
                                    type="tel"
                                    error={errors.card_exp}
                                    placeholder="MM/YY*"
                                    disabled={!isAuth}
                                  />
                                )}
                              />
                            </div>
                            <div className="col-sm-3">
                              <Controller
                                control={control}
                                name="card_cvc"
                                className="form-control"
                                render={({
                                  onChange,
                                  onBlur,
                                  value,
                                  name,
                                  ref,
                                }) => (
                                  <FormTextInput
                                    name={name}
                                    onChange={e => {
                                      onChange(e)
                                      allowNumberOnly(e, name, setValue)
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    inputRef={ref}
                                    type="tel"
                                    maxLength={4}
                                    // onKeyPress={(event: any) => handleKeyPress(event)}
                                    error={errors.card_cvc}
                                    placeholder="CVV*"
                                    disabled={!isAuth}
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className="mb-3">
                            <Controller
                              control={control}
                              name="card_name"
                              className="form-control"
                              render={({
                                onChange,
                                onBlur,
                                value,
                                name,
                                ref,
                              }) => (
                                <FormTextInput
                                  name={name}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  value={value}
                                  inputRef={ref}
                                  type="text"
                                  error={errors.card_name}
                                  placeholder="Name on card*"
                                  disabled={!isAuth}
                                />
                              )}
                            />
                          </div>
                          <div className="mb-3">
                            <Controller
                              control={control}
                              name="zip_code"
                              className="form-control"
                              render={({
                                onChange,
                                onBlur,
                                value,
                                name,
                                ref,
                              }) => (
                                <FormTextInput
                                  name={name}
                                  onChange={e => {
                                    onChange(e)
                                    allowNumberOnly(e, name, setValue)
                                  }}
                                  onBlur={onBlur}
                                  value={value}
                                  inputRef={ref}
                                  type="tel"
                                  // pattern="^[0-9\b]+$"
                                  error={errors.zip_code}
                                  // onKeyPress={(event: any) => handleKeyPress(event)}
                                  // onKeyUp={(event: any) => handleKeyUp(event,name)}
                                  placeholder="Postal Code*"
                                  disabled={!isAuth}
                                />
                              )}
                            />
                          </div>
                          <input name="allowComment" type="text" ref={register} hidden />
                          {/* {fundraisingDetails?.settings?.allow_suppoter == "1" ?
                            <div className="mb-3">
                              <Controller
                                control={control}
                                name="comment"
                                className="form-control"
                                render={({
                                  onChange,
                                  onBlur,
                                  value,
                                  name,
                                  ref,
                                }) => (
                                  <FormTextAreaInput
                                    name={name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    inputRef={ref}
                                    rows={3}
                                    type="text"
                                    error={errors.comment}
                                    placeholder="Comment*"
                                  />
                                )}
                              />
                            </div>
                            : null
                          } */}
                          <div className="mb-3 form-check">
                            <Controller
                              control={control}
                              name="anonymous_donation"
                              className="form-check-input"
                              render={({
                                onChange,
                                onBlur,
                                value,
                                name,
                                ref,
                              }) => (
                                <CheckboxInput
                                  name={name}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  value={value}
                                  inputRef={ref}
                                  error={errors.anonymous_donation}
                                  // disabled={anonymousCheck}
                                  label="Anonymous Donation"
                                  disabled={!isAuth}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <p className="mt-3">
                        Thank you for donating to this fundraiser and supporting rural Americans.
                      </p>
                      <div className="payment-donate-btn d-flex align-items-center">
                        <button
                          className="btn btn-primary setup-btn"
                          onClick={handleSubmit(onSubmit)}
                          disabled={!isAuth}
                        >
                          Donate Now
                        </button>
                        <p className="ms-3">
                          <i className="fa fa-lock" aria-hidden="true" /> Secure
                          donation
                        </p>
                      </div>
                      <p className="privacy-text">
                        By continuing, you agree with{" "}
                        <a href="#">Rural America In Action</a> and{" "}
                        <a href="#">privacy policy</a>.
                      </p>
                      <div className="protect-donation-pan pt-4 mt-4">
                        <div className="d-flex">
                          <div className="shield-icon me-3">
                            <i className="fa fa-shield" aria-hidden="true" />
                          </div>
                          <div>
                            <h4>Rural America In Action protects your donation</h4>
                            <p>
                              We guarantee you a full refund for up to a year in
                              the rare case that fraud occurs.{" "}
                              <a href="#">
                                See our Rural America In Action Giving Guarantee.
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="card-setup-block card-setup-donation">
                      <h3 className="mb-4">Your Donation</h3>
                      <div className="d-flex justify-content-between donation-amt-row">
                        <p>Your Donation</p>
                        <p>${donationAmount ? donationAmount.toLocaleString() : 0}</p>
                      </div>
                      <div className="d-flex justify-content-between donation-total-row pt-3">
                        <p>Total due today</p>
                        <p>${donationAmount ? donationAmount.toLocaleString() : 0}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <HomePageModal
        shouldShow={showPopup}
        onClose={closePopup}
        popupSetting={popupSetting}
      />
      <Footer />
    </>
  );
};

export default FundraisingDonate;
