import React from "react";
import { Link } from "react-router-dom";
import StartPetitionButton from "src/components/petition/common/StartPetitionButton";
import StartSurveyButton from "src/components/survey/common/StartSurveyButton";
import { useAppUserAction } from "src/_common/hooks/actions/user/appUserActionHook";
import { useAuthStatus } from "src/_common/hooks/authHook";
import { URLS } from "src/_config";

const NavbarSurveyMidSec = () => {

  const userAction = useAppUserAction()
  const isAuth = useAuthStatus()

  const setLink = () => {
    if (!isAuth) {
      userAction.setLink(URLS.SURVEY.MY_SURVEY)
    }
  }

  return (
    <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll" >
      <li className="nav-item">
        <Link className="nav-link" to={URLS.HOME}>
          Home
        </Link>
      </li>
      <li className="nav-item">
        <StartSurveyButton className={"nav-link"} noImage={true} text={"Start a Survey"}/>
        {/* <Link className="nav-link" to={"#"}>
          Start  a Survey
        </Link> */}
      </li>
      <li className="nav-item">
        <Link className={"nav-link"+(window.location.pathname==URLS.SURVEY.MY_SURVEY?" active":"")} to={URLS.SURVEY.MY_SURVEY}  onClick={setLink}>
          My Survey
        </Link>
      </li>
      <li className="nav-item">
        <Link className={"nav-link"+(window.location.pathname==URLS.SURVEY.BROWSE_SURVEY?" active":"")} to={URLS.SURVEY.BROWSE_SURVEY} aria-disabled="true">
          Browse
        </Link>
      </li>
    </ul>
  );
};

export default NavbarSurveyMidSec;
