import { call, put } from "redux-saga/effects";
import { ACTIONS, API_URL, STORAGE } from "../_config";
import { CallApi } from "./api/callApi";

export function* fundraisingCategoryList(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET,API_URL.FUNDRAISING.CATEGORY_LIST,data,true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      yield put({
        type: ACTIONS.FUNDRAISING.CATEGORY_LIST,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* startFundraising(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST,API_URL.FUNDRAISING.START_FUNDRAISING,data,true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      // yield put({
      //   type: ACTIONS.PETITION.CATEGORY_LIST,
      //   payload: resp.data.data
      // })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* fundraisingList(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.FUNDRAISING.LISTING, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      yield put({
        type: ACTIONS.FUNDRAISING.LIST,
        payload: resp.data.data.list,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* fundraisingDetails(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.FUNDRAISING.DETAILS, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      yield put({
        type: ACTIONS.FUNDRAISING.DETAILS,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* fundraisingCharityList(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.FUNDRAISING.CHARITY_LIST, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      yield put({
        type: ACTIONS.FUNDRAISING.CHARITY_LIST,
        payload: resp.data.data.list,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* fundraisingCommentsList(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.FUNDRAISING.COMMENT_LIST, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* viewAllDonors(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.FUNDRAISING.VIEW_ALL_DONORS, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* viewTopDonors(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.FUNDRAISING.VIEW_TOP_DONORS, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getFundraisingTips(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET,API_URL.FUNDRAISING.TIPS,data,true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      yield put({
        type: ACTIONS.FUNDRAISING.TIPS,
        payload: resp.data.data.list,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* makeFundraisingDonation(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.FUNDRAISING.MAKE_DONATION, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* myFundraisingList(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.FUNDRAISING.MY_FUNDRAISERS_LIST, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      yield put({
        type: ACTIONS.FUNDRAISING.MY_FUNDRAISERS_LIST,
        payload: resp.data.data.list,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* editMyFundraising(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.FUNDRAISING.MY_FUNDRAISER_EDIT, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* createUpdate(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.FUNDRAISING.CREATE_UPDATE, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getUpdatesList(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.FUNDRAISING.UPDATES_LIST, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* removeUpdate(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.FUNDRAISING.REMOVE_UPDATE, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* inviteSupporters(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.FUNDRAISING.INVITE_SUPPORTER, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getInviteSupportersList(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.FUNDRAISING.INVITED_SUPPORTERS_LIST, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* addBankAccount(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.FUNDRAISING.BANK_ACCOUNT, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getBankAccountDetails(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.FUNDRAISING.BANK_ACCOUNT_DETAILS, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      yield put({
        type: ACTIONS.FUNDRAISING.BANK_ACCOUNT_DETAILS,
        payload: resp.data.data.list,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* deleteBankAccount(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.FUNDRAISING.BANK_ACCOUNT_DELETE, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* connectWithStripe(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.FUNDRAISING.CONNECT_WITH_STRIPE, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* connectWithStripeSuccess(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.FUNDRAISING.CONNECT_WITH_STRIPE_SUCCESS, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* topDonatedFundraiser(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.FUNDRAISING.TOP_DONATED_FUNDRAISER, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* fundCsvExport(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST,API_URL.FUNDRAISING.CSV_EXPORT, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* deleteFundraiser(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST,API_URL.FUNDRAISING.DELETE_FUNDRAISER, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* fundraiserSettings(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST,API_URL.FUNDRAISING.FUNDRAISING_SETTINGS, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      yield put({
        type: ACTIONS.FUNDRAISING.DETAILS,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* updateFundraiserSettings(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST,API_URL.FUNDRAISING.UPDATE_FUNDRAISING_SETTINGS, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* requestForPayout(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST,API_URL.FUNDRAISING.REQUEST_FOR_PAYOUT, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* closeFundraiser(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST,API_URL.FUNDRAISING.CLOSE_FUNDRAISER, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}