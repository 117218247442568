import { ActionExtended } from "../../../_common/interfaces/ActionExtended";
import { ACTIONS } from "../../../_config";

export interface FundraisingReducer {
  fundraisingCategoryList: any;
  fundraisingCharityList: any;
  startFundraising: any;
  fundraisingList: any;
  fundraisingDetails: any;
  tips: any;
  myFundraisingList: any;
  bankAccountDetails: any;
}

const initialState: FundraisingReducer = {
  fundraisingCategoryList: null,
  fundraisingCharityList: null,
  startFundraising: null,
  fundraisingList: null,
  fundraisingDetails: null,
  tips: null,
  myFundraisingList: null,
  bankAccountDetails: null,
};

const fundraisingReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.FUNDRAISING.CATEGORY_LIST:
      return {
        ...state,
        fundraisingCategoryList: action.payload,
      };
    case ACTIONS.FUNDRAISING.CHARITY_LIST:
      return {
        ...state,
        fundraisingCharityList: action.payload,
      };
    case ACTIONS.FUNDRAISING.START_FUNDRAISING:
      let x = state.startFundraising;
      if (x) {
        Object.assign(x, action.payload);
      } else {
        x = action.payload;
      }
      return {
        ...state,
        startFundraising: x,
      };
    case ACTIONS.FUNDRAISING.RESET_START_FUNDRAISING:
      return {
        ...state,
        startFundraising: null,
      };
    case ACTIONS.FUNDRAISING.LIST:
      return {
        ...state,
        fundraisingList: action.payload,
      };
    case ACTIONS.FUNDRAISING.RESET_LIST:
      return {
        ...state,
        fundraisingList: null,
      };
    case ACTIONS.FUNDRAISING.DETAILS:
      return {
        ...state,
        fundraisingDetails: action.payload,
      };
    case ACTIONS.FUNDRAISING.RESET_FUNDRAISING_DETAILS:
      return {
        ...state,
        fundraisingDetails: null
      }
    case ACTIONS.FUNDRAISING.TIPS:
      return {
        ...state,
        tips: action.payload,
      };
    case ACTIONS.FUNDRAISING.MY_FUNDRAISERS_LIST:
      return {
        ...state,
        myFundraisingList: action.payload,
      };
    case ACTIONS.FUNDRAISING.BANK_ACCOUNT_DETAILS:
      return {
        ...state,
        bankAccountDetails: action.payload,
      };
    default:
      return state;
  }
};

export default fundraisingReducer;
