import React from 'react'
import FundraisingDashboard from 'src/components/fundraising/my-fundraising-dashboard/FundraisingDashboard'
import MyFundraisingDashboard from 'src/components/fundraising/my-fundraising-dashboard/MyFundraisingDashboard'

export default function MyFundraisingDashboardPage() {
  return (
    <React.Fragment>
       {/* <FundraisingDashboard /> */}
       <MyFundraisingDashboard />
    </React.Fragment>
  )
}
