import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useToaster } from 'src/_common/hooks/actions/common/appToasterHook'
import { useAppSurveyAction } from 'src/_common/hooks/actions/survey/appSurveyActionHook'
import { useAppSurveyApi } from 'src/_common/hooks/api/survey/appSurveyApiHook'
import { useAppAnswerTypeListSelector, useAppSurveyDetailsSelector } from 'src/_common/hooks/selectors/survey/surveySelector'
import { URLS } from 'src/_config'
import SurveyBanner from './SurveyBanner'
import parse from 'html-react-parser'

interface SurveyPreviewProps {
    setPageChangeBtn: Function;
}

const SurveyPreview = ({ setPageChangeBtn }: SurveyPreviewProps) => {

    const surveyDetails = useAppSurveyDetailsSelector()
    const surveyApi = useAppSurveyApi()
    const surveyAction = useAppSurveyAction()
    const history = useHistory()
    const toast = useToaster()
    const answerTypeList = useAppAnswerTypeListSelector()

    const completeSurvey = () => {
        let params = {
            survey_id: surveyDetails.id
        }
        surveyApi.callSurveyComplete(
            params,
            (message: string, resp: any) => {
                surveyAction.resetSurveyDetails()
                history.push(URLS.SURVEY.MY_SURVEY)
                toast.success(message)
            },
            (message: string) => {
            }
        )
    }

    useEffect(() => {
        surveyAction.createSurveyPage("survey_preview")
    }, [])

    return (
        <>
            <SurveyBanner page={3} />

            <section className="create-survey-page">
                <div className="container">
                    <div className="survey-preview-wrap box-wrap"
                        style={{ backgroundImage: `url(${surveyDetails?.survey_background_image?.id != 0 ? surveyDetails?.survey_background_image?.thumb : ''})` }}
                    >
                        <div className="surver-preview-main-title">{surveyDetails?.title}</div>
                        <div className="survey-preview-top">
                            <h2>{surveyDetails?.page_title}</h2>
                            {surveyDetails?.description ?
                                <p>
                                    {parse(surveyDetails?.description)}
                                </p>
                                :
                                null
                            }
                        </div>
                        {surveyDetails && surveyDetails.questions && surveyDetails.questions.length ?
                            surveyDetails.questions.map((ques: any, index: any) =>
                                <>
                                    <div className="survey-question-wrap">
                                        <div className="survey-question-head">
                                            <h2>{index + 1}. {ques.question}</h2>
                                            <div className='qst-answ-type'>{answerTypeList.find((a: any) => ques?.answer_type?.id == a.id)?.display_name}</div>
                                            {/* <div className="right-actions">
                                                <a onClick={()=>editQuestion(ques)}>Edit</a>
                                                <a onClick={()=>dupicateQuestion(ques.id)}>Duplicate</a>
                                                <a onClick={()=>deleteQuestion(ques.id)}>Delete</a>
                                            </div> */}
                                        </div>
                                        {ques && ques.answer_option && ques.answer_option.length ?
                                            <div className="options-wrap">
                                                <ul>
                                                    {ques.answer_option.map((ans: any, i: any) =>
                                                        <li key={ans.id}>
                                                            {ques?.answer_type?.id == 15 && (i == 0 ? "Min - " : 'Max - ')}{ans.answer_option}
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                            : null
                                        }
                                    </div>
                                    <div className="page-break-wrap">
                                        <hr />
                                        {/* <span>Page Break</span> */}
                                        {/* <div className="page-break-delete"><i className="fa fa-trash" onClick={()=>deletePageBreak(index+1)}></i></div> */}
                                    </div>
                                </>
                            )
                            :
                            <div className="survey-question-wrap">
                                <div className="survey-question-head">
                                    <h2>No Questions added yet</h2>
                                </div>
                            </div>
                        }
                        <div className="survey-preview-top survey-preview-footer">
                            <h2>{surveyDetails?.footer_title}</h2>
                        </div>
                        <div className="submit-btn-wrap">
                            <a onClick={() => setPageChangeBtn("survey_two")} className="btn btn-secondary">
                                Previous
                            </a>
                            {/* <a onClick={completeSurvey} className="btn btn-primary btn-done"> */}
                            <a onClick={completeSurvey} className="btn btn-primary m-2">
                                Done
                            </a>
                        </div>
                    </div>
                </div>
            </section>

        </>

    )
}

export default SurveyPreview