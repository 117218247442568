import { AddPetitionCommentReq,deleteMyPetitionsReq, myPetitionsReq, LikeReasonReq, PetitionCommentsListReq, PetitionListReq, PetitionSignReq, ReplyToCommentReq, ReportReq, AddUpdateReq, EditPetitionReq, TopThreePetitionListReq, CsvExportReq, ChipInReq, ChipInListReq } from 'src/_common/interfaces/ApiReqRes';
// import { AddPetitionCommentReq, deleteMyPetitionsReq, myPetitionsReq, PetitionCommentsListReq, PetitionListReq, PetitionSignReq, ReplyToCommentReq, ReportReq } from 'src/_common/interfaces/ApiReqRes';
import { PetitionDetailsReq } from 'src/_common/interfaces/ApiReqRes';
import { ACTIONS, SAGA_ACTIONS } from '../../../../_config'
import { useApiCall } from '../../common/appApiCallHook'
import { CreatePetitionReq } from '../../../interfaces/ApiReqRes'

export function useAppPetitionApi() {

  const callApi = useApiCall()
  
  const getCategoryList = ( onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PETITION.CATEGORY_LIST, null, onSuccess, onError);
  }
  
  const createPetition = ( data: CreatePetitionReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PETITION.CREATE_PETITION, data, onSuccess, onError);
  }

  const getPetitionList = ( data: PetitionListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PETITION.LIST, data, onSuccess, onError);
  }

  const getPetitionFeaturedTopicList = ( onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PETITION.FEATURED_TOPIC_LIST, null, onSuccess, onError);
  }

  const getPetitionCommentsList = ( data: PetitionCommentsListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PETITION.COMMENT_LIST, data, onSuccess, onError);
  }

  const getPetitionUpdatesList = ( data: PetitionCommentsListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PETITION.UPDATES_LIST, data, onSuccess, onError);
  }

  const addUpdate = ( data: AddUpdateReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PETITION.ADD_UPDATE, data, onSuccess, onError);
  }

  const getPetitionReasonsList = ( data: PetitionCommentsListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PETITION.REASONS_LIST, data, onSuccess, onError);
  }
  
  const getPetitionDetails = ( data: PetitionDetailsReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PETITION.PETITION_DETAILS, data, onSuccess, onError);
  }
  
  const addPetitionComment = ( data: AddPetitionCommentReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PETITION.ADD_COMMENT, data, onSuccess, onError);
  }
  
  const replyToComment = ( data: ReplyToCommentReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PETITION.REPLY_COMMENT, data, onSuccess, onError);
  }
  
  const report = ( data: ReportReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PETITION.REPORT, data, onSuccess, onError);
  }
  
  const petitionSign = ( data: PetitionSignReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PETITION.SIGN_PETITION, data, onSuccess, onError);
  }
  
  const getTips = ( onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PETITION.TIPS, null, onSuccess, onError);
  }
  
  const likeReason = ( data: LikeReasonReq,onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PETITION.LIKE_REASON, data, onSuccess, onError);
  }

  const getMyPetitions = ( data: myPetitionsReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PETITION.MYPETITIONS, data, onSuccess, onError);
  }

  const deleteMyPetitions = ( data: deleteMyPetitionsReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PETITION.DELETE_MYPETITIONS, data, onSuccess, onError);
  }

  const increasePetitionViewCount = ( data: PetitionDetailsReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PETITION.PETITION_VIEW_COUNT, data, onSuccess, onError);
  }

  const petitonDeclareVictory = ( data: deleteMyPetitionsReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PETITION.PETITION_DECLARE_VICTORY, data, onSuccess, onError);
  }
  
  const editPetition = ( data: EditPetitionReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PETITION.EDIT_PETITION, data, onSuccess, onError);
  }

  const getTopThreePetitions = ( data: TopThreePetitionListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PETITION.PETITION_TOP_THREE_LIST, data, onSuccess, onError);
  }

  const csvExport = ( data: CsvExportReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PETITION.CSV_EXPORT, data, onSuccess, onError);
  }

  const chipIn = ( data: ChipInReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PETITION.CHIP_IN, data, onSuccess, onError);
  }

  const chipInList = ( data: ChipInListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PETITION.CHIP_IN_LIST, data, onSuccess, onError);
  }

  return {
    callgetCategoryList: getCategoryList,
    callgetPetitionList: getPetitionList,
    callgetFeaturedTopicList: getPetitionFeaturedTopicList,
    callCreatePetition: createPetition,
    callGetPetitionCommentsList: getPetitionCommentsList,
    callGetPetitionUpdatesList: getPetitionUpdatesList,
    callGetPetitionReasonsList: getPetitionReasonsList,
    callgetPetitionDetails: getPetitionDetails,
    callAddPetitionComment: addPetitionComment,
    callReplyToComment: replyToComment,
    callReport: report,
    callPetitionSign: petitionSign,
    callGetTips: getTips,
    callLikeReason: likeReason,
    callgetMyPetitions: getMyPetitions,
    calldeleteMyPetitions: deleteMyPetitions,
    callAddPetitionViewCount: increasePetitionViewCount,
    callAddUpdate: addUpdate,
    callPetitionDeclareVictory: petitonDeclareVictory,
    callEditPetition: editPetition,
    callGetTopThreePetitions: getTopThreePetitions,
    callCsvExport: csvExport,
    callChipIn: chipIn,
    callChipInList: chipInList,
  }
}