import { toast } from 'react-toastify'

export function useToaster() {
  const show = (message: string) => {
    toast.dismiss()
    toast(message)
  }
  const error = (message: string) => {
    toast.dismiss()
    toast.error(message)
  }
  const success = (message: string) => {
    toast.dismiss()
    toast.success(message)
  }
  const warning = (message: string) => {
    toast.dismiss()
    toast.warning(message)
  }
  const info = (message: string) => {
    toast.dismiss()
    toast.info(message)
  }
  // const dismiss = () => {
  //   toast.dismiss()
  // }
  return { error, show, success, warning, info }
}