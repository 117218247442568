import React from 'react'
import Footer from 'src/components/common/Footer'
import NavbarMain from 'src/components/common/navbar/NavbarMain'
import RightStickyBtn from 'src/components/common/RightStickyBtn'
import StartSurveyButton from '../common/StartSurveyButton'
import SurveyLandingBanner from './SurveyLandingBanner'
import NavbarSearchBtn from "src/components/common/navbar/NavbarSearchBtn";
import MetaDecorator from 'src/components/common/MetaDecorator'

const SurveyLanding = () => {
  return (
    <>

      <MetaDecorator
          title={"Survey Landing"}
          description={"Survey Landing"}
      />

      <NavbarMain section={"survey"} />

      <SurveyLandingBanner />

      <div className="innerContentArea survey-section">
        <div className="container">
          <div className="survey-landing-search">
            <div className="search-box-left">
                <NavbarSearchBtn/>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <div className="survey-box">
                <div className="survey-img-box">
                  <img src="/images/Login-Our-System.jpg" alt="" />
                </div>
                <div className="survey-text">
                  <h2>Login To Our System</h2>
                  {/* <p>
                    Provide better customer experiences while keeping your teams
                    engaged.
                  </p> */}
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="survey-box">
                <div className="survey-img-box">
                  <img src="/images/Create-a-Survey1.png" alt="" />
                </div>
                <div className="survey-text">
                  <h2>Create Survey</h2>
                  {/* <p>
                    Retain your workforce by fostering a world-class employee
                    experience.
                  </p> */}
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="survey-box">
                <div className="survey-img-box">
                  <img src="/images/Share-a-Survey.jpg" alt="" />
                </div>
                <div className="survey-text">
                  <h2>Share Survey</h2>
                  {/* <p>
                    Retain your workforce by fostering a world-class employee
                    experience.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="survey-btn-wrap">
            {/* <a className="btn btn-primary" href="create-survey-1.html">
              Get Started{" "}
              <img className="fa" src="/images/arrow-forward-outline.svg" />
            </a> */}
            <StartSurveyButton className={"btn btn-primary"} noImage={false} text={"Get Started"} />
          </div>
          <div className="survey-reporting-section-wrap">
            <div className="row">
              <div className="col-sm-6">
                <div className="survey-reporting-text">
                  <h2>Survey reporting</h2>
                  <p>
                    Make sense of your results with custom configured dashboards. Or,
                    opt for cross-tabulation reports that include statistical testing
                    to uncover significant differences between respondent segments.
                  </p>
                  {/* <a className="btn btn-primary" href="create-survey-1.html">
                    Get Started{" "}
                    <img className="fa" src="/images/arrow-forward-outline.svg" />
                  </a> */}
                  <StartSurveyButton className={"btn btn-primary"} noImage={false} text={"Get Started"} />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="survey-reporting-img">
                  <img src="/images/Create-a-Survey2.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <RightStickyBtn />

      <Footer />
    </>
  )
}

export default SurveyLanding