import React from 'react';

const LazyPetitionDetails = React.lazy(() =>
  import(/* webpackChunkName: "PetitionDetailsPage" */ './petitiondetailspage')
);

const PetitionDetailsPage = (props: Record<string, any>) => (
  <React.Suspense fallback={<div className="loader"></div>}>
    <LazyPetitionDetails {...props} />
  </React.Suspense>
);

export default PetitionDetailsPage