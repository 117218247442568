import React from 'react'
import BrowseFundraising from '../../../components/fundraising/browse-fundraising/BrowseFundraising'

export default function BrowseFundraisingPage() {
  return (
    <React.Fragment>
       <BrowseFundraising />
    </React.Fragment>
  )
}
