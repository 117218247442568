import React from 'react'
import BrowseSurvey from 'src/components/survey/browse-survey/BrowseSurvey'

export default function BrowseSurveyPage() {
  return (
    <React.Fragment>
       <BrowseSurvey />
    </React.Fragment>
  )
}
