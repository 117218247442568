import React, { useEffect, useState } from 'react'
import { Controller, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useParams } from 'react-router'
import { changeString, CRYPTO_SECRET_KEY, extractContent, URLS } from 'src/_config'
import { getSignedTime } from '../../../_config'
import { useAppPetitionDetailsSelector } from 'src/_common/hooks/selectors/petition/petitionSelector';
import SignPetitionModal from './SignPetitionModal';
import { useToaster } from 'src/_common/hooks/actions/common/appToasterHook';
import { useAuthStatus } from 'src/_common/hooks/authHook'

interface SignPetitionFormValues {
  display: boolean,
  reasons?: string,
}

const signPetitionSchema = yup.object().shape({
  display: yup
    .boolean(),
  reasons: yup
    .string()
    .required("Required"),
})

const SignPetition = () => {

  const { register, control, setValue, handleSubmit, errors } = useForm<SignPetitionFormValues>({
    resolver: yupResolver(signPetitionSchema),
    defaultValues: {
      display: false,
      reasons: '',
    },
  })

  const petitionDetails = useAppPetitionDetailsSelector()
  const [getPetitionSignedUsers, setPetitionSignedUsers] = useState<any>(null)
  const [showSignPetitionModal, setshowSignPetitionModal] = useState<boolean>(false)
  const toast = useToaster()
  const isAuth = useAuthStatus()
  const history = useHistory()

  const openSignPetitionModal = () => {
    // console.log({ petitionDetails })
    if (isAuth && petitionDetails && petitionDetails.is_signed) {
      toast.error("Sorry! You have already signed the petition...")
    }
    else {
      setshowSignPetitionModal(true)
    }
  }

  const onCloseSignPetitionModal = () => {
    setshowSignPetitionModal(false)
  }

  useEffect(() => {
    if (petitionDetails && petitionDetails.sign_users) {
      let x = petitionDetails.sign_users.length <= 3 ? petitionDetails.sign_users : petitionDetails.sign_users.slice(0, 3);
      setPetitionSignedUsers(x);
      // console.log(getPetitionSignedUsers, x);
    }
    // else {
    //   history.push(URLS.PETITION.BROWSEPETITION)
    // }
  }, [petitionDetails])

  return (
    <div className="sign_petitionbox">
      <div className="saverodio">
        {petitionDetails && petitionDetails["petition_image"] && petitionDetails["petition_image"].id != 0 && (
          <img
            src={petitionDetails["petition_image"]["thumb"]}
            alt="petitionImage"
            className="w-100"
          />
        )}
      </div>
      <p>
        {petitionDetails && petitionDetails["description"] && changeString(extractContent(petitionDetails["description"]), 80)}
      </p>
      <h5>
        <span>
          <b>{petitionDetails && petitionDetails["total_sign_users"] && petitionDetails["total_sign_users"].toLocaleString()}</b> have signed.
        </span>{" "}
        Let's get to {petitionDetails && petitionDetails["target_users"] && petitionDetails["target_users"].toLocaleString()}!
      </h5>
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{
            width: `${Math.round(
              ((petitionDetails && petitionDetails["total_sign_users"]) /
                (petitionDetails && petitionDetails["target_users"])) *
              100
            )}%`
          }}
        ></div>
      </div>
      <div className="sign_petition_list">
        <ul>
          <li>
            <span className="icon">
              <img src={"/images/graph-icon.svg"} alt="graph" />
            </span>
            <p>
              At <span>{petitionDetails && petitionDetails["target_users"] && petitionDetails["target_users"].toLocaleString()}</span>signatures, this petition is more likely to be featured in recommendations!
            </p>
          </li>
          {getPetitionSignedUsers &&
            getPetitionSignedUsers.map((item: any, index: any) => (
              <li key={index}>
                {item.user ?
                  <>
                    <span className="icon">
                      <img
                        src={item?.user?.avatar?.thumb}
                        alt="person"
                      />
                    </span>
                    <p>
                      {
                        (item?.is_anonymous == 0 && item?.show_only_me == 0) ?
                          <>
                            <span>{item?.user?.full_name}</span>signed{" "}
                          </>
                          :
                          <><span>Anonymous</span>signed{" "}</>
                      }
                      {getSignedTime(item?.sign_date)}
                    </p>
                  </>
                  :
                  <>
                    <span className="icon">
                      <img
                        src="/images/no-profile.png"
                        alt="person"
                      />
                    </span>
                    <p>
                      <span>Anonymous</span>signed{" "}
                      {getSignedTime(item?.sign_date)}
                    </p>
                  </>
                }
              </li>
            ))}
        </ul>
      </div>
      {petitionDetails?.status == 1 ?
        <div>
          <a className="btn btn-primary w-100 d-flex justify-content-between sign-petition" onClick={openSignPetitionModal}>
            {isAuth && petitionDetails && petitionDetails.is_signed ? "Already Signed" : "Sign"} this petition <img className="fa" src={"/images/lock-closed-outline.svg"} alt="lock" />
          </a>
        </div>
        : null}

      {/* {showSignPetitionModal && petitionDetails && */}
        <SignPetitionModal
          onClose={onCloseSignPetitionModal}
          shouldShow={showSignPetitionModal}
          petitionDetails={petitionDetails}
        />
      {/* } */}

    </div>
  )
}

export default SignPetition
