import React from 'react';

const LazyPetition = React.lazy(() =>
  import(/* webpackChunkName: "Petition" */ './petition')
);

const Petition = (props: Record<string, any>) => (
  <React.Suspense fallback={<div className="loader"></div>}>
    <LazyPetition {...props} />
  </React.Suspense>
);

export default Petition