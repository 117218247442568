import { useDispatch } from 'react-redux'
import { ACTIONS } from '../../../../_config'

export function useAppGlobalAction() {
  const dispatch = useDispatch()

  const setSearchKeyword = (data: any) => {
    dispatch({
      type: ACTIONS.COMMON.SET_SEARCH_KEYWORD,
      payload: data,
    })
  }

  const resetSearchKeyword = () => {
    dispatch({
      type: ACTIONS.COMMON.SET_SEARCH_KEYWORD,
      payload: null,
    })
  }

  // const hideLoginModal = () => {
  //   dispatch({
  //     type: ACTIONS.GLOBAL.SET_LOGIN_MODAL,
  //     payload: false,
  //   })
  // }

  return {
    // showLoginModal,
    // hideLoginModal,
    setSearchKeyword,
    resetSearchKeyword
  }
}