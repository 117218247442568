

import { CreateUpdateReq, DonorsDetailsReq, EditMyFundraisingReq, FundraisingDetailsReq, FundraisingDonationReq, FundraisingListReq, RemoveUpdateReq, StartFundraisingReq, UpdatesListReq, InvitedSupportersListReq, InviteSupportersReq, AddBankAccountReq, DeleteBankAccountReq, ConnectWithStripeReq, ConnectWithStripeSuccessReq, FundCsvExportReq, DeleteFundraiserReq, FundraisingSettingsReq, FundraisingSettingsUpdateReq } from 'src/_common/interfaces/ApiReqRes';
import { FundraisingCharityListReq, FundraisingCommentsListReq } from 'src/_common/interfaces/ApiReqRes';
import { SAGA_ACTIONS } from '../../../../_config'
import { useApiCall } from '../../common/appApiCallHook'

export function useAppFundraisingApi() {

  const callApi = useApiCall()
  
  const getFundraisingCategoryList = ( onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.CATEGORY_LIST, null, onSuccess, onError);
  }
  
  const startFundraising = ( data: StartFundraisingReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.START_FUNDRAISING, data, onSuccess, onError);
  }

  const getFundraisingList = ( data: FundraisingListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.LISTING, data, onSuccess, onError);
  }

  const getFundraisingCharityList = ( data: FundraisingCharityListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.CHARITY_LIST, data, onSuccess, onError);
  }

  const getFundraisingDetails = ( data: FundraisingDetailsReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.DETAILS, data, onSuccess, onError);
  }

  const getFundraisingCommentsList = ( data: FundraisingCommentsListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.COMMENT_LIST, data, onSuccess, onError);
  }

  const getAllDonors = ( data: DonorsDetailsReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.VIEW_ALL_DONORS, data, onSuccess, onError);
  }

  const getTopDonors = ( data: DonorsDetailsReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.VIEW_TOP_DONORS, data, onSuccess, onError);
  }
  
  const getTips = ( onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.TIPS, null, onSuccess, onError);
  }

  const makeFundraisingDonation = ( data: FundraisingDonationReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.MAKE_DONATION, data, onSuccess, onError);
  }
    
  const getMyFundraisingList = ( data: FundraisingListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.MY_FUNDRAISERS_LIST, data, onSuccess, onError);
  }
    
  const editMyFundraising = ( data: EditMyFundraisingReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.MY_FUNDRAISER_EDIT, data, onSuccess, onError);
  }
    
  const inviteSupporters = ( data: InviteSupportersReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.INVITE_SUPPORTER, data, onSuccess, onError);
  }
    
  const createUpdate = ( data: CreateUpdateReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.CREATE_UPDATE, data, onSuccess, onError);
  }
    
  const getUpdatesList = ( data: UpdatesListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.UPDATES_LIST, data, onSuccess, onError);
  }
    
  const removeUpdate = ( data: RemoveUpdateReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.REMOVE_UPDATE, data, onSuccess, onError);
  }
    
  const getInvitedSupportersList = ( data: InvitedSupportersListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.INVITED_SUPPORTERS_LIST, data, onSuccess, onError);
  }

  const addBankAccount = ( data: AddBankAccountReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.BANK_ACCOUNT, data, onSuccess, onError);
  }

  const getBankAccountDetails = ( onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.BANK_ACCOUNT_DETAILS, null, onSuccess, onError);
  }

  const deleteBankAccount = ( data: DeleteBankAccountReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.BANK_ACCOUNT_DELETE, data, onSuccess, onError);
  }

  const connectWithStripe = ( data: ConnectWithStripeReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.CONNECT_WITH_STRIPE, data, onSuccess, onError);
  }

  const connectWithStripeSuccess = ( data: ConnectWithStripeSuccessReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.CONNECT_WITH_STRIPE_SUCCESS, data, onSuccess, onError);
  }

  const topDonatedFundraiser = ( onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.TOP_DONATED_FUNDRAISER, null, onSuccess, onError);
  }

  const csvExport = ( data: FundCsvExportReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.CSV_EXPORT, data, onSuccess, onError);
  }

  const deleteFundraiser = ( data: DeleteFundraiserReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.DELETE_FUNDRAISER, data, onSuccess, onError);
  }

  const closeFundraiser = ( data: DeleteFundraiserReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.CLOSE_FUNDRAISER, data, onSuccess, onError);
  }

  const fundraisingSettings = ( data: FundraisingSettingsReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.FUNDRAISING_SETTINGS, data, onSuccess, onError);
  }

  const updateFundraisingSettings = ( data: FundraisingSettingsUpdateReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.UPDATE_FUNDRAISING_SETTINGS, data, onSuccess, onError);
  }

  const requestForPayout = ( data: FundraisingSettingsReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.FUNDRAISING.REQUEST_FOR_PAYOUT, data, onSuccess, onError);
  }

  return {
    callgetFundraisingCategoryList: getFundraisingCategoryList,
    callStartFundraising: startFundraising,
    callGetFundraisingList: getFundraisingList,
    callGetFundraisingDetails: getFundraisingDetails,
    callGetFundraisingCharityList: getFundraisingCharityList,
    callGetFundraisingCommentsList: getFundraisingCommentsList,
    callGetAllDonors: getAllDonors,
    callGetTopDonors: getTopDonors,
    callGetTips: getTips,
    callMakeFundraisingDonation: makeFundraisingDonation,
    callGetMyFundraisingList: getMyFundraisingList,
    callEditMyFundraising: editMyFundraising,
    callInviteSupporters: inviteSupporters,
    callCreateUpdate: createUpdate,
    callGetUpdatesList: getUpdatesList,
    callRemoveUpdate: removeUpdate,
    callGetInvitedSupportersList: getInvitedSupportersList,
    callAddBankAccount: addBankAccount,
    callGetBankAccountDetails: getBankAccountDetails,
    callDeleteBankAccount: deleteBankAccount,
    callConnectWithStripe: connectWithStripe,
    callConnectWithStripeSuccess: connectWithStripeSuccess,
    callTopDonatedFundraiser: topDonatedFundraiser,
    callCsvExport: csvExport,
    callDeleteFundraiser: deleteFundraiser,
    callFundraisingSettings: fundraisingSettings,
    callUpdateFundraisingSettings: updateFundraisingSettings,
    callRequestForPayout: requestForPayout,
    callCloseFundraiser: closeFundraiser,
  }
}