import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router";
import { useAppFundraisingApi } from "src/_common/hooks/api/fundraising/appFundraisingApiHook";
import { useToaster } from "src/_common/hooks/common/appToasterHook";
import { CRYPTO_SECRET_KEY, getSignedTime } from "src/_config";
import { useAppFundraisingDetailsSelector } from "src/_common/hooks/selectors/fundraising/fundraisingSelector";
import LoginSignup from "./LoginSignup";
import { useAppUserAuthSelector } from "src/_common/hooks/selectors/user/userSelector";

const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);
const queryString = require("query-string");

interface LoginSignupModalProps {
  onClose: () => void;
  shouldShow: boolean;
}

export default function LoginSignupModal({
  onClose,
  shouldShow,
}: LoginSignupModalProps) {

  const isAuth = useAppUserAuthSelector()

  useEffect(() => {
    if(isAuth) {
      onClose()
    }
  }, [isAuth])

  return (
    <Modal
      show={shouldShow}
      backdrop="static"
      keyboard={false}
      className="stackholder-modal login-font-family"
      centered
      size={'xl'}
    >
      <Modal.Header>
        <h5 className="modal-title donor-title">
          Login/Signup
        </h5>        
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => onClose()}
        >
          <span>
            <i className="fa fa-times"></i>
          </span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <LoginSignup page="endSurvey" />
      </Modal.Body>
    </Modal>
  );
}
