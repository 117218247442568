import { type } from 'os';
import { ActionExtended } from '../../../_common/interfaces/ActionExtended';
import { ACTIONS } from '../../../_config'

export interface CommonReducer {
  searchKeyword: string;
  popped: boolean;
}

const initialState: CommonReducer = {
  searchKeyword: '',
  popped: false,
};

const commonReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.COMMON.SET_SEARCH_KEYWORD:
      return {
        ...state,
        searchKeyword: action.payload,
      };
    default:
      return state;
  }
};

export default commonReducer;
