import React from 'react'
import MyCauses from 'src/components/fundraising/my-causes/MyCauses'

export default function MyCausesPage() {
  return (
    <React.Fragment>
       <MyCauses />
    </React.Fragment>
  )
}
