import React, { useEffect, useState } from 'react'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory, useParams } from 'react-router'
import Footer from 'src/components/common/Footer'
import NavbarMain from 'src/components/common/navbar/NavbarMain'
import RightStickyBtn from 'src/components/common/RightStickyBtn'
import FormTextInput from 'src/_common/components/form-elements/textinput/formTextInput'
import { useAppSurveyAction } from 'src/_common/hooks/actions/survey/appSurveyActionHook'
import { useAppSurveyApi } from 'src/_common/hooks/api/survey/appSurveyApiHook'
import { useAppSurveyDetailsSelector } from 'src/_common/hooks/selectors/survey/surveySelector'
import { useAppUserAuthSelector, useAppUserDetailsSelector } from 'src/_common/hooks/selectors/user/userSelector'
import { CRYPTO_SECRET_KEY, URLS } from 'src/_config'
import { useToaster } from 'src/_common/hooks/common/appToasterHook';
import MetaDecorator from 'src/components/common/MetaDecorator';
import parse from 'html-react-parser'
import LoginSignupModal from 'src/components/login-signup/LoginSignupModal';

interface StartSurveyFormValues {
    email: string;
}

const SurveySchema = yup.object().shape({
    email: yup
        .string()
        .email('Please provide valid email')
        // .required('Email is required'),
})

const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

const StartSurvey = () => {

    const { register, control, setValue, handleSubmit, errors } = useForm<StartSurveyFormValues>({
        resolver: yupResolver(SurveySchema),
        defaultValues: {
            email: '',
        },
    })

    const history = useHistory()
    const { slug } = useParams<any>()
    const surveyApi = useAppSurveyApi()
    const surveyAction = useAppSurveyAction()
    const surveyDetails = useAppSurveyDetailsSelector()
    const userDetails = useAppUserDetailsSelector()
    const toast = useToaster()
    const [errorMsg,setErrorMsg] = useState<boolean>(false)
    const isAuth = useAppUserAuthSelector()
    const [showLoginSignupModal, setShowLoginSignupModal] = useState<any>()
  
    const openLoginSignupModal = () => {
        setShowLoginSignupModal(true)
    }
  
    const closeLoginSignupModal = () => {
        setShowLoginSignupModal(false)
    }

    const getSurveyDetails = () => {
        let params = {
            slug
        }
        surveyApi.callGetSurveyDetails(
            params,
            (message: string, resp: any) => {
                if (resp.data.survey_details == null) {
                    history.push(URLS.SURVEY.BROWSE_SURVEY)
                }
            },
            (message: string) => {
                // toast.error(message)
            }
        )
    }

    const handleStartSurvey = () => {
        history.push(`/survey/${slug}`)
    }

    const onSubmit = (values: StartSurveyFormValues) => {
        if(!userDetails && !values.email) {
            setErrorMsg(true)
            return
        }
        var params = {
            email: !userDetails ? values.email : userDetails?.email,
            survey_id: surveyDetails?.id
            // survey_id: cryptr.decrypt(id)surveyDetails
        }
        surveyApi.callCheckEmailExist(params,
            (message: string, resp: any) => {
                console.log({ resp })
                if (resp.data) {
                    if (resp.data.length != 0) {
                        toast.error("Sorry! You have already submitted this survey...")
                    }
                    else {
                        localStorage.setItem("surveyEmail", params.email)
                        history.push(`/survey/${slug}`)
                    }
                }
            }, (message: string) => {
                toast.error(message)
            })
    }

    useEffect(() => {
        if (userDetails) {
            setValue("email", userDetails.email)
        }
    }, [userDetails])

    useEffect(() => {
        getSurveyDetails()

        return () => {
            surveyAction.resetSurveyDetails()
            surveyAction.resetStartSurvey()
        }
    }, [])

    return (
        <>

            <MetaDecorator
                title={"Start Survey"}
                description={"Start Survey"}
            />

            <NavbarMain section={"survey"} />

            <RightStickyBtn />

            <section className="create-survey-page">
                <div className="container">
                    <div
                        className="survey-preview-wrap box-wrap"
                    // style={{ backgroundImage: "url(/images/happening-bg.png)" }}
                    >
                        <div className="surver-preview-main-title">{surveyDetails?.title}</div>
                        <div className="survey-preview-top">
                            {surveyDetails?.description ?
                                <p>
                                    {parse(surveyDetails?.description)}
                                </p>
                                :
                                null
                            }
                        </div>
                        {/* <div className="survey-preview-top">
                            <h2>Info Text</h2>
                            <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                            </p>
                        </div> */}
                        <form onSubmit={handleSubmit(onSubmit)} noValidate>
                            {
                                !userDetails && 
                                <div className="form-group">
                                    <Controller
                                        control={control}
                                        name="email"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <FormTextInput
                                                name={name}
                                                onChange={e=>{
                                                    onChange(e);
                                                    setErrorMsg(false)
                                                }}
                                                onBlur={onBlur}
                                                value={value}
                                                inputRef={ref}
                                                type="email"
                                                error={errors.email}
                                                placeholder="Your email address..."
                                            // disabled={userDetails?true:false}
                                            />
                                        )}
                                    />
                                    {errorMsg && <div className="invalid-feedback">Email is required</div>}
                                </div>
                            }
                            <div className="submit-btn-wrap">
                                <button type="submit" className="btn btn-primary btn-done">
                                    Start your survey
                                </button>
                            </div>
                        </form>
                        {!isAuth &&
                            <><div className="survey-preview-top mt-3">
                                <p style={{color:"#1e1e43"}}>
                                    Make sure to sign up for a safe and free user account to get full access to the entire Rural America In Action platform.
                                </p>
                            </div>
                            <div className="submit-btn-wrap">
                                {!isAuth &&
                                    <a onClick={openLoginSignupModal} className="btn btn-secondary btn-done">
                                        Login/Signup
                                    </a>
                                }
                            </div>
                            </>
                        }
                    </div>
                </div>
            </section>

            {showLoginSignupModal &&
                <LoginSignupModal
                    shouldShow={showLoginSignupModal}
                    onClose={closeLoginSignupModal}
                />
            }

            <Footer />
        </>
    )
}

export default StartSurvey