import React, { useEffect, useRef, useState } from 'react'
import { useAppSurveyApi } from 'src/_common/hooks/api/survey/appSurveyApiHook'
import { useAppAnswerTypeListSelector, useAppCreateSurveySelector, useAppSurveyDetailsSelector } from 'src/_common/hooks/selectors/survey/surveySelector'
import { Controller, useFieldArray, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormTextInput from 'src/_common/components/form-elements/textinput/formTextInput';
import SelectInput from 'src/_common/components/form-elements/selectinput/selectInput';
import { OptionValue } from 'src/_common/interfaces/common';
import { useToaster } from 'src/_common/hooks/common/appToasterHook';
import QuestionEditModal from './QuestionEditModal';
import { useParams } from 'react-router';
import { CRYPTO_SECRET_KEY } from 'src/_config';
import DeleteDuplicateConfirmationModal from 'src/components/common/DeleteDuplicateConfirmationModal';
import SurveyBanner from './SurveyBanner';
import moment from 'moment';
import DateInput from 'src/_common/components/form-elements/datepicker/dateInput';
import { useAppSurveyAction } from 'src/_common/hooks/actions/survey/appSurveyActionHook';

const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

interface SurveyTwoProps {
    setPageChangeBtn: Function;
}

interface questionTypeArrayValues {
    question: string,
    type: OptionValue | undefined,
}

interface Option {
    option: string
}

interface questionTypeFormValues {
    questionType: questionTypeArrayValues[],
    answerOptions: Option[],
    min: string,
    max: string,
}

const inviteSchema = yup.object().shape({
    questionType: yup.array().of(
        yup.object().shape({
            question: yup.string()
                .required('Question is required'),
            type: yup
                .object()
                .nullable()
                .shape({
                    value: yup.string().required('Type is required'),
                })
                .required('Type is required'),
        })
    ),
    answerOptions: yup.array().of(
        yup.object().shape({
            option: yup.string()
                .required('Option is required'),
        })
    ),
    min: yup
        .string()
        .when("questionType", (key: any) => {
            console.log({ key })
            if (key[0] && key[0].type && key[0].type.value && key[0].type.value == '15')
                return yup.string().required("Min Value is required")
        }),
    // .required('Min Value is required'),
    max: yup
        .string()
        .when("questionType", (key: any) => {
            console.log({ key })
            if (key[0] && key[0].type && key[0].type.value && key[0].type.value == '15')
                return yup.string().required("Max Value is required")
        }),
    // .required('Max Value is required'),
})


const SurveyTwo = ({ setPageChangeBtn }: SurveyTwoProps) => {

    const [isStyleSelectd, setIsStyleSelectd] = useState<boolean>(false)
    const [isBuildSelectd, setIsBuildSelectd] = useState<boolean>(false)
    // const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false)
    const [showPageTitle, setShowPageTitle] = useState<boolean>(false)
    const [pageTitle, setPageTitle] = useState<string>('')
    const [showSurveyEndMessage, setShowSurveyEndMessage] = useState<boolean>(false)
    const [surveyEndMessage, setSurveyEndMessage] = useState<string>('')
    const [showExpireDate, setShowExpireDate] = useState<boolean>(false)
    const [expireDate, setExpireDate] = useState<any>()
    const [showFooter, setShowFooter] = useState<boolean>(false)
    const [footerTitle, setFooterTitle] = useState<string>('')
    const [currentQuestion, setCurrentQuestion] = useState<any>()
    const [currentId, setCurrentId] = useState<any>()
    const [currentType, setCurrentType] = useState<any>()
    const [showEditQuestionModal, setShowEditQuestionModal] = useState<boolean>(false)
    const [pagebreakPositions, setPagebreakPositions] = useState<any>([])
    const [imageFile, setImageFile] = useState<any>();
    const [imgSrc, setImgSrc] = useState<string>();

    const surveyApi = useAppSurveyApi()
    const answerTypeList = useAppAnswerTypeListSelector()
    const createSurveyDetails = useAppCreateSurveySelector()
    const surveyDetails = useAppSurveyDetailsSelector()
    const toast = useToaster()
    const [showDeleteDuplicateConfirmModal, setShowDeleteDuplicateConfirmModal] = useState<boolean>(false)
    const [showPageBreakIcon, setShowPageBreakIcon] = useState<any>(-1)
    const surveyAction = useAppSurveyAction()

    const { slug } = useParams<any>()
    // const { id } = useParams<any>()
    // const surveyId = id ? cryptr.decrypt(id) : null

    const { register, control, setValue, handleSubmit, errors, reset, getValues } = useForm<questionTypeFormValues>({
        resolver: yupResolver(inviteSchema),
        defaultValues: {
            questionType: [
                // {
                //   question: '',
                //   type: undefined
                // }
            ],
            answerOptions: [
                {
                    option: ''
                }
            ],
            // min: '',
            // max: '',
        },
    })

    const {
        fields: questionTypeFields,
        append: questionTypeAppend,
        prepend: questionTypePrepend,
        remove: questionTypeRemove,
    } = useFieldArray({ control, name: "questionType" })

    const {
        fields: answerOptionsFields,
        append: answerOptionsAppend,
        prepend: answerOptionsPrepend,
        remove: answerOptionsRemove
    } = useFieldArray({ control, name: `answerOptions` })

    const getAnswerTypeList = () => {
        surveyApi.callGetAnswerTypeList(
            (message: string, resp: any) => {
                // console.log({resp})
            },
            (message: string) => {
                // toast.error(message)
            }
        )
    }

    const getSurveyDetails = () => {
        let params = {
            slug: slug ? slug : String(createSurveyDetails.slug)
        }
        surveyApi.callGetSurveyDetails(
            params,
            (message: string, resp: any) => {
                // console.log({resp})
            },
            (message: string) => {
                // toast.error(message)
            }
        )
    }

    const createNewQuestion = (type: any) => {
        // setShowMobileMenu(false)
        setIsBuildSelectd(false)
        setIsStyleSelectd(false)
        console.log({ type })
        if (questionTypeFields.length) {
            toast.error("Please save the current question before adding a new one...")
        }
        else {
            questionTypeAppend({ question: '', type: { value: String(type.id), label: type.display_name } })
        }

        // questionTypeAppend({question:'',type:''})
    }

    const onSubmit = (values: any) => {
        console.log({ values })
        if (values && values.questionType) {
            let params: any = {
                survey_id: surveyDetails?.id,
                question: values.questionType[0].question,
            }
            if (values.questionType[0].type) {
                params["answer_type"] = values.questionType[0].type.value
            }
            if (values.min && values.max) {
                params["answer_option"] = [values.min, values.max]
            }
            if (values.answerOptions) {
                let optionsArr: any = []
                values.answerOptions.map((opt: any) => {
                    optionsArr.push(opt.option)
                })
                if (optionsArr && optionsArr.length) {
                    params["answer_option"] = optionsArr
                }
            }
            surveyApi.callAddEditQuestion(
                params,
                (message: string, resp: any) => {
                    getSurveyDetails()
                    questionTypeRemove(0)
                    let len = answerOptionsFields.length
                    for (let i = 0; i < len; i++) {
                        answerOptionsRemove(0)
                    }
                    answerOptionsAppend([{ option: '' }])
                    // console.log({resp})
                },
                (message: string) => {
                    toast.error(message)
                }
            )
        }
    }

    const onTypeChange = (e: any, ind: any) => {
        console.log(e)
        // if(item.type && item.type.value && (item.type.value=="4" || item.type.value=="5" || item.type.value=="6"))
        if (e) {
            let len = answerOptionsFields.length
            for (let i = 0; i < len; i++) {
                answerOptionsRemove(0)
            }
            answerOptionsAppend([{ option: '' }])
            let x = getValues(`questionType[${ind}].question`)
            console.log(x)
            questionTypeRemove(ind)
            questionTypeAppend({ question: x, type: { value: String(e.value), label: e.label } })
        }
        // questionTypeUpdate(i,{question:questionTypeFields[i].question,type:{value:String(e.value),label:e.label}})
        // setValue(`questionType[${i}].type`,{value:String(e.value),label:e.label})
    }

    const saveTitleFooter = (type: string, dateValue?: any) => {
        let params: any = {
            // slug: slug ? slug : String(createSurveyDetails.slug)
            id: surveyDetails?.id ? surveyDetails?.id :  String(createSurveyDetails.id)
        }
        if (type == "title") {
            setShowPageTitle(false)
            params["page_title"] = pageTitle
        }
        if (type == "footer") {
            setShowFooter(false)
            params["footer_title"] = footerTitle
        }
        if (type == "surveyEndMessage") {
            setShowSurveyEndMessage(false)
            params["end_survey_message"] = surveyEndMessage
        }
        if (type == "expireDate") {
            setExpireDate(dateValue)
            setShowExpireDate(false)
            console.log(dateValue)
            if(dateValue) {
                const date = new Date(dateValue);
                const formatedDate = moment(date).format("YYYY-MM-DD HH:mm:ss");
                params["expire_date"] = formatedDate
            }
            else {
                params["expire_date"] = ""
            }
        }
        surveyApi.callSetTitleFooter(
            params,
            (message: string, resp: any) => {
                getSurveyDetails()
            },
            (message: string) => {
                toast.error(message)
                if (type == "title") {
                    setPageTitle(surveyDetails.page_title)
                }
                if (type == "footer") {
                    setFooterTitle(surveyDetails.footer_title)
                }
                if (type == "surveyEndMessage") {
                    setSurveyEndMessage(surveyDetails.end_survey_message)
                }
            }
        )
        // setFooterTitle(footerRef.current?.value)
    }

    const closeEditQuestionModal = () => {
        setShowEditQuestionModal(false)
    }

    const editQuestion = (question: any) => {
        setCurrentQuestion(question)
        setShowEditQuestionModal(true)
    }

    const dupicateQuestion = (id: any) => {
        surveyApi.callDuplicateSurveyQuestion(
            { question_id: id },
            (message: string, resp: any) => {
                getSurveyDetails()
                closeDeleteDuplicateConfirmModal()
                // console.log({resp})
                toast.success(message)
            },
            (message: string) => {
                toast.error(message)
            }
        )
    }

    const deleteQuestion = (id: any) => {
        surveyApi.callSurveyQuestionDelete(
            { question_id: id },
            (message: string, resp: any) => {
                getSurveyDetails()
                closeDeleteDuplicateConfirmModal()
                // console.log({resp})
                toast.success(message)
            },
            (message: string) => {
                toast.error(message)
            }
        )
    }

    const uploadImage = (params: any) => {
        // console.log(params.get('image'))
        surveyApi.callBackgroundImageUpload(
            params,
            (message: string, resp: any) => {
                toast.success("Background image uploaded successfully")
                setImgSrc(URL.createObjectURL(params.get('image')))
                getSurveyDetails();
            },
            (message: string) => {
                toast.error(message)
            }
        )
    }

    const onImageChange = (e: any) => {
        console.log("file", e.target.files[0]);
        let file = e.target.files[0]
        if (file) {
            let imgTypes = ["image/jpeg", "image/png", "image/jpg", "image/gif", "image/svg", "image/svg+xml"]

            if (file.type && imgTypes.includes(file.type)) {
                // setImageFile(e.target.files[0])
                // setImgSrc(URL.createObjectURL(e.target.files[0]))
                // console.log(URL.createObjectURL(e.target.files[0]))
                // loadImage(setImageDimensions, URL.createObjectURL(e.target.files[0]));
                const formData = new FormData();
                formData.append('id', surveyDetails?.id ? surveyDetails?.id : String(createSurveyDetails.id))
                formData.append('image', file)
                uploadImage(formData)
            }
            else if (file.type && !imgTypes.includes(file.type)) {
                toast.error("The image must be of type: jpeg, png, jpg, gif, svg")
            }
            else {
                toast.error("Please select only image file...")
            }
        }
    }

    const handleImageRemoveClick = () => {
        // console.log(surveyDetails)
        let params = {
            entity_id: surveyDetails?.id,
            type: "survey"
        }
        surveyApi.callBackgroundImageDelete(
            params,
            (message: string, resp: any) => {
                toast.success(message)
                getSurveyDetails()
                setImgSrc('');
            },
            (message: string) => {
                toast.error(message)
            }
        )
        // setImageFile(null);
        // toast.success("Image removed successfully");
    }

    const closeDeleteDuplicateConfirmModal = () => {
        setShowDeleteDuplicateConfirmModal(false)
    }

    const openDeleteDuplicateConfirmModal = (type: string, id: string) => {
        setCurrentId(id)
        setCurrentType(type)
        setShowDeleteDuplicateConfirmModal(true)
    }

    const addPageBreak = (page_break_position: any) => {
        console.log({ page_break_position })
        let params = {
            survey_id: surveyDetails?.id,
            page_break_position
        }
        surveyApi.callAddSurveyPageBreak(
            params,
            (message: string, resp: any) => {
                toast.success(message)
                getSurveyDetails()
                // console.log({resp})
            },
            (message: string) => {
                // toast.error(message)
            }
        )
    }

    const deletePageBreak = (pos: any) => {
        let q = surveyDetails.pagebreak.find((p: any) => p.page_break_position == pos)
        if (q) {
            let params = {
                id: q.id
            }
            surveyApi.callDeleteSurveyPageBreak(
                params,
                (message: string, resp: any) => {
                    toast.success(message)
                    getSurveyDetails()
                    // console.log({resp})
                },
                (message: string) => {
                    // toast.error(message)
                }
            )
        }
    }

    const gotoPreview = () => {
        if (surveyDetails && surveyDetails.questions && surveyDetails.questions.length) {
            setPageChangeBtn("survey_preview")
        }
        else {
            toast.error("Please add atleast one question to continue...")
        }
    }

    const handleKeyPress = (event: any) => {
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    }

    useEffect(() => {
        getAnswerTypeList()
        getSurveyDetails()
        surveyAction.createSurveyPage("survey_two")
    }, [])

    useEffect(() => {
        console.log({ questionTypeFields })
    }, [questionTypeFields])

    useEffect(() => {
        if (surveyDetails) {
            if (surveyDetails.page_title) {
                setPageTitle(surveyDetails.page_title)
            }
            if (surveyDetails.footer_title) {
                setFooterTitle(surveyDetails.footer_title)
            }
            if (surveyDetails.end_survey_message) {
                setSurveyEndMessage(surveyDetails.end_survey_message)
            }
            if (surveyDetails.expire_date) {
                const date = new Date(surveyDetails.expire_date);
                const formatedDate = moment(date).format("YYYY-MM-DD");
                // setExpireDate(formatedDate)
                setExpireDate(date)
            }
            if (!surveyDetails.expire_date) {
                setExpireDate('')
            }
            if (surveyDetails.survey_background_image) {
                if (surveyDetails.survey_background_image.id != 0) {
                    setImgSrc(surveyDetails.survey_background_image.thumb)
                }
            }
            if (surveyDetails.pagebreak && surveyDetails.pagebreak.length) {
                let x: any = []
                surveyDetails.pagebreak.map((p: any, ind: any) => {
                    x.push(p.page_break_position)
                })
                console.log({ x })
                setPagebreakPositions(x)
            }
            else {
                setPagebreakPositions(null)
            }
        }
    }, [surveyDetails])

    return (
        <>
            <SurveyBanner page={2} />

            <section className="create-survey-page">
                <div className="container">
                    <div className="designsurvey-wrap">
                        <div className="design-survey-left">
                            <ul className="design-survey-nav"
                            // onClick={()=>setShowMobileMenu(!showMobileMenu)}
                            >
                                <li>
                                    <a
                                        onClick={() => {
                                            setIsStyleSelectd(false)
                                            setIsBuildSelectd(!isBuildSelectd)
                                        }}
                                        className={"design-survey-nav-link " + (isBuildSelectd ? "active" : "")}
                                        id="build"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={40}
                                            height={40}
                                            viewBox="0 0 40 40"
                                        >
                                            <g id="grid-outline" transform="translate(-47 -47)">
                                                <rect
                                                    id="Rectangle_183"
                                                    data-name="Rectangle 183"
                                                    width={16}
                                                    height={16}
                                                    rx={3}
                                                    transform="translate(48 48)"
                                                    fill="none"
                                                    stroke="#a28861"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                />
                                                <rect
                                                    id="Rectangle_184"
                                                    data-name="Rectangle 184"
                                                    width={16}
                                                    height={16}
                                                    rx={3}
                                                    transform="translate(70 48)"
                                                    fill="none"
                                                    stroke="#a28861"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                />
                                                <rect
                                                    id="Rectangle_185"
                                                    data-name="Rectangle 185"
                                                    width={16}
                                                    height={16}
                                                    rx={3}
                                                    transform="translate(48 70)"
                                                    fill="none"
                                                    stroke="#a28861"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                />
                                                <rect
                                                    id="Rectangle_186"
                                                    data-name="Rectangle 186"
                                                    width={16}
                                                    height={16}
                                                    rx={3}
                                                    transform="translate(70 70)"
                                                    fill="none"
                                                    stroke="#a28861"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                />
                                            </g>
                                        </svg>
                                        <span>Build</span>
                                    </a>
                                </li>
                                {/* {surveyDetails?.survey_format && surveyDetails?.survey_format=="1" &&
                                    <li>
                                        <a
                                            // href="javascript:void(0);"
                                            className="design-survey-nav-link"
                                            id="page-break"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={51}
                                                height={35}
                                                viewBox="0 0 51 35"
                                            >
                                                <g id="grid-outline2" transform="translate(-41 -47)">
                                                    <path
                                                        id="Path_240"
                                                        data-name="Path 240"
                                                        d="M3,0H35a3,3,0,0,1,3,3V30a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0Z"
                                                        transform="translate(48 48)"
                                                        fill="none"
                                                        stroke="#a28861"
                                                        strokeLinecap="round"
                                                        strokeWidth={2}
                                                    />
                                                    <text
                                                        id="_-------"
                                                        data-name="-------"
                                                        transform="translate(41 70)"
                                                        fill="#a28861"
                                                        fontSize={18}
                                                        fontFamily="SegoeUI-Bold, Segoe UI"
                                                        fontWeight={700}
                                                    >
                                                        <tspan x={0} y={0}>
                                                            -------
                                                        </tspan>
                                                    </text>
                                                </g>
                                            </svg>
                                            <span>Page Break</span>
                                        </a>
                                    </li>
                                } */}
                                <li>
                                    <a
                                        onClick={() => {
                                            setIsStyleSelectd(!isStyleSelectd)
                                            setIsBuildSelectd(false)
                                        }}
                                        className={"design-survey-nav-link " + (isStyleSelectd ? "active" : "")} id="style">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="40.229"
                                            height="40.229"
                                            viewBox="0 0 40.229 40.229"
                                        >
                                            <path
                                                id="brush-outline"
                                                d="M85.139,48.861h0a3.719,3.719,0,0,0-5.259,0L60.491,70.454a6.1,6.1,0,0,1,4.524,4.524L85.139,54.12A3.719,3.719,0,0,0,85.139,48.861ZM56.266,74.244a4.993,4.993,0,0,0-4.96,5.039A3.38,3.38,0,0,1,48,82.641,8.508,8.508,0,0,0,54.613,86a6.664,6.664,0,0,0,6.613-6.718A4.993,4.993,0,0,0,56.266,74.244Z"
                                                transform="translate(-47 -46.771)"
                                                fill="none"
                                                stroke="#a28861"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                            />
                                        </svg>
                                        <span>Style</span>
                                    </a>
                                </li>
                                {/* <li>
                                    <a
                                        href="javascript:void(0);"
                                        className="design-survey-nav-link"
                                        id="print"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={40}
                                            height="41.102"
                                            viewBox="0 0 40 41.102"
                                        >
                                            <g id="print-outline" transform="translate(-63 -63)">
                                                <path
                                                    id="Path_232"
                                                    data-name="Path 232"
                                                    d="M95.667,151.75h2.375A3.97,3.97,0,0,0,102,147.792V131.958A3.97,3.97,0,0,0,98.042,128H67.958A3.97,3.97,0,0,0,64,131.958v15.833a3.97,3.97,0,0,0,3.958,3.958h2.375"
                                                    transform="translate(0 -57.667)"
                                                    fill="none"
                                                    stroke="#a28861"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                />
                                                <rect
                                                    id="Rectangle_187"
                                                    data-name="Rectangle 187"
                                                    width={26}
                                                    height={22}
                                                    rx={4}
                                                    transform="translate(70 81.102)"
                                                    fill="none"
                                                    stroke="#a28861"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                />
                                                <path
                                                    id="Path_233"
                                                    data-name="Path 233"
                                                    d="M153.333,70.333V67.958A3.97,3.97,0,0,0,149.375,64H131.958A3.97,3.97,0,0,0,128,67.958v2.375"
                                                    transform="translate(-57.667)"
                                                    fill="none"
                                                    stroke="#a28861"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                />
                                                <ellipse
                                                    id="Ellipse_59"
                                                    data-name="Ellipse 59"
                                                    cx={2}
                                                    cy={3}
                                                    rx={2}
                                                    ry={3}
                                                    transform="translate(94 73.102)"
                                                    fill="#a28861"
                                                />
                                            </g>
                                        </svg>
                                        <span>Print</span>
                                    </a>
                                </li> */}
                            </ul>
                            {!isStyleSelectd ?
                                <div className={"design-survey-nav-inner " + ((isBuildSelectd || isStyleSelectd) && "mobile-menu-show")}>
                                    <ul className="main-list">
                                        {answerTypeList && answerTypeList.length ?
                                            answerTypeList.map((atype: any) =>
                                                <li key={atype.id} onClick={() => createNewQuestion(atype)} style={{ cursor: "pointer" }}>
                                                    <a >
                                                        <img src={atype?.icon ? "/images/" + atype.icon : ""} alt="" /> {atype.display_name}
                                                        {/* <img src="/images/add-outline-blue.svg" /> */}
                                                    </a>
                                                </li>
                                            )
                                            : null
                                        }
                                    </ul>
                                </div>
                                :
                                <div className={"design-survey-nav-inner " + ((isBuildSelectd || isStyleSelectd) && "mobile-menu-show")}>
                                    <h2 className="design-heading">Upload a picture</h2>
                                    {/* <ul className="style-menu-list"> */}
                                    {/* <li>
                                            <a href="#">
                                                <span>Page Title</span>
                                                <em>
                                                    <img src="images/add-outline-blue.svg" alt="" />
                                                </em>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <span>Logo</span>
                                                <em>
                                                    <img src="images/add-outline-blue.svg" alt="" />
                                                </em>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <span>Footer</span>
                                                <em>
                                                    <img src="images/add-outline-blue.svg" alt="" />
                                                </em>
                                            </a>
                                        </li> */}
                                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                                    {imgSrc ?
                                        <div className="background-preview-wrap">
                                            <a
                                                className="remove-phhoto"
                                                onClick={handleImageRemoveClick}
                                                style={{ cursor: "pointer" }}
                                            >
                                                ✖
                                            </a>
                                            <img src={imgSrc} alt="" />
                                        </div>
                                        :
                                        <>
                                            <ul className="style-menu-list">
                                                <li>
                                                    <a className="active">
                                                        <span>Background</span>
                                                        <label htmlFor="image">
                                                            <img src="/images/add-outline-blue.svg" alt="" />
                                                        </label>
                                                        <input
                                                            type="file"
                                                            name="image"
                                                            id="image"
                                                            accept="image/*"
                                                            onChange={onImageChange}
                                                            hidden
                                                        />
                                                    </a>
                                                </li>
                                            </ul>
                                            <b>File types accepted:</b> '.jpeg', '.png', '.jpg', '.gif', '.svg'<br/>
                                            <b>Max size:</b> 5MB
                                        </>
                                    }
                                </div>
                            }
                        </div>
                        <div className="design-survey-right">
                            <a onClick={gotoPreview} className="btn-preview" style={{ cursor: "pointer" }}>
                                Save & Preview
                            </a>
                            <div className="survey-title-wrap">
                                <h2>{surveyDetails?.title}</h2>
                            </div>
                            {!showPageTitle ?
                                <div className="add-page-title-btn-wrap">
                                    <a className="btn-add-page-titile" onClick={() => setShowPageTitle(true)}>
                                        {pageTitle ?
                                            pageTitle
                                            :
                                            <>Add Page Title <img src="/images/add-outline.svg" alt="" /></>
                                        }
                                    </a>
                                </div>
                                :
                                <div className="add-page-titile-fld">
                                    <input
                                        type="text"
                                        value={pageTitle}
                                        onChange={e => setPageTitle(e.target.value)}
                                        onBlur={() => saveTitleFooter("title")}
                                        autoFocus
                                        placeholder="Enter page title"
                                    />
                                </div>
                            }
                            {!showSurveyEndMessage ?
                                <div className="add-page-title-btn-wrap mt-3">
                                    <a className="btn-add-page-titile" onClick={() => setShowSurveyEndMessage(true)}>
                                        {surveyEndMessage ?
                                            surveyEndMessage
                                            :
                                            <>Add Survey End Message <img src="/images/add-outline.svg" alt="" /></>
                                        }
                                    </a>
                                </div>
                                :
                                <div className="add-page-titile-fld mt-3">
                                    <input
                                        type="text"
                                        value={surveyEndMessage}
                                        onChange={e => setSurveyEndMessage(e.target.value)}
                                        onBlur={() => saveTitleFooter("surveyEndMessage")}
                                        autoFocus
                                        placeholder="Enter Survey End Message"
                                    />
                                </div>
                            }
                            {!showExpireDate ?
                                <div className="add-page-title-btn-wrap mt-3">
                                    <a className="btn-add-page-titile" onClick={() => setShowExpireDate(true)}>
                                        {expireDate ?
                                            moment(expireDate).format("MM/DD/YYYY")
                                            :
                                            <>Add Survey Expire Date <img src="/images/add-outline.svg" alt="" /></>
                                        }
                                    </a>
                                </div>
                                :
                                <div className="add-page-titile-fld mt-3">
                                    {/* <input
                                        type="date"
                                        value={expireDate}
                                        onChange={e => {setExpireDate(e.target.value); saveTitleFooter("expireDate",e.target.value)}}
                                        // onBlur={() => saveTitleFooter("expireDate")}
                                        min={moment(new Date()).format("YYYY-MM-DD")}
                                        autoFocus
                                        placeholder="Enter Survey Expire Date"
                                        onKeyDown={e=>e.preventDefault()}
                                    /> */}
                                    <DateInput
                                        value={expireDate}
                                        dateFormat="MM/dd/yyyy"
                                        minDate={new Date()}
                                        // showTimeInput={true}
                                        onChange={e => {saveTitleFooter("expireDate",e)}}
                                        placeholder="Enter Survey Expire Date"
                                        // onChange={e=>console.log(e,ques)}
                                    />
                                </div>
                            }
                            {surveyDetails && surveyDetails.questions && surveyDetails.questions.length ?
                                surveyDetails.questions.map((ques: any, index: any) =>
                                    <>
                                        <div className="survey-question-wrap"
                                            key={index}
                                            onMouseEnter={() => setShowPageBreakIcon(index)}
                                            onMouseLeave={() => setShowPageBreakIcon(-1)}
                                        >
                                            <div className="survey-question-head">
                                                <h2>{index + 1}. {ques.question} </h2>
                                                <div className='qst-answ-type'>{answerTypeList.find((a:any)=>ques?.answer_type?.id==a.id)?.display_name}</div>
                                                <div className="right-actions">
                                                    <a onClick={() => editQuestion(ques)} style={{ cursor: "pointer" }}>Edit</a>
                                                    {/* <a onClick={()=>dupicateQuestion(ques.id)} style={{cursor:"pointer"}}>Duplicate</a> */}
                                                    <a onClick={() => openDeleteDuplicateConfirmModal("duplicate", ques.id)} style={{ cursor: "pointer" }}>Duplicate</a>
                                                    {/* <a onClick={()=>deleteQuestion(ques.id)} style={{cursor:"pointer"}}>Delete</a> */}
                                                    <a onClick={() => openDeleteDuplicateConfirmModal("delete", ques.id)} style={{ cursor: "pointer" }}>Delete</a>
                                                </div>
                                            </div>
                                            {ques && ques.answer_option && ques.answer_option.length ?
                                                <div className="options-wrap">
                                                    <ul>
                                                        {ques.answer_option.map((ans: any) =>
                                                            <li key={ans.id}>
                                                                {ans.answer_option}
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                                : null
                                            }
                                            {/* <div className="page-break-icon" onClick={()=>addPageBreak(index+1)}>
                                                <img src="images/page-break-down-arrow.svg" alt="" />
                                                <span>Add Page Break</span>
                                            </div>                                             */}
                                            {
                                                pagebreakPositions?.includes(index + 1) ?
                                                    null :
                                                    surveyDetails?.survey_format && surveyDetails?.survey_format == "1" && showPageBreakIcon == index && showPageBreakIcon < surveyDetails.questions.length - 1 &&
                                                    <>
                                                        <div className="page-break-icon" onClick={() => addPageBreak(index + 1)}>
                                                            <img src="/images/page-break-down-arrow.svg" alt="" />
                                                            <span>Add Page Break</span>
                                                        </div>
                                                        {/* <div className="page-break-icon">
                                                            <i className="fa fa-plus" onClick={()=>addPageBreak(index+1)}></i>
                                                        </div> */}
                                                    </>
                                            }
                                        </div>
                                        {/* <hr className="dashed-2"/> */}
                                        {
                                            pagebreakPositions?.includes(index + 1) ?
                                                <div className="page-break-wrap">
                                                    <hr />
                                                    <span>Page Break</span>
                                                    <div className="page-break-delete"><i className="fa fa-trash" onClick={() => deletePageBreak(index + 1)}></i></div>
                                                </div>
                                                :
                                                surveyDetails?.survey_format && surveyDetails?.survey_format == "1" && showPageBreakIcon == index && showPageBreakIcon < surveyDetails.questions.length - 1 && <hr className="dashed-2" />
                                        }
                                        {ques.pagebreak && ques.pagebreak.map((pb: any) =>
                                            console.log(ques)
                                        )}
                                    </>
                                )
                                :
                                !questionTypeFields.length ?
                                    <div className="survey-question-wrap">
                                        <div className="survey-question-head">
                                            <h2>No Questions added yet</h2>
                                        </div>
                                    </div>
                                    : null
                            }
                            <DeleteDuplicateConfirmationModal
                                shouldShow={showDeleteDuplicateConfirmModal}
                                onClose={closeDeleteDuplicateConfirmModal}
                                warningText={"Are you sure you want to " + currentType + " this question?"}
                                handleConfirm={currentType == "delete" ? deleteQuestion : dupicateQuestion}
                                type={currentType}
                                id={currentId}
                            />
                            {questionTypeFields.map((item, index) => (
                                <div className="survey-question-wrap" key={item.id}>
                                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                                        <div className="question-with-multiple-choice-drop">
                                            <h2>{(surveyDetails && surveyDetails.questions && surveyDetails.questions.length ? surveyDetails.questions.length : 0) + index + 1}.</h2>
                                            <div className="text-drop-wrap row">
                                                {/* <span>{index + 1}</span> */}
                                                <div className="col-8">
                                                    <Controller
                                                        name={`questionType[${index}].question`}
                                                        control={control}
                                                        defaultValue={item.question} // make sure to set up defaultValue
                                                        render={({ onChange, onBlur, value, name, ref }) => (
                                                            <FormTextInput
                                                                name={name}
                                                                onChange={onChange}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                inputRef={ref}
                                                                type="text"
                                                                error={errors?.questionType?.[index]?.question}
                                                                placeholder="Enter Question..."
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <Controller
                                                        control={control}
                                                        name={`questionType[${index}].type`}
                                                        defaultValue={item.type} // make sure to set up defaultValue
                                                        render={({ onChange, onBlur, value, name, ref }) => (
                                                            <SelectInput
                                                                name={name}
                                                                onChange={e => {
                                                                    onChange(e)
                                                                    onTypeChange(e, index)
                                                                }}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                inputRef={ref}
                                                                options={answerTypeList.map((ans: any) => ({
                                                                    value: String(ans.id),
                                                                    label: ans.display_name,
                                                                }))}
                                                                error={errors?.questionType?.[index]?.type}
                                                                placeholder="Select Answer Type"
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                {/* <div className="col-1">
                                                    <a onClick={()=>questionTypeRemove(index)}>
                                                        <img src="/images/remove-icon.svg" alt="" />
                                                    </a>
                                                </div> */}
                                            </div>
                                        </div>
                                        {item.type && item.type.value && item.type.value == "15" ?
                                            <div className="multiple-questions-wrap">
                                                <div className="make-this-single-row-wrap">
                                                    <h3>Enter min and max values</h3>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <Controller
                                                                name={"min"}
                                                                control={control}
                                                                // defaultValue={}
                                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                                    <FormTextInput
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        onBlur={onBlur}
                                                                        value={value}
                                                                        inputRef={ref}
                                                                        type="number"
                                                                        min={0}
                                                                        error={errors.min}
                                                                        onKeyPress={(event: any) => handleKeyPress(event)}
                                                                        placeholder="Enter Min Value..."
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            <Controller
                                                                name={"max"}
                                                                control={control}
                                                                // defaultValue={}
                                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                                    <FormTextInput
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        onBlur={onBlur}
                                                                        value={value}
                                                                        inputRef={ref}
                                                                        type="number"
                                                                        min={0}
                                                                        error={errors.max}
                                                                        onKeyPress={(event: any) => handleKeyPress(event)}
                                                                        placeholder="Enter Max Value..."
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                        }
                                        <div className="multiple-questions-wrap">
                                            {item.type && item.type.value && (item.type.value == "4" || item.type.value == "5" || item.type.value == "6") ?
                                                <div className="make-this-single-row-wrap">
                                                    <h3>Enter the options</h3>
                                                    {answerOptionsFields.map((opt: any, ind: any) =>
                                                        <div className="make-this-single-row" key={ind}>
                                                            <Controller
                                                                name={`answerOptions[${ind}].option`}
                                                                control={control}
                                                                defaultValue={opt.option} // make sure to set up defaultValue
                                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                                    <FormTextInput
                                                                        name={name}
                                                                        onChange={onChange}
                                                                        onBlur={onBlur}
                                                                        value={value}
                                                                        inputRef={ref}
                                                                        type="text"
                                                                        error={errors?.answerOptions?.[ind]?.option}
                                                                        placeholder="Enter Answer..."
                                                                    />
                                                                )}
                                                            />
                                                            <div className="single-right-actions">
                                                                <a onClick={answerOptionsAppend}>
                                                                    <img src="/images/add-circle-outline-icon.svg" alt="" />
                                                                </a>
                                                                {ind > 0 ?
                                                                    <a onClick={() => answerOptionsRemove(ind)}>
                                                                        <img src="/images/remove-icon.svg" alt="" />
                                                                    </a>
                                                                    : null
                                                                }
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                : null
                                            }
                                            <div className="btn-wrap-right survey-btn-wrap-right">
                                                <a className="btn btn-cream" onClick={() => questionTypeRemove(index)}>
                                                    Cancel
                                                    <img className="fa" src="/images/remove-circle-outline-white.svg" />
                                                </a>
                                                <button className="btn btn-primary btn-save" type="submit">
                                                    Save
                                                    <img className="fa" src="/images/arrow-forward-outline.svg" />
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>)
                            )}
                            {!showFooter ?
                                <div className="add-page-title-btn-wrap mt-4">
                                    <a className="btn-add-page-titile" onClick={() => setShowFooter(true)}>
                                        {footerTitle ?
                                            footerTitle
                                            :
                                            <>Add Footer <img src="/images/add-outline.svg" alt="" /></>
                                        }
                                    </a>
                                </div>
                                :
                                <div className="add-page-titile-fld mt-4">
                                    <input
                                        type="text"
                                        // ref={footerRef}
                                        value={footerTitle}
                                        onChange={e => setFooterTitle(e.target.value)}
                                        onBlur={() => saveTitleFooter("footer")}
                                        autoFocus
                                        placeholder="Enter footer"
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
            <QuestionEditModal
                shouldShow={showEditQuestionModal}
                onClose={closeEditQuestionModal}
                getSurveyDetails={getSurveyDetails}
                currentQuestion={currentQuestion}
            />
        </>

    )
}

export default SurveyTwo