import { useDispatch } from 'react-redux'
import { ACTIONS } from '../../../../_config'

export function useAppUserAction() {

  const dispatch = useDispatch()

  const logout = () => {
    dispatch({
      type: ACTIONS.USER.LOGOUT,
    })
  }

  const setLink = (link:any) => {
    dispatch({
      type: ACTIONS.USER.SET_LINK,
      payload: link
    })
  }

  return {
    logout,
    setLink,
  }
}