import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useAppPetitionApi } from "src/_common/hooks/api/petition/appPetitionApiHook";
import { useAppPetitionDetailsSelector } from "src/_common/hooks/selectors/petition/petitionSelector";
import { useAppUserAuthSelector, useAppUserDetailsSelector } from "src/_common/hooks/selectors/user/userSelector";
import { getSignedTime } from "src/_config";
import LoginSignupModal from "src/components/login-signup/LoginSignupModal";

interface ChipinConfirmModalProps {
  onClose: () => void;
  shouldShow: boolean;
  handleConfirm: () => void;
}

const ChipinConfirmationModal = ({ onClose, shouldShow, handleConfirm }: ChipinConfirmModalProps) => {

  const petitionDetails = useAppPetitionDetailsSelector()
  const userDetails = useAppUserDetailsSelector()
  const petitionApi = useAppPetitionApi()
  const [chipInList, setChipInList] = useState<any>([])
  const isAuth = useAppUserAuthSelector()
  const [showLoginSignupModal, setShowLoginSignupModal] = useState<any>()

  const openLoginSignupModal = () => {
      setShowLoginSignupModal(true)
  }

  const closeLoginSignupModal = () => {
      if(isAuth) {
      }
      setShowLoginSignupModal(false)
  }

  const getChipInList = () => {
    petitionApi.callChipInList({petition_id:petitionDetails?.id},
      (message:any,resp:any)=>{
        setChipInList(resp.data)
      },
      (message:any)=>{
        
      }
    )
  }

  useEffect(()=>{
    const abortController = new AbortController()
    getChipInList()
    return () => {
      abortController.abort()
      // stop the query by aborting on the AbortController on unmount
    }
  },[shouldShow])

  return (
    <Modal
      show={shouldShow}
      backdrop="static"
      keyboard={false}
      className="stackholder-modal"
      centered
    >
      <Modal.Header>
        <h5 className="modal-title">
          {/* <strong>{userDetails?.first_name}, can you chip in ${petitionDetails?.chip_in_value} to get this petition on the agenda?</strong> */}
          <strong>Western, can you chip in ${petitionDetails?.chip_in_value} to get this petition on the agenda?</strong>
        </h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onClose}
        >
          <span>
            <i className="fa fa-times" aria-hidden="true"></i>
          </span>
        </button>
      </Modal.Header>
      <Modal.Body>

        <p>Getting support in a timely manner is critical to a petition's success.</p>
        <p>Your contribution can help Rural America In Action reach new potential supporters in a matter of hours.</p>
        <p>Chipping in allows us to promote the petition on Rural America In Action and through email.</p>
        <p>Impact</p>
        <p>People who've already chipped in helped this petition gather additional signatures.</p>

        <div className="sign_petition_list">
          <ul>
          {chipInList &&
            chipInList.map((item: any, index: any) => (
              <li key={index}>
                {item.user ?
                  <>
                    <span className="icon">
                      <img
                        src={item?.user?.avatar?.thumb}
                        alt="person"
                      />
                    </span>
                    <p>
                      {
                        (item?.anonymous_donation == 0) ?
                          <>
                            <span>{item?.user?.full_name}</span>chipped in{" "}
                          </>
                          :
                          <><span>Anonymous</span>chipped in{" "}</>
                      }
                      {getSignedTime(item?.created_at)}
                    </p>
                  </>
                  :
                  <>
                    <span className="icon">
                      <img
                        src="/images/no-profile.png"
                        alt="person"
                      />
                    </span>
                    <p>
                      <span>Anonymous</span>chipped in{" "}
                      {getSignedTime(item?.created_at)}
                    </p>
                  </>
                }
              </li>
            ))}
          </ul>
        </div>
        
        <div className="chipin-btn-wrap petetion_btn">
          <button type="button" className="btn btn-primary " onClick={handleConfirm}>Yes, I'll chip in ${petitionDetails?.chip_in_value} to distribute this petition</button>
          <button type="button" className="btn btn-secondary" onClick={onClose}>No, I'll share instead</button>
          {!isAuth &&
            <button type="button" className="btn btn-secondary signingbtn mt-0" onClick={openLoginSignupModal}>Login/Signup</button>
          }
        </div>

        {showLoginSignupModal &&
            <LoginSignupModal
                shouldShow={showLoginSignupModal}
                onClose={closeLoginSignupModal}
            />
        }

      </Modal.Body>
    </Modal>
  );
};

export default ChipinConfirmationModal;
