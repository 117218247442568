import React, { useEffect } from 'react'
import { Controller, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CheckboxInput from 'src/_common/components/form-elements/checkboxinput/checkboxInput';
import FormTextAreaInput from 'src/_common/components/form-elements/textarea/textareaInput';
import { useHistory, useParams } from 'react-router'
import { CRYPTO_SECRET_KEY, URLS } from 'src/_config'
import { useAppPetitionDetailsSelector } from 'src/_common/hooks/selectors/petition/petitionSelector';

interface SignPetitionProps {
    onClose: () => void;
    setParams:Function;
    petitionDetails: any;
 }

interface SignPetitionFormValues {
    display: boolean,
    reasons?: string,
    anonymous: boolean,
  }
  
const signPetitionSchema = yup.object().shape({
    display: yup
        .boolean(),
    reasons: yup
        .string(),
    anonymous: yup
        .boolean(),
})

const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);
const queryString = require("query-string");

const AuthSignPetitionForm = ( { petitionDetails, setParams, onClose }: SignPetitionProps) => {

    const { register, control, setValue, handleSubmit, watch, errors } = useForm<SignPetitionFormValues>({
      resolver: yupResolver(signPetitionSchema),
      defaultValues: {
        display: false,
        reasons: '',
        anonymous: false,
      },
      mode: 'onBlur'
    })

    const displayCheck = watch('display');
    const anonymousCheck = watch('anonymous');

    const {slug} = useParams<any>();
    // const id = parseInt(cryptr.decrypt(petitionId.id));
    // const slug = petitionId.slug;

    const onSubmit = (values: SignPetitionFormValues) => {
        let parms:any = {
            petition_id: petitionDetails?.id,
            show_only_me: values.display ? "1" : "0",
            is_anonymous: values.anonymous ? "1" : "0",
        }
        if(values.reasons)
        {
            parms["reasons"] = values.reasons
        }
        setParams(parms)
    }
    
    return (
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="check-wrap">
          {/* <div className="custom-check-container">
            <Controller
              control={control}
              name={"display"}
              render={({ onChange, onBlur, value, name, ref }) => (
              <CheckboxInput
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              inputRef={ref}
              error={errors.display}
              disabled={anonymousCheck}
              label=""
              />
              )}
            />
            <span>Only Me</span> 
          </div> */}
          <div className="custom-check-container">
            <Controller
              control={control}
              name={"anonymous"}
              render={({ onChange, onBlur, value, name, ref }) => (
              <CheckboxInput
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              inputRef={ref}
              error={errors.anonymous}
              disabled={displayCheck}
              label=""
              />
              )}
            />
            <span>Anonymous</span> 
          </div>        
        </div>
        <div>I’m signing because… (optional)</div>
        <Controller
          control={control}
          name="reasons"
          render={({ onChange, onBlur, value, name, ref }) => (
            <FormTextAreaInput
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            rows={2}
            // inputRef={inputRef}
            type="text"
            error={errors.reasons}
            placeholder="Enter your reason here..."
            />
          )}
        />
        <br />
        <button className="btn btn-primary" type="submit">
          Sign Petition{" "}
          <img className="fa" src={"/images/arrow-forward-outline.svg"} alt="forward"/>
        </button>
      </form>
    )
}

export default AuthSignPetitionForm
