import React, { useEffect, useState } from "react"
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook"
import { useAppFundraisingApi } from "src/_common/hooks/api/fundraising/appFundraisingApiHook"
import { useAppPetitionApi } from "src/_common/hooks/api/petition/appPetitionApiHook"
import { useAppFundraisingDetailsSelector } from "src/_common/hooks/selectors/fundraising/fundraisingSelector";
import { useAppPetitionDetailsSelector } from "src/_common/hooks/selectors/petition/petitionSelector";

interface GetReportProps {
    slug: any;
    page: any;
}

const GetReport = ({ slug, page }: GetReportProps) => {

    const [type, setType] = useState<any>("")
    const [errorMsg, setErrorMsg] = useState<boolean>(false)
    const petitionApi = useAppPetitionApi()
    const fundraisingApi = useAppFundraisingApi()
    const toast = useToaster()
    const petitionDetails = useAppPetitionDetailsSelector()
    const fundraisingDetails = useAppFundraisingDetailsSelector()

    const onTypeChange = (e: any) => {
        console.log(e.target.value)
        setErrorMsg(false)
        setType(e.target.value)
    }

    const downloadFile = (fileUrl: any) => {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.target = '_blank';
        link.setAttribute("download", fileUrl.substring(fileUrl.lastIndexOf('/') + 1));
        // console.log('link==>',link)
        document.body.appendChild(link);
        link.click();
        toast.success('Download started');
        document.body.removeChild(link);
    }

    const getReport = () => {
        console.log(slug, type)
        if (type && slug) {
            console.log(slug, type)
            let params: any = {
                type: type.toLowerCase()
            }
            let api: any
            if (page == "petition") {
                params["petition_id"] = petitionDetails?.id
                api = petitionApi
                // petitionApi.callCsvExport(
                //     params,
                //     (message: string, resp: any) => {
                //         console.log({ resp });
                //         downloadFile(resp.data.url)
                //         // toast.success(message);
                //     },
                //     (message: string) => {
                //         toast.error(message);
                //     }
                // );
            }
            if (page == "fundraising") {
                params["fundraiser_id"] = fundraisingDetails?.details?.id
                api = fundraisingApi
                // fundraisingApi.callCsvExport(
                //     params,
                //     (message: string, resp: any) => {
                //         console.log({ resp });
                //         downloadFile(resp.data.url)
                //         // toast.success(message);
                //     },
                //     (message: string) => {
                //         toast.error(message);
                //     }
                // );
            }
            api.callCsvExport(
                params,
                (message: string, resp: any) => {
                    console.log({ resp });
                    downloadFile(resp.data.url)
                    // toast.success(message);
                },
                (message: string) => {
                    toast.error(message);
                }
            );
        }
        else {
            setErrorMsg(true)
        }
    }

    return (
        <div className="white-rounded-box mb-4">
            <div className="white-box-header">
                Generate Report
            </div>
            <div className="white-box-body">
                <p className="normal-text">
                    Download your reports here.
                </p>
                <div className="get-repot-btns">
                    <select
                        // className="form-control"
                        onChange={onTypeChange}
                    >
                        <option value="">Select</option>
                        {page == "petition" && <option value="signature">Signatures</option>}
                        {page == "fundraising" && <option value="donner">Donors</option>}
                        {page == "petition" && <option value="comments">Comments</option>}
                    </select>
                    <a className="btn btn-post" onClick={getReport}>
                        Get Report
                    </a>
                </div>
                {errorMsg && <div className="invalid-feedback">Please select report type</div>}
            </div>
        </div>
    )
}

export default GetReport