import { ActionExtended } from "../../../_common/interfaces/ActionExtended";
import { ACTIONS } from "../../../_config";

export interface SurveyReducer {
  answer_type_list: any;
  create_survey: any;
  create_survey_page: any;
  survey_details: any;
  mySurveyList: any;
  surveyList: any;
  start_survey: any;
}

const initialState: SurveyReducer = {
  answer_type_list: null,
  create_survey: null,
  create_survey_page: null,
  survey_details: null,
  mySurveyList: null,
  surveyList: null,
  start_survey: null,
};

const surveyReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.SURVEY.GET_ANSWER_TYPE_LIST:
      return {
        ...state,
        answer_type_list: action.payload
      };
    case ACTIONS.SURVEY.CREATE_SURVEY:
      return {
        ...state,
        create_survey: action.payload
      };
    case ACTIONS.SURVEY.RESET_CREATE_SURVEY:
      return {
        ...state,
        create_survey: null
      };
    case ACTIONS.SURVEY.CREATE_SURVEY_PAGE:
      return {
        ...state,
        create_survey_page: action.payload
      };
    case ACTIONS.SURVEY.RESET_CREATE_SURVEY_PAGE:
      return {
        ...state,
        create_survey_page: null
    };
    case ACTIONS.SURVEY.SURVEY_DETAILS:
      return {
        ...state,
        survey_details: action.payload
      };
    case ACTIONS.SURVEY.SURVEY_PAGE_TITLE_FOOTER:
      return {
        ...state,
        survey_details: {
          ...state.survey_details,
          page_title: action.payload.page_title,
          footer_title: action.payload.footer_title
        }
      };
    case ACTIONS.SURVEY.RESET_MY_SURVEY_LIST:
      return {
        ...state,
        mySurveyList: null,
      };
    case ACTIONS.SURVEY.RESET_SURVEY_LIST:
      return {
        ...state,
        surveyList: null,
      };
    case ACTIONS.SURVEY.MY_SURVEY_LIST:
      return {
        ...state,
        mySurveyList: action.payload,
      };
    case ACTIONS.SURVEY.SURVEY_LIST:
      return {
        ...state,
        surveyList: action.payload,
      };
    case ACTIONS.SURVEY.RESET_SURVEY_DETAILS:
      return {
        ...state,
        survey_details: null
      };
    case ACTIONS.SURVEY.START_SURVEY:
      let data = action.payload
      console.log({data})
      let x:any = []
      if(state.start_survey && state.start_survey.length) {
        let q = state.start_survey.find((s:any)=>s.questionDetails.sort_order==data.questionDetails.sort_order)
        if(q) {
          x = state.start_survey.filter((s:any)=>s.questionDetails.sort_order!=data.questionDetails.sort_order)
          if(data.value) {
            x.push(data)
          }
        }
        else {
          x = state.start_survey
          x.push(data)
        }
      }
      else {
        if(data.value) {
          x.push(data)
        }
      }
      console.log(x)
      return {
        ...state,
        start_survey: x,
      };
    case ACTIONS.SURVEY.RESET_START_SURVEY:
      return {
        ...state,
        start_survey: null
      };
    default:
      return state;
  }
};

export default surveyReducer;
