import React from 'react';

const LazyBrowsePetitionPage = React.lazy(() =>
  import(/* webpackChunkName: "BrowsePetitionPage" */ './browsepetitionpage')
);

const BrowsePetitionPage = (props: Record<string, any>) => (
  <React.Suspense fallback={<div className="loader"></div>}>
    <LazyBrowsePetitionPage {...props} />
  </React.Suspense>
);

export default BrowsePetitionPage