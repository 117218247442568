import React, { useEffect, useState } from "react"
import { useHistory } from "react-router";
import Footer from "src/components/common/Footer";
import MetaDecorator from "src/components/common/MetaDecorator";
import NavbarMain from "src/components/common/navbar/NavbarMain";
import RightStickyBtn from "src/components/common/RightStickyBtn";
import ShareModal from "src/components/common/ShareModal";
import { useAppUserAction } from "src/_common/hooks/actions/user/appUserActionHook";
import { useAppFundraisingApi } from "src/_common/hooks/api/fundraising/appFundraisingApiHook";
import { useToaster } from "src/_common/hooks/common/appToasterHook";
import { useAppMyFundraisingListSelector } from "src/_common/hooks/selectors/fundraising/fundraisingSelector";
import { changeString, CRYPTO_SECRET_KEY, extractContent } from "src/_config";
import BrowseFundraisingBanner from "../browse-fundraising/BrowseFundraisingBanner";
import DeleteDuplicateConfirmationModal from "src/components/common/DeleteDuplicateConfirmationModal";

const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

const MyCauses = () => {

  const [myFundraisingList, setMyFundraisingList] = useState<any>([])
  const fundraisingApi = useAppFundraisingApi()
  const history = useHistory()
  const [showShareModal, setShowShareModal] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const myFundraisingListState = useAppMyFundraisingListSelector()
  const userAction = useAppUserAction()
  const [currentFundraisingId, setCurrentFundraisingId ] = useState<any>()
  const [currentFundraisingSlug, setCurrentFundraisingSlug ] = useState<any>()
  const toast = useToaster()
  const [showModal, setShowModal] = useState<boolean>(false)

  const openShareModal = (slug:any) => {
    setCurrentFundraisingSlug(slug)
    setShowShareModal(true)
  }

  const closeShareModal = () => {
    setShowShareModal(false)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const openModal = (id:any) => {
    setCurrentFundraisingId(id)
    setShowModal(true)
  }

  const loadMore = () => {
    let newPage = currentPage + 1
    getMyFundraisingList({ page: newPage })
    setCurrentPage(newPage)
  }

  const getMyFundraisingList = (params?: any) => {
    // if(!params?.category_id && categoryId && categoryId!=0)
    // {
    //   params["category_id"] = categoryId
    // }
    fundraisingApi.callGetMyFundraisingList(
      params,
      (message: string, resp: any) => {
        let x: any = []
        if (params?.page) {
          x = myFundraisingList
        }
        x = x.concat(resp.data.list.data)
        setMyFundraisingList(x)
      },
      (message: string) => {
      }
    )
  }

  const gotoDashboardPage = (slug: any) => {
    userAction.setLink("my-causes")
    history.push(`/my-fundraising-dashboard/${slug}`)
  }

  const deleteFundraiser = (id: any) => {
    let params = {
      fundraiser_id: id
    }
    fundraisingApi.callDeleteFundraiser(params,
      (message:any)=>{
        getMyFundraisingList()
        closeModal()
        toast.success(message)
      },
      (message:any)=>{
        toast.error(message)
      })
  }

  useEffect(() => {
    getMyFundraisingList()
  }, [])

  return (
    <>

      <MetaDecorator
        title={"My Causes"}
        description={"My Causes"}
      />
      
      <NavbarMain section={"fundraising"} />

      <BrowseFundraisingBanner />

      <div className="innerContentArea">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 mobm-30 order-md-2">
              <h3 className="section-title">MY FUNDRAISERS</h3>
              <div className="ruralamerican_list">
                <ul>
                  {myFundraisingList && myFundraisingList.length ?
                    myFundraisingList.map((f: any) =>
                      <li key={f?.id}>
                        <div className="ruralamerican_leftimg" onClick={() => gotoDashboardPage(f?.slug)}>
                          <img src={f?.fundraiser_image?.thumb} />
                        </div>
                        <div className="ruralamerican_rightcnt">
                          <div className="tending_bg">
                            <h5>
                              <img src="/images/pricetag-outline.svg" /> Trending in{" "}
                              <span>{f?.category?.title}</span>
                            </h5>
                            {f.status==0 && 
                            <a
                              style={{ cursor: "pointer", color: "#db3636" }}
                              className="see_more"
                              onClick={()=>openModal(f.id)}
                              title={"Delete Fundraising"}
                            >
                              <i className="fa fa-trash" aria-hidden="true"></i>
                              {/* Delete */}
                            </a>}
                            {/* <a className="see_more" onClick={()=>changeCategory(f?.category_id)}>
                            See More
                          </a> */}
                          </div>
                          <h4 onClick={() => gotoDashboardPage(f?.slug)}>{f?.title && changeString(extractContent(f?.title), 50)}</h4>
                          <p>
                            {f?.description && changeString(extractContent(f?.description), 200)}
                          </p>
                          <h6>
                            <span>${f?.total_fund_value?.total_amount ? f?.total_fund_value?.total_amount.toLocaleString() : 0}</span> have been raised. Let's get to ${f?.target_fund_value.toLocaleString()}!
                          </h6>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: `${f?.total_fund_value?.total_amount / f?.target_fund_value * 100}%` }}
                            // aria-valuenow={parseInt(f?.total_fund_value.total_amount)/parseInt(f?.target_fund_value)*100}
                            // aria-valuemin={0}
                            // aria-valuemax={100}
                            />
                          </div>
                          <div className="rodeo_name">
                            <div className="rodeo_nameleft">
                              <div className="rodeo_imageist">
                                <div className="rodeo_image">
                                  <img src={f?.user?.avatar?.thumb} />
                                </div>
                                <div className="rodeo_imagecontent">
                                  {f?.user?.full_name}
                                </div>
                              </div>
                              <div className="rodeo_imageist">
                                <div className="rodeo_image supportimg">
                                  <img src="/images/person-add-outline_blue.png" />
                                </div>
                                <div className="rodeo_imagecontent support_txt">
                                  <span>{f?.total_donor.toLocaleString()}</span> supporters
                                </div>
                              </div>
                              <div style={{ cursor: "pointer" }} className={(f?.status==1 && f?.is_payout_request==0 && f?.close_fundriser==0) ? "rodeo_imageist" : "d-none"} onClick={()=>openShareModal(f.slug)}>
                                <div className="rodeo_image supportimg">
                                  <img src="/images/share-social-outline.svg" />
                                </div>
                                <div className="rodeo_imagecontent support_txt">
                                  <span>
                                    Share
                                  </span>
                                </div>
                              </div>
                            </div>
                            <a onClick={() => gotoDashboardPage(f?.slug)} className="read_more">
                              Read More
                            </a>
                          </div>
                        </div>
                      </li>
                    )
                    :
                    <div className="col-lg-12 col-md-8">
                      <h1 className="alert-danger alert">No Fundraising Added Yet</h1>
                    </div>
                  }
                </ul>
              </div>
              {
                currentFundraisingSlug && showShareModal &&
                <ShareModal
                  shouldShow={showShareModal}
                  onClose={closeShareModal}
                  slug={currentFundraisingSlug}
                  section="fundraising"
                />
              }
              {myFundraisingListState?.last_page != currentPage ?
                <div style={{ cursor: "pointer" }} className="loadmore" onClick={() => loadMore()}>
                  <span>
                    <a>Load More</a>
                  </span>
                </div>
                : null}
            </div>
          </div>
        </div>
      </div>

      <RightStickyBtn />

      <Footer />
      { showModal && currentFundraisingId && 
        <DeleteDuplicateConfirmationModal
          shouldShow={showModal}
          onClose={closeModal}
          warningText={"Are you sure you want to delete this fundraiser?"}
          handleConfirm={deleteFundraiser}
          type={"delete"}
          id={currentFundraisingId}
        />
      }
    </>
  );
};

export default MyCauses
