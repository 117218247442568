import React from 'react'
import {useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import { URLS } from 'src/_config'
import moment from 'moment';
import axios from 'axios'
import { useCommonApi } from 'src/_common/hooks/api/common/appCommonApiHook';

const Footer = () => {

  // const [ip,setIP] = useState('');.
  const [cmsLinks, setCmsLinks] = useState<any>()
  const commonApi = useCommonApi()

  const getCmsLinks = () => {

    // let params = {
    //   type: "list"
    // }

    commonApi.callCmsLinks(
      (message:any, resp:any)=>{
        console.log(resp)
        setCmsLinks(resp.data)
      },
      (message:any)=>{

      }
    )
  }
  
  // //creating function to load ip address from the API
  // const getData = async()=>{
  //     const res = await axios.get('https://geolocation-db.com/json/')
  //     console.log(res.data);
  //     setIP(res.data.IPv4)
  // }
  
  useEffect(()=>{
      // //passing getData method to the lifecycle method
      // getData()
      // setTimeout(()=>{
        getCmsLinks()
      // },2000)
  },[])

  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <>
            <div className="col-lg-12 col-md-12">
              <div className="footer_logo_wrap">
                <div className="footer_logo">
                  <Link to={URLS.HOME}><img src={"/images/LOGO_NEW.png"} alt="footerlogo"/></Link>
                </div>
                <div className="foot-text">
                  <p>
                    Rural America In Action is a platform designed by rural Americans. In
                    our work, we found the online universe lacking a place that offered
                    the tools to better communicate with and support those that share
                    passion for rural American lifestyles.
                  </p>
                  <p>
                    Our safe and secure platform offers you the tools you need to help
                    your rural community, start a fundraiser for a cause you care about,
                    and get ideas and information from those in similar situations through
                    petitions and surveys.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="footerrht_top">
                <div className="footer_link">
                  <h4>Quick Links</h4>
                  <ul>
                    {cmsLinks && cmsLinks.length ?
                      cmsLinks.map((link:any)=>
                        <li key={link.id}><Link to={'/cms/'+link.slug}>{link.title}</Link></li>
                      )
                      : null
                    }
                  </ul>
                </div>
              </div>
              <div className="footerrht_bot">
                <div className="copyright_txt d-flex align-items-center justify-content-between">
                  <a>© 2010-{moment().format("YYYY")} Rural America In Action</a>
                  <div className="footer_social">
                    <ul>
                      <li><a href={"https://www.facebook.com/RuralAmericaInAction/about"} target="_blank"><img src={"/images/logo-facebook.png"} alt="facebook"/></a></li>
                      <li><a><img src={"/images/logo-twitter.png"} alt="twitter"/></a></li>
                      <li><a><img src={"/images/logo-instagram.png"} alt="instagram"/></a></li>
                      <li><a><img src={"/images/logo-youtube.png"} alt="youtube"/></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  )
}

export default Footer