import React from 'react'
import StartSurvey from 'src/components/survey/start-survey/StartSurvey'

export default function StartSurveyPage() {
  return (
    <React.Fragment>
       <StartSurvey />
    </React.Fragment>
  )
}
