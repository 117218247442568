import React from 'react';

const LazyLogin = React.lazy(() =>
  import(/* webpackChunkName: "Login" */ './login')
);

const Login = (props: Record<string, any>) => (
  <React.Suspense fallback={<div className="loader"></div>}>
    <LazyLogin {...props} />
  </React.Suspense>
);

export default Login