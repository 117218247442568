import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import Footer from 'src/components/common/Footer'
import MetaDecorator from 'src/components/common/MetaDecorator'
import NavbarMain from 'src/components/common/navbar/NavbarMain'
import RightStickyBtn from 'src/components/common/RightStickyBtn'
import { useAppSurveyAction } from 'src/_common/hooks/actions/survey/appSurveyActionHook'
import { useAppCreateSurveyPageSelector } from 'src/_common/hooks/selectors/survey/surveySelector'
import { CRYPTO_SECRET_KEY } from 'src/_config'
import SurveyOne from './components/SurveyOne'
import SurveyPreview from './components/SurveyPreview'
import SurveyTwo from './components/SurveyTwo'
import SurveyTwoStyle from './components/SurveyTwoStyle'

const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

const CreateSurvey = () => {

  const { slug } = useParams<any>()
  // const surveyId = id ? cryptr.decrypt(id) : null
  const surveyAction = useAppSurveyAction()
  const pageSelector = useAppCreateSurveyPageSelector()

  const [pageChangeBtn, setPageChangeBtn] = useState<string>(pageSelector?pageSelector:"survey_one");

  useEffect(()=>{
    if(!slug) {
      setPageChangeBtn("survey_one")
      surveyAction.resetSurveyDetails()
    }
  },[slug])

  // useEffect(()=>{
  //   if(pageSelector && surveyId) {
  //     setPageChangeBtn(pageSelector)
  //     console.log({pageSelector})
  //   }
  // },[pageSelector, surveyId])

  useEffect(()=>{

    return ()=> {
      surveyAction.resetCreateSurveyPage()
    }
  },[])

  return (
    <>

      <MetaDecorator
          title={"Create Survey"}
          description={"Create Survey"}
      />

      <NavbarMain section={"survey"} />

      <RightStickyBtn />

      {pageChangeBtn && pageChangeBtn === "survey_one" ? <SurveyOne setPageChangeBtn={setPageChangeBtn} /> : null}

      {pageChangeBtn && pageChangeBtn === "survey_two" ? <SurveyTwo setPageChangeBtn={setPageChangeBtn} /> : null}
      
      {/* {pageChangeBtn && pageChangeBtn === "survey_two_style" ? <SurveyTwoStyle setPageChangeBtn={setPageChangeBtn} /> : null} */}

      {pageChangeBtn && pageChangeBtn === "survey_preview" ? <SurveyPreview setPageChangeBtn={setPageChangeBtn} /> : null}

      <Footer />
    </>
  )
}

export default CreateSurvey