import React from 'react'
import MyFundraisingSettings from 'src/components/fundraising/my-causes/MyFundraisingSettings_bkp'

export default function MyFundraisingSettingsPage() {
  return (
    <React.Fragment>
       <MyFundraisingSettings />
    </React.Fragment>
  )
}
