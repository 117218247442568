import React from 'react'
import FundraisingDonate from 'src/components/fundraising/fundraising-donate/FundraisingDonate'

export default function FundraisingDonatePage() {
  return (
    <React.Fragment>
       <FundraisingDonate />
    </React.Fragment>
  )
}
