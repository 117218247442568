import React, { useEffect, useRef, useState } from "react";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import Footer from "../../common/Footer";
import RightStickyBtn from "../../common/RightStickyBtn";
import NavbarMain from "src/components/common/navbar/NavbarMain";
import { useAppFundraisingApi } from "src/_common/hooks/api/fundraising/appFundraisingApiHook";
import BrowseFundraisingBanner from "./BrowseFundraisingBanner";
import { useAppFundraisingCategoryListSelector, useAppFundraisingListSelector } from "src/_common/hooks/selectors/fundraising/fundraisingSelector";
import { Link, useHistory } from "react-router-dom";
import { changeString, CRYPTO_SECRET_KEY, extractContent } from "src/_config";
import ShareModal from "src/components/common/ShareModal";
import { useAppFundraisingAction } from "src/_common/hooks/actions/fundraising/appFundraisingActionHook";
import { useAppSearchKeywordSelector } from "src/_common/hooks/selectors/common/commonSelector";
import { useAppGlobalAction } from "src/_common/hooks/actions/common/appGlobalActionHook";
import MetaDecorator from "src/components/common/MetaDecorator";

const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);
const queryString = require("query-string");

const BrowseFundraising = () => {

  const fundraisingApi = useAppFundraisingApi()
  const fundraisingAction = useAppFundraisingAction()
  const categoryList = useAppFundraisingCategoryListSelector()
  const fundraisingListState = useAppFundraisingListSelector()
  const toast = useToaster()
  const history = useHistory()
  const [showShareModal, setShowShareModal] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [categoryId, setCategoryId] = useState<number>(0)
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(-1)
  const [fundraisingFor, setFundraisingFor] = useState<number>(0)
  const [fundraisingList, setFundraisingList] = useState<any>([])
  const [fundraiseSearchValue, setFundraiseSearchValue] = useState<any>('')
  // const searchKeyword = useAppSearchKeywordSelector()
  const globalAction = useAppGlobalAction()
  const [currentFundraisingSlug, setCurrentFundraisingSlug ] = useState<any>()
  const fundFor = localStorage.getItem("fundFor")    
  const inputRef = useRef<any>()

  const loadMore = () => {
    let newPage = currentPage + 1
    getFundraisingList({ page: newPage })
    setCurrentPage(newPage)
  }

  const changeCategory = (id: any) => {
    window.scrollTo(0,0)
    fundraisingAction.resetFundraisingList()
    setCategoryId(id)
    setSelectedCategoryId(id)
    setFundraisingFor(0)
    setCurrentPage(1)
    getFundraisingList({ category_id: id })
  }

  const changeSearch = (id?: any) => {
    window.scrollTo(0,0)
    fundraisingAction.resetFundraisingList()
    setCategoryId(0)
    setSelectedCategoryId(0)
    setFundraisingFor(0)
    setCurrentPage(1)
    // getFundraisingList({ title: searchKeyword ? searchKeyword : fundraiseSearchValue })
    getFundraisingList({ title: fundraiseSearchValue })
  }

  const getFundraisingList = (params?: any) => {
    if (params?.page && fundraisingFor && fundraisingFor != 0) {
      params["fundraising_for"] = fundraisingFor
    }
    if (params?.page && categoryId && categoryId != 0) {
      params["category_id"] = categoryId
    }
    fundraisingApi.callGetFundraisingList(
      params,
      (message: string, resp: any) => {
        let x: any = []
        if (params?.page) {
          x = fundraisingList
        }
        x = x.concat(resp.data.list.data)
        setFundraisingList(x)
      },
      (message: string) => {
      }
    )
  }

  const getFundraisingCategoryList = () => {
    fundraisingApi.callgetFundraisingCategoryList(
      (message: string, resp: any) => {
      },
      (message: string) => { }
    );
  }

  const gotoDetailsPage = (slug: any) => {
    history.push(`/fundraising/${slug}`)
  }

  const openShareModal = (slug:any) => {
    setCurrentFundraisingSlug(slug)
    setShowShareModal(true)
  }

  const closeShareModal = () => {
    setShowShareModal(false)
  }

  const changeFundFor = (id: any) => {
    window.scrollTo(0,0)
    fundraisingAction.resetFundraisingList()
    setFundraisingFor(id)
    setCategoryId(0)
    setSelectedCategoryId(0)
    setCurrentPage(1)
    getFundraisingList({ fundraising_for: id })
  }

  const handleReset = () => {
    window.scrollTo(0,0)
    if(inputRef?.current) {
      inputRef.current.value = ''
    }
    getFundraisingList()
    setSelectedCategoryId(-1)
  }

  useEffect(() => {
    getFundraisingCategoryList()
    getFundraisingList()
  }, [])

  useEffect(() => {
    // console.log({ fundraisingList })
  }, [fundraisingList])

  // useEffect(() => {
  //     if(searchKeyword) {
  //         console.log({searchKeyword})
  //         setFundraiseSearchValue(searchKeyword)
  //         changeSearch()
  //         // setTimeout(()=>{
  //         // },500)
  //         globalAction.resetSearchKeyword()
  //     }
  // }, [searchKeyword])

  useEffect(()=>{
    if(fundFor) {
      changeFundFor(fundFor)
    localStorage.removeItem("fundFor")
    }
  },[fundFor])

  return (
    <>

      <MetaDecorator
        title={"Browse Fundraising"}
        description={"Browse Fundraising"}
      />

      <NavbarMain section={"fundraising"} />

      <BrowseFundraisingBanner />

      <div className="innerContentArea">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 order-md-1">
              <div className="search-box-left">
              <h3>SEARCH BY:</h3>
              <div>
                {/* <button type="button" className="btn btn-outline-primary" ></button> */}
              </div>
              <div className="nav_search">
                <input className="search_input" type="text" placeholder="Search by title..." ref={inputRef} onChange={(e) => setFundraiseSearchValue(e.target.value)} />
                <button className="nav_searchbtn" onClick={changeSearch}></button>
                <span className="reload-btn" onClick={handleReset}><i className="fa fa-refresh" ></i></span>
              </div>
              </div>
              
              <h3>TOPICS</h3>
              <div className="left-sidebar-wrap">
                <ul className="feature_list">
                  <li style={{ cursor: "pointer" }} onClick={() => { window.scrollTo(0,0); getFundraisingList(); setSelectedCategoryId(-1) }}
                    className={selectedCategoryId == -1 ? "active" : ""}>
                    All
                  </li>
                  {categoryList && categoryList.length &&
                    categoryList.map((c: any) =>
                      <li key={c.id} style={{ cursor: "pointer" }} onClick={() => changeCategory(c.id)} className={selectedCategoryId == c?.id ? "active" : ""}>
                        {c.title}
                      </li>
                    )}
                </ul>
              </div>
              <h3>FUNDRAISE FOR...</h3>
              <ul className="fundraising-for-list" style={{cursor:"pointer"}}>
                <li>
                  <a onClick={() => changeFundFor("1")}>
                    <img src="/images/Layer_2.png" alt="" /> <span>Crisis Relief</span>
                  </a>
                </li>
                <li>
                  <a onClick={() => changeFundFor("2")}>
                    <img src="/images/yourself-icon2a.png" alt="" />{" "}
                    <span>Community Initiatives</span>
                  </a>
                </li>
                <li>
                  <a onClick={() => changeFundFor("3")}>
                    <img src="/images/yourself-icon3a.png" alt="" /> <span>Event</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-9 col-md-8 mobm-30 order-md-2">
              <h3 className="section-title">All Approved Fundraisers</h3>
              <div className="ruralamerican_list">
                <ul>
                  {fundraisingList && fundraisingList.length ?
                    fundraisingList.map((f: any) =>
                      <li key={f?.id}>
                        <div className="ruralamerican_leftimg" onClick={() => gotoDetailsPage(f?.slug)}>
                          <img src={f?.fundraiser_image?.thumb} />
                        </div>
                        <div className="ruralamerican_rightcnt">
                          <div className="tending_bg">
                            <h5>
                              <img src="/images/pricetag-outline.svg" /> Trending in{" "}
                              <span>{f?.category?.title}</span>
                            </h5>
                            <a className="see_more" onClick={() => changeCategory(f?.category_id)}>
                              See More
                            </a>
                          </div>
                          <h4 onClick={() => gotoDetailsPage(f?.slug)}>{f?.title && changeString(extractContent(f?.title), 50)}</h4>
                          <p>
                            {f?.description && changeString(extractContent(f?.description), 150)}
                          </p>
                          <h6>
                            <span>${f?.total_fund_value?.total_amount ? f?.total_fund_value?.total_amount.toLocaleString() : 0}</span> USD raised of ${f?.target_fund_value.toLocaleString()} goal!
                          </h6>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: `${f?.total_fund_value?.total_amount / f?.target_fund_value * 100}%` }}
                            // aria-valuenow={parseInt(f?.total_fund_value.total_amount)/parseInt(f?.target_fund_value)*100}
                            // aria-valuemin={0}
                            // aria-valuemax={100}
                            />
                          </div>
                          <div className="rodeo_name">
                            <div className="rodeo_nameleft">
                              <div className="rodeo_imageist">
                                <div className="rodeo_image">
                                  <img src={f?.user?.avatar?.thumb} />
                                </div>
                                <div className="rodeo_imagecontent">
                                  {f?.user?.full_name}
                                </div>
                              </div>
                              <div className="rodeo_imageist">
                                <div className="rodeo_image supportimg">
                                  <img src="/images/person-add-outline_blue.png" />
                                </div>
                                <div className="rodeo_imagecontent support_txt">
                                  <span>{f?.total_donor.toLocaleString()}</span> supporters
                                </div>
                              </div>
                              <div style={{ cursor: "pointer" }} className={(f?.status==1 && f?.is_payout_request==0 && f?.close_fundriser==0) ? "rodeo_imageist" : "d-none"} onClick={()=>openShareModal(f.slug)}>
                                <div className="rodeo_image supportimg">
                                  <img src="/images/share-social-outline.svg" />
                                </div>
                                <div className="rodeo_imagecontent support_txt">
                                  <span>
                                    Share
                                  </span>
                                </div>
                              </div>
                            </div>
                            <a onClick={() => gotoDetailsPage(f?.slug)} className="read_more">
                              Read More
                            </a>
                          </div>
                        </div>
                      </li>
                    )
                    :
                    <div className="col-lg-12 col-md-8">
                      <h1 className="alert-danger alert">No Fundraisings Added Yet</h1>
                    </div>
                  }
                </ul>
              </div>
              {
                currentFundraisingSlug && showShareModal &&
                <ShareModal
                  shouldShow={showShareModal}
                  onClose={closeShareModal}
                  slug={currentFundraisingSlug}
                  section="fundraising"
                />
              }
              {fundraisingListState?.last_page != currentPage ?
                <div style={{ cursor: "pointer" }} className="loadmore" onClick={() => loadMore()}>
                  <span>
                    <a>Load More</a>
                  </span>
                </div>
                : null}
            </div>
          </div>
        </div>
      </div>

      <RightStickyBtn />

      <Footer />

      {/* <ShareModal
        shouldShow={showShareModal}
        onClose={()=>closeShareModal()}
        id={cryptr.encrypt(fundraisingId)}
        section="fundraising"
      /> */}

    </>
  );
};

export default BrowseFundraising;
