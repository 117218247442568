import { useSelector } from 'react-redux'
import { StateExtended } from '../../../interfaces/StateExtended'


export function useAppPetitionCategoryListSelector() {
  const categoryList: any = useSelector((state: StateExtended) => state.petition.categoryList)
  return categoryList
}

export function useAppPetitionListSelector() {
  const petitionList: any = useSelector((state: StateExtended) => state.petition.petitionList)
  return petitionList
}

export function useAppPetitionFeaturedTopicListSelector() {
  const petitionfeaturedTopicList: any = useSelector((state: StateExtended) => state.petition.featuredTopicList)
  return petitionfeaturedTopicList
}
export function useAppCreatePetitionSelector() {
  const createPetition: any = useSelector((state: StateExtended) => state.petition.createPetition)
  return createPetition
}

export function useAppPetitionDetailsSelector() {
  const petitionDetails: any = useSelector((state: StateExtended) => state.petition.petitionDetails)
  return petitionDetails
}

export function useAppPetitionTipsSelector() {
  const tips: any = useSelector((state: StateExtended) => state.petition.tips)
  return tips
}

export function useAppPetitionUpdatesListSelector() {
  const tips: any = useSelector((state: StateExtended) => state.petition.updatesList)
  return tips
}

export function useAppPetitionSignPetitionSelector() {
  const signPetition: any = useSelector((state: StateExtended) => state.petition.signPetition)
  return signPetition
}
