import React from 'react'
import EditMyPetition from 'src/components/petition/my-petition-dashboard/EditMyPetition'

export default function MyPetitionEditPage() {
  return (
    <React.Fragment>
       <EditMyPetition />
    </React.Fragment>
  )
}
