import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import moment from 'moment'
import { Controller, useFieldArray, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormTextAreaInput from 'src/_common/components/form-elements/textarea/textareaInput'
import { useAppPetitionApi } from 'src/_common/hooks/api/petition/appPetitionApiHook';
import FormTextInput from 'src/_common/components/form-elements/textinput/formTextInput';
import { useToaster } from 'src/_common/hooks/common/appToasterHook';
import { useAppFundraisingApi } from 'src/_common/hooks/api/fundraising/appFundraisingApiHook';
import { useAppFundraisingDetailsSelector } from 'src/_common/hooks/selectors/fundraising/fundraisingSelector';
import SelectInput from 'src/_common/components/form-elements/selectinput/selectInput';
import { useAppAnswerTypeListSelector, useAppSurveyDetailsSelector } from 'src/_common/hooks/selectors/survey/surveySelector';
import { OptionValue } from 'src/_common/interfaces/common';
import { useAppSurveyApi } from 'src/_common/hooks/api/survey/appSurveyApiHook';

interface QuestionEditModalProps {
   onClose: () => void;
   shouldShow: boolean;
   getSurveyDetails: Function;
   currentQuestion: any
}

interface Option {
    option:string
}

interface QuestionEditFormValues {
    question: string,
    type: OptionValue | undefined,
    answerOptions: Option[],
    min:string,
    max:string,
}

const QuestionEditSchema = yup.object().shape({
    question: yup
        .string()
        .required('Question is required'),
    type: yup
        .object()
        .nullable()
        .shape({
            value: yup.string().required('Type is required'),
        })
        .required('Type is required'),
    answerOptions: yup.array().of(
        yup.object().shape({
            option: yup.string()
                .required('Option is required'), 
        })
    ),
    min: yup
        .string()
        .when("type", (key: any) => {
            console.log({key})
            if (key.value && key.value == '15')
                return yup.string().required("Min Value is required")
        }),
        // .required('Min Value is required'),
    max: yup
        .string()
        .when("type", (key: any) => {
            console.log({key})
            if (key.value && key.value == '15')
                return yup.string().required("Max Value is required")
        }),
})

export default function QuestionEditModal({ onClose, shouldShow, getSurveyDetails, currentQuestion }: QuestionEditModalProps) {

    const toast = useToaster()
    const answerTypeList = useAppAnswerTypeListSelector()
    const [currentType, setCurrentType] = useState<any>()
    const surveyApi = useAppSurveyApi()
    const surveyDetails = useAppSurveyDetailsSelector()

    const { register, control, setValue, handleSubmit, errors } = useForm<QuestionEditFormValues>({
        resolver: yupResolver(QuestionEditSchema),
        defaultValues: {
            question: '',
            type: undefined,
            answerOptions: [
              {
                  option:''
              }
            ]
        },
    })

    const {
        fields: answerOptionsFields,
        append: answerOptionsAppend,
        prepend: answerOptionsPrepend,
        remove: answerOptionsRemove
        } = useFieldArray({ control, name: `answerOptions` })

    const onTypeChange = (e:any) => {
        setCurrentType(e)
        setValue("answerOptions",[{option:''}])
    }

    const handleKeyPress = (event: any) => {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
      }
    }

    const onSubmit = (values: QuestionEditFormValues) => {
        console.log({values})
        if(values) {
            let params: any = {
                id: currentQuestion?.id,
                survey_id: surveyDetails?.id,
                answer_type: values?.type?.value,
                question: values.question,
            }
            if(values?.type?.value) {
                params["answer_type"] = values?.type?.value
            }
            if(values.answerOptions) {
                let optionsArr:any = []
                values.answerOptions.map((opt:any)=>{
                    optionsArr.push(opt.option)
                })
                if(optionsArr && optionsArr.length) {
                    params["answer_option"] = optionsArr
                }
            }
            if(values.min && values.max) {
                params["answer_option"]=[values.min,values.max]
            }
            surveyApi.callAddEditQuestion(
                params,
                (message: string, resp: any) => {
                    getSurveyDetails()
                    onClose()
                    // console.log({resp})
                },
                (message: string) => {
                    toast.error(message)
                }
            )
        }
    }

    useEffect(()=>{
        if(currentQuestion && shouldShow) {
            if(currentQuestion.question) {
                setValue("question", currentQuestion.question)
            }
            if(currentQuestion.answer_type) {
                let type = {value:String(currentQuestion.answer_type.id),label:answerTypeList.find((x:any)=>x.id==currentQuestion.answer_type.id).display_name}
                setCurrentType(type)
                setValue("type",type)
            }
            if(currentQuestion.answer_option && currentQuestion.answer_option.length) {
                let len = answerOptionsFields.length
                for(let i=0;i<len;i++) {
                    answerOptionsRemove(0)
                }
                currentQuestion.answer_option.map((op:any)=>{
                    answerOptionsAppend({option:op.answer_option})
                })
                console.log({currentQuestion})
                if(currentQuestion.answer_type.id==15) {
                    console.log({currentQuestion})
                    setValue("min",currentQuestion.answer_option[0].answer_option)
                    setValue("max",currentQuestion.answer_option[1].answer_option)
                }
            }
        }
    },[currentQuestion, shouldShow])

   return (
      <React.Fragment>
         <Modal
            show={shouldShow}
            backdrop="static"
            keyboard={false}
            className="stackholder-modal"
            centered
         >
            <Modal.Header>
               {/* <img src="/images/pop-hand-left.svg" alt="" /> */}
               <h5 className="modal-title"><strong>Edit Question</strong></h5>
               <button type="button" className="close" data-dismiss="modal" aria-label="Close"  onClick={()=>onClose()}>
                  <span><i className="fa fa-times" aria-hidden="true"></i></span>
               </button>
            </Modal.Header>
            <Modal.Body>
                {/* <div className="container-fluid text-center p-4 border rounded"> */}
                <div>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <div className="form-wrap">
                            <div className="row">
                                <div className="col mb-3">
                                    <Controller
                                    control={control}
                                    name="question"
                                    defaultValue={currentQuestion?.question}
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <FormTextInput
                                        name={name}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        inputRef={ref}
                                        type="text"
                                        error={errors.question}
                                        placeholder="Enter Question..."
                                        />
                                    )}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col mb-3">
                                    <div className="form-group">
                                    <Controller
                                        control={control}
                                        name={"type"}
                                        // defaultValue={{value:String(currentQuestion?.answer_type?.id),label:currentQuestion?.answer_type?.display_name}} // make sure to set up defaultValue
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                        <SelectInput
                                            name={name}
                                            onChange={e=>{
                                                onChange(e)
                                                onTypeChange(e)
                                            }}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            options={answerTypeList.map((ans: any) => ({
                                                value: String(ans.id),
                                                label: ans.display_name,
                                            }))}
                                            error={errors.type}
                                            placeholder="Select Answer Type"
                                        />
                                        )}
                                    />
                                    </div>
                                </div>
                            </div>
                            {currentType && currentType.value && (currentType.value=="4" || currentType.value=="5" || currentType.value=="6") ? 
                                <div className="make-this-single-row-wrap">
                                    <h3>Enter the options</h3>
                                    {answerOptionsFields.map((opt:any,ind:any)=>
                                        <div className="make-this-single-row">
                                            <Controller
                                                name={`answerOptions[${ind}].option`}
                                                control={control}
                                                defaultValue={opt.option} // make sure to set up defaultValue
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                <FormTextInput
                                                    name={name}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    inputRef={ref}
                                                    type="text"
                                                    error={errors?.answerOptions?.[ind]?.option}
                                                    placeholder="Enter Answer..."
                                                />
                                                )}
                                            />
                                            {/* <input
                                                type="text"
                                                placeholder="Enter a answer"
                                                className="form-control"
                                            /> */}
                                            <div className="single-right-actions">
                                                <a onClick={answerOptionsAppend}>
                                                    <img src="/images/add-circle-outline-icon.svg" alt="" />
                                                </a>
                                                {ind>0 ? 
                                                    <a onClick={()=>answerOptionsRemove(ind)}>
                                                        <img src="/images/remove-icon.svg" alt="" />
                                                    </a>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                                :null
                            }
                            {currentType && currentType.value && currentType.value == "15" ?
                                <div className="make-this-single-row-wrap">
                                    <h3>Enter min and max values</h3>
                                    <div className="row">
                                        <div className="col-6">
                                            <Controller
                                                name={"min"}
                                                control={control}
                                                // defaultValue={}
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <FormTextInput
                                                        name={name}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        value={value}
                                                        inputRef={ref}
                                                        type="number"
                                                        min={0}
                                                        error={errors.min}
                                                        placeholder="Enter Min Value..."
                                                        onKeyPress={(event: any) => handleKeyPress(event)}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <Controller
                                                name={"max"}
                                                control={control}
                                                // defaultValue={}
                                                render={({ onChange, onBlur, value, name, ref }) => (
                                                    <FormTextInput
                                                        name={name}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        value={value}
                                                        inputRef={ref}
                                                        type="number"
                                                        min={0}
                                                        error={errors.max}
                                                        placeholder="Enter Max Value..."
                                                        onKeyPress={(event: any) => handleKeyPress(event)}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                :null
                            }
                            <div className="btn-wrap">
                                <button type="submit" className="btn btn-primary mt-4">
                                    Submit
                                    <img className="fa" src={"/images/arrow-forward-outline.svg"} alt="forward"/>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal.Body>
            {/* <Modal.Footer /> */}
         </Modal>
      </React.Fragment>
   )
}