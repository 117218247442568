import { useDispatch } from 'react-redux'
import { ACTIONS } from '../../../../_config'

export function useAppPetitionAction() {

  const dispatch = useDispatch()

  const createPetition = (data: any) => {
    dispatch({
      type: ACTIONS.PETITION.CREATE_PETITION,
      payload: data
    })
  }

  const resetCreatePetition = () => {
    dispatch({
      type: ACTIONS.PETITION.RESET_CREATE_PETITION
    })
  }

  const resetPetitionList= () => {
    dispatch({
      type: ACTIONS.PETITION.RESET_LIST
    })
  }

  const resetPetitionDetails = () => {
    dispatch({
      type: ACTIONS.PETITION.PETITION_DETAILS,
      payload: {petition_details: null}
    })
  }

  return {
    createPetition,
    resetCreatePetition,
    resetPetitionList,
    resetPetitionDetails
  }
}