import React from 'react';

const LazySurveyDetailsPage = React.lazy(() =>
  import(/* webpackChunkName: "SurveyDetailsPage" */ './surveydetailspage')
);

const SurveyDetailsPage = (props: Record<string, any>) => (
  <React.Suspense fallback={<div className="loader"></div>}>
    <LazySurveyDetailsPage {...props} />
  </React.Suspense>
);

export default SurveyDetailsPage