import React, { useEffect, useState } from 'react'

interface SurveyBannerProps {
    page: number
}

const SurveyBanner = ({ page }: SurveyBannerProps) => {

    return (
        <div className="innerpageBanner survey-create-banner">
                <div className="container">
                    <div className="step-survey-wrap">
                        <div className="step-survey-box">
                            <a 
                                // onClick={() => setPageChangeBtn("survey_one")}
                                className={page>=1?"active":""}
                            >
                                <span>1</span>
                                <em>Summary</em>
                            </a>
                        </div>
                        <div className="step-survey-box">
                            <a
                                // onClick={() => setPageChangeBtn("survey_two")}
                                className={page>=2?"active":""}
                            >
                                <span>2</span>
                                <em>DESIGN SURVEY</em>
                            </a>
                        </div>
                        <div className="step-survey-box">
                            <a
                                // onClick={() => setPageChangeBtn("survey_preview")}
                                className={page>=3?"active":""}
                            >
                                <span>3</span>
                                <em>PREVIEW</em>
                            </a>
                        </div>
                    </div>
                    <div className="survey-progerss">
                        <div className="progress">
                            <div
                                className="progress-bar"
                                role="progressbar"
                                aria-label="Basic example"
                                style={{ width: (page*100/3)+"%" }}
                            />
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default SurveyBanner;