import { useSelector } from 'react-redux'
import { StateExtended } from '../../../interfaces/StateExtended'

export function useAppAnswerTypeListSelector() {
  const answer_type_list: any = useSelector((state: StateExtended) => state.survey.answer_type_list)
  return answer_type_list
}

export function useAppCreateSurveySelector() {
  const create_survey: any = useSelector((state: StateExtended) => state.survey.create_survey)
  return create_survey
}

export function useAppCreateSurveyPageSelector() {
  const create_survey_page: any = useSelector((state: StateExtended) => state.survey.create_survey_page)
  return create_survey_page
}

export function useAppSurveyDetailsSelector() {
  const survey_details: any = useSelector((state: StateExtended) => state.survey.survey_details)
  return survey_details
}

export function useAppMySurveyListSelector() {
  const mySurveyList: any = useSelector((state: StateExtended) => state.survey.mySurveyList)
  return mySurveyList
}

export function useAppSurveyListSelector() {
  const surveyList: any = useSelector((state: StateExtended) => state.survey.surveyList)
  return surveyList
}

export function useAppStartSurveySelector() {
  const start_survey: any = useSelector((state: StateExtended) => state.survey.start_survey)
  return start_survey
}