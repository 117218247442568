import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { URLS } from 'src/_config'
import StartPetitionButton from './StartPetitionButton'

const GotoStartPetition = () => {

    const history = useHistory()

    const gotoCreatePetition = () => {
        history.push(URLS.PETITION.START_PETITION)
    }

    return (
    <div className="petetion_own">
        <div className="petetion_ownimg">
            <img src={"/images/pitition-earth-icon.png"} alt="petition"/>
        </div>
        <div className="petetion_owntxt">
            <h3>START A PETITION OF YOUR OWN</h3>
            <p>
                This petition starter stood up and took action. Will you do the same?
            </p>
        </div>
        <StartPetitionButton className={"btn btn-primary"}/>
    </div>
    )
}

export default GotoStartPetition
