import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import moment from 'moment'
import { Controller, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormTextAreaInput from 'src/_common/components/form-elements/textarea/textareaInput'
import { useAppPetitionApi } from 'src/_common/hooks/api/petition/appPetitionApiHook';
import { useToaster } from 'src/_common/hooks/actions/common/appToasterHook';
import SelectInput from 'src/_common/components/form-elements/selectinput/selectInput';
import { OptionValue } from 'src/_common/interfaces/common';
import { useCommonApi } from 'src/_common/hooks/api/common/appCommonApiHook';

interface ReportModalProps {
   onClose: () => void;
   shouldShow: boolean;
   petition_id: string;
   entity_id: string;
   type: string;
}

interface ReasonFormValues {
    category: OptionValue | undefined,
    reasons: string,
}

const reasonSchema = yup.object().shape({
    category: yup
        .object()
        .nullable()
        .shape({
            value: yup.string().required('Category is required'),
        })
        .required('Category is required'),
    reasons: yup
        .string()
        .required('Reason is required'),
})

export default function ReportModal({ onClose, shouldShow, petition_id, entity_id, type }: ReportModalProps) {

    const petitionApi = useAppPetitionApi()
    const toast = useToaster()
    const commonApi = useCommonApi()
    const [reportCategoryList, setReportCategoryList] = useState<any>([])

    const { register, control, setValue, handleSubmit, errors } = useForm<ReasonFormValues>({
        resolver: yupResolver(reasonSchema),
        defaultValues: {
            reasons: '',
        },
    })

    const onSubmit = (values: ReasonFormValues) => {
        let params = {
            petition_id,
            category_id: String(values?.category?.value),
            entity_id,
            type,
            reasons: values.reasons
        }
        console.log({params})
        petitionApi.callReport(params, (message: string, resp: any) => {
            setValue("reasons",'')   
            onClose()
            console.log({resp})
            toast.success(message)
          }, (message: string) => {
          })
    }

    const getReportCategoryList = () => {
        commonApi.callReportCategoryList(
            (message: string, resp: any) => {
                console.log({resp})
                setReportCategoryList(resp.data)
            }, (message: string) => {

            }
        )
    }

    useEffect(()=>{
        if(shouldShow) {
            getReportCategoryList()
        }
    },[shouldShow])

   return (
      <React.Fragment>
         <Modal
            show={shouldShow}
            backdrop="static"
            keyboard={false}
            className="stackholder-modal"
            centered
         >
            <Modal.Header>
               {/* <img src="/images/pop-hand-left.svg" alt="" /> */}
               <h5 className="modal-title"><strong>Report the {type}</strong></h5>
               <button type="button" className="close" data-dismiss="modal" aria-label="Close"  onClick={()=>onClose()}>
                  <span><i className="fa fa-times" aria-hidden="true"></i></span>
               </button>
            </Modal.Header>
            <Modal.Body>
                {/* <div className="container-fluid text-center p-4 border rounded"> */}
                <div>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <div className="form-wrap">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <Controller
                                        control={control}
                                        name={"category"}
                                        // defaultValue={item.type} // make sure to set up defaultValue
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <SelectInput
                                                name={name}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                                inputRef={ref}
                                                options={reportCategoryList?.map((item:any)=>({
                                                    value: String(item.id),
                                                    label: item.title
                                                }))}
                                                error={errors.category}
                                                placeholder="Select Category"
                                            />
                                        )}
                                    />
                                    </div>
                                </div>
                                <div className="col-12 mt-3">
                                    <div className="form-group">
                                        <Controller
                                            control={control}
                                            name="reasons"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <FormTextAreaInput
                                                    name={name}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    rows={3}
                                                    // inputRef={inputRef}
                                                    type="text"
                                                    error={errors.reasons}
                                                    placeholder="Enter your reason here..."
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="btn-wrap">
                                <button type="submit" className="btn btn-primary mt-4">
                                    Submit
                                    <img className="fa" src={"/images/arrow-forward-outline.svg"} alt="forward"/>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal.Body>
            <Modal.Footer />
         </Modal>
      </React.Fragment>
   )
}