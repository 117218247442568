import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CheckboxInput from "src/_common/components/form-elements/checkboxinput/checkboxInput";
import FormTextAreaInput from "src/_common/components/form-elements/textarea/textareaInput";
import { useHistory, useParams } from "react-router";
import { CRYPTO_SECRET_KEY, URLS } from "src/_config";
import FormTextInput from "src/_common/components/form-elements/textinput/formTextInput";
import SelectInput from "src/_common/components/form-elements/selectinput/selectInput";
import { OptionValue } from "src/_common/interfaces/common";
import { useCommonApi } from "src/_common/hooks/api/common/appCommonApiHook";
import { useAppPetitionDetailsSelector } from "src/_common/hooks/selectors/petition/petitionSelector";
import { useToaster } from "src/_common/hooks/common/appToasterHook";

interface SignPetitionProps {
  onClose: () => void;
  setParams: Function;
  petitionDetails: any;
}

interface SignPetitionFormValues {
  // display: boolean;
  // anonymous: boolean;
  firstName?: string;
  lastName?: string;
  email?: string;
  reasons: string,
  country: OptionValue | undefined;
  state: OptionValue | undefined;
  city: OptionValue | undefined;
  zip: string;
  // addressLine1: string;
}

const signPetitionSchema = yup.object().shape({
  // display: yup.boolean(),
  // anonymous: yup.boolean(),
  firstName: yup.string()
    .required("First Name is required"),
    // .when("anonymous", {
    //     is: (anonymous) => anonymous === true ,
    //     then: yup.string().required("First Name is required"),
    //     otherwise: yup.string().when("display",{
    //       is: (display) => display === false ,
    //       then: yup.string().required("First Name is required"),
    //     })
    // }),
  lastName: yup.string()
    .required("First Name is required"),
    // .when("anonymous", {
    //   is: (anonymous) => anonymous === true,
    //   then: yup.string().required("Last Name is required"),
    //   otherwise: yup.string().when("display",{
    //     is: (display) => display === false ,
    //     then: yup.string().required("Last Name is required"),
    //   })
    // }),
  email: yup.string()
    .email("Enter a valid email address").required("Email is required"),
    // .when("anonymous", {
    //   is: (anonymous) => anonymous === true,
    //   then: yup.string().email("Enter a valid email address").required("Email is required"),
    //   otherwise: yup.string().when("display",{
    //     is: (display) => display === false ,
    //     then: yup.string().required("Last Name is required"),
    //   })
    // }),
  // reasons: yup.string().required("Comments is required"),
  country: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Country is required"),
    })
    .required("Country is required"),
  state: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("State is required"),
    })
    .required("State is required"),
  city: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("City is required"),
    })
    .required("City is required"),
  zip: yup.string().required("Zip is required"),
  // addressLine1: yup.string().required("Address Line 1 is required"),
});

const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);
const queryString = require("query-string");

const NoAuthSignPetitionForm = ({ petitionDetails, setParams, onClose }: SignPetitionProps) => {
  const { register, control, setValue, handleSubmit, errors, watch, clearErrors } =
    useForm<SignPetitionFormValues>({
      resolver: yupResolver(signPetitionSchema),
      defaultValues: {
        // display: false,
        // anonymous: false,
        firstName: "",
        lastName: "",
        email: "",
        country: undefined,
        state: undefined,
        city: undefined,
        zip: "",
        // addressLine1: "",
      },
      mode: "onBlur",
    });

  const toast = useToaster();
  const [countries, setCountries] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const selectedCountry = watch("country");
  const selectedState = watch("state");
  const selectedCity = watch("city");
  const displayCheck = watch("display");
  const anonymousCheck = watch("anonymous");
  const commonApi = useCommonApi();

  const petitionId = useParams<any>();
  // const id = parseInt(cryptr.decrypt(petitionId.id));

  const onSubmit = (values: SignPetitionFormValues) => {
    if(displayCheck){
        let parms = {
            petition_id: petitionDetails?.id,
            // show_only_me: values.display ? "1" : "0",
            // is_anonymous: values.anonymous ? "1" : "0",
            show_only_me: "0",
            is_anonymous: "1",
            reasons: values.reasons,
            // full_name: values.firstName + " " + values.lastName,
            // email: values.email,
            country_id: values.country?.value,
            state_id: values.state?.value,
            city_id: values.city?.value,
            postal_code: values.zip,
            // address_line_1: values.addressLine1,
        };
        setParams(parms);
    }
    else {
        let parms = {
          petition_id: petitionDetails?.id,
          // show_only_me: values.display ? "1" : "0",
          // is_anonymous: values.anonymous ? "1" : "0",
          show_only_me: "0",
          is_anonymous: "1",
          full_name: values.firstName + " " + values.lastName,
          email: values.email,
          reasons: values.reasons,
          country_id: values.country?.value,
          state_id: values.state?.value,
          city_id: values.city?.value,
          postal_code: values.zip,
        //   address_line_1: values.addressLine1,
        };
        setParams(parms);
    }
  };

  const resetStates = () => {
    setValue("state", undefined);
    setStates([]);
  };

  const resetCities = () => {
    setValue("city", undefined);
    setCities([]);
  };

  const countryList = () => {
      commonApi.callCountryList(
          (message: string, data: any) => {
          // console.log(data.data.list)
          if (data && data.data && data.data.list) {
              setCountries(data.data.list);
          }
          },
          (message: string) => {
          //   toast.error(message)
          }
      );
  };

  const stateList = (cid: any) => {
    commonApi.callStateList(
      {
        country_id: cid,
      },
      (message: string, data: any) => {
        // console.log(data.data.list)
        if (data && data.data && data.data.list) {
          setStates(data.data.list);
        }
      },
      (message: string) => {
        //   toast.error(message)
      }
    );
  };

  const cityList = (sid: any) => {
    commonApi.callCityList(
      {
        state_id: sid,
      },
      (message: string, data: any) => {
        console.log(data.data.list);
        if (data && data.data && data.data.list) {
          setCities(data.data && data.data.list);
        }
      },
      (message: string) => {
        //   toast.error(message)
      }
    );
  };

  useEffect(()=> {
    if(displayCheck){
      clearErrors()
      setValue('firstName',"")
      setValue('lastName',"")
      setValue('email',"")
    }
  },[displayCheck])

  useEffect(() => {
    resetStates();
    if (selectedCountry && selectedCountry.value) {
      stateList(selectedCountry.value);
    }
  }, [selectedCountry]);

  useEffect(() => {
    resetCities();
    if (selectedState && selectedState.value) {
      cityList(selectedState.value);
    }
  }, [selectedState]);

  useEffect(()=>{
      countryList()
  },[])

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {/* <div className="check-wrap">
        <div className="custom-check-container">
          <Controller
            control={control}
            name={"display"}
            render={({ onChange, onBlur, value, name, ref }) => (
              <CheckboxInput
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                error={errors.display}
                disabled={anonymousCheck}
                label=""
              />
            )}
          />
          <span>Only Me</span>
        </div>
        <div className="custom-check-container">
          <Controller
            control={control}
            name={"anonymous"}
            render={({ onChange, onBlur, value, name, ref }) => (
              <CheckboxInput
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                error={errors.anonymous}
                disabled={displayCheck}
                label=""
              />
            )}
          />
          <span>Anonymous</span>
        </div>        
      </div> */}
      <div className="row">
        <div className="col mb-3">
          <Controller
            control={control}
            name="firstName"
            render={({ onChange, onBlur, value, name, ref }) => (
              <FormTextInput
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                type="text"
                error={errors.firstName}
                // disabled={displayCheck}
                placeholder="First name"
              />
            )}
          />
        </div>
      </div>
      <div className="row">
        <div className="col mb-3">
          <Controller
            control={control}
            name="lastName"
            render={({ onChange, onBlur, value, name, ref }) => (
              <FormTextInput
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                type="text"
                error={errors.lastName}
                // disabled={displayCheck}
                placeholder="Last name"
              />
            )}
          />
        </div>
      </div>
      <div className="row">
        <div className="col mb-3">
          <Controller
            control={control}
            name="email"
            render={({ onChange, onBlur, value, name, ref }) => (
              <FormTextInput
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                type="email"
                error={errors.email}
                // disabled={displayCheck}
                placeholder="Email"
              />
            )}
          />
        </div>
      </div>
      <div className="row">
        <div className="col mb-3">
        <Controller
          control={control}
          name="reasons"
          render={({ onChange, onBlur, value, name, ref }) => (
            <FormTextAreaInput
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            rows={2}
            // inputRef={inputRef}
            type="text"
            error={errors.reasons}
            placeholder="Enter your reason here..."
            />
          )}
        />
        </div>
      </div>
      <div className="row">
        <div className="col mb-3">
          <Controller
            control={control}
            name="country"
            render={({ onChange, onBlur, value, name, ref }) => (
              <SelectInput
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                // options={[
                //   {
                //     value: String(231),
                //     label: "United States",
                //   },
                // ]}
                options={countries.map((c: any) => ({
                  value: String(c.id),
                  label: c.country_name,
                }))}
                error={errors.country}
                placeholder="Country"
              />
            )}
          />
        </div>
      </div>
      <div className="row">
        <div className="col mb-3">
          <Controller
            control={control}
            name="state"
            render={({ onChange, onBlur, value, name, ref }) => (
              <SelectInput
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                options={states.map((c: any) => ({
                  value: String(c.id),
                  label: c.state_name,
                }))}
                error={errors.state}
                placeholder="State"
              />
            )}
          />
        </div>
      </div>
      <div className="row">
        <div className="col mb-3">
          <Controller
            control={control}
            name="city"
            render={({ onChange, onBlur, value, name, ref }) => (
              <SelectInput
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                options={cities.map((c: any) => ({
                  value: String(c.id),
                  label: c.city_name,
                }))}
                error={errors.city}
                placeholder="City"
              />
            )}
          />
        </div>
      </div>
      <div className="row">
        <div className="col mb-3">
          <Controller
            control={control}
            name="zip"
            render={({ onChange, onBlur, value, name, ref }) => (
              <FormTextInput
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                type="text"
                error={errors.zip}
                placeholder="zip"
              />
            )}
          />
        </div>
      </div>
      {/* <div className="row">
        <div className="col mb-3">
          <Controller
            control={control}
            name="addressLine1"
            render={({ onChange, onBlur, value, name, ref }) => (
              <FormTextInput
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                type="text"
                error={errors.addressLine1}
                placeholder="Address Line 1"
              />
            )}
          />
        </div>
      </div> */}
      <button className="btn btn-primary" type="submit">
        Sign Petition{" "}
        <img
          className="fa"
          src={"/images/arrow-forward-outline.svg"}
          alt="forward"
        />
      </button>
    </form>
  );
};

export default NoAuthSignPetitionForm;
