import { type } from "os";
import { ActionExtended } from "../../../_common/interfaces/ActionExtended";
import { User } from "../../../_common/interfaces/models/user";
import { ACTIONS } from "../../../_config";

export interface PetitionReducer {
  categoryList: any;
  petitionList: any;
  featuredTopicList: any;
  createPetition: any;
  petitionDetails: any;
  tips: any;
  updatesList: any;
  signPetition: any;
}

const initialState: PetitionReducer = {
  categoryList: null,
  petitionList: null,
  featuredTopicList: null,
  createPetition: null,
  petitionDetails: null,
  tips: null,
  updatesList: null,
  signPetition: null,
};

const petitionReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.PETITION.CATEGORY_LIST:
      return {
        ...state,
        categoryList: action.payload,
      };
    case ACTIONS.PETITION.LIST:
      return {
        ...state,
        petitionList: action.payload,
      };
    case ACTIONS.PETITION.RESET_LIST:
      return {
        ...state,
        petitionList: null,
      };
    case ACTIONS.PETITION.UPDATES_LIST:
      return {
        ...state,
        updatesList: action.payload,
      };
    case ACTIONS.PETITION.TIPS:
      return {
        ...state,
        tips: action.payload,
      };
    case ACTIONS.PETITION.FEATURED_TOPIC_LIST:
      return {
        ...state,
        featuredTopicList: action.payload,
      };
    case ACTIONS.PETITION.PETITION_DETAILS:
      return {
        ...state,
        petitionDetails: action.payload.petition_details,
      };
    case ACTIONS.PETITION.CREATE_PETITION:
      let x = state.createPetition
      if(x)
      {
        Object.assign(x,action.payload)
      }
      else
      {
        x = action.payload
      }
      return {
        ...state,
        createPetition: x
      };
    case ACTIONS.PETITION.RESET_CREATE_PETITION:
      return {
        ...state,
        createPetition: null
      };
    case ACTIONS.PETITION.SIGN_PETITION:
      return {
        ...state,
        signPetition: action.payload
      };
    default:
      return state;
  }
};

export default petitionReducer;
