import React from 'react';

const LazyMyPetitions = React.lazy(() =>
  import(/* webpackChunkName: "MyPetitionsPage" */ './mypetitionpage')
);

const MyPetitionsPage = (props: Record<string, any>) => (
  <React.Suspense fallback={<div className="loader"></div>}>
    <LazyMyPetitions {...props} />
  </React.Suspense>
);

export default MyPetitionsPage