import React from "react";
import { Link } from "react-router-dom";
import StartFundraisingButton from "src/components/fundraising/common/StartFundraisingButton";
import { useAppUserAction } from "src/_common/hooks/actions/user/appUserActionHook";
import { useAuthStatus } from "src/_common/hooks/authHook";
import { URLS } from "src/_config";

const NavbarFundraisingMidSec = () => {

  const userAction = useAppUserAction()
  const isAuth = useAuthStatus()

  const setLink = () => {
    if (!isAuth) {
      userAction.setLink(URLS.FUNDRAISING.MY_CAUSES)
    }
  }

  return (
    <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll" >
      <li className="nav-item">
        <Link className="nav-link" to={URLS.HOME}>
          Home
        </Link>
      </li>
      <li className="nav-item">
        <StartFundraisingButton className={"nav-link"} noImage={true} />
      </li>
      <li className="nav-item">
        <Link className={"nav-link"+(window.location.pathname==URLS.FUNDRAISING.MY_CAUSES?" active":"")} to={URLS.FUNDRAISING.MY_CAUSES} onClick={setLink}>
          My Causes
        </Link>
      </li>
      <li className="nav-item">
        <Link className={"nav-link"+(window.location.pathname==URLS.FUNDRAISING.BROWSE_FUNDRAISING?" active":"")} to={URLS.FUNDRAISING.BROWSE_FUNDRAISING}>
          Browse
        </Link>
      </li>
    </ul>
  );
};

export default NavbarFundraisingMidSec;
