import React from 'react';

const LazyMySurveyPage = React.lazy(() =>
  import(/* webpackChunkName: "MySurveyPage" */ './mysurveypage')
);

const MySurveyPage = (props: Record<string, any>) => (
  <React.Suspense fallback={<div className="loader"></div>}>
    <LazyMySurveyPage {...props} />
  </React.Suspense>
);

export default MySurveyPage