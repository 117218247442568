import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAppFundraisingApi } from "src/_common/hooks/api/fundraising/appFundraisingApiHook";
import { useToaster } from "src/_common/hooks/common/appToasterHook";
import { useAppFundraisingDetailsSelector } from "src/_common/hooks/selectors/fundraising/fundraisingSelector";
import { changeString, CRYPTO_SECRET_KEY, extractContent } from "src/_config";
import DonorsListModal from "../common/modals/DonorsListModal";
import ShareDonateButtons from "../common/ShareAndDonateButtons";
import parse from 'html-react-parser';
// import { htmlToText } from 'html-to-text';

const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

interface RightDetailsProps {
  openShareModal: Function
}

const RightDetailsComponent = ({ openShareModal }: RightDetailsProps) => {

  const [showDonorsListModal, setShowDonorsListModal] = useState<boolean>(false)
  const [donorsListType, setDonorsListType] = useState('')
  const {slug} = useParams<any>()
  // const id = cryptr.decrypt(fundraisingId.id)
  // const slug = fundraisingId.slug
  const fundraisingDetails = useAppFundraisingDetailsSelector()
  const [donorsList, setDonorsList] = useState<any>([])
  const fundraisingApi = useAppFundraisingApi()
  const toast = useToaster()

  const openDonorsListModal = (type: string) => {
    setDonorsListType(type)
    setShowDonorsListModal(true)
  }

  const closeDonorsListModal = () => {
    setShowDonorsListModal(false)
  }

  const getTopDonors = () => {
    var params = { slug }
    fundraisingApi.callGetTopDonors(
      params,
      (message: string, resp: any) => {
        setDonorsList(resp.data.list.data)
      },
      (message: string) => {
        toast.error(message)
      }
    )
  }

  useEffect(() => {
    getTopDonors()
  }, [])

  return (
    <>
      <div className="col-md-4 mobm-30 sticky-right">
        {/* <div className="petetion_btn"> */}
          {/* <div className="d-flex align-items-center justify-content-between">
            <Link to={`/fundraising-words-of-support/${slug}`} className={`btn btn-secondary signingbtn w-48 mt-0`}>
              Words of support <span className="signin_icon" />
            </Link>
            <Link to={`/fundraising-updates/${slug}`} className={`btn btn-secondary w-48`} href="#updates">
              Updates <span className="reload_icon"></span>
            </Link>
          </div> */}
          {/* <Link to={'/fundraising-words-of-support/' + slug} className="btn btn-secondary signingbtn w-100 mt-0" >
            Words of support <span className="signin_icon" />
          </Link>
        </div> */}
        <div className="sign_petitionbox mt-0">
          {fundraisingDetails?.details?.fundraiser_image?.id == 0 ? null :
            <div className="saverodio">
              <img src={fundraisingDetails?.details?.fundraiser_image?.thumb} className="w-100" />
            </div>
          }
          <p>
            {fundraisingDetails?.details?.description ? changeString(extractContent(fundraisingDetails?.details?.description), 80) : ""}
          </p>
          <h5>
            <span>
              <b>${fundraisingDetails?.details?.total_fund_value?.total_amount ? fundraisingDetails?.details?.total_fund_value?.total_amount.toLocaleString() : 0}</b> USD
            </span>{" "}
            raised of ${fundraisingDetails?.details?.target_fund_value.toLocaleString()} goal
          </h5>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${fundraisingDetails?.details?.total_fund_value?.total_amount / fundraisingDetails?.details?.target_fund_value * 100}%` }}
            />
          </div>
          {fundraisingDetails?.details?.video_link ?
            <div>
              <a className="btn btn-secondary sharebtn w-100 mt-3" href={fundraisingDetails?.details?.video_link} target="_blank" rel="noreferrer">Fundraiser Link
              </a>
            </div>
            : null
          }
          <ShareDonateButtons className="btn-shre-donate-wrap" openShareModal={openShareModal} />
          <div className="donation-count">{fundraisingDetails?.all_donors?.total.toLocaleString()} donations</div>
          <div className="donated-row">
            <div className="donated-icon">
              <img src="/images/graph-icon.svg" />
            </div>
            <div className="donated-text">
              <span>{fundraisingDetails?.all_donors?.total.toLocaleString()} people</span> just donated
            </div>
          </div>
          <div className="donation-list-wrap">
            {donorsList && donorsList.length ?
              donorsList.map((d: any) =>
                <div className="donar-row">
                  <div className="donar-img">
                    <span>
                      <img src={d?.user?.avatar?.thumb} alt="" />
                    </span>
                  </div>
                  <div className="donar-text">
                    <h3>{d?.anonymous_donation == '1' ? "Anonymous" : d?.user?.full_name}</h3>
                    <span>
                      ${d?.amount.toLocaleString()}
                      {/* <em>Recent donation</em> */}
                    </span>
                  </div>
                </div>
              )
              : null
            }
          </div>
          <div className="dual-btn-wrap" style={{ cursor: "pointer" }}>
            <a onClick={() => openDonorsListModal("all")}>See All</a>
            <a onClick={() => openDonorsListModal("top")}>
              <img src="/images/star-icon.svg" alt="" /> See top donations
            </a>
          </div>
        </div>
      </div>
      {showDonorsListModal &&
        <DonorsListModal
          onClose={closeDonorsListModal}
          shouldShow={showDonorsListModal}
          donorsListType={donorsListType}
          setDonorsListType={setDonorsListType}
        />
      }
    </>
  );
};

export default RightDetailsComponent;
