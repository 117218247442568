import React from 'react'
import { Link } from 'react-router-dom'
import Footer from 'src/components/common/Footer'
import MetaDecorator from 'src/components/common/MetaDecorator'
import NavbarMain from 'src/components/common/navbar/NavbarMain'
import RightStickyBtn from 'src/components/common/RightStickyBtn'
import { RODEO_PAGE_NAME, URLS } from 'src/_config'

export default function Page404() {
  return (
    <React.Fragment>

      <MetaDecorator
          title={"Page Not Found"}
          description={"Page Not Found"}
      />

      <NavbarMain section={"login"} />
      <RightStickyBtn />

      <section className="inner-page">
        <div className="container">
          <div className="main-container not-found-wrap">
            <h1>404</h1>
            <h2>Not Found</h2>
          </div>
          <p className="home-link">
            <Link to={URLS.HOME}>Go to Home</Link>
          </p>
        </div>
      </section>
      
      <Footer/>
    </React.Fragment>

  )
}
