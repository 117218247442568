import { useDispatch } from 'react-redux'
import { ACTIONS } from '../../../../_config'

export function useAppFundraisingAction() {

  const dispatch = useDispatch()

  const startFundraising = (data: any) => {
    dispatch({
      type: ACTIONS.FUNDRAISING.START_FUNDRAISING,
      payload: data
    })
  }

  const resetStartFundraising = () => {
    dispatch({
      type: ACTIONS.FUNDRAISING.RESET_START_FUNDRAISING
    })
  }

  const resetFundraisingList= () => {
    dispatch({
      type: ACTIONS.FUNDRAISING.RESET_LIST
    })
  }

  const resetFundraisingDetails= () => {
    dispatch({
      type: ACTIONS.FUNDRAISING.RESET_FUNDRAISING_DETAILS
    })
  }

  return {
    startFundraising,
    resetStartFundraising,
    resetFundraisingList,
    resetFundraisingDetails
  }
}