import React from 'react'
import StartFundraisingButton from '../common/StartFundraisingButton'

const BrowseFundraisingBanner = () => {
  return (
    <div className="innerpageBanner">
        <div className="container">
          <h2>Our Fundraising Supports Rural America</h2>
          {/* <h4>48,95,09,597 people taking action Victories every day.</h4> */}
          <div className="text-center mb-4">
            <StartFundraisingButton className={"btn btn-primary"}/>
          </div>
        </div>
    </div>
  )
}

export default BrowseFundraisingBanner