import React from "react";

const NavbarBlankMidSec = () => {


  return (
    <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll" >
      <li className="nav-item">
      </li>
      <li className="nav-item">
      </li>
      <li className="nav-item">
      </li>
    </ul>
  );
};

export default NavbarBlankMidSec;