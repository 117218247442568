import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { useAppGlobalAction } from 'src/_common/hooks/actions/common/appGlobalActionHook'

const NavbarSearchBtn = ({section}:any) => {

  const [inputVal, setInputVal] = useState('')
  const history = useHistory()
  const globalAction = useAppGlobalAction()

  const gotoBrowsePage = (e:any) => {
    e.preventDefault()
    if(inputVal && inputVal.length) {
      globalAction.setSearchKeyword(inputVal)
      if(!section) {
        history.push('/browse-survey')
      }
      else {
        history.push('/search')
      }
    }
  }

  const handleSearchKeyPress = (e:any) => {
    if (e.key === "Enter") {
      gotoBrowsePage(e)
    }
  }

  return (
    <div className="nav_search">
      <input className="search_input" type="text" placeholder="Search" onChange={e=>setInputVal(e.target.value)} onKeyPress={handleSearchKeyPress}/>
      <button className="nav_searchbtn" onClick={e=>gotoBrowsePage(e)}></button>
    </div>
  )
}

export default NavbarSearchBtn