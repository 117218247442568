import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useAppUserAction } from 'src/_common/hooks/actions/user/appUserActionHook';
import { useAuthStatus } from 'src/_common/hooks/authHook';
import { useAppFundraisingDetailsSelector } from 'src/_common/hooks/selectors/fundraising/fundraisingSelector';

interface ShareAndDonateButtonsProps {
  className: string;
  openShareModal: Function
}

const ShareAndDonateButtons = ({ className, openShareModal }: ShareAndDonateButtonsProps) => {

  const { slug } = useParams<any>()
  const fundraisingDetails = useAppFundraisingDetailsSelector()

  const userAction = useAppUserAction()
  const isAuth = useAuthStatus()

  const setLink = () => {
    if (!isAuth) {
      userAction.setLink('/fundraising-donate/' + slug)
    }
  }

  return (
    <div className={className}>
      {fundraisingDetails?.details?.status == 1 && fundraisingDetails?.details?.close_fundriser==0 && fundraisingDetails?.details?.is_payout_request==0 ?
      <a className="btn btn-secondary sharebtn w-100" onClick={() => openShareModal()}>
        Share <span className="share_icon" />
      </a>
      : null
      }
      {fundraisingDetails?.settings?.allow_fundraiser_donation == 1 && fundraisingDetails?.details?.status == 1 && fundraisingDetails?.details?.close_fundriser==0 && fundraisingDetails?.details?.is_payout_request==0 ?
        <Link to={'/fundraising-donate/' + slug} className="btn btn-primary w-100 donatenowbtn" onClick={setLink}>
          Donate now <img className="fa" src="/images/rodeo_hover.svg" />
        </Link>
        : null}
    </div>
  )
}

export default ShareAndDonateButtons
