import React from 'react';

const LazyAnalyzeResultPage = React.lazy(() =>
  import(/* webpackChunkName: "AnalyzeResultPage" */ './analyzeResultpage')
);

const AnalyzeResultPage = (props: Record<string, any>) => (
  <React.Suspense fallback={<div className="loader"></div>}>
    <LazyAnalyzeResultPage {...props} />
  </React.Suspense>
);

export default AnalyzeResultPage