import { call, put } from "redux-saga/effects";
import { ACTIONS, API_URL, STORAGE } from "../_config";
import { CallApi } from "./api/callApi";

export function* answerTypeList(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET,API_URL.SURVEY.GET_ANSWER_TYPE_LIST,null,true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      yield put({
        type: ACTIONS.SURVEY.GET_ANSWER_TYPE_LIST,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* createSurvey(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST,API_URL.SURVEY.CREATE_SURVEY, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      yield put({
        type: ACTIONS.SURVEY.CREATE_SURVEY,
        payload: resp.data.data.details,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getSurveyDetails(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST,API_URL.SURVEY.SURVEY_DETAILS, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      yield put({
        type: ACTIONS.SURVEY.SURVEY_DETAILS,
        payload: resp.data.data.survey_details,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* setTitleFooter(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST,API_URL.SURVEY.TITLE_FOOTER, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      yield put({
        type: ACTIONS.SURVEY.SURVEY_PAGE_TITLE_FOOTER,
        payload: resp.data.data.details,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* addEditQuestion(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST,API_URL.SURVEY.ADD_EDIT_QUESTION, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* mySurveyList(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SURVEY.MY_SURVEY_LIST, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      yield put({
        type: ACTIONS.SURVEY.MY_SURVEY_LIST,
        payload: resp.data.data.list,
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* surveyList(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SURVEY.SURVEY_LIST, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      yield put({
        type: ACTIONS.SURVEY.SURVEY_LIST,
        payload: resp.data.data.list,
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* duplicateSurveyQuestion(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SURVEY.DUPLICATE_SURVEY_QUESTION, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* surveyQuestionDelete(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SURVEY.SURVEY_QUESTION_DELETE, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* surveyComplete(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SURVEY.SURVEY_COMPLETE, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* backgroundImageUpload(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SURVEY.BACKGROUND_IMAGE_UPLOAD, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* backgroundImageDelete(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SURVEY.BACKGROUND_IMAGE_DELETE, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* deleteSurvey(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SURVEY.DELETE_SURVEY, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* duplicateSurvey(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SURVEY.DUPLICATE_SURVEY, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* updateSurveyStatus(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SURVEY.UPDATE_SURVEY_STATUS, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* editSurvey(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SURVEY.EDIT_SURVEY, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* addSurveyPageBreak(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SURVEY.ADD_SURVEY_PAGE_BREAK, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* deleteSurveyPageBreak(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SURVEY.DELETE_SURVEY_PAGE_BREAK, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* startUserSurvey(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SURVEY.START_USER_SURVEY, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* answerFileUpload(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SURVEY.ANSWER_FILE_UPLOAD, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* checkEmailExist(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SURVEY.CHECK_EMAIL_EXIST, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* topFourSurvey(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SURVEY.TOP_FOUR_SURVEY, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* surveyReport(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SURVEY.SURVEY_REPORT, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* verifySurvey(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SURVEY.VERIFY_SURVEY, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* associateUserSurvey(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SURVEY.ASSOCIATE_USER_SURVEY, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e:any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}