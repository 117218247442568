import { combineReducers } from 'redux';
import commonReducer from './common/commonReducer';

import loaderReducer from './common/loaderReducer'
import fundraisingReducer from './fundraising/fundraisingReducer';
import petitionReducer from './petition/petitionReducer';
import surveyReducer from './survey/surveyReducer';
import userReducer from './user/userReducer'

const rootReducer = combineReducers({
  loader: loaderReducer,
  user: userReducer,
  petition: petitionReducer,
  fundraising: fundraisingReducer,
  survey: surveyReducer,
  common: commonReducer,
})

// export type RootState = ReturnType<typeof rootReducer>
export default rootReducer