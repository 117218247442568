import { call, put } from 'redux-saga/effects';
import {ACTIONS, API_URL, STORAGE } from '../_config'
import { CallApi } from './api/callApi';

export function* countryList(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.COMMON.COUNTRY_LIST, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      // yield put({
      //   type: ACTIONS.COMMON.STATE_LIST,
      //   payload: resp.data.data,
      // })      
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* stateList(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.COMMON.STATE_LIST, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      // yield put({
      //   type: ACTIONS.COMMON.STATE_LIST,
      //   payload: resp.data.data,
      // })      
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* cityList(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.COMMON.CITY_LIST, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      // yield put({
      //   type: ACTIONS.COMMON.CITY_LIST,
      //   payload: resp.data.data,
      // })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* shareCount(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.COMMON.SHARE_COUNT, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* deleteImage(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.COMMON.DELETE_IMAGE, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* popupSetting(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.COMMON.POPUP_SETTING, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* cmsContent(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.COMMON.CMS_CONTENT, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* cmsLinks(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.COMMON.CMS_LINKS, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* homepageCounting(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.COMMON.HOME_PAGE_COUNTING, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* globalSearch(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.COMMON.GLOBAL_SEARCH, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* reportCategoryList(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.COMMON.REPORT_CATEGORY_LIST, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}