import React, { useEffect, useState } from "react";
import RightStickyBtn from "../common/RightStickyBtn";
import Footer from "../common/Footer";
import Login from "./Login";
import Signup from "./Signup";
import NavbarMain from "../common/navbar/NavbarMain";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import { useUserApi } from "src/_common/hooks/api/user/appUserApiHook";
import { useHistory } from "react-router";
import { useAppLinkSelector } from "src/_common/hooks/selectors/user/userSelector";
import { useAppUserAction } from "src/_common/hooks/actions/user/appUserActionHook";
import SocialLogins from "./SocialLogins";

interface LoginSignupProps {
  page?: any
}

const LoginSignup = ({ page }:LoginSignupProps) => {
  const [showLogin, setShowLogin] = useState(true);

  const toast = useToaster();
  const userApi = useUserApi();
  const history = useHistory();
  const link = useAppLinkSelector()
  const userAction = useAppUserAction()
  const [loggedIn, setLoggedin] = useState<boolean>(false)

  // const clientId="850326453397-ec2ube3bt58t966vajrskq8chndbto0c.apps.googleusercontent.com" //dev
  const clientId="475007904468-9nmklgjijnuj2qeslsqqcqfhdskso82b.apps.googleusercontent.com"  //live

  const responseFacebook = (response:any) => {
    console.log({response})
    if(response.email && response.name){
      onSocialLogin({email: response.email, first_name: response.name.substring(0,response.name.lastIndexOf(' ')), last_name: response.name.substring(response.name.lastIndexOf(' ')+1)})
    }
  }

  const onSuccess = (res: any) => {
    console.log({res})
    // refreshTokenSetup(res)
    if(res.profileObj.email && res.profileObj.name){
      onSocialLogin({email: res.profileObj.email, first_name: res.profileObj.givenName, last_name: res.profileObj.familyName})
    }
  };

  const onFailure = (res: any) => {
    console.log('[Login Failed] res: ',res);
  };

  const onSocialLogin = (params: any) => {
    console.log({params});
    userApi.callSocialLogin(params, (message: string, resp: any) => {
        if (resp) {
          // console.log({ resp });
          if(link)
          {
            history.push(link)
            userAction.setLink(null)
          }
          toast.success(message)
        } else {
          toast.error(message)
        }
    }, (message: string) => {
      toast.error(message)
    })
  }

  const scrollToLoginSection = () => {
    let x = document.getElementById("loginSection")
    if(x) {
      x.scrollIntoView();
    }
  }

  useEffect(()=>{
    return ()=>{
      if(!loggedIn) {
        localStorage.removeItem('likeReasonParams')
      }
    }
  },[])

  const Heading = ({type}: any) => {
    return (
      type=="login" ?
      <>
        <h2 className="section-title login-heading">Already have an<br/>account?</h2>
        <div className="form-wrap">
          <p className="form-label mb-15">
            Thank you for supporting Rural America In Action. Welcome back.
          </p>
        </div>
      </>
      :
      <>
        <h2 className="section-title login-heading">New User?</h2>
        <div className="form-wrap">
          <p className="form-label mb-15">
            {page=="fundDonate" ?
              "Create a safe and free user account to enjoy full access to the Rural America In Action platform."
              :
              "Create a safe and free user account to have full access to all Rural America In Action has to offer."
            }
          </p>
        </div>
      </>
    )
  }

  useEffect(()=>{
    if(page) {
      userAction.setLink(null)
    }
  },[page])

  return (
    <>
        <div className="container">
          <div className={"login-signup-box"}>
            <div className="login-section" id="loginSection">
              <div className="login-div">
                <Heading type={showLogin ? "login" : "signup"}/>
                { showLogin ?
                  <Login setLoggedin={setLoggedin} page/> 
                  :
                  <Signup setShowLogin={setShowLogin} setLoggedin={setLoggedin} page/>
                }
                <SocialLogins clientId={clientId} onSuccess={onSuccess} onFailure={onFailure} responseFacebook={responseFacebook} />
              </div>
              <div className="signup-div">
                <Heading type={showLogin ? "signup" : "login"}/>
                <div className="btn-wrap">
                  <button className="btn btn-primary" onClick={() => {
                    setShowLogin(!showLogin);
                    scrollToLoginSection()
                  }}>
                    Continue
                    <img
                      className="fa"
                      src={"/images/arrow-forward-outline.svg"}
                      alt="forward"
                    />
                  </button>
                </div>
              </div>
              {/* {showLogin === true ?
              <>  */}
                {/* <p className="sign-up-links">
                  Don't have an account?{" "}
                  <span style={{ color: "red", textDecoration: "underline", cursor: "pointer" }} onClick={() => setShowLogin(!showLogin)}>
                      Sign up
                  </span>
                </p> */}
                  {/* <div className="login-div">
                    <Heading type={"login"}/>
                    <Login setLoggedin={setLoggedin}/>
                    <SocialLogins clientId={clientId} onSuccess={onSuccess} onFailure={onFailure} responseFacebook={responseFacebook} />
                  </div>
                  <div className="signup-div">
                    <Heading type={"signup"}/>
                    <div className="btn-wrap">
                      <button className="btn btn-primary" onClick={() => setShowLogin(!showLogin)}>
                        Continue
                        <img
                          className="fa"
                          src={"/images/arrow-forward-outline.svg"}
                          alt="forward"
                        />
                      </button>
                    </div>
                  </div>
              </>
              :
              <> 
                <div className="login-div">
                  <Heading type={"signup"}/>
                  <Signup setShowLogin={setShowLogin} setLoggedin={setLoggedin}/>
                  <SocialLogins clientId={clientId} onSuccess={onSuccess} onFailure={onFailure} responseFacebook={responseFacebook} />
                </div>
                <div className="signup-div">
                  <Heading type={"login"}/>
                  <div className="btn-wrap">
                    <button className="btn btn-primary" onClick={() => setShowLogin(!showLogin)}>
                      Continue
                      <img
                        className="fa"
                        src={"/images/arrow-forward-outline.svg"}
                        alt="forward"
                      />
                    </button>
                  </div>
                </div>
              </>
              } */}
            </div>
          </div>
        </div>

    </>
  );
};

export default LoginSignup;
