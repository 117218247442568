import React from 'react';

const LazyCmsPage = React.lazy(() =>
  import(/* webpackChunkName: "CmsPage" */ './cmsPage')
);

const CmsPage = (props: Record<string, any>) => (
  <React.Suspense fallback={<div className="loader"></div>}>
    <LazyCmsPage {...props} />
  </React.Suspense>
);

export default CmsPage