import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { useToaster } from "../../_common/hooks/common/appToasterHook";
import { useUserApi } from "../../_common/hooks/api/user/appUserApiHook";
import FormTextInput from "src/_common/components/form-elements/textinput/formTextInput";
import { useAppLinkSelector } from "src/_common/hooks/selectors/user/userSelector";
import { useAppUserAction } from "src/_common/hooks/actions/user/appUserActionHook";
import { URLS } from "src/_config";
import MetaDecorator from "../common/MetaDecorator";
// import { URLS } from "../../_config";
// import FormTextInput from "../../_common/components/form-elements/textinput/formTextInput";

interface LoginFormValues {
  email: string;
  password: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is invalid"),
  password: Yup.string().required("Password is required"),
});

const Login = ({ setLoggedin, page }: { setLoggedin: Function, page?: any }) => {
  const { control, errors, handleSubmit } = useForm<LoginFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const toast = useToaster();
  const userApi = useUserApi();
  const history = useHistory();
  const link = useAppLinkSelector()
  const userAction = useAppUserAction()
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const gotoForgotPassword = () => {
    // history.push(URLS.USER.FORGOT_PASSWORD)
    const win: any = window.open(URLS.USER.FORGOT_PASSWORD, "_blank");
    win.focus();
  }

  const onSubmitLogin = (values: LoginFormValues) => {
    // e.preventDefault()
    const params = {
      email: values.email?.trim(),
      password: values.password?.trim(),
    }
    userApi.callUserLogin(params, (message: string, resp: any) => {
      if (resp) {
        // console.log({ resp });
        if (link) {
          history.push(link)
          userAction.setLink(null)
        }
        setLoggedin(false)
        toast.success(message)
      } else {
        toast.error(message)
      }
    }, (message: string) => {
      toast.error(message)
    })
  }


  return (
    <form>

      {!page &&
        <MetaDecorator
          title={"Login"}
          description={"Login to Rural America In Action"}
        />
      }

      <div className="form-wrap">
        <div className="mb-3">
          <label className="form-label">Email address<span style={{ color: 'red' }}>*</span></label>
          {/* <input
            name="email"
            type="email"
            className="form-control"
            placeholder="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          /> */}
          <Controller
            control={control}
            name="email"
            render={({ onChange, onBlur, value, name, ref }) => (
              <FormTextInput
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                type="text"
                error={errors.email}
                placeholder="Email"
              />
            )}
          />
          {/* {formErrors.email && (
            <span className="error">{formErrors.email}</span>
          )} */}
        </div>
        <div className="mb-3 password-field-show">
          <label className="form-label">Password<span style={{ color: 'red' }}>*</span></label>
          {/* <input
            name="password"
            type="password"
            className="form-control"
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          /> */}
          <Controller
            control={control}
            name="password"
            render={({ onChange, onBlur, value, name, ref }) => (
              <FormTextInput
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                type={showPassword == false ? "password" : "text"}
                error={errors.password}
                placeholder="Password"
              />
            )}
          />
          {
            showPassword == false ?
              <i className="fa fa-eye-slash" aria-hidden="true" onClick={() => setShowPassword(!showPassword)}></i>
              :
              <i className="fa fa-eye" aria-hidden="true" onClick={() => setShowPassword(!showPassword)}></i>
          }
          {/* {formErrors.password && (
            <span className="error">{formErrors.password}</span>
          )} */}
        </div>
        <p>
          <b>Forgot Your Password?{" "}</b>
          <span style={{ color: "red", textDecoration: "underline", cursor: "pointer", display: "block" }} onClick={gotoForgotPassword}>
            Click here to have your password reset.
          </span>
        </p>
        <div className="btn-wrap">
          {/* <!-- <a href="javascript:void(0);" className="btn btn-secondary"><span className="back-icon"></span>Previous</a> --> */}
          {/* <!-- ADD *disabled* className when the field is empty --> */}
          {/* <Link to="#" className="btn btn-primary" onClick={(e) => onSubmit(e)}> */}
          <button type="submit" className="btn btn-primary" onClick={handleSubmit(onSubmitLogin)}>
            Login
            <img
              className="fa"
              src={"/images/arrow-forward-outline.svg"}
              alt="forward"
            />
          </button>
          {/* </Link> */}
        </div>
        <div className="or-wrap">
          <span>or</span>
        </div>

      </div>
    </form>
  );
};

export default Login;
