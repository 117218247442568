import React from 'react'
import SurveyLanding from 'src/components/survey/survey-landing/SurveyLanding'

export default function LandingSurveyPage() {
  return (
    <React.Fragment>
       <SurveyLanding />
    </React.Fragment>
  )
}
