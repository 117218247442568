import React from 'react';

const LazyLandingPetitionPage = React.lazy(() =>
  import(/* webpackChunkName: "LandingPetitionPage" */ './landingpetitionpage')
);

const LandingPetitionPage = (props: Record<string, any>) => (
  <React.Suspense fallback={<div className="loader"></div>}>
    <LazyLandingPetitionPage {...props} />
  </React.Suspense>
);

export default LandingPetitionPage