import React, { useEffect, useState } from "react";
import Footer from "../../common/Footer";
import RightStickyBtn from "../../common/RightStickyBtn";
import { CRYPTO_SECRET_KEY } from "../../../_config/site_statics";
import { useHistory, useParams } from "react-router";
import { useAppPetitionApi } from "src/_common/hooks/api/petition/appPetitionApiHook";
import { useToaster } from "src/_common/hooks/common/appToasterHook";
import { useAppPetitionDetailsSelector } from "src/_common/hooks/selectors/petition/petitionSelector";
import { getSignedTime } from "src/_config/functions";
import { EmailIcon, EmailShareButton, FacebookShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
// import DeleteConfirmModal from "./DeleteConfirmModal";
// import NavTabs from "./NavTabs";
// import AddUpdateModal from "./AddUpdateModal";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Ma } from "react-flags-select";
import NavbarMain from "src/components/common/navbar/NavbarMain";
import { useCommonApi } from "src/_common/hooks/api/common/appCommonApiHook";
// import GetReport from "./GetReport";
import MetaDecorator from "src/components/common/MetaDecorator";import { useAppLinkSelector, useAppUserDetailsSelector } from "src/_common/hooks/selectors/user/userSelector";
import { useAppFundraisingApi } from "src/_common/hooks/api/fundraising/appFundraisingApiHook";
import { useAppBankAccountDetailSelector, useAppFundraisingDetailsSelector } from "src/_common/hooks/selectors/fundraising/fundraisingSelector";
import MyFundraisingSettings from "../my-causes/MyFundraisingSettings";
import GetReport from "src/components/petition/my-petition-dashboard/GetReport";
import { Link } from "react-router-dom";
import AddUpdateModal from "./updates/AddUpdateModal";
import { useAppFundraisingAction } from "src/_common/hooks/actions/fundraising/appFundraisingActionHook";
import DeleteDuplicateConfirmationModal from "src/components/common/DeleteDuplicateConfirmationModal";
import NavTabs from "./NavTabs";
;

const MyFundraisingDashboard = () => {
  
  

  const user: any = useAppUserDetailsSelector()
  const toast = useToaster()
  const { slug } = useParams<any>();
  // const id = parseInt(cryptr.decrypt(findraisingId.id));
  const history = useHistory()

  const link = useAppLinkSelector()
  const fundraisingApi = useAppFundraisingApi()
  const fundraisingAction = useAppFundraisingAction()
  const fundraisingDetails = useAppFundraisingDetailsSelector()?.details;
  const fundraisingSettings = useAppFundraisingDetailsSelector()?.settings;
  const bankAccountDetails = useAppBankAccountDetailSelector()?.bank_list;

  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [finalBankDetail, setFinalBankDetail] = useState<any>([])
  const [donorsListData, setDonorsListData] = useState<any>([])
  const [copiedValues, setCopiedValues] = useState<any>(`${window.location.origin}/fundraising/${slug}`)
  const [showAddUpdateModal, setShowAddUpdateModal] = useState<boolean>(false)
  const commonApi = useCommonApi()

  function openModal() {
    setIsOpen(true);
  };

  function closeModal() {
    setIsOpen(false);
  };

  const getFundraisingDetails = () => {
    const params = {
      slug,
    };
    fundraisingApi.callGetFundraisingDetails(
      params,
      (message: string, resp: any) => {
        // console.log("fundDe", { resp });
        if(user?.id != resp?.data?.details?.user_id) {
          history.push(`/`)
        }
      },
      (message: string) => {
        toast.error(message)
      }
    );
  };

  const getBankAccountDetails = () => {
    fundraisingApi.callGetBankAccountDetails((message: string, resp: any) => {
      // filterBankDetails()
      // console.log(resp)
      // toast.success(message)
    }, (message: string) => {
      // toast.error(message);
    })
  }

  const filterBankDetails = () => {
    // console.log(bankAccountDetails)
    let bankAccountDetail = bankAccountDetails.filter((item: any) => item.slug == slug);
    // console.log(bankAccountDetail)
    setFinalBankDetail(bankAccountDetail)
  }
  const gotoDetailsPage = (id?: any) => {
    history.push(`/fundraising/${slug}`)
  }

  const gotoEditPage = (id: any) => {
    history.push(`/edit-fundraising/${slug}`)
  }

  const gotoSettingsPage = (id: any) => {
    history.push(`/fundraising-settings/${slug}`)
  }

  const handleCopyChange = (event: any) => {
    setCopiedValues(event.target.value);
    setIsCopied(false);
  };

  const handleShareCountClick = () => {
    let params = {
      entity_type: 'fundraising',
      entity_id: fundraisingDetails?.id,
    }
    commonApi.callShareCount(params, (message: string, resp: any) => {
      // console.log("called sharecount")
      getFundraisingDetails();
    },
      (message: string) => {
        // toast.error(message)
      })
  }

  const getAllDonors = (page?: any) => {
    var params: any = { slug };
    if (page) {
      params["page"] = page
    }
    fundraisingApi.callGetAllDonors(
      params,
      (message: string, resp: any) => {
        // console.log({ resp });
        let x=resp.data.list.data
        x=x.slice(0,3)
        setDonorsListData(x);
        // console.log(donorsListData);
      },
      (message: string) => {
        toast.error(message);
      }
    )
  }

  const openAddUpdateModal = () => {
    setShowAddUpdateModal(true)
  }

  const closeAddUpdateModal = () => {
    setShowAddUpdateModal(false)
  }

  const handleFundraiserClose = (id:any) => {
    // fundraisingApi.callCloseFundraiser({fundraiser_id:id},
    //   (message:any, resp:any)=>{
    //     console.log({resp})
    //     closeModal()
    //     getFundraisingDetails()
    //     toast.success(message)
    //   },
    //   (message:any)=>{
    //     toast.error(message)
    //   }
    // )

    let params: any = {
      fundraiser_id: fundraisingDetails?.id,
    }   
    fundraisingApi.callRequestForPayout(
      params,
      (message:any,resp:any)=>{
        console.log(resp)
        closeModal()
        getFundraisingDetails()
        toast.success(message)
      },
      (message:any)=>{
        toast.error(message)
      }
    )
  }

  useEffect(() => {
    getFundraisingDetails();
    getBankAccountDetails();
    getAllDonors();
  }, []);

  useEffect(() => {
    if (bankAccountDetails) {
      filterBankDetails()
    }
  }, [bankAccountDetails]);

  // useEffect(() => {
  //   if(user?.id != fundraisingDetails?.user_id) {
  //     history.push(`/`)
  //   }
  // },[user, fundraisingDetails])

  useEffect(()=>{
    return ()=>{
      fundraisingAction.resetFundraisingDetails()
    }
  },[])

  return (
    <>

      <MetaDecorator
        title={fundraisingDetails?.title + " : Dashboard"}
        description={fundraisingDetails?.title + " : Dashboard"}
      />

      <NavbarMain section={"fundraising"} />

      <div className="innerpageBanner nobanner_image"></div>
      <RightStickyBtn />

      {/* main section starts */}

      <section className="inner-page adjusted-padding-top">
        <div className="container">
          <div className="custom-tab-wrap">
            {/* Nav tabs */}
            <NavTabs
              page={"dashboard"}
            // setPage={setPage}
            />
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="Dashboard"
                role="tabpanel"
                aria-labelledby="Dashboard-tab"
                tabIndex={0}
              >
                <div className="inner-tab-wrap">
                  <h2 className="tab-title">{fundraisingDetails?.title}</h2>
                  <div className="my-Petition-des-wrap">
                    <div className="row">
                      <div className="col-sm-3">
                        <div className="white-rounded-box mb-4">
                          <div className="supporter-counts progress-wrapper">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              viewBox="37 -5 120 100"
                              width={120}
                              height={100}
                            >
                              <path
                                className="grey"
                                d="M55,90 A55,55 0 1,1 140,90"
                                style={{ fill: "none" }}
                              />
                              <path
                                className={`signed signed-${Math.round((fundraisingDetails?.total_fund_value?.total_amount / fundraisingDetails?.target_fund_value) * 10)}`}
                                d="M55,90 A55,55 0 1,1 140,90"
                                style={{ fill: "none" }}
                              />
                            </svg>
                            <span className="supporter-text">
                              <em>
                                {fundraisingDetails &&
                                  fundraisingDetails?.total_donor.toLocaleString()}
                              </em>
                              <i>Donor{fundraisingDetails?.total_donor>1?"s":""}</i>
                            </span>
                          </div>
                          <div className="supporter-needed">
                            Only{" "}
                            <span>
                              ${fundraisingDetails?.total_fund_value?.total_amount > fundraisingDetails?.target_fund_value ? 0 : ((fundraisingDetails?.target_fund_value || 0) - (fundraisingDetails?.total_fund_value?.total_amount || 0)).toLocaleString()} more
                            </span> amount to the goal!
                          </div>
                          <div className="fund-share-wrap">
                            <div className="share-wrap">
                              <span>
                                {fundraisingDetails &&
                                fundraisingDetails["total_share"].toLocaleString()}
                              </span> shares
                            </div>
                          </div>
                        </div>
                        <div className="white-rounded-box mb-4">
                          <div className="white-box-header">
                            Recent Donors
                          </div>
                          <div className="white-box-body">
                            <div className="recent-supporter-wrap">
                              {donorsListData?.length ?
                                donorsListData?.map((d: any) =>
                                  <div className="supporter-row" key={d.id}>
                                    <div className="supporter-img">
                                      <img
                                        src={d?.user?.avatar?.thumb}
                                        alt="person"
                                      />
                                    </div>
                                    <div className="recent-supporter-text">
                                      {d?.anonymous_donation == '1' ? "Anonymous" : d?.user?.full_name}
                                      <span>
                                        Donated{" "}
                                        {/* ${d?.amount},  */}
                                        {getSignedTime(d?.created_at)}
                                      </span>
                                    </div>
                                  </div>
                                )
                                :
                                "No Donation done yet"
                              }
                              {/* {getPetitionSignedUsers &&
                                getPetitionSignedUsers.map(
                                  (item: any, index: any) => (
                                    <div className="supporter-row" key={index}>
                                      {item.user ?
                                        <>
                                          <div className="supporter-img">
                                            <img
                                              src={item?.user?.avatar?.thumb}
                                              alt="person"
                                            />
                                          </div>
                                          <div className="recent-supporter-text">
                                            {
                                              (item?.is_anonymous == 1 || item?.show_only_me == 1) ?
                                                <h3>Anonymous</h3> :
                                                <h3>{item.user?.full_name}</h3>
                                            }
                                            <span>
                                              Signed{" "}
                                              {getSignedTime(item?.sign_date)}
                                            </span>
                                          </div>
                                        </>
                                        :
                                        <>
                                          <div className="supporter-img">
                                            <img
                                              src="/images/no-profile.png"
                                              alt="person"
                                            />
                                          </div>
                                          <div className="recent-supporter-text">
                                            <h3>Anonymous</h3>
                                            <span>
                                              Signed{" "}
                                              {getSignedTime(item?.sign_date)}
                                            </span>
                                          </div>
                                        </>
                                      }
                                    </div>
                                  )
                                )} */}
                            </div>
                          </div>
                        </div>
                        {slug && <GetReport slug={slug} page="fundraising" />}
                      </div>
                      <div className="col-sm-9">
                        {fundraisingDetails?.status == 1 && fundraisingDetails?.is_payout_request==0 && fundraisingDetails?.close_fundriser==0 ?
                          <div className="white-rounded-box mb-4">
                            <div className="white-box-body">
                              <h3 className="white-box-inner-heading">
                                Share your fundraising
                              </h3>
                              <p className="normal-text">
                                Successful fundraising starters share their fundraising
                                about 12 times. Try to share on as many different
                                platforms as you can, and be sure to ask others to
                                sign and share your fundraising to build momentum!
                              </p>
                              <div className="mb-3">
                                <label htmlFor="" className="form-label">
                                  Share link
                                </label>
                                <div className="copy-link">
                                  <input
                                    type="text"
                                    disabled={true}
                                    className="form-control"
                                    defaultValue={copiedValues}
                                    spellCheck="false"
                                    data-ms-editor="true"
                                    onChange={(event) => handleCopyChange(event)}
                                  />
                                  <CopyToClipboard text={copiedValues}
                                    onCopy={() => setIsCopied(true)}>
                                    <button>Copy Link</button>
                                  </CopyToClipboard>
                                </div>
                              </div>
                              <div className="share-petition-wrap">
                                <a href="">
                                  <FacebookShareButton
                                    quote={fundraisingDetails?.title}
                                    url={copiedValues}
                                    className="Demo__some-network__share-button"
                                    onClick={handleShareCountClick}
                                  >
                                    <i className="fa fa-facebook" />{" "}
                                    <span>Facebook</span>
                                  </FacebookShareButton>
                                </a>
                                {/* <a href="">
                                <i className="fa fa-google" />{" "}
                                <span>Gmail</span>
                              </a> */}
                                <a href="">
                                  <TwitterShareButton
                                    title={fundraisingDetails?.title}
                                    url={copiedValues}
                                    onClick={handleShareCountClick}
                                  >
                                    <i className="fa fa-twitter" />{" "}
                                    <span>Twitter</span>
                                  </TwitterShareButton>
                                </a>
                                <a href=""
                                  onClick={handleShareCountClick}
                                >
                                  <EmailShareButton
                                    title={fundraisingDetails?.title}
                                    url={copiedValues}
                                  // onClick={handleShareCountClick}
                                  >
                                    <i className="fa fa-envelope" />{" "}
                                    <span>Email</span>
                                  </EmailShareButton>
                                </a>
                                <a href="">
                                  <WhatsappShareButton
                                    title={fundraisingDetails?.title}
                                    url={copiedValues}
                                    onClick={handleShareCountClick}
                                  >
                                    <i className="fa fa-whatsapp" />{" "}
                                    <span>WhatsApp</span>
                                  </WhatsappShareButton>
                                </a>
                              </div>
                            </div>
                          </div>
                          : null
                        }            
                        {
                          fundraisingDetails?.status==1 && fundraisingDetails?.close_fundriser==0 &&
                          <div className="white-rounded-box mb-4">
                            <div className="white-box-body">
                              <h3 className="white-box-inner-heading">
                                Update your donors
                              </h3>
                              <p className="normal-text">
                                Continue to keep your donors and network updated. Encourage donations by posting updates often. You can post about fundraiser milestones or any changes to your situation.
                              </p>
                              <div className="inner-btn-wrap">
                                <a className="btn btn-post"
                                  onClick={openAddUpdateModal}
                                >
                                  Post an update
                                </a>
                              </div>
                            </div>
                          </div>
                        }
                        <div className="white-rounded-box mb-4">
                          <div className="white-box-body">
                            <h3 className="white-box-inner-heading">
                              Close Your Fundraiser & Receive Payment
                            </h3>
                            <p className="normal-text">
                              To start the process for fundraiser closeout and payment, you must ﬁrst have a US bank account and have it veriﬁed through Stripe
                            </p>
                            <div className="inner-btn-wrap">
                              {/* {fundraisingDetails && fundraisingDetails.status && fundraisingDetails.status == 1 ?
                                // !victoryStatus ? */}

                                {
                                  // finalBankDetail && finalBankDetail.length == 0 && fundraisingDetails?.fundraising_for && fundraisingDetails?.event_option != 1 ?
                                  !fundraisingDetails?.bank_account ?
                                    <div className="no-bank-added">
                                      <span><i className="fa fa-bank"></i> No Bank Account added</span> <Link to={`/bank-account/${slug}`} style={{ cursor: 'pointer' }}>click here to add</Link>
                                    </div>
                                    :
                                    <div className="no-bank-added">
                                      <span><i className="fa fa-bank"></i> Bank Account added</span> <Link to={`/bank-account/${slug}`} style={{ cursor: 'pointer' }}>click here to check</Link>
                                    </div>
                                    // <div className="alert alert-info alert-dismissible fade show mt-2 d-none">
                                    //   Bank Account is added <Link to={`/bank-account/${slug}`} style={{ cursor: 'pointer' }}><strong>click here to check</strong></Link>
                                    // </div>
                                }
                                  {/* <Link to={`/bank-account/${slug}`} className="btn btn-post">Add Bank Account</Link> */}
                                  {/* <a
                                    href="javascript:void(0);"
                                    className="btn btn-post"
                                    // onClick={() => { openModal(); setModelType("victory"); }}
                                  >
                                    Add Bank Account
                                  </a>  */}
                                  {/* // :
                                  // <a
                                  //   href="javascript:void(0);"
                                  //   className="btn btn-post"
                                  // >
                                  //   Declared
                                  // </a>
                              //   : null
                              // } */}
                              {/* <a
                                href="javascript:void(0);"
                                className="text-link"
                                // onClick={() => { openModal(); setModelType("delete"); }}
                              >
                                Close petition
                              </a> */}
                            </div>
                            <p className="normal-text mt-4">
                              Once your bank account has been veriﬁed, close your fundraiser and initiate payment using this buton. If an admin has questions, they will email you from Admin@ruralamericainaction.com
                            </p>
                            {fundraisingDetails?.bank_account && fundraisingDetails?.bank_account?.is_connect_to_stripe==1 ?
                              fundraisingDetails?.is_payout_request == 0 ?
                                <a
                                  className="btn btn-post"
                                  // onClick={() => { openModal(); setModelType("victory"); }}
                                  onClick={() => openModal() }
                                >
                                  Close Fundraiser
                                </a> 
                                :
                                <a
                                  className="btn btn-disabled"
                                >
                                  Closed Fundraiser
                                </a>
                              :
                              <a
                                className="btn btn-disabled"
                              >
                                Close Fundraiser
                              </a> 
                            }
                          </div>
                        </div>
                        {fundraisingSettings && <MyFundraisingSettings fundraisingSettings={fundraisingSettings}/>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* main section end */}

      <Footer />
      {showAddUpdateModal &&
        <AddUpdateModal
          shouldShow={showAddUpdateModal}
          onClose={closeAddUpdateModal}
          // getUpdatesList={getUpdatesList}
        />
      }
      {fundraisingDetails && 
        <DeleteDuplicateConfirmationModal
          shouldShow={modalIsOpen}
          onClose={closeModal}
          warningText={"Are you sure you want to close this fundraiser?"}
          handleConfirm={handleFundraiserClose}
          type={"close"}
          id={fundraisingDetails?.id}
        />
      }

      {/* {showUpdateModal && petitionDetails &&
        <AddUpdateModal
          onClose={onCloseUpdateModal}
          shouldShow={showUpdateModal}
          petition_id={String(petitionDetails?.id)}
        />
      } */}

      {/* {modalIsOpen && petitionDetails &&
        <DeleteConfirmModal
          onClose={closeModal}
          shouldShow={modalIsOpen}
          petition_id={String(petitionDetails?.id)}
          modelType={modelType}
          setVictoryStatus={setVictoryStatus}
        />
      } */}
    </>
  );
};

export default MyFundraisingDashboard;
