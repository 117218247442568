import React from 'react'
import BankAccountSuccess from 'src/components/fundraising/bank-account/BankAccountSuccess'

export default function BankAccountSuccessPage() {
  return (
    <React.Fragment>
       <BankAccountSuccess />
    </React.Fragment>
  )
}
