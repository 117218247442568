import React from 'react'
import { Modal } from 'react-bootstrap'
import moment from 'moment'
import { Controller, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormTextAreaInput from 'src/_common/components/form-elements/textarea/textareaInput'
import { useAppPetitionApi } from 'src/_common/hooks/api/petition/appPetitionApiHook';
import { useToaster } from 'src/_common/hooks/actions/common/appToasterHook';

interface WriteCommentModalProps {
   onClose: () => void;
   shouldShow: boolean;
   petition_id: string;
   getCommentsList: () => void;
}

interface CommentFormValues {
    comment: string,
}

const commentSchema = yup.object().shape({
    comment: yup
        .string()
        .required('Comment is required'),
})

export default function WriteCommentModal({ onClose, shouldShow, petition_id, getCommentsList }: WriteCommentModalProps) {

    const petitionApi = useAppPetitionApi();
    const toast = useToaster();

    const { register, control, setValue, handleSubmit, errors } = useForm<CommentFormValues>({
        resolver: yupResolver(commentSchema),
        defaultValues: {
            comment: '',
        },
    })

    const onSubmit = (values: CommentFormValues) => {
        let params = {
            petition_id: petition_id,
            body: values.comment
        }
        petitionApi.callAddPetitionComment(params, (message: string, resp: any) => {
            setValue("comment",'')   
            onClose()
            getCommentsList()
            toast.success(message)
            // console.log({resp})
          }, (message: string) => {
              toast.error(message)
          })
    }

   return (
      <React.Fragment>
         <Modal
            show={shouldShow}
            backdrop="static"
            keyboard={false}
            className="stackholder-modal"
            centered
         >
            <Modal.Header>
               {/* <img src="/images/pop-hand-left.svg" alt="" /> */}
               <h5 className="modal-title"><strong>Write your comment</strong></h5>
               <button type="button" className="close" data-dismiss="modal" aria-label="Close"  onClick={()=>onClose()}>
                  <span><i className="fa fa-times" aria-hidden="true"></i></span>
               </button>
            </Modal.Header>
            <Modal.Body>
                {/* <div className="container-fluid text-center p-4 border rounded"> */}
                <div>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <div className="form-wrap">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <Controller
                                            control={control}
                                            name="comment"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <FormTextAreaInput
                                                    name={name}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    rows={3}
                                                    // inputRef={inputRef}
                                                    type="text"
                                                    error={errors.comment}
                                                    placeholder="Enter your comment here..."
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="btn-wrap">
                                <button type="submit" className="btn btn-primary mt-4">
                                    Submit
                                    <img className="fa" src={"/images/arrow-forward-outline.svg"} alt="forward"/>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal.Body>
            {/* <Modal.Footer /> */}
         </Modal>
      </React.Fragment>
   )
}