import React from 'react'
import '../form-elem.css'
import { OptionValue } from '../../../interfaces/common';
import { Form } from 'react-bootstrap'

interface RadioInputProps {
  onChange: (...args: any) => void;
  onBlur: () => void;
  options: OptionValue[];
  value: string | undefined;
  name: string;
  inputRef: any;
  placeholder?: string;
  error?: any;
  id?: string;
}


function RadioInput({
  onChange,
  onBlur,
  value,
  name,
  inputRef,
  placeholder,
  error,
  id,
  options,
}: RadioInputProps) {
  return (
    <>
      {
        options.map((opt, index) => {
          return (
          <div className="category-block">
            <input
              type="radio"
              checked={value == opt.value}
              name={name}
              value={opt.value}
              id={(id?id:"myRadio")+index}
              onChange={(evt) => {
                onChange(evt.target.value)
              }}
            />
            {/* <span className="checkmark"></span> */}
            <label htmlFor={(id?id:"myRadio")+index}>
              <p>{opt.label}</p>
            </label>
          </div>)
        })
      }
      {
        error && error.message ? <>
          <Form.Control.Feedback type="invalid" >
            {error.message}
          </Form.Control.Feedback>
        </> : null
      }
    </>
  )
}

export default RadioInput
