import React, { useState } from "react";
// import { Link, useHistory } from "react-router-dom";
import { useUserApi } from "../../_common/hooks/api/user/appUserApiHook";
import * as Yup from "yup";
// import { URLS } from "../../_config";
import FormTextInput from "src/_common/components/form-elements/textinput/formTextInput";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToaster } from "../../_common/hooks/common/appToasterHook";
import NavbarMain from "../common/navbar/NavbarMain";
import RightStickyBtn from "../common/RightStickyBtn";
import Footer from "../common/Footer";
import { useHistory, useParams } from "react-router";
import { URLS } from "src/_config";

interface SetPasswordFormValues {
  password: string;
  password_confirmation: string;
}

const validationSchema = Yup.object().shape({
  password: Yup.string().required("Password is required").min(6, "Password must be at least 6 characters"),
  password_confirmation: Yup.string().required("Confirm Password is required").min(6).oneOf([Yup.ref('password'), ''], 'Passwords must match'),
});

const SetPassword = () => {

  const { control, errors, handleSubmit, reset } = useForm<SetPasswordFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      password: "",
      password_confirmation: "",
    },
  });

  const { token } = useParams<any>()
  const toast = useToaster()
  const userApi = useUserApi()
  const history = useHistory()

  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)

  const onSubmit = (values: SetPasswordFormValues) => {
    const params = {
        token,
        password: values.password?.trim(),
        c_password: values.password_confirmation?.trim(),
    }
    userApi.callSetPassword(
      params,
      (message:any)=>{
        toast.success(message)
        reset()
        setTimeout(() => {          
          history.push(URLS.USER.LOGIN)
        }, 3000);
      },
      (message:any)=>{
        toast.error(message)
      },
    )        
  }

  return (
    <>
    <NavbarMain section={"login"} />
    <RightStickyBtn />

    <section className="inner-page">
      <div className="container">
        <div className="login-signup-box mw-400">
          <h2 className="section-title">Reset Password</h2>
            <div className="form-wrap">
                <div className="mb-3 password-field-show">
                    <label className="form-label">Password<span style={{ color: 'red' }}>*</span></label>
                    <Controller
                    control={control}
                    name="password"
                    render={({ onChange, onBlur, value, name, ref }) => (
                        <FormTextInput
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        inputRef={ref}
                        type={showPassword == false ? "password": "text"}
                        error={errors.password}
                        placeholder="Password"
                        />
                    )}
                    />
                    {
                    showPassword == false ?
                        <i className="fa fa-eye-slash" aria-hidden="true" onClick={() => setShowPassword(!showPassword)}></i>
                        :
                        <i className="fa fa-eye" aria-hidden="true" onClick={() => setShowPassword(!showPassword)}></i>
                    }
                </div>
                <div className="mb-3 password-field-show">
                    <label className="form-label">Confirm Password<span style={{ color: 'red' }}>*</span></label>
                    <Controller
                    control={control}
                    name="password_confirmation"
                    render={({ onChange, onBlur, value, name, ref }) => (
                        <FormTextInput
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        inputRef={ref}
                        type={showConfirmPassword == false ? "password": "text"}
                        error={errors.password_confirmation}
                        placeholder="Confirm Password"
                        />
                    )}
                    />
                    {
                    showConfirmPassword == false ?
                        <i className="fa fa-eye-slash" aria-hidden="true" onClick={() => setShowConfirmPassword(!showConfirmPassword)}></i>
                        :
                        <i className="fa fa-eye" aria-hidden="true" onClick={() => setShowConfirmPassword(!showConfirmPassword)}></i>
                    }
                </div>
                <div className="btn-wrap">
                    <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleSubmit(onSubmit)}
                    >
                    Continue{" "}
                    <img
                        className="fa"
                        src={"/images/arrow-forward-outline.svg"}
                        alt="forward"
                    />
                    </button>
                    {/* </Link> */}
                </div>            
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default SetPassword;
