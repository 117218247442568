import React from 'react';

const LazyEndSurveyPage = React.lazy(() =>
  import(/* webpackChunkName: "EndSurveyPage" */ './endsurveypage')
);

const EndSurveyPage = (props: Record<string, any>) => (
  <React.Suspense fallback={<div className="loader"></div>}>
    <LazyEndSurveyPage {...props} />
  </React.Suspense>
);

export default EndSurveyPage