import React from "react";
import { Modal } from "react-bootstrap";

interface DeleteModalProps {
  onClose: () => void;
  shouldShow: boolean;
  handleConfirm: Function;
  warningText: string;
  id: string;
  type: string;
}

const DeleteDuplicateConfirmationModal = ({ onClose, shouldShow, handleConfirm, warningText, id, type }: DeleteModalProps) => {

  return (
    <Modal
      show={shouldShow}
      backdrop="static"
      keyboard={false}
      className="stackholder-modal"
      centered
    >
      <Modal.Header>
        <h5 className="modal-title">
          <strong>Confirm if you want to {type}</strong>
        </h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onClose}
        >
          <span>
            <i className="fa fa-times" aria-hidden="true"></i>
          </span>
        </button>
      </Modal.Header>
      <Modal.Body>

        <div className="container">
          <p>{warningText}</p>
        </div>

        <div className="two-btn-wrap py-5">
          <button type="button" className="btn btn-secondary" onClick={onClose}>No</button>
          <button type="button" className="btn btn-primary " onClick={() => handleConfirm(id)}>Yes</button>
        </div>

      </Modal.Body>
    </Modal>
  );
};

export default DeleteDuplicateConfirmationModal;
