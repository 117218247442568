import React from 'react';

const LazyMyPetitionDashboard = React.lazy(() =>
  import(/* webpackChunkName: "MyPetitionDashboardPage" */ './mypetitiondashboardpage')
);

const MyPetitionDashboardPage = (props: Record<string, any>) => (
  <React.Suspense fallback={<div className="loader"></div>}>
    <LazyMyPetitionDashboard {...props} />
  </React.Suspense>
);

export default MyPetitionDashboardPage