import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useAppUserAction } from 'src/_common/hooks/actions/user/appUserActionHook'
import { useAuthStatus } from 'src/_common/hooks/authHook'
import { URLS } from 'src/_config'

interface StartSurveyButtonProps {
  className: string;
  noImage?: boolean;
  text?: string;
}

const StartSurveyButton = ({ className, noImage, text }: StartSurveyButtonProps) => {

  const userAction = useAppUserAction()
  const isAuth = useAuthStatus()

  const setLink = () => {
    if (!isAuth) {
      userAction.setLink('/create-survey')
    }
  }

  return (
    <Link className={className+(className=="nav-link" && URLS.SURVEY.CREATE_SURVEY.includes(window.location.pathname)?" active":"")} to={'/create-survey'} onClick={setLink}>
      {text && text}{" "}
      {!noImage && <img className="fa" src={"/images/arrow-forward-outline.svg"} alt="arrow" />}
    </Link>
  )
}

export default StartSurveyButton
