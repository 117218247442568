import React from 'react';

const LazyVerifySurvey = React.lazy(() =>
  import(/* webpackChunkName: "VerifySurvey" */ './verifySurvey')
);

const VerifySurvey = (props: Record<string, any>) => (
  <React.Suspense fallback={<div className="loader"></div>}>
    <LazyVerifySurvey {...props} />
  </React.Suspense>
);

export default VerifySurvey