import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, withRouter, HashRouter, Redirect } from 'react-router-dom';
import './App.css';
import { URLS } from './_config';
import requireNoAuth from './_common/hoc/reqNoAuth'
import requireAuth from './_common/hoc/reqAuth'
import HomePage from './containers/homepage'
import Login from './containers/login'
import CreatePetition from './containers/petition/create-petition';
import BrowsePetitionPage from './containers/petition/browse-petition';
import LandingPetitionPage from './containers/petition/landing-petition';
import PetitionDetailsPage from './containers/petition/petition-details';
import PetitionCommentsPage from './containers/petition/petition-comments/petitioncommentspage';
import PetitionUpdatesPage from './containers/petition/petition-updates';
import PetitionReasonsPage from './containers/petition/petition-reasons';
import MyPetitionsPage from './containers/petition/my-petiton';
import MyPetitionDashboardPage from './containers/petition/my-petiton-dashboard';
import MyPetitionEditPage from './containers/petition/my-petiton-edit/mypetitioneditpage';
import StartFundraisingPage from './containers/fundraising/start-fundraising';
import BrowseFundraisingPage from './containers/fundraising/browse-fundraising/browsefundraisingpage';
import FundraisingDetailsPage from './containers/fundraising/fundraising-details/fundraisingdetailspage';
import MyFundraisingDashboardPage from './containers/fundraising/my-fundraising-dashboard/myfundraisingdashboardpage';
import WordOfSupportPage from './containers/fundraising/word-of-support/wordsofsupportpage';
import FundraisingDonatePage from './containers/fundraising/fundraising-donate/fundraisingdonatepage';
import MyCausesPage from './containers/fundraising/my-causes/mycausespage';
import MyFundraisingEditPage from './containers/fundraising/my-fundraising-edit/myfundraisingeditpage';
import BankAccountPage from './containers/fundraising/bank-account';
import LandingSurveyPage from './containers/survey/landing-petition/landingsurveypage';
import CreateSurveyPage from './containers/survey/create-survey/createsurveypage';
import MySurveyPage from './containers/survey/my-survey';
import BankAccountSuccessPage from './containers/fundraising/bank-account-success/bankaccountsuccesspage';
import BankAccountFailPage from './containers/fundraising/bank-account-fai/bankaccountfailpage';
import BrowseSurveyPage from './containers/survey/browse-survey/browsesurveypage';
import StartSurveyPage from './containers/survey/start-survey/startsurveypage';
import SurveyDetailsPage from './containers/survey/survey-details';
import MyProfile from './containers/myProfile'
import CmsPage from './containers/cms'
import SearchPage from './containers/search'
import {gapi} from 'gapi-script';
import Settings from './containers/settings';
import EndSurveyPage from './containers/survey/end-survey';
import AnalyzeResultPage from './containers/survey/analyze-result';
import MyFundraisingSettingsPage from './containers/fundraising/my-fundraising-settings/myFundraisingSettingsPage';
import SetPassword from './containers/set-password/set-password';
import ForgotPassword from './containers/forgot-password/forgot-password';
import { HelmetProvider } from 'react-helmet-async'
import Page404 from './containers/page-404/page404';
import VerifySurvey from './containers/survey/verify-survey';
import FundraisingUpdates from './components/fundraising/fundraising-updates/FundraisingUpdates';


const clientId="850326453397-ec2ube3bt58t966vajrskq8chndbto0c.apps.googleusercontent.com"

function App() {

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope:""
      })
    }

    gapi.load('client:auth2', start)
  })
  
  return (
    <Router>
      <Switch>

        <Route exact path={URLS.HOME} component={() => (
          <Redirect to={URLS.USER.HOME}/>
        )} />

        <Route exact path={URLS.CMS} component={(CmsPage)} />
        <Route exact path={URLS.SEARCH} component={(SearchPage)} />

        {/* User */}
        <Route exact path={URLS.USER.LOGIN} component={requireNoAuth(Login)} />
        <Route exact path={URLS.USER.HOME} component={(HomePage)} />
        <Route exact path={URLS.USER.MY_PROFILE} component={requireAuth(MyProfile)} />
        <Route exact path={URLS.USER.SETTINGS} component={requireAuth(Settings)} />
        <Route exact path={URLS.USER.FORGOT_PASSWORD} component={requireNoAuth(ForgotPassword)} />
        <Route exact path={URLS.USER.SET_PASSWORD} component={requireNoAuth(SetPassword)} />

        {/* Petition */}
        <Route exact path={URLS.PETITION.BROWSEPETITION} component={(BrowsePetitionPage)} />
        <Route exact path={URLS.PETITION.LANDINGPETITION} component={(LandingPetitionPage)} />
        <Route exact path={URLS.PETITION.PETITIONDETAILS} component={(PetitionDetailsPage)} />
        <Route exact path={URLS.PETITION.PETITIONCOMMENTS} component={(PetitionCommentsPage)} />
        <Route exact path={URLS.PETITION.PETITIONUPDATES} component={(PetitionUpdatesPage)} />
        <Route exact path={URLS.PETITION.PETITIONREASONS} component={(PetitionReasonsPage)} />
        <Route exact path={URLS.PETITION.START_PETITION} component={requireAuth(CreatePetition)} />
        <Route exact path={URLS.PETITION.MYPETITIONS} component={requireAuth(MyPetitionsPage)} />
        <Route exact path={URLS.PETITION.MYPETITION_DASHBOARD} component={requireAuth(MyPetitionDashboardPage)} />
        <Route exact path={URLS.PETITION.MYPETITION_EDIT} component={requireAuth(MyPetitionEditPage)} />

        {/* Fundraising */}
        <Route exact path={URLS.FUNDRAISING.START_FUNDRAISING} component={requireAuth(StartFundraisingPage)} />
        <Route exact path={URLS.FUNDRAISING.BROWSE_FUNDRAISING} component={(BrowseFundraisingPage)} />
        <Route exact path={URLS.FUNDRAISING.FUNDRAISING_DETAILS} component={(FundraisingDetailsPage)} />
        <Route exact path={URLS.FUNDRAISING.MY_FUNDRAISING_DASHBOARD} component={requireAuth(MyFundraisingDashboardPage)} />
        <Route exact path={URLS.FUNDRAISING.MY_FUNDRAISING_EDIT} component={requireAuth(MyFundraisingEditPage)} />
        <Route exact path={URLS.FUNDRAISING.MY_FUNDRAISING_SETTINGS} component={requireAuth(MyFundraisingSettingsPage)} />
        {/* <Route exact path={URLS.FUNDRAISING.WORD_OF_SUPPORT} component={requireAuth(WordOfSupportPage)} /> */}
        <Route exact path={URLS.FUNDRAISING.WORD_OF_SUPPORT} component={(WordOfSupportPage)} />
        {/* <Route exact path={URLS.FUNDRAISING.FUNDRAISING_UPDATES} component={(FundraisingUpdates)} /> */}
        <Route exact path={URLS.FUNDRAISING.DONATE} component={(FundraisingDonatePage)} />
        <Route exact path={URLS.FUNDRAISING.MY_CAUSES} component={requireAuth(MyCausesPage)} />
        <Route exact path={URLS.FUNDRAISING.BANK_ACCOUNT} component={requireAuth(BankAccountPage)} />
        <Route exact path={URLS.FUNDRAISING.BANK_ACCOUNT_SUCCESS} component={requireAuth(BankAccountSuccessPage)} />
        <Route exact path={URLS.FUNDRAISING.BANK_ACCOUNT_FAIL} component={requireAuth(BankAccountFailPage)} />

        {/* Survey */}
        <Route exact path={URLS.SURVEY.SURVEY_LANDING} component={(LandingSurveyPage)} />
        <Route exact path={URLS.SURVEY.CREATE_SURVEY} component={requireAuth(CreateSurveyPage)} />
        <Route exact path={URLS.SURVEY.MY_SURVEY} component={requireAuth(MySurveyPage)} />
        <Route exact path={URLS.SURVEY.BROWSE_SURVEY} component={(BrowseSurveyPage)} />
        <Route exact path={URLS.SURVEY.START_SURVEY} component={(StartSurveyPage)} />
        <Route exact path={URLS.SURVEY.SURVEY_DETAILS} component={(SurveyDetailsPage)} />
        <Route exact path={URLS.SURVEY.END_SURVEY} component={(EndSurveyPage)} />
        <Route exact path={URLS.SURVEY.ANALYZE_RESULT} component={(AnalyzeResultPage)} />
        <Route exact path={URLS.SURVEY.VERIFY_SURVEY} component={(VerifySurvey)} />
        
        <Route path="*" component={(Page404)} />

      </Switch>
    </Router>
  );
}

export default App;
