import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import Footer from 'src/components/common/Footer'
import NavbarMain from 'src/components/common/navbar/NavbarMain'
import RightStickyBtn from 'src/components/common/RightStickyBtn'
import { useAppFundraisingApi } from 'src/_common/hooks/api/fundraising/appFundraisingApiHook'
import { useAppFundraisingDetailsSelector } from 'src/_common/hooks/selectors/fundraising/fundraisingSelector'
import { useAppUserDetailsSelector } from 'src/_common/hooks/selectors/user/userSelector'
import { CRYPTO_SECRET_KEY } from 'src/_config'

const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

const BankAccountSuccess = () => {

    const history = useHistory();
    const fundraisingApi = useAppFundraisingApi();
    const userDetails = useAppUserDetailsSelector();
    const fundraisingDetails = useAppFundraisingDetailsSelector();

    const connectWithStripeSuccess = () => {
        let params = {
            user_uid: String(userDetails?.id),
            fundraiser_id: String(fundraisingDetails?.details?.id)
        }
        fundraisingApi.callConnectWithStripeSuccess(params,(message: string, resp: any) => {
            if(resp){
                setTimeout(() => {
                    history.push(`/bank-account/${fundraisingDetails?.details?.slug}`)
                },3000)
            }
        }, (message: string) => {
        })
    }

    useEffect(() => {
        connectWithStripeSuccess();
    }, [])

    return (
        <>
            <NavbarMain section={"fundraising"} />

            <RightStickyBtn />

            <section className="inner-page">
                <div className="container">
                    {/* <h1 className="alert-success alert">Payment Done Succesfully</h1> */}
                    <h1 className="alert-success alert">Bank Account Added Succesfully</h1>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default BankAccountSuccess