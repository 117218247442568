import React from 'react';

const LazyBankAccountPage = React.lazy(() =>
  import(/* webpackChunkName: "BankAccountPage" */ './bankaccountpage')
);

const BankAccountPage = (props: Record<string, any>) => (
  <React.Suspense fallback={<div className="loader"></div>}>
    <LazyBankAccountPage {...props} />
  </React.Suspense>
);

export default BankAccountPage