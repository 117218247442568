import { ACTIONS, SAGA_ACTIONS } from '../../../../_config'
import { CityListReq, CmsContentReq, GlobalSearchReq, DeleteImageReq, LoginReq, RegisterReq, ShareCountReq, StateListReq } from '../../../interfaces/ApiReqRes'
import { useApiCall } from '../../common/appApiCallHook'

export function useCommonApi() {

  const callApi = useApiCall()
  
  const countryList = ( onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.COUNTRY_LIST, null, onSuccess, onError);
  }
  
  const stateList = (data: StateListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.STATE_LIST, data, onSuccess, onError);
  }

  const cityList = (data: CityListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.CITY_LIST, data, onSuccess, onError);
  }

  const shareCount = (data: ShareCountReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.SHARE_COUNT, data, onSuccess, onError);
  }

  const deleteImage = (data: DeleteImageReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.DELETE_IMAGE, data, onSuccess, onError);
  }

  const popupSetting = ( onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.POPUP_SETTING, null, onSuccess, onError);
  }

  const cmsContent = (data: CmsContentReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.CMS_CONTENT, data, onSuccess, onError);
  }

  const cmsLinks = ( onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.CMS_LINKS, null, onSuccess, onError);
  }

  const homepageCounts = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.HOME_PAGE_COUNTING, null, onSuccess, onError);
  }

  const globalSearch = (data: GlobalSearchReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.GLOBAL_SEARCH, data, onSuccess, onError);
  }

  const reportCategoryList = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.REPORT_CATEGORY_LIST, null, onSuccess, onError);
  }

  return {
    callCountryList: countryList,
    callStateList: stateList,
    callCityList: cityList,
    callShareCount: shareCount,
    callDeleteImage: deleteImage,
    callPopupSetting: popupSetting,
    callCmsContent: cmsContent,
    callCmsLinks: cmsLinks,
    callHomepageCounts: homepageCounts,
    callGlobalSearch: globalSearch,
    callReportCategoryList: reportCategoryList,
  }
}