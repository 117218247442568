import React from 'react';

const LazySearchPage = React.lazy(() =>
  import(/* webpackChunkName: "SearchPage" */ './searchPage')
);

const SearchPage = (props: Record<string, any>) => (
  <React.Suspense fallback={<div className="loader"></div>}>
    <LazySearchPage {...props} />
  </React.Suspense>
);

export default SearchPage