import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

interface MetaDecoratorProps {
  title: string,
  description: string,
  imageUrl?: string,
};

const MetaDecorator = ({ title, description, imageUrl }:MetaDecoratorProps) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />

    <meta property="fb:app_id" content="1123824818512127" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={window.location.href} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta name="image" content={imageUrl ? imageUrl : "/images/LOGO_NEW.png"} itemProp="image" property="og:image" />
    {/* <meta property="og:image:width" content="300"/>
    <meta property="og:image:height" content="300"/> */}

    <meta name="twitter:card" content="summary_large_image" />
    <meta property="twitter:image" content={imageUrl ? imageUrl : "/images/LOGO_NEW.png"} />
    <meta name="twitter:image:alt" content={title} />
    <meta property="twitter:title" content={title} />
    <meta property="twitter:description" content={description} />
    <meta name="twitter:site" content={title} />
  </Helmet>
);

export default MetaDecorator;
