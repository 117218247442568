import parse from 'html-react-parser'
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

interface HomePageModalProps {
  onClose: () => void;
  shouldShow: boolean;
  popupSetting: any;
}

const HomePageModal = ({ onClose, shouldShow, popupSetting }: HomePageModalProps) => {

  useEffect(()=>{
    if(popupSetting && popupSetting.show_popup_option && popupSetting.show_popup_option==3 && popupSetting.time) {
      setTimeout(()=>{
        onClose()
      },popupSetting.time*1000)
    }
  },[shouldShow])

  return (
    <Modal
      show={shouldShow}
      backdrop="static"
      keyboard={false}
      className="stackholder-modal homepage-popup"
      centered
    >
      <Modal.Header>
        <h5 className="modal-title">
          <strong>{popupSetting && popupSetting.title}</strong>
        </h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onClose}
        >
          <span>
            <i className="fa fa-times"></i>
          </span>
        </button>
      </Modal.Header>
      <Modal.Body>
        {/* <div className="row home-popuop">
          {popupSetting && popupSetting.popup_image && popupSetting.popup_image.length ?
            popupSetting.popup_image.map((im:any)=>
              <div className="col-6">
                <img src={im.original} />
              </div>
            )
            :null
          }
        </div> */}
        {popupSetting && popupSetting.description && parse(popupSetting.description)}
      </Modal.Body>
    </Modal>
  );
};

export default HomePageModal;
