import React from 'react'
import StartSurveyButton from '../common/StartSurveyButton'

const SurveyLandingBanner = () => {
    return (
        <>
            <div className="innerpageBanner survey-create-banner">
                <div className="container">
                    <h2>The Opinions of Rural America Matter</h2>
                    {/* <h4>48,95,09,597 people taking action Victories every day.</h4> */}
                    <div className="text-center mb-4">
                        {/* <a className="btn btn-primary" href="create-survey-1.html">
                            Get Started{" "}
                            <img className="fa" src="images/arrow-forward-outline.svg" />
                        </a> */}
                        <StartSurveyButton className={"btn btn-primary"} noImage={false} text={"Get Started"} />
                    </div>
                </div>
            </div>

        </>
    )
}

export default SurveyLandingBanner