import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import DeleteDuplicateConfirmationModal from "src/components/common/DeleteDuplicateConfirmationModal";
import MetaDecorator from "src/components/common/MetaDecorator";
import NavbarMain from "src/components/common/navbar/NavbarMain";
import RightStickyBtn from "src/components/common/RightStickyBtn";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import { useAppFundraisingApi } from "src/_common/hooks/api/fundraising/appFundraisingApiHook";
import { useAppFundraisingDetailsSelector } from "src/_common/hooks/selectors/fundraising/fundraisingSelector";
import { CRYPTO_SECRET_KEY, URLS } from "src/_config";

const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

const MyFundraisingSettings = () => {

  const {slug} = useParams<any>()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [settingsId, setSettingsId] = useState<any>()
  const [settings, setSettings] = useState<any>()
  const [checkboxChecked, setCheckboxChecked] = useState<any>([])
  const fundraisingApi = useAppFundraisingApi()  
  const toast = useToaster()
  const history = useHistory()
  const fundraisingDetails = useAppFundraisingDetailsSelector()
  const deleteText = "You will no longer have access to this fundraiser after closing."

  const checkboxContents = [
    {
      value: "allow_fundraiser_donation",
      label: "Allow my fundraiser to accept donations"
    },
    {
      value: "allow_suppoter",
      label: "Allow donors to leave words of support on my fundraiser"
    },
    {
      value: "allow_apper_search",
      label: "Allow my fundraiser to appear in search results"
    }
  ]

  const handleFundraiserDelete = (id: any) => {
    let params = {
      fundraiser_id: id
    }
    fundraisingApi.callDeleteFundraiser(params,
      (message:any)=>{
        toast.success(message)
        history.push(URLS.FUNDRAISING.MY_CAUSES)
      },
      (message:any)=>{
        toast.error(message)
      })
  }

  const getFundraiserSetting = () => {
    let params = {
      fundraiser_id: fundraisingDetails?.details?.id
    }
    fundraisingApi.callFundraisingSettings(params,
      (message:any,resp:any)=>{
        setSettingsId(resp.data.settings.id)
        setSettings(resp.data.settings)
      },
      (message:any)=>{

      }
    )
  }

  const updateFundraiserSetting = (val:any, i:any) => {
    let params: any = {
      id: settingsId,
      fundraiser_id: fundraisingDetails?.details?.id,
    }
    if(i==0) {
      params["allow_fundraiser_donation"] = val
    }
    if(i==1) {
      params["allow_suppoter"] = val
    }
    if(i==2) {
      params["allow_apper_search"] = val
    }
    fundraisingApi.callUpdateFundraisingSettings(
      params,
      (message:any,resp:any)=>{
        console.log(resp)
        setSettings(resp.data)
      },
      (message:any)=>{

      }
    )
  }

  const requestPayout = () => { 
    let params: any = {
      fundraiser_id: fundraisingDetails?.details?.id,
    }   
    fundraisingApi.callRequestForPayout(
      params,
      (message:any,resp:any)=>{
        console.log(resp)
      },
      (message:any)=>{

      }
    )
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const openModal = () => {
    setShowModal(true)
  }

  const onChange = (e:any,i:any) => {
    console.log(e.target.checked,i)
    updateFundraiserSetting(e.target.checked ? "1" : "0", i)
  }

  useEffect(()=>{
    getFundraiserSetting()
  },[])

  useEffect(()=>{
    if(settings) {
      let x:any = []
      x[0] = settings.allow_fundraiser_donation && settings.allow_fundraiser_donation=="1"
      x[1] = settings.allow_suppoter && settings.allow_suppoter=="1"
      x[2] = settings.allow_apper_search && settings.allow_apper_search=="1"
      setCheckboxChecked(x)
    }
  },[settings])

  return (
    <>

      <MetaDecorator
        title={fundraisingDetails?.details?.title+" : Settings"}
        description={fundraisingDetails?.details?.title+" : Settings"}
        imageUrl={fundraisingDetails?.details?.fundraiser_image?.thumb}
      />
      
      <NavbarMain section={"fundraising"} />
      
      <RightStickyBtn />

      <section className="inner-page">
        <div className="container">
          <h2 className="tab-title">{fundraisingDetails?.details?.title}</h2>
          <div className="row">
            <div className="col-lg-4 col-md-12 col-12">
              <div className="Fundraiser">
                <h2>Fundraiser page</h2>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 col-12">
              <div className="question-wrap Fundraiser-checkbox">
                <ul>
                  {checkboxContents.map((item:any,i:any)=>
                    <>
                      <li key={i}>
                        <label
                          className="container-checkbox"
                          htmlFor={"flexCheckDefault"+i}
                        >
                          {item.label}
                          <input
                            type="checkbox"
                            value={item.value}
                            id={"flexCheckDefault"+i}
                            onChange={e=>onChange(e,i)}
                            checked={checkboxChecked[i]}
                          />
                          <span className="checkmark" />
                        </label>
                        {/* <input
                          className="form-check-input "
                          type="checkbox"
                          id={"flexCheckDefault"+i}
                          onChange={e=>onChange(e,i)}
                        />
                        <label
                          className="form-check-label ps-1"
                          htmlFor={"flexCheckDefault"+i}
                        >
                          {item.label}
                        </label> */}
                      </li>
                    </>
                  )}
                </ul>
                {fundraisingDetails && fundraisingDetails.details && fundraisingDetails.details.is_payout_request && fundraisingDetails.details.is_payout_request==0 && fundraisingDetails.details.total_donor && fundraisingDetails.details.total_donor>0 ?
                  <button type="button" className="dlt-btn" onClick={requestPayout}>
                    Request For Payout
                  </button>
                  :null
                }
              </div>
            </div>
            {
              fundraisingDetails && fundraisingDetails.details && fundraisingDetails.details?.close_fundriser==0 ?
              <>
                <div className="col-lg-4 col-md-12 col-12">
                  <div className="Fundraiser">
                    <h2>Close Fundraising</h2>
                  </div>
                </div>
                <div className="col-lg-8 col-md-12 col-12">
                  <div className="question-wrap Fundraiser-checkbox">
                    <p>
                      {deleteText}
                    </p>
                    <button type="button" className="dlt-btn" onClick={openModal}>
                      <i className="fa fa-trash-o" aria-hidden="true" /> Close Fundraising
                    </button>
                  </div>
                </div>
              </>
              :
              <button type="button" className="dlt-btn">
                Closed
              </button>
            }
          </div>
        </div>
      </section>
      <DeleteDuplicateConfirmationModal
        shouldShow={showModal}
        onClose={closeModal}
        warningText={"Are you sure you want to close this fundraiser?"}
        handleConfirm={handleFundraiserDelete}
        type={"close"}
        id={fundraisingDetails.id}
      />

    </>
  )
};

export default MyFundraisingSettings;