
import { AddEditQuestionReq, AddSurveyPageBreakReq, AssociateUserSurveyReq, BackgroundfImageDeleteReq, BackgroundfImageUploadReq, CheckEmailExistReq, CreateSurveyReq, DeleteSurveyPageBreakReq, DeleteSurveyReq, DuplicateSurveyQuestionReq, EditSurveyReq, StartUserSurveyReq, SurveyCompleteReq, SurveyDetailsReq, SurveyListReq, SurveyReportReq, TitleFooterReq, UpdateSurveyStatusReq, VerifySurveyReq } from 'src/_common/interfaces/ApiReqRes';

import { MySurveyListReq } from 'src/_common/interfaces/ApiReqRes';
import { SAGA_ACTIONS } from '../../../../_config'
import { useApiCall } from '../../common/appApiCallHook'

export function useAppSurveyApi() {

  const callApi = useApiCall()
  
  const getAnswerTypeList = ( onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SURVEY.GET_ANSWER_TYPE_LIST, null, onSuccess, onError);
  }
  
  const createSurvey = ( data: CreateSurveyReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SURVEY.CREATE_SURVEY, data, onSuccess, onError);
  }
  
  const getSurveyDetails = ( data: SurveyDetailsReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SURVEY.SURVEY_DETAILS, data, onSuccess, onError);
  }
  
  const setTitleFooter = ( data: TitleFooterReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SURVEY.TITLE_FOOTER, data, onSuccess, onError);
  }
  
  const addEditQuestion = ( data: AddEditQuestionReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SURVEY.ADD_EDIT_QUESTION, data, onSuccess, onError);
  }

  const getMySurveyList = ( data: MySurveyListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SURVEY.MY_SURVEY_LIST, data, onSuccess, onError);
  }

  const getSurveyList = ( data: SurveyListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SURVEY.SURVEY_LIST, data, onSuccess, onError);
  }

  const duplicateSurveyQuestion = ( data: DuplicateSurveyQuestionReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SURVEY.DUPLICATE_SURVEY_QUESTION, data, onSuccess, onError);
  }

  const surveyQuestionDelete = ( data: DuplicateSurveyQuestionReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SURVEY.SURVEY_QUESTION_DELETE, data, onSuccess, onError);
  }

  const surveyComplete = ( data: SurveyCompleteReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SURVEY.SURVEY_COMPLETE, data, onSuccess, onError);
  }

  const backgroundImageUpload = ( data: BackgroundfImageUploadReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SURVEY.BACKGROUND_IMAGE_UPLOAD, data, onSuccess, onError);
  }

  const backgroundImageDelete = ( data: BackgroundfImageDeleteReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SURVEY.BACKGROUND_IMAGE_DELETE, data, onSuccess, onError);
  }

  const deleteSurvey = ( data: DeleteSurveyReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SURVEY.DELETE_SURVEY, data, onSuccess, onError);
  }

  const duplicateSurvey = ( data: DeleteSurveyReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SURVEY.DUPLICATE_SURVEY, data, onSuccess, onError);
  }

  const updateSurveyStatus = ( data: UpdateSurveyStatusReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SURVEY.UPDATE_SURVEY_STATUS, data, onSuccess, onError);
  }

  const editSurvey = ( data: EditSurveyReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SURVEY.EDIT_SURVEY, data, onSuccess, onError);
  }

  const addSurveyPageBreak = ( data: AddSurveyPageBreakReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SURVEY.ADD_SURVEY_PAGE_BREAK, data, onSuccess, onError);
  }

  const deleteSurveyPageBreak = ( data: DeleteSurveyPageBreakReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SURVEY.DELETE_SURVEY_PAGE_BREAK, data, onSuccess, onError);
  }

  const startUserSurvey = ( data: StartUserSurveyReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SURVEY.START_USER_SURVEY, data, onSuccess, onError);
  }

  const answerFileUpload = ( data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SURVEY.ANSWER_FILE_UPLOAD, data, onSuccess, onError);
  }

  const checkEmailExist = ( data: CheckEmailExistReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SURVEY.CHECK_EMAIL_EXIST, data, onSuccess, onError);
  }

  const topFourSurvey = ( onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SURVEY.TOP_FOUR_SURVEY, null, onSuccess, onError);
  }

  const surveyReport = ( data: SurveyReportReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SURVEY.SURVEY_REPORT, data, onSuccess, onError);
  }

  const verifySurvey = ( data: VerifySurveyReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SURVEY.VERIFY_SURVEY, data, onSuccess, onError);
  }

  const associateUserSurvey = ( data: AssociateUserSurveyReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SURVEY.ASSOCIATE_USER_SURVEY, data, onSuccess, onError);
  }

  return {
    callGetAnswerTypeList: getAnswerTypeList,
    callCreateSurvey: createSurvey,
    callGetSurveyDetails: getSurveyDetails,
    callSetTitleFooter: setTitleFooter,
    callAddEditQuestion: addEditQuestion,
    callGetMySurveyList: getMySurveyList,
    callGetSurveyList: getSurveyList,
    callDuplicateSurveyQuestion: duplicateSurveyQuestion,
    callSurveyQuestionDelete: surveyQuestionDelete,
    callSurveyComplete: surveyComplete,
    callBackgroundImageUpload: backgroundImageUpload,
    callBackgroundImageDelete: backgroundImageDelete,
    callDeleteSurvey: deleteSurvey,
    callDuplicateSurvey: duplicateSurvey,
    callUpdateSurveyStatus: updateSurveyStatus,
    callEditSurvey: editSurvey,
    callAddSurveyPageBreak: addSurveyPageBreak,
    callDeleteSurveyPageBreak: deleteSurveyPageBreak,
    callStartUserSurvey: startUserSurvey,
    callAnswerFileUpload: answerFileUpload,
    callCheckEmailExist: checkEmailExist,
    callTopFourSurvey: topFourSurvey,
    callSurveyReport: surveyReport,
    callVerifySurvey: verifySurvey,
    callAssociateUserSurvey: associateUserSurvey,
  }
}