import React from 'react'
import PetitionComments from 'src/components/petition/petition-comments/PetitionComments'

export default function PetitionCommentsPage() {
  return (
    <React.Fragment>
       <PetitionComments/>
    </React.Fragment>
  )
}
