import React, { useEffect, useRef, useState } from "react";
import Footer from "src/components/common/Footer";
import RightStickyBtn from "src/components/common/RightStickyBtn";
import { Controller, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormTextInput from "src/_common/components/form-elements/textinput/formTextInput";
import FormMaskedInput from "src/_common/components/form-elements/maskedInput/maskedInput";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import { useHistory, useParams } from "react-router";
import NavbarMain from "src/components/common/navbar/NavbarMain";
import SelectInput from "src/_common/components/form-elements/selectinput/selectInput";
import { useAppFundraisingApi } from "src/_common/hooks/api/fundraising/appFundraisingApiHook";
import { useAppFundraisingCategoryListSelector, useAppFundraisingCharityListSelector, useAppFundraisingDetailsSelector, useAppStartFundraisingSelector } from "src/_common/hooks/selectors/fundraising/fundraisingSelector";
import { OptionValue } from "src/_common/interfaces/common";
import RadioInput from "src/_common/components/form-elements/radioinput/radioInput";
import CharityFundraisingModal from "../common/modals/CharityFundraisingModal";
import FormTextAreaInput from "src/_common/components/form-elements/textarea/textareaInput";
import { allowNumberOnly, CRYPTO_SECRET_KEY } from "src/_config";
import { Link } from "react-router-dom";
import { useCommonApi } from "src/_common/hooks/api/common/appCommonApiHook";
import SweetAlert from "react-bootstrap-sweetalert";
import MetaDecorator from "src/components/common/MetaDecorator";
import ImagePicker from "src/_common/components/elements/imagePicker/imagePicker";
import { useAppFundraisingAction } from "src/_common/hooks/actions/fundraising/appFundraisingActionHook";
import CharityEditFundraisingModal from "./CharityEditFundraisingModal";
import { Editor } from "@tinymce/tinymce-react";
import ImagePickerNew from "src/_common/components/elements/imagePicker/imagePickerNew";
import NavTabs from "../my-fundraising-dashboard/NavTabs";

const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

interface EditFundraisingFormValues {
  category_id: string;
  country_id: OptionValue | undefined;
  location: string;
  zipcode: string;
  first_name: string;
  last_name: string;
  fundraising_for: string;
  target_fund_value: string;
  image: FileList;
  video_link: string;
  title: string;
  // description: string;
  // relationship_details: string;
}

const editFundraisingSchema = yup.object().shape({
  category_id: yup.string().required("Category is required"),
  country_id: yup.object()
    .nullable()
    .shape({
      value: yup.string().required("Location is required"),
    })
    .required("State is required"),
  zipcode: yup.string().required("ZipCode is required"),
  location: yup.string().required("location is required"),
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  // fundraising_for: yup.string().required("Select any one option"),
  target_fund_value: yup.string().required('Goal Amount is required'),
  title: yup.string().required('Title is required'),
  // description: yup.string().required('Story is required'),
  // relationship_details: yup.string().required('Details is required'),
})

const EditMyFundraising = () => {

  const MAX_LEN = 100

  const { register, control, setValue, handleSubmit, errors } = useForm<EditFundraisingFormValues>({
    resolver: yupResolver(editFundraisingSchema),
    defaultValues: {
      category_id: "",
      country_id: undefined,
      zipcode: "",
      location: "",
      first_name: "",
      last_name: "",
      fundraising_for: "",
      target_fund_value: "",
      image: "",
      video_link: "",
      title: "",
      // description: "",
      // relationship_details: "",
    },
  })

  const toast = useToaster();
  const history = useHistory();
  const { slug } = useParams<any>()
  // const id = cryptr.decrypt(fundraisingId.id)

  const fundraisingApi = useAppFundraisingApi();
  const commonApi = useCommonApi();
  const categoryListSelector = useAppFundraisingCategoryListSelector()
  const startFundraising = useAppStartFundraisingSelector();
  const fundraisingDetails = useAppFundraisingDetailsSelector()?.details;
  const charityListSelector = useAppFundraisingCharityListSelector()?.data;

  const [modalContinueClicked, setModelContinueClicked] = useState<boolean>(false);
  const [modalIsOpen, setModelIsOpen] = useState<boolean>(false);
  const [count, setCount] = useState<number>(MAX_LEN)
  const [descCount, setDescCount] = useState<number>(MAX_LEN * 6)
  const [storyCount, setStoryCount] = useState<number>(MAX_LEN * 10)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [imgSrc, setImgSrc] = useState<string>('')
  const [imageFile, setImageFile] = useState<any>()
  const [selectedCharity, setSelectedCharity] = useState<any>([]);
  const [fundraisingFor, setFundraisingFor] = useState<any>(fundraisingDetails?.fundraising_for);
  const [charityId, setCharityId] = useState<any>(fundraisingDetails?.charity_id);
  // const [videoLink, setVideoLink] = useState<any>(fundraisingDetails?.video_link);
  const [imageDimensions, setImageDimensions] = useState({});
  const [alert, setAlert] = useState<any>(null);
  const fundraisingAction = useAppFundraisingAction();
  const [detailsErrorMessage, setDetailsErrorMessage] = useState<string>('')
  const [descErrorMessage, setDescErrorMessage] = useState<string>('')
  const editorRefRelation: any = useRef(null);
  const editorRefDesc: any = useRef(null);

  const handleFundraisingForClick = (value?: any) => {
    if (value == 1 || value == 2) {
      setModelContinueClicked(false)
      setCharityId(0)

    } else {
      openModal()
    }
    setFundraisingFor(value)
    // console.log(value)
  }

  function openModal() {
    setModelIsOpen(true);
  };

  function closeModal(params?: any) {
    setModelIsOpen(false);
  };

  const handleKeyPress = (event: any) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  }

  const onChangeVideoLink = (value: any) => {
    // console.log("videoLink", value);
    // setVideoLink(value);
    if (value != "") {
      setErrorMessage("")
    }
  };

  const handleImageRemoveClick = () => {
    setImgSrc('');
    setImageFile(null);
    if (fundraisingDetails.fundraiser_image && fundraisingDetails.fundraiser_image.id != 0) {
      let params = {
        entity_id: fundraisingDetails?.id,
        type: "fundraising"
      }
      commonApi.callDeleteImage(params, (message: string, resp: any) => {
        // console.log({ resp })
        toast.success(message)
        hideAlert()
      }, (message: string) => {
        toast.error(message)
      })
    } else {
      toast.success("Image has been successfully deleted.")
      hideAlert()
    }
  }

  const handleRemoveImage = (e: any) => {
    e.preventDefault()
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Delete!"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="primary"
        title="Remove Image"
        onConfirm={handleImageRemoveClick}
        onCancel={hideAlert}
      // focusCancelBtn
      >
        Are you sure to remove the Image?
      </SweetAlert>
    );
  }

  const hideAlert = () => {
    setAlert(null)
  }

  const loadImage = (setImageDimensions: any, imageUrl: any) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      // console.log({height: img.height,width: img.width})
      setImageDimensions({
        height: img.height,
        width: img.width
      });
      if (img.width < 720) {
        setImageFile(null)
        setImgSrc('')
        toast.error("Please select an image of minimum width 720px...")
      }
    };
    img.onerror = (err) => {
      // console.log("img error");
      // console.error(err);
    };
  };

  const changeStoryCount = (e: any) => {
    let x = String(e)
    setStoryCount(MAX_LEN * 10 - x.length)
  }


  const onChange = (e: any, onsuccess: () => {}, onerror: () => {}) => {
    // console.log("file", e.target.files[0]);
    let file = e
    let imgTypes = ["image/jpeg", "image/png", "image/jpg", "image/gif", "image/svg", "image/svg+xml"]
    if (file && file.type && imgTypes.includes(file.type)) {
      setImageFile(e)
      // setImgSrc(URL.createObjectURL(e))
      // console.log(URL.createObjectURL(e))
      loadImage(setImageDimensions, URL.createObjectURL(e));
    }
    else if (file && file.type && !imgTypes.includes(file.type)) {
      toast.error("The image must be of type: jpeg, png, jpg, gif, svg")
    }
    else {
      toast.error("Please select only image file...")
    }
    // setImageFile(e);
    // setImgSrc(URL.createObjectURL(e));
    if (imageFile || imgSrc) {
      setErrorMessage("")
    }
    // setValue("image",null)
    onsuccess()
  };

  const changeCount = (e: any) => {
    let x = String(e)
    setCount(MAX_LEN - x.length)
  }

  const changeDescCount = (e: any) => {
    let x = String(e)
    setDescCount(MAX_LEN * 6 - x.length)
  }

  const editFundraising = (parms: any) => {
    fundraisingApi.callEditMyFundraising(parms, (message: string, resp: any) => {
      // console.log({ resp })
      toast.success(message)
      history.push(`/my-fundraising-dashboard/${slug}`)
    }, (message: string) => {
      toast.error(message)
    })
  }

  const onSubmit = (values: any) => {
    // console.log(startFundraising?.charity_id, charityId)
    let formData = new FormData();

    // if (fundraisingFor == 3 && (startFundraising?.charity_id == 0 || charityId == 0 || startFundraising?.charity_id == undefined || charityId == undefined)) {
    if (fundraisingFor == 3 && charityId && charityId == 0) {
      // console.log(fundraisingFor, startFundraising?.charity_id, charityId)
      toast.error("Please select a non-profit to continue..");
      return
    }
    let event = startFundraising?.event
    if (event) {
      formData.append("event_option", fundraisingFor == 3  && (charityId != 0 || charityId != undefined) ? event : null)
    }

    formData.append("crowdfunding_id", fundraisingDetails.id)
    formData.append("location", values.location)
    formData.append("country_id", values.country_id.value)
    formData.append("category_id", values.category_id)
    formData.append("zipcode", values.zipcode)
    formData.append("first_name", values.first_name)
    formData.append("last_name", values.last_name)
    formData.append("target_fund_value", values.target_fund_value)
    formData.append("title", values.title)
    formData.append("description", values.description)
    formData.append("relationship_details", values.relationship_details)
    formData.append("target_date", values.target_date ? values.target_date : "2022-08-15")//needed to change
    formData.append("fundraising_for", fundraisingFor)
    formData.append("charity_id", (fundraisingFor != 3 || (fundraisingFor == 3 && event == 2)) ? 0 : startFundraising?.charity_id ? startFundraising?.charity_id : charityId)

    if (editorRefDesc.current) {
      let editorContent = editorRefDesc?.current.getContent()
      if (editorContent == '') {
        setDescErrorMessage("Story text is required")
        editorRefDesc?.current.focus()
      }
      else if (errorMessage != '') {
        editorRefDesc?.current.focus()
      }
      else {
        formData.append('description', editorContent)
      }
    }

    if (imageFile || imgSrc || values.video_link != "") {
      if (imageFile) {
        formData.append("image", imgSrc);
        formData.append("video_link", values.video_link);
        setErrorMessage("")
        editFundraising(formData)
      } else {
        const checkUrl = validateYouTubeUrl(values?.video_link);
        if (imgSrc != '') {
          formData.append("video_link", values.video_link);
          setErrorMessage("")
          editFundraising(formData)
        } else {
          if (checkUrl) {
            formData.append("video_link", values.video_link);
            setErrorMessage("")
            editFundraising(formData)
          } else {
            setErrorMessage("Please provide valid youtube url");
          }
        }
      }
    } else {
      setErrorMessage("Please provide any one of the details");
    }

  }

  function validateYouTubeUrl(url: any) {
    if (url != undefined || url != '') {
      var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      var match = url.match(regExp);
      if (match && match[2].length == 11) {
        return true
      }
      else {
        return false
      }
    }
  }

  const getCategoryList = () => {
    fundraisingApi.callgetFundraisingCategoryList(
      (message: string, resp: any) => {
        // console.log({ resp });
      },
      (message: string) => { }
    );
  }

  const getFundraisingDetails = () => {
    let params = {
      slug
    }
    fundraisingApi.callGetFundraisingDetails(params,
      (message: string, resp: any) => {
        // console.log({ resp });
      },
      (message: string) => { }
    );
  }

  const onChangeRelationDetails = (e: any, relationship_details: any) => {
    if (relationship_details) {
      let editorContent = relationship_details.getContent({ format: 'text' })
      if (editorContent.length <= MAX_LEN * 6) {
        changeDescCount(editorContent)
        setDetailsErrorMessage('')
      }
      else {
        setDescCount(0)
        setDetailsErrorMessage('Character limit exceeded')
      }
    }
  }

  const onChangeDesciption = (e: any, description: any) => {
    if (description) {
      let editorContent = description.getContent({ format: 'text' })
      if (editorContent.length <= MAX_LEN * 10) {
        changeStoryCount(editorContent)
        setDescErrorMessage('')
      }
      else {
        setStoryCount(0)
        setDescErrorMessage('Character limit exceeded')
      }
    }
  }

  useEffect(() => {
    getCategoryList()
    getFundraisingDetails()

    return () => {
      fundraisingAction.resetStartFundraising()
    }
  }, []);

  useEffect(() => {
    // console.log({ fundraisingDetails })
    if (fundraisingDetails) {
      if (fundraisingDetails.category_id) {
        setValue("category_id", fundraisingDetails.category_id);
      }
      if (fundraisingDetails.country_id) {
        // setValue("country_id", fundraisingDetails.country_id);
        if (fundraisingDetails.country_id == '231') {
          setValue("country_id", { value: "231", label: "USA" });
        } else {
          setValue("country_id", { value: "38", label: "Canada" });
        }
      }
      if (fundraisingDetails.location) {
        setValue("location", fundraisingDetails.location);
      }
      if (fundraisingDetails.zipcode) {
        setValue("zipcode", fundraisingDetails.zipcode);
      }
      if (fundraisingDetails.first_name) {
        setValue("first_name", fundraisingDetails.first_name);
      }
      if (fundraisingDetails.last_name) {
        setValue("last_name", fundraisingDetails.last_name);
      }
      if (fundraisingDetails.fundraising_for) {
        setFundraisingFor(fundraisingDetails.fundraising_for)
        setValue("fundraising_for", fundraisingDetails.fundraising_for);
      }
      if (fundraisingDetails.charity_id) {
        setValue("charity_id", fundraisingDetails.charity_id);
        setCharityId(fundraisingDetails.charity_id)
        fundraisingAction.startFundraising({ charity_id: fundraisingDetails.charity_id })
      }
      if (fundraisingDetails.event_option) {
        fundraisingAction.startFundraising({ event: String(fundraisingDetails.event_option) })
      }
      if (fundraisingDetails.target_fund_value) {
        setValue("target_fund_value", fundraisingDetails.target_fund_value);
      }
      if (fundraisingDetails.fundraiser_image) {
        if (fundraisingDetails.fundraiser_image.id != 0) {
          setImgSrc(fundraisingDetails.fundraiser_image.original);
        } else {
          setImgSrc('');
        }
      }
      if (fundraisingDetails.video_link) {
        setValue("video_link", fundraisingDetails.video_link);
        // setVideoLink(fundraisingDetails.video_link);
      }
      if (fundraisingDetails.title) {
        setValue("title", fundraisingDetails.title);
        setCount(MAX_LEN - fundraisingDetails.title.length)
      }
      // if (fundraisingDetails.description) {
      //   setValue("description", fundraisingDetails.description);
      //   setStoryCount(MAX_LEN * 10 - fundraisingDetails.description.length)
      // }
      // if (fundraisingDetails.relationship_details) {
      //   setValue("relationship_details", fundraisingDetails.relationship_details);
      //   setDescCount(MAX_LEN * 6 - fundraisingDetails.relationship_details.length)
      // }
      if (fundraisingDetails.fundraising_for == '3') {
        setModelContinueClicked(true);
      }
    }
    if (modalContinueClicked) {
      const charityDetails = charityListSelector?.filter((item: any) => {
        return item.id == fundraisingDetails?.charity_id
      })
      // console.log(charityDetails)
      setSelectedCharity(charityDetails)
    }
  }, [fundraisingDetails])

  useEffect(() => {
    setCharityId(startFundraising?.charity_id)
    if (modalContinueClicked || (startFundraising && startFundraising.charity_id)) {
      // console.log("modalcontinue")
      const charityDetails = charityListSelector?.filter((item: any) => {
        return item.id == startFundraising?.charity_id
      })
      // console.log(charityDetails)
      setSelectedCharity(charityDetails)
    }
    // console.log(modalContinueClicked)
  }, [startFundraising?.charity_id, modalContinueClicked])

  return (
    <>

      <MetaDecorator
        title={fundraisingDetails?.title + " : Edit"}
        description={"Edit " + fundraisingDetails?.title}
        imageUrl={fundraisingDetails?.fundraiser_image?.thumb}
      />

      {alert}
      <NavbarMain section={"fundraising"} />

      <RightStickyBtn />

      {/* main section starts */}

      <section className="inner-page">
        <div className="container">
          <div className="custom-tab-wrap">
            <NavTabs
              page={"edit"}
            // setPage={setPage}
            />
            <div className="tab-content">
              <div
                className="tab-pane show active"
                id="Edit"
                role="tabpanel"
                aria-labelledby="Edit-tab"
                tabIndex={0}
              >
                <div className="inner-tab-wrap">
                  {/* <Link
                    className="btn btn-secondary"
                    to={`/my-fundraising-dashboard/${fundraisingId.id}`}
                  >
                    <i className="fa fa-angle-left me-2" />
                    Return to fundraiser <span className="chat_icon" />
                  </Link> */}
                  <h2 className="tab-title">Edit your fundraiser</h2>
                  <div className="edit-petition-wrap">
                    <div className="row">
                      <div className="col-sm-9">
                        <form onSubmit={handleSubmit(onSubmit)} noValidate>
                          <div className="form-edit">
                            <div className="mb-4">
                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="mb-3">
                                    <label className="form-label">Country<i style={{ color: 'red' }}>*</i></label>
                                    <Controller
                                      control={control}
                                      className="form-control"
                                      name="country_id"
                                      render={({ onChange, onBlur, value, name, ref }) => (
                                        <SelectInput
                                          name={name}
                                          onChange={onChange}
                                          onBlur={onBlur}
                                          value={value}
                                          inputRef={ref}
                                          options={[{ value: "231", label: "USA" }, { value: "38", label: "Canada" }]}
                                          error={errors.country_id}
                                          placeholder=""
                                        />
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="mb-3">
                                    <label className="form-label">Zip code<i style={{ color: 'red' }}>*</i></label>
                                    <Controller
                                      control={control}
                                      className="form-control"
                                      name="zipcode"
                                      render={({ onChange, onBlur, value, name, ref }) => (
                                        <FormTextInput
                                          name={name}
                                          onChange={e => {
                                            onChange(e)
                                            allowNumberOnly(e, name, setValue)
                                          }}
                                          onBlur={onBlur}
                                          value={value}
                                          inputRef={ref}
                                          type="tel"
                                          error={errors.zipcode}
                                          placeholder=""
                                        />
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="mb-4">
                              <label className="form-label">Location<i style={{ color: 'red' }}>*</i></label>
                              <Controller
                                control={control}
                                className="form-control"
                                name="location"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                  <FormTextInput
                                    name={name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    inputRef={ref}
                                    type="text"
                                    error={errors.location}
                                    placeholder=""
                                  />
                                )}
                              />
                            </div>
                            <div className="mb-4">
                              <h2 className="sub-title">
                                What best describes why you're fundraising?<i style={{ color: 'red' }}>*</i>
                              </h2>
                              <div className="mb-3">
                                <div className="category-wrap">
                                  {
                                    categoryListSelector && categoryListSelector.length &&
                                    <Controller
                                      control={control}
                                      className="form-control"
                                      name="category_id"
                                      render={({ onChange, onBlur, value, name, ref }) => (
                                        <RadioInput
                                          name={name}
                                          options={categoryListSelector.map((c: any) => ({
                                            value: String(c.id),
                                            label: c.title
                                          }))}
                                          onChange={onChange}
                                          onBlur={onBlur}
                                          value={value}
                                          inputRef={ref}
                                          error={errors.category_id}
                                        />
                                      )}
                                    />
                                  }
                                  {/* <div className="category-block">
                                    <input type="radio" id="myRadio1" checked />
                                    <label htmlFor="myRadio1">
                                      <p>Rural America</p>
                                    </label>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                            <div className="mb-4">
                              <div className="row mb-3">
                                <div className="col-sm-6">
                                  <div className="mb-3">
                                    <label className="form-label">First name<i style={{ color: 'red' }}>*</i></label>
                                    <Controller
                                      control={control}
                                      className="form-control"
                                      name="first_name"
                                      render={({ onChange, onBlur, value, name, ref }) => (
                                        <FormTextInput
                                          name={name}
                                          onChange={onChange}
                                          onBlur={onBlur}
                                          value={value}
                                          inputRef={ref}
                                          type="text"
                                          error={errors.first_name}
                                          placeholder=""
                                        />
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="mb-3">
                                    <label className="form-label">Last name<i style={{ color: 'red' }}>*</i></label>
                                    <Controller
                                      control={control}
                                      className="form-control"
                                      name="last_name"
                                      render={({ onChange, onBlur, value, name, ref }) => (
                                        <FormTextInput
                                          name={name}
                                          onChange={onChange}
                                          onBlur={onBlur}
                                          value={value}
                                          inputRef={ref}
                                          type="text"
                                          error={errors.last_name}
                                          placeholder=""
                                        />
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="mb-4">
                              <h2 className="sub-title d-none">What are you fundraising for?<i style={{ color: 'red' }}>*</i></h2>
                              <div className="mb-3 d-none">
                                <label htmlFor="" className="form-label">
                                  Describe your cause<span style={{ color: 'red' }}>*</span>
                                </label>
                                <Editor
                                  apiKey="2utn329kvrgai26fg6qt532okdsdvy9m1lgfk4ss1jaytsu9"
                                  onInit={(evt: any, relationship_details: any) => {
                                    editorRefRelation.current = relationship_details
                                    if (fundraisingDetails && fundraisingDetails.relationship_details) {
                                      relationship_details.setContent(fundraisingDetails.relationship_details)
                                    }
                                  }}
                                  initialValue={fundraisingDetails && fundraisingDetails.relationship_details ? fundraisingDetails.relationship_details : null}
                                  onEditorChange={onChangeRelationDetails}
                                  init={{
                                    height: 300,
                                    menubar: false,
                                    plugins: [
                                      'advlist autolink lists link image charmap print preview anchor',
                                      'searchreplace visualblocks code fullscreen',
                                      'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar: 'undo redo | formatselect | ' +
                                      'bold italic underline | alignleft aligncenter ' +
                                      'alignright alignjustify | bullist numlist outdent indent | ' +
                                      'removeformat | help',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                  }}
                                />
                                <span className="count-letter">
                                  <span>Remaining:</span>{descCount}
                                </span>
                                {detailsErrorMessage && detailsErrorMessage.length && <div className="invalid-feedback">{detailsErrorMessage}</div>}
                              </div>
                              <div className="mb-3">
                                <div className="choose-fundraise-wrap">
                                  {/* <label className="radio-img" onClick={() => setModelContinueClicked(false)}> */}
                                  <label className={`radio-img ${fundraisingFor == '1' ? 'active' : ''}`} onClick={() => handleFundraisingForClick(1)}>
                                    <input type="radio" name="fundraising_for" value={1} ref={register} checked={fundraisingFor == '1' ? true : false} />
                                    <div className="image-info">
                                      <div className="info-left">
                                        <span>Crisis Relief</span>
                                        {/* <p>
                                          Funds are delivered to your bank account for
                                          your own use
                                        </p> */}
                                      </div>
                                      <div className="img-right">
                                        <img src="/images/Layer_2.png" alt="" />
                                      </div>
                                    </div>
                                  </label>
                                  {/* <label className="radio-img" onClick={() => setModelContinueClicked(false)}> */}
                                  <label className={`radio-img ${fundraisingFor == '2' ? 'active' : ''}`} onClick={() => handleFundraisingForClick(2)}>
                                    <input type="radio" name="fundraising_for" value={2} ref={register} checked={fundraisingFor == '2' ? true : false} />
                                    <div className="image-info">
                                      <div className="info-left">
                                        <span>Community Initiatives</span>
                                        {/* <p>
                                          You'll invite a beneficiary to receive funds or
                                          distribute them yourself
                                        </p> */}
                                      </div>
                                      <div className="img-right">
                                        <img src="/images/yourself-icon2a.png" alt="" />
                                      </div>
                                    </div>
                                  </label>
                                  {/* <label className="radio-img" onClick={openModal}> */}
                                  <label className={`radio-img ${fundraisingFor == '3' ? 'active' : ''}`} onClick={() => handleFundraisingForClick(3)}>
                                    <input type="radio" name="fundraising_for" value={3} ref={register} checked={fundraisingFor == '3' ? true : false} />
                                    <div className="image-info">
                                      <div className="info-left">
                                        <span>Event</span>
                                        {/* <p>
                                          Funds are delivered to your chosen nonprofit for
                                          you
                                        </p> */}
                                      </div>
                                      <div className="img-right">
                                        <img src="/images/yourself-icon3a.png" alt="" />
                                      </div>
                                    </div>
                                  </label>
                                  {fundraisingFor == 3 && startFundraising?.event == "1" ? "Non Profit" : fundraisingFor == 3 && startFundraising?.event == "2" ? "Own Event" : ""}
                                  {modalContinueClicked && selectedCharity && selectedCharity.length ?
                                    <div className="image-info">
                                      <div className="m-charity-text">
                                        <h2>
                                          <span>{selectedCharity[0]["title"]}</span> <em>Society</em>{" "}
                                          <i className="fa fa-check-circle" />
                                        </h2>
                                        <div className="m-charity-info-details-wrapper">
                                          <div className="m-charity-detail">
                                            {selectedCharity[0]["category"]}
                                          </div>
                                          <div className="m-charity-detail">
                                            {selectedCharity[0]["state"]["state_name"]}, OK
                                          </div>
                                          <div className="m-charity-detail">
                                            EIN: {selectedCharity[0]["ein_number"]}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    : null
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="mb-4">
                              <label className="form-label">Goal amount<i style={{ color: 'red' }}>*</i></label>
                              <div className="goal-amount">
                                <Controller
                                  control={control}
                                  name="target_fund_value"
                                  className="form-control"
                                  render={({ onChange, onBlur, value, name, ref }) => (
                                    <FormTextInput
                                      name={name}
                                      onChange={e => {
                                        onChange(e)
                                        allowNumberOnly(e, name, setValue)
                                      }}
                                      onBlur={onBlur}
                                      value={Math.abs(value)}
                                      inputRef={ref}
                                      min={0}
                                      maxLength={8}
                                      type="tel"
                                      // onKeyPress={(event: any) => handleKeyPress(event)}
                                      error={errors.target_fund_value}
                                      placeholder="Enter goal amount..."
                                    />
                                  )}
                                />
                              </div>
                              <span className="help-note">
                                Keep in mind that transaction <a>fees</a>,
                                including credit and debit charges, are deducted from
                                each donation.
                              </span>
                            </div>
                            <div className="mb-4">
                              <h2 className="sub-title">Add a cover photo or video<i style={{ color: 'red' }}>*</i></h2>
                              <h3 className="normal-heading">
                                {/* Using a bright and clear photo helps people connect to your fundraiser right away. */}
                                <b>File types accepted:</b> '.jpeg', '.png', '.jpg', '.gif', '.svg' <b>Max size:</b> 5MB <br /><br />
                                <b>Final photos will be displayed in 720px X 400px</b>
                              </h3>
                              <div className="upload-btn-wrapper">
                                {imgSrc ? (
                                  <>
                                    <a
                                      className="remove-phhoto"
                                      onClick={(e) => handleRemoveImage(e)}
                                    // onClick={handleImageRemoveClick}
                                    // onClick={() => { setImgSrc(''); setImageFile(null) }}
                                    >
                                      ✖
                                    </a>
                                    <img src={imgSrc} alt="" />
                                  </>
                                ) : (
                                  <>
                                    <button className="btn-upload">
                                      {/* <ImagePicker
                                        fieldName={"image"}
                                        onUploadImage={onChange}
                                      /> */}
                                      <ImagePickerNew
                                        fieldName={"image"}
                                        onUploadImage={onChange}
                                        setImgSrc={setImgSrc}
                                      />
                                    </button>
                                  </>
                                )}
                              </div>
                              <div className="invalid-feedback">
                                {errors.image?.message}
                              </div>
                              <div className="or-wrap">
                                <span>OR</span>
                              </div>
                              <div className="mb-3">
                                <label className="form-label">{(!imageFile && !imgSrc) ? 'Add a youtube link' : 'Add a website link'}</label>
                                {/* <input type="text" className="form-control" name="video_link" ref={register} /> */}
                                <Controller
                                  control={control}
                                  className="form-control"
                                  name="video_link"
                                  render={({ onChange, onBlur, value, name, ref }) => (
                                    <FormTextInput
                                      name={name}
                                      onChange={(event) => {
                                        onChange(event)
                                        onChangeVideoLink(event)
                                      }
                                      }
                                      onBlur={onBlur}
                                      value={value}
                                      inputRef={ref}
                                      type="text"
                                      error={errors.video_link}
                                      placeholder=""
                                    />
                                  )}
                                />
                                <div className="invalid-feedback">
                                  {errors.video_link?.message}
                                </div>
                              </div>
                              {errorMessage && errorMessage.length && <div className="invalid-feedback">{errorMessage}</div>}
                            </div>
                            <div className="mb-4">
                              <label className="form-label">
                                Give your fundraiser a title<i style={{ color: 'red' }}>*</i>
                              </label>
                              <Controller
                                control={control}
                                name="title"
                                className="form-control"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                  <FormTextInput
                                    name={name}
                                    onChange={e => {
                                      onChange(e)
                                      changeCount(e)
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    maxLength={MAX_LEN}
                                    inputRef={ref}
                                    type="text"
                                    error={errors.title}
                                    placeholder=""
                                  />
                                )}
                              />
                              <span className="count-letter">
                                <span>Remaining:</span>{count}
                              </span>
                            </div>
                            <div className="mb-4">
                              <label htmlFor="" className="form-label">
                                Tell your story<i style={{ color: 'red' }}>*</i>
                              </label>
                              <Editor
                                apiKey="2utn329kvrgai26fg6qt532okdsdvy9m1lgfk4ss1jaytsu9"
                                onInit={(evt: any, description: any) => {
                                  editorRefDesc.current = description
                                  if (fundraisingDetails && fundraisingDetails.description) {
                                    description.setContent(fundraisingDetails.description)
                                  }
                                }}
                                initialValue={fundraisingDetails && fundraisingDetails.description ? fundraisingDetails.description : null}
                                onEditorChange={onChangeDesciption}
                                init={{
                                  height: 300,
                                  menubar: false,
                                  plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                  ],
                                  toolbar: 'undo redo | formatselect | ' +
                                    'bold italic underline | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat | help',
                                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                              />
                              <span className="count-letter">
                                <span>Remaining:</span>{storyCount}
                              </span>
                              {descErrorMessage && descErrorMessage.length && <div className="invalid-feedback">{descErrorMessage}</div>}
                            </div>
                            <div className="two-btns-wrap">
                              {/* <Link className="btn btn-secondary cancelbtn w-100" to={`/my-fundraising-dashboard/${fundraisingId?.id}`}>
                                Cancel
                              </Link> */}
                              {/* <button type="button" className="btn btn-secondary cancelbtn w-100" onClick={handleCancleButton}>
                                Cancel
                              </button> */}
                              <button type="submit" className="btn btn-primary w-100 savebtn">
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* main section end */}

      <Footer />
      {modalIsOpen &&
        <CharityFundraisingModal
          onClose={closeModal}
          shouldShow={modalIsOpen}
          setModelContinueClicked={setModelContinueClicked}
        />
      }

    </>
  )
};

export default EditMyFundraising;