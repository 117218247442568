import React from 'react';

const LazyHomePage = React.lazy(() =>
  import(/* webpackChunkName: "HomePage" */ './homepage')
);

const HomePage = (props: Record<string, any>) => (
  <React.Suspense fallback={<div className="loader"></div>}>
    <LazyHomePage {...props} />
  </React.Suspense>
);

export default HomePage