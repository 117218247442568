import { call, put } from "redux-saga/effects";
import { ACTIONS, API_URL, STORAGE } from "../_config";
import { CallApi } from "./api/callApi";

export function* petitionCategoryList(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET,API_URL.PETITION.CATEGORY_LIST,data,true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      yield put({
        type: ACTIONS.PETITION.CATEGORY_LIST,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* createPetition(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST,API_URL.PETITION.CREATE_PETITION,data,true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      // yield put({
      //   type: ACTIONS.PETITION.CATEGORY_LIST,
      //   payload: resp.data.data
      // })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* petitionList(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.PETITION.LIST, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      yield put({
        type: ACTIONS.PETITION.LIST,
        payload: resp.data.data.list,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* petitionFeaturedTopicList(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET,API_URL.PETITION.FEATURED_TOPIC_LIST,data,true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      yield put({
        type: ACTIONS.PETITION.FEATURED_TOPIC_LIST,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* petitionCommentsList(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.PETITION.COMMENT_LIST, data);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* petitionUpdatesList(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.PETITION.UPDATES_LIST, data);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      yield put({
        type: ACTIONS.PETITION.UPDATES_LIST,
        payload: resp.data.data.list.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* petitionReasonsList(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.PETITION.REASONS_LIST, data,true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* petitionDeatils(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST,API_URL.PETITION.PETITION_DETAILS,data,true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      yield put({
        type: ACTIONS.PETITION.PETITION_DETAILS,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* petitionAddComment(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.PETITION.ADD_COMMENT, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* replyToComment(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.PETITION.REPLY_COMMENT, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* report(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.PETITION.REPORT, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* petitionSign(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.PETITION.SIGN_PETITION, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      yield put({
        type: ACTIONS.PETITION.SIGN_PETITION,
        payload: resp.data.data.data,
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getPetitionTips(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET,API_URL.PETITION.TIPS,data,true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      yield put({
        type: ACTIONS.PETITION.TIPS,
        payload: resp.data.data.list,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* likeReason(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST,API_URL.PETITION.LIKE_REASON, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* myPetitions(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.PETITION.MYPETITIONS, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* deleteMyPetitions(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.PETITION.DELETE_MYPETITIONS, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* increasePetitionViewCount(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.PETITION.PETITION_VIEW_COUNT, data);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* petitionAddUpdate(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.PETITION.ADD_UPDATE, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* petitionDeclareVictory(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.PETITION.PETITION_DECLARE_VICTORY, data,true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* editPetition(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST,API_URL.PETITION.EDIT_PETITION,data,true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      // yield put({
      //   type: ACTIONS.PETITION.CATEGORY_LIST,
      //   payload: resp.data.data
      // })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* topThreePetitionList(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST,API_URL.PETITION.PETITION_TOP_THREE_LIST,data);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* csvExport(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST,API_URL.PETITION.CSV_EXPORT, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* chipIn(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST,API_URL.PETITION.CHIP_IN, data, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* chipInList(action: any) {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET,API_URL.PETITION.CHIP_IN_LIST+"/"+data.petition_id, null, true);
    if ( resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}