import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { getSignedTime, URLS } from 'src/_config'

interface ReplyProps {
    replyList: any;
    openReportCommentModal: Function;
}

const Reply = ( {replyList, openReportCommentModal}: ReplyProps ) => {

    // const history = useHistory()

    // const gotoCreatePetition = () => {
    //     history.push(URLS.PETITION)
    // }

    return (
        <div className="reasonsignin_list">
          <ul>
          {replyList && replyList.length ?
            replyList.map((reply:any)=>
              <li key={reply.id}>
                <div className="reasonsignin_listtop">
                  <div className="reasonsignin_listleft">
                    <div className="reasonsignin_listleftimg">
                      <img src={reply?.user?.avatar?.thumb} />
                    </div>
                    <div className="reasonsignin_listleftcont">
                      <h4>{reply?.user?.full_name}</h4>
                      <h6>{getSignedTime(reply?.comment_date)}</h6>
                    </div>
                  </div>
                  <div className="reasonsignin_listright" onClick={()=>openReportCommentModal(reply.id)}>
                    {/* <a href="#" className="like">
                      <img src="/images/like.jpg" /> 150
                    </a> */}
                    <a className="report">
                      Report
                    </a>
                  </div>
                </div>
                <p>
                  {reply?.body}
                </p>
                <Reply
                    replyList={reply?.comment_replies}
                    openReportCommentModal={openReportCommentModal}
                />
                {/* <div className="comment_reply">
                  <span className="comment_icon">
                    <img src="/images/Group 26.svg" />
                  </span>
                  <input
                    className="reply_input"
                    type="text"
                    placeholder="Write a reply..."
                    onChange={e=>onChangeReplyText(e,reply.id)}
                    value={(reply.id==replyDetails?.comment_id)?replyDetails.body:''}
                  />
                  <button className="reply_txtbtn" onClick={e=>replyToComment(e,reply.id)}>Reply</button>
                </div> */}
              </li>)
              :
              null
            }
          </ul>
        </div>
    )
}

export default Reply
