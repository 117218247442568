import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { CRYPTO_SECRET_KEY, URLS } from 'src/_config'

// const Cryptr = require("cryptr");
// const cryptr = new Cryptr(CRYPTO_SECRET_KEY);
// const queryString = require("query-string");

interface ButtonPanelProps {
  page?: string;
}

const RightPanelButtons = ({page} : ButtonPanelProps) => {

  const urlParams = useParams<any>();
  // const id = parseInt(cryptr.decrypt(urlParams.id));

  return (
      <div className="petetion_btn">
        <div className="d-flex align-items-center justify-content-between">
          <Link to={`/petition-comments/${urlParams.slug}`} className={`btn btn-secondary w-48 ${page === "comments"? "active": ""}`}>
            Comments <span className="chat_icon"></span>
          </Link>
          <Link to={`/petition-updates/${urlParams.slug}`} className={`btn btn-secondary w-48 ${page === "updates"? "active": ""}`} href="#updates">
            Updates <span className="reload_icon"></span>
          </Link>
        </div>
        <Link to={`/petition-reasons/${urlParams.slug}`} className={`btn btn-secondary signingbtn w-100 ${page === "reasons"? "active": ""}`} href="#">
          Reasons for signing <span className="signin_icon"></span>
        </Link>
      </div>
  )
}

export default RightPanelButtons
