import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useAppUserAction } from 'src/_common/hooks/actions/user/appUserActionHook'
import { useAuthStatus } from 'src/_common/hooks/authHook'
import { URLS } from 'src/_config'

interface StartFundraisingButtonProps {
  className:string;
  noImage?:boolean;
}

const StartFundraisingButton = ({className, noImage}: StartFundraisingButtonProps) => {

  const userAction = useAppUserAction()
  const isAuth = useAuthStatus()

  const setLink = () => {
    if(!isAuth)
    {
      userAction.setLink(URLS.FUNDRAISING.START_FUNDRAISING)
    }
  }

  return (
      <Link className={className+(className=="nav-link" && (window.location.pathname==URLS.FUNDRAISING.START_FUNDRAISING)?" active":"")} to={URLS.FUNDRAISING.START_FUNDRAISING} onClick={setLink}>
        Start Fundraising{" "}
        {!noImage && <img className="fa" src={"/images/arrow-forward-outline.svg"} alt="arrow" />}
      </Link>
  )
}

export default StartFundraisingButton
