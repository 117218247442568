
import { API_BASE_URL } from './site_urls'
// export const APPLICATION_NAME = 'Big Screen Selfie'
// export const APPLICATION_GLOBAL_SLUG = 'big-screen-selfie'

// export const INSIDERODEO_EVENT_STORAGE = `EXTERNAL-EVENT-FOR-BIGSCREENSELFIE`

// export const BIGSCREENSELFIE_NEXT_GAME_IDS = `${APPLICATION_NAME}-NEXT-GAMES`

// export const IS_SHOWING_ADD_TO_HOME_SCREEN_IOS = `${APPLICATION_NAME}-IOS`

export const PER_PAGE = 10;
export const WITHOUT_PER_PAGE = 99999;

export const FILE_BASE_PATH = ''
export const APP_STAGE = process.env.REACT_APP_STAGE

export const DATE_FORMAT = 'MM/dd/yyyy';

export const DATE_ALL_FORMAT = {
  DATE_PICKER_FORMAT: 'MM/dd/yyyy',
  MOMENT_FORMAT: 'MM/DD/yyyy'
}

export const CHAT_DATE_TIME_FORMAT = {
  DISPLAY_DATE_WITH_TIME: 'Do MMMM, YYYY hh:mm a',
  DISPLAY_DAY_TIME: 'dddd hh:mm a'
}
export const TIME_CONFIG = {
  TIME_FORMAT: 'hh:mm a',
  TIME_INTERVALS: 5,
}

export const STORAGE = 'wj-ui'

export const APP_VERSION = process.env.REACT_APP_VERSION

export const URLS = {
  HOME: '/',
  CMS: '/cms/:slug',
  SEARCH: '/search',
  USER: {
    LOGIN: '/login',
    HOME: '/home',
    MY_PROFILE: '/my-profile',
    SETTINGS: '/settings',
    FORGOT_PASSWORD: '/forgot-password',
    SET_PASSWORD: '/set-password/:token'
  },
  PETITION: {
    START_PETITION: '/create-petition',
    BROWSEPETITION: '/browse-petition',
    LANDINGPETITION: '/petition-landing',
    PETITIONDETAILS: '/petition/:slug',
    PETITIONCOMMENTS: '/petition-comments/:slug',
    PETITIONUPDATES: '/petition-updates/:slug',
    PETITIONREASONS: '/petition-reasons/:slug',
    MYPETITIONS: '/my-petitions',
    MYPETITION_DASHBOARD: '/my-petition-dashboard/:slug',
    MYPETITION_EDIT: '/edit-petition/:slug',
  },
  FUNDRAISING: {
    START_FUNDRAISING: '/start-fundraising',
    BROWSE_FUNDRAISING: '/browse-fundraising',
    FUNDRAISING_DETAILS: '/fundraising/:slug',
    WORD_OF_SUPPORT: '/fundraising-words-of-support/:slug',
    FUNDRAISING_UPDATES: '/fundraising-updates/:slug',
    DONATE: '/fundraising-donate/:slug',
    MY_FUNDRAISING_DASHBOARD: '/my-fundraising-dashboard/:slug',
    MY_FUNDRAISING_EDIT: '/edit-fundraising/:slug',
    MY_FUNDRAISING_SETTINGS: '/fundraising-settings/:slug',
    MY_CAUSES: '/my-causes',
    BANK_ACCOUNT: '/bank-account/:slug',
    BANK_ACCOUNT_SUCCESS: '/payment/success',
    BANK_ACCOUNT_FAIL: '/payment/fail',
  },
  SURVEY: {
    SURVEY_LANDING: '/survey-landing',
    CREATE_SURVEY: '/create-survey/:slug?',
    MY_SURVEY: '/my-survey',
    BROWSE_SURVEY: '/browse-survey',
    START_SURVEY: '/start-survey/:slug',
    SURVEY_DETAILS: '/survey/:slug',
    END_SURVEY: '/end-survey/:slug',
    ANALYZE_RESULT: '/analyze-result/:slug',
    VERIFY_SURVEY: '/verify-survey/:url_parameter',
  }
}

export const API_URL = {
  PETITION: {
    CATEGORY_LIST: `${API_BASE_URL}petitions/category-list`,
    LIST: `${API_BASE_URL}petitions/list`,
    FEATURED_TOPIC_LIST: `${API_BASE_URL}petitions/category-is-featured-list`,
    CREATE_PETITION: `${API_BASE_URL}petitions/create`,
    COMMENT_LIST: `${API_BASE_URL}petitions/comment-list`,
    UPDATES_LIST: `${API_BASE_URL}petitions-upadte/list`,
    ADD_UPDATE: `${API_BASE_URL}petitions-upadte/create`,
    REASONS_LIST: `${API_BASE_URL}petitions/reason-list`,
    ADD_COMMENT: `${API_BASE_URL}petitions/comment-add`,
    REPLY_COMMENT: `${API_BASE_URL}petitions/reply-comment`,
    REPORT: `${API_BASE_URL}petitions/report`,
    DELETE_COMMENT: `${API_BASE_URL}petitions/comment-delete`,
    PETITION_DETAILS: `${API_BASE_URL}petitions/details`,
    SIGN_PETITION: `${API_BASE_URL}petitions/sign`,
    TIPS: `${API_BASE_URL}steps/petition`,
    LIKE_REASON: `${API_BASE_URL}petitions/reason-like`,
    MYPETITIONS: `${API_BASE_URL}petitions/my-petitions`,
    DELETE_MYPETITIONS: `${API_BASE_URL}petitions/delete`,
    PETITION_VIEW_COUNT: `${API_BASE_URL}petitions/add-petition-viewer`,
    PETITION_DECLARE_VICTORY: `${API_BASE_URL}petitions/achieved`,
    EDIT_PETITION: `${API_BASE_URL}petitions/edit`,
    PETITION_TOP_THREE_LIST: `${API_BASE_URL}petitions/top-popular-fatured`,
    CSV_EXPORT: `${API_BASE_URL}petitions/expost-csv`,
    CHIP_IN: `${API_BASE_URL}petitions/make-donation`,
    CHIP_IN_LIST: `${API_BASE_URL}petitions/chip-in-list`,
  },
  USER: {
    LOGIN: `${API_BASE_URL}login`,
    REGISTER: `${API_BASE_URL}register`,
    UPDATE_USER_INFO: `${API_BASE_URL}users/update-user-information`,
    CHANGE_PASSWORD: `${API_BASE_URL}users/change-password`,
    DEACTIVATE_ACCOUNT: `${API_BASE_URL}users/deactivate-account`,
    PROFILE_PIC_UPLOAD: `${API_BASE_URL}users/avatar`,
    PROFILE_PIC_DELETE: `${API_BASE_URL}users/avatar-delete`,
    SOCIAL_LOGIN: `${API_BASE_URL}social-login`,
    FORGOT_PASSWORD: `${API_BASE_URL}forgot/password`,
    SET_PASSWORD: `${API_BASE_URL}set/password`,
  },
  COMMON: {
    COUNTRY_LIST: `${API_BASE_URL}country-list`,
    STATE_LIST: `${API_BASE_URL}state-list`,
    CITY_LIST: `${API_BASE_URL}city-list`,
    SHARE_COUNT: `${API_BASE_URL}users/share-count`,
    DELETE_IMAGE: `${API_BASE_URL}users/delete-image`,
    CMS_CONTENT: `${API_BASE_URL}get-cms-content`,
    CMS_LINKS: `${API_BASE_URL}get-all-cms-content`,
    POPUP_SETTING: `${API_BASE_URL}popup-setting`,
    HOME_PAGE_COUNTING: `${API_BASE_URL}home-page-counting`,
    GLOBAL_SEARCH: `${API_BASE_URL}global/search`,
    REPORT_CATEGORY_LIST: `${API_BASE_URL}report-category-list`,
  },
  FUNDRAISING: {
    CATEGORY_LIST: `${API_BASE_URL}crowdfunding/category-list`,
    START_FUNDRAISING: `${API_BASE_URL}crowdfunding/create`,
    LISTING: `${API_BASE_URL}crowdfunding/list`,
    MAKE_DONATION: `${API_BASE_URL}crowdfunding/make-donation`,
    DETAILS: `${API_BASE_URL}crowdfunding/details`,
    VIEW_ALL_DONORS: `${API_BASE_URL}crowdfunding/view-all-donors`,
    VIEW_TOP_DONORS: `${API_BASE_URL}crowdfunding/view-top-donors`,
    TIPS: `${API_BASE_URL}steps/crowdfunding`,
    ADD_COMMENT: `${API_BASE_URL}crowdfunding/comment-add`,
    DELETE_COMMENT: `${API_BASE_URL}crowdfunding/comment-delete`,
    COMMENT_LIST: `${API_BASE_URL}crowdfunding/comment-list`,
    REPORT: `${API_BASE_URL}crowdfunding/report`,
    CHARITY_LIST: `${API_BASE_URL}charity/list`,
    MY_FUNDRAISERS_LIST: `${API_BASE_URL}crowdfunding/my-fundraisers-list`,
    MY_FUNDRAISER_EDIT: `${API_BASE_URL}crowdfunding/edit`,
    CREATE_UPDATE: `${API_BASE_URL}crowdfunding-upadte/create`,
    UPDATES_LIST: `${API_BASE_URL}crowdfunding-upadte/list`,
    REMOVE_UPDATE: `${API_BASE_URL}crowdfunding-upadte/remove`,
    INVITE_SUPPORTER: `${API_BASE_URL}crowdfunding/invite-supporter`,
    INVITED_SUPPORTERS_LIST: `${API_BASE_URL}crowdfunding/invite-supporter-list`,
    BANK_ACCOUNT: `${API_BASE_URL}users/create-account`,
    CONNECT_WITH_STRIPE_SUCCESS: `${API_BASE_URL}users/connect-with-stripe-success`,
    CONNECT_WITH_STRIPE: `${API_BASE_URL}users/connect-with-stripe`,
    BANK_ACCOUNT_DETAILS: `${API_BASE_URL}users/bank-list`,
    BANK_ACCOUNT_DELETE: `${API_BASE_URL}users/delete-account`,
    TOP_DONATED_FUNDRAISER: `${API_BASE_URL}crowdfunding/top-donner-fundraiser`,
    CSV_EXPORT: `${API_BASE_URL}crowdfunding/expost-csv`,
    DELETE_FUNDRAISER: `${API_BASE_URL}crowdfunding/delete`,
    FUNDRAISING_SETTINGS: `${API_BASE_URL}crowdfunding/settings`,
    UPDATE_FUNDRAISING_SETTINGS: `${API_BASE_URL}crowdfunding/settings-update`,
    REQUEST_FOR_PAYOUT: `${API_BASE_URL}crowdfunding/request/payout`,
    CLOSE_FUNDRAISER: `${API_BASE_URL}crowdfunding/close`,
  },
  SURVEY: {
    GET_ANSWER_TYPE_LIST: `${API_BASE_URL}survey/answer-type-list`,
    CREATE_SURVEY: `${API_BASE_URL}survey/create`,
    SURVEY_DETAILS: `${API_BASE_URL}survey/details`,
    TITLE_FOOTER: `${API_BASE_URL}survey/update`,
    ADD_EDIT_QUESTION: `${API_BASE_URL}survey/question-add-edit`,
    MY_SURVEY_LIST: `${API_BASE_URL}survey/my-survey-list`,
    SURVEY_LIST: `${API_BASE_URL}survey/list`,
    DUPLICATE_SURVEY_QUESTION: `${API_BASE_URL}survey/duplicate-question`,
    SURVEY_QUESTION_DELETE: `${API_BASE_URL}survey/delete-question`,
    SURVEY_COMPLETE: `${API_BASE_URL}survey/complete-servey`,
    BACKGROUND_IMAGE_UPLOAD: `${API_BASE_URL}survey/file-upload`,
    BACKGROUND_IMAGE_DELETE: `${API_BASE_URL}users/delete-image`,
    DELETE_SURVEY: `${API_BASE_URL}survey/delete`,
    DUPLICATE_SURVEY: `${API_BASE_URL}survey/duplicate-survey`,
    UPDATE_SURVEY_STATUS: `${API_BASE_URL}survey/status-update`,
    EDIT_SURVEY: `${API_BASE_URL}survey/edit`,
    ADD_SURVEY_PAGE_BREAK: `${API_BASE_URL}survey/add-survey-page-break`,
    DELETE_SURVEY_PAGE_BREAK: `${API_BASE_URL}survey/delete-survey-page-break`,
    START_USER_SURVEY: `${API_BASE_URL}survey/start-survey`,
    ANSWER_FILE_UPLOAD: `${API_BASE_URL}survey/user-survey-file-upload`,
    CHECK_EMAIL_EXIST: `${API_BASE_URL}survey/check-survey-email-exist`,
    TOP_FOUR_SURVEY: `${API_BASE_URL}survey/top-four-user-submited-survey`,
    SURVEY_REPORT: `${API_BASE_URL}survey/report`,
    VERIFY_SURVEY: `${API_BASE_URL}survey/verify-user-submitted-survey`,
    ASSOCIATE_USER_SURVEY: `${API_BASE_URL}survey/associate-user-survey-status`,
  }
}

export const ACTIONS = {
  PETITION: {
    CATEGORY_LIST: 'ACTIONS/PETITION/GET_CATEGORY_LIST',
    LIST: 'ACTIONS/PETITION/LIST',
    FEATURED_TOPIC_LIST: 'ACTIONS/PETITION/FEATURED_TOPIC_LIST',
    CREATE_PETITION: 'ACTIONS/PETITION/CREATE_PETITION',
    RESET_CREATE_PETITION: 'vRESET_CREATE_PETITION',
    PETITION_DETAILS: 'ACTIONS/PETITION/PETITION_DETAILS',
    TIPS: 'ACTIONS/PETITION/TIPS',
    UPDATES_LIST: 'ACTIONS/PETITION/UPDATES_LIST',
    RESET_LIST: 'ACTIONS/PETITION/RESET_LIST',
    SIGN_PETITION: 'ACTIONS/PETITION/SIGN_PETITION'
  },
  LOADER: {
    SET_FP_STATUS: 'ACTIONS/LOADER/SET_FP_STATUS',
  },
  USER: {
    LOGIN: 'ACTIONS/USER/LOGIN',
    ME: 'ACTIONS/USER/ME',
    ADDRESS: 'ACTIONS/USER/ADDRESS',
    LOGOUT: 'ACTIONS/USER/LOGOUT',
    REGISTER: 'ACTIONS/USER/REGISTER',
    SET_LINK: 'ACTIONS/USER/SET_LINK',
  },
  COMMON: {
    STATE_LIST: 'ACTIONS/COMMON/STATE_LIST',
    CITY_LIST: 'ACTIONS/COMMON/CITY_LIST',
    SET_SEARCH_KEYWORD: 'ACTIONS/COMMON/SET_SEARCH_KEYWORD'
  },
  FUNDRAISING: {
    CATEGORY_LIST: 'ACTIONS/FUNDRAISING/GET_CATEGORY_LIST',
    START_FUNDRAISING: 'ACTIONS/FUNDRAISING/START_FUNDRAISING',
    RESET_START_FUNDRAISING: 'ACTIONS/FUNDRAISING/RESET_START_FUNDRAISING',
    LIST: 'ACTIONS/FUNDRAISING/LIST',
    RESET_LIST: 'ACTIONS/FUNDRAISING/RESET_LIST',
    RESET_FUNDRAISING_DETAILS: 'ACTIONS/FUNDRAISING/RESET_FUNDRAISING_DETAILS',
    DETAILS: 'ACTIONS/FUNDRAISING/DETAILS',
    CHARITY_LIST: 'ACTIONS/FUNDRAISING/GET_CHARITY_LIST',
    TIPS: 'ACTIONS/FUNDRAISING/TIPS',
    MY_FUNDRAISERS_LIST: 'ACTIONS/FUNDRAISING/MY_FUNDRAISERS_LIST',
    BANK_ACCOUNT: 'ACTIONS/FUNDRAISING/CREATE_ACCOUNT',
    CONNECT_WITH_STRIPE_SUCCESS: 'ACTIONS/FUNDRAISING/CONNECT_WITH_STRIPE_SUCCESS',
    CONNECT_WITH_STRIPE: 'ACTIONS/FUNDRAISING/CONNECT_WITH_STRIPE',
    BANK_ACCOUNT_DETAILS: 'ACTIONS/FUNDRAISING/BANK_ACCOUNT_DETAILS',
    TOP_DONATED_FUNDRAISER: 'ACTIONS/FUNDRAISING/TOP_DONATED_FUNDRAISER',
  },
  SURVEY: {
    CREATE_SURVEY: 'ACTIONS/SURVEY/CREATE_SURVEY',
    RESET_CREATE_SURVEY: 'ACTIONS/SURVEY/RESET_CREATE_SURVEY',
    CREATE_SURVEY_PAGE: 'ACTIONS/SURVEY/CREATE_SURVEY_PAGE',
    RESET_CREATE_SURVEY_PAGE: 'ACTIONS/SURVEY/RESET_CREATE_SURVEY_PAGE',
    GET_ANSWER_TYPE_LIST: 'ACTIONS/SURVEY/GET_ANSWER_TYPE_LIST',
    SURVEY_DETAILS: 'ACTIONS/SURVEY/SURVEY_DETAILS',
    RESET_SURVEY_DETAILS: 'ACTIONS/SURVEY/RESET_SURVEY_DETAILS',
    SURVEY_PAGE_TITLE_FOOTER: 'ACTIONS/SURVEY/SURVEY_PAGE_TITLE_FOOTER',   
    MY_SURVEY_LIST: 'ACTIONS/SURVEY/MY_SURVEY_LIST',
    SURVEY_LIST: 'ACTIONS/SURVEY/SURVEY_LIST',
    RESET_MY_SURVEY_LIST: 'ACTIONS/SURVEY/RESET_MY_SURVEY_LIST',
    RESET_SURVEY_LIST: 'ACTIONS/SURVEY/RESET_SURVEY_LIST',
    START_SURVEY: 'ACTIONS/SURVEY/START_SURVEY',
    RESET_START_SURVEY: 'ACTIONS/SURVEY/RESET_START_SURVEY',
  }
}

export const SAGA_ACTIONS = {
  PETITION: {
    CATEGORY_LIST: 'SAGA_ACTIONS/PETITION/CATEGORY_LIST',
    LIST: 'SAGA_ACTIONS/PETITION/LIST',
    FEATURED_TOPIC_LIST: 'SAGA_ACTIONS/PETITION/FEATURED_TOPIC_LIST',
    CREATE_PETITION: 'SAGA_ACTIONS/PETITION/CREATE_PETITION',
    COMMENT_LIST: 'SAGA_ACTIONS/PETITION/COMMENT_LIST',
    UPDATES_LIST: 'SAGA_ACTIONS/PETITION/UPDATES_LIST',
    ADD_UPDATE: 'SAGA_ACTIONS/PETITION/ADD_UPDATE',
    REASONS_LIST: 'SAGA_ACTIONS/PETITION/REASONS_LIST',
    ADD_COMMENT: 'SAGA_ACTIONS/PETITION/ADD_COMMENT',
    REPLY_COMMENT: 'SAGA_ACTIONS/PETITION/REPLY_COMMENT',
    REPORT: 'SAGA_ACTIONS/PETITION/REPORT',
    DELETE_COMMENT: 'SAGA_ACTIONS/PETITION/DELETE_COMMENT',
    PETITION_DETAILS: 'SAGA_ACTIONS/PETITION/PETITION_DETAILS',
    SIGN_PETITION: 'SAGA_ACTIONS/PETITION/SIGN_PETITION',
    TIPS: 'SAGA_ACTIONS/PETITION/TIPS',
    LIKE_REASON: 'SAGA_ACTIONS/PETITION/LIKE_REASON',
    MYPETITIONS: 'SAGA_ACTIONS/PETITION/MY_PETITIONS',
    DELETE_MYPETITIONS: 'SAGA_ACTIONS/PETITION/DELETE_MY_PETITIONS',
    PETITION_VIEW_COUNT: 'SAGA_ACTIONS/PETITION/PETITION_VIEW_COUNT',
    PETITION_DECLARE_VICTORY: 'SAGA_ACTIONS/PETITION/PETITION_DECLARE_VICTORY',
    EDIT_PETITION: 'SAGA_ACTIONS/PETITION/EDIT_PETITION',
    PETITION_TOP_THREE_LIST: 'SAGA_ACTIONS/PETITION/PETITION_TOP_THREE_LIST',
    CSV_EXPORT: 'SAGA_ACTIONS/PETITION/CSV_EXPORT',
    CHIP_IN: 'SAGA_ACTIONS/PETITION/CHIP_IN',
    CHIP_IN_LIST: 'SAGA_ACTIONS/PETITION/CHIP_IN_LIST',
  },
  USER: {
    LOGIN: 'SAGA_ACTIONS/USER/LOGIN',
    REGISTER: 'SAGA_ACTIONS/USER/REGISTER',
    UPDATE_USER_INFO: 'SAGA_ACTIONS/USER/UPDATE_USER_INFO',
    CHANGE_PASSWORD: 'SAGA_ACTIONS/USER/CHANGE_PASSWORD',
    DEACTIVATE_ACCOUNT: 'SAGA_ACTIONS/USER/DEACTIVATE_ACCOUNT',
    PROFILE_PIC_UPLOAD: 'SAGA_ACTIONS/USER/PROFILE_PIC_UPLOAD',
    PROFILE_PIC_DELETE: 'SAGA_ACTIONS/USER/PROFILE_PIC_DELETE',
    SOCIAL_LOGIN: 'SAGA_ACTIONS/USER/SOCIAL_LOGIN',
    FORGOT_PASSWORD: 'SAGA_ACTIONS/USER/FORGOT_PASSWORD',
    SET_PASSWORD: 'SAGA_ACTIONS/USER/SET_PASSWORD',
  },
  COMMON: {
    COUNTRY_LIST: 'SAGA_ACTIONS/COMMON/COUNTRY_LIST',
    STATE_LIST: 'SAGA_ACTIONS/COMMON/STATE_LIST',
    CITY_LIST: 'SAGA_ACTIONS/COMMON/CITY_LIST',
    SHARE_COUNT: 'SAGA_ACTIONS/COMMON/SHARE_COUNT',
    DELETE_IMAGE: 'SAGA_ACTIONS/COMMON/DELETE_IMAGE',
    CMS_CONTENT: 'SAGA_ACTIONS/COMMON/CMS_CONTENT',
    CMS_LINKS: 'SAGA_ACTIONS/COMMON/CMS_LINKS',
    POPUP_SETTING: 'SAGA_ACTIONS/COMMON/POPUP_SETTING',
    HOME_PAGE_COUNTING: 'SAGA_ACTIONS/COMMON/HOME_PAGE_COUNTING',
    GLOBAL_SEARCH: 'SAGA_ACTIONS/COMMON/GLOBAL_SEARCH',
    REPORT_CATEGORY_LIST: 'SAGA_ACTIONS/COMMON/REPORT_CATEGORY_LIST',
  },
  FUNDRAISING: {
    CATEGORY_LIST: 'SAGA_ACTIONS/FUNDRAISING/GET_CATEGORY_LIST',
    START_FUNDRAISING: 'SAGA_ACTIONS/FUNDRAISING/START_FUNDRAISING',
    LISTING: 'SAGA_ACTIONS/FUNDRAISING/LISTING',
    MAKE_DONATION: 'SAGA_ACTIONS/FUNDRAISING/MAKE_DONATION',
    DETAILS: 'SAGA_ACTIONS/FUNDRAISING/DETAILS',
    VIEW_ALL_DONORS: 'SAGA_ACTIONS/FUNDRAISING/VIEW_ALL_DONORS',
    VIEW_TOP_DONORS: 'SAGA_ACTIONS/FUNDRAISING/VIEW_TOP_DONORS',
    TIPS: 'SAGA_ACTIONS/FUNDRAISING/TIPS',
    ADD_COMMENT: 'SAGA_ACTIONS/FUNDRAISING/ADD_COMMENT',
    DELETE_COMMENT: 'SAGA_ACTIONS/FUNDRAISING/DELETE_COMMENT',
    COMMENT_LIST: 'SAGA_ACTIONS/FUNDRAISING/COMMENT_LIST',
    REPORT: 'SAGA_ACTIONS/FUNDRAISING/REPORT',
    CHARITY_LIST: 'SAGA_ACTIONS/FUNDRAISING/GET_CHARITY_LIST',
    MY_FUNDRAISERS_LIST: 'SAGA_ACTIONS/FUNDRAISING/MY_FUNDRAISERS_LIST',
    MY_FUNDRAISER_EDIT: 'SAGA_ACTIONS/FUNDRAISING/MY_FUNDRAISER_EDIT',
    CREATE_UPDATE: 'SAGA_ACTIONS/FUNDRAISING/CREATE_UPDATE',
    UPDATES_LIST: 'SAGA_ACTIONS/FUNDRAISING/UPDATES_LIST',
    REMOVE_UPDATE: 'SAGA_ACTIONS/FUNDRAISING/REMOVE_UPDATE',
    INVITE_SUPPORTER: 'SAGA_ACTIONS/FUNDRAISING/INVITE_SUPPORTER',
    INVITED_SUPPORTERS_LIST: 'SAGA_ACTIONS/FUNDRAISING/INVITED_SUPPORTERS_LIST',
    BANK_ACCOUNT: 'SAGA_ACTIONS/FUNDRAISING/CREATE_ACCOUNT',
    BANK_ACCOUNT_DETAILS: 'SAGA_ACTIONS/FUNDRAISING/BANK_ACCOUNT_DETAILS',
    BANK_ACCOUNT_DELETE: 'SAGA_ACTIONS/FUNDRAISING/BANK_ACCOUNT_DELETE',    
    CONNECT_WITH_STRIPE_SUCCESS: 'SAGA_ACTIONS/FUNDRAISING/CONNECT_WITH_STRIPE_SUCCESS',
    CONNECT_WITH_STRIPE: 'SAGA_ACTIONS/FUNDRAISING/CONNECT_WITH_STRIPE',
    TOP_DONATED_FUNDRAISER: 'SAGA_ACTIONS/FUNDRAISING/TOP_DONATED_FUNDRAISER',
    CSV_EXPORT: 'SAGA_ACTIONS/FUNDRAISING/CSV_EXPORT',
    DELETE_FUNDRAISER: 'SAGA_ACTIONS/FUNDRAISING/DELETE_FUNDRAISER',
    FUNDRAISING_SETTINGS: 'SAGA_ACTIONS/FUNDRAISING/FUNDRAISING_SETTINGS',
    UPDATE_FUNDRAISING_SETTINGS: 'SAGA_ACTIONS/FUNDRAISING/UPDATE_FUNDRAISING_SETTINGS',
    REQUEST_FOR_PAYOUT: 'SAGA_ACTIONS/FUNDRAISING/REQUEST_FOR_PAYOUT',
    CLOSE_FUNDRAISER: 'SAGA_ACTIONS/FUNDRAISING/CLOSE_FUNDRAISER',
  },
  SURVEY: {
    GET_ANSWER_TYPE_LIST: 'SAGA_ACTIONS/SURVEY/GET_ANSWER_TYPE_LIST',
    CREATE_SURVEY: 'SAGA_ACTIONS/SURVEY/CREATE_SURVEY',
    SURVEY_DETAILS: 'SAGA_ACTIONS/SURVEY/SURVEY_DETAILS',
    TITLE_FOOTER: 'SAGA_ACTIONS/SURVEY/TITLE_FOOTER',
    ADD_EDIT_QUESTION: 'SAGA_ACTIONS/SURVEY/ADD_EDIT_QUESTION',
    MY_SURVEY_LIST: 'SAGA_ACTIONS/SURVEY/MY_SURVEY_LIST',
    SURVEY_LIST: 'SAGA_ACTIONS/SURVEY/SURVEY_LIST',
    DUPLICATE_SURVEY_QUESTION: 'SAGA_ACTIONS/SURVEY/DUPLICATE_SURVEY_QUESTION',
    SURVEY_QUESTION_DELETE: 'SAGA_ACTIONS/SURVEY/SURVEY_QUESTION_DELETE',
    SURVEY_COMPLETE: 'SAGA_ACTIONS/SURVEY/SURVEY_COMPLETE',
    BACKGROUND_IMAGE_UPLOAD: 'SAGA_ACTIONS/SURVEY/BACKGROUND_IMAGE_UPLOAD',
    BACKGROUND_IMAGE_DELETE: 'SAGA_ACTIONS/SURVEY/BACKGROUND_IMAGE_DELETE',
    DELETE_SURVEY: 'SAGA_ACTIONS/SURVEY/DELETE_SURVEY',
    DUPLICATE_SURVEY: 'SAGA_ACTIONS/SURVEY/DUPLICATE_SURVEY',
    UPDATE_SURVEY_STATUS: 'SAGA_ACTIONS/SURVEY/UPDATE_SURVEY_STATUS',
    EDIT_SURVEY: 'SAGA_ACTIONS/SURVEY/EDIT_SURVEY',
    ADD_SURVEY_PAGE_BREAK: 'SAGA_ACTIONS/SURVEY/ADD_SURVEY_PAGE_BREAK',
    DELETE_SURVEY_PAGE_BREAK: 'SAGA_ACTIONS/SURVEY/DELETE_SURVEY_PAGE_BREAK',
    START_USER_SURVEY: 'SAGA_ACTIONS/SURVEY/START_USER_SURVEY',
    ANSWER_FILE_UPLOAD: 'SAGA_ACTIONS/SURVEY/ANSWER_FILE_UPLOAD',
    CHECK_EMAIL_EXIST: 'SAGA_ACTIONS/SURVEY/CHECK_EMAIL_EXIST',
    TOP_FOUR_SURVEY: 'SAGA_ACTIONS/SURVEY/TOP_FOUR_SURVEY',
    SURVEY_REPORT: 'SAGA_ACTIONS/SURVEY/SURVEY_REPORT',
    VERIFY_SURVEY: 'SAGA_ACTIONS/SURVEY/VERIFY_SURVEY',
    ASSOCIATE_USER_SURVEY: 'SAGA_ACTIONS/SURVEY/ASSOCIATE_USER_SURVEY',
  }
}

export const FB_SHARE = {
  DEV: process.env.REACT_APP_FB_SHARE_DEV,
  UAT: process.env.REACT_APP_FB_SHARE_UAT,
  PROD: process.env.REACT_APP_FB_SHARE_PROD
}

export const zoomScale = '.10';


export * from './site_statics'
export * from './site_urls'
export * from './functions'
export * from './canvasUtils'
export * from './card_utils'