let shareUrl = ''

let appStage = process.env.REACT_APP_STAGE

if (appStage == 'dev') {
  shareUrl = 'https://westernjustice-dev.dreamztesting.com/fb_share.php?'
} else if (appStage == 'uat') {
  shareUrl = 'https://westernjustice.dreamztesting.com/fb_share.php?'
} else if (appStage == 'prod') {
  shareUrl = 'https://www.ruralamericainaction.com/fb_share.php?'
}

export const SHARE_URL = shareUrl