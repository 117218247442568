import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { useAppFundraisingApi } from "src/_common/hooks/api/fundraising/appFundraisingApiHook";
import { useAppFundraisingCharityListSelector, useAppStartFundraisingSelector } from "src/_common/hooks/selectors/fundraising/fundraisingSelector";
import { useAppFundraisingAction } from "src/_common/hooks/actions/fundraising/appFundraisingActionHook";
import debounce from 'lodash.debounce';
import CheckboxInput from "src/_common/components/form-elements/checkboxinput/checkboxInput";
import RadioInput from "src/_common/components/form-elements/radioinput/radioInput";

interface CharityFundraisingModalProps {
  onClose: Function;
  shouldShow: boolean;
  setModelContinueClicked: Function;
}

interface CharityFundraisingFormValues {
  charity_id: string;
  event: string;
}

const CharityFundraisingFormSchema = yup.object().shape({
  charity_id: yup.string().required("Select any one option"),
  event: yup.string().required("Select any one option"),
});

export default function CharityFundraisingModal({onClose,shouldShow,setModelContinueClicked}: CharityFundraisingModalProps) {

  
  const fundraisingApi = useAppFundraisingApi();
  const fundraisingAction = useAppFundraisingAction();
  const charityListSelector = useAppFundraisingCharityListSelector();
  const startFundraising = useAppStartFundraisingSelector();

  const [charitySearchValue, setCharitySearchValue] = useState<any>('');
  const [selectedEvent, setSelectedEvent] = useState<any>('');
  
  const EVENT_OPTIONS = [
    {value:"1",label:"Non Profit"},
    {value:"2",label:"Own Event"},
  ]
  
  const { register, control, setValue, getValues, handleSubmit, errors } =
  useForm<CharityFundraisingFormValues>({
    resolver: yupResolver(CharityFundraisingFormSchema),
    defaultValues: {
      charity_id: "",
      event: "",
    },
  });

  // const handleOnClicked = () => {
  //   setModelContinueClicked(true);
  //   onClose();
  // }

  const onSubmit = (values: any) => {
    // console.log({ values });
    fundraisingAction.startFundraising({charity_id:values});
    setModelContinueClicked(true)
    onClose();
  };
  
  const changeHandler = (event: any) => {
    setCharitySearchValue(event.target.value);
  };
  const debouncedChangeHandler = useCallback(
    debounce(changeHandler, 500)
  , []);
  
  const onEventChange = (e: any) => {
    setSelectedEvent(e)
    fundraisingAction.startFundraising({event:e})
    if(e=='2') {
      fundraisingAction.startFundraising({charity_id: 0})
    }
    // console.log(e)
  }

  useEffect(() => {
    // console.log("err",errors)
  },[errors])

  useEffect(() => {
    let params = {
      title: charitySearchValue
    }
    fundraisingApi.callGetFundraisingCharityList(
      params,
      (message: string, resp: any) => {
        // console.log({ resp });
      },
      (message: string) => { }
    );
  }, [charitySearchValue]);

  useEffect(() => {
    if (startFundraising && shouldShow) {
      if (startFundraising.charity_id) {
        setValue("charity_id", startFundraising.charity_id);
      }
      if (startFundraising.event) {
        setValue("event", startFundraising.event)
      }
    }
  }, [startFundraising, shouldShow]);

  useEffect(()=>{
    setCharitySearchValue('')
  },[shouldShow])

  useEffect(()=>{
    if(selectedEvent && selectedEvent=='2') {
      onClose()
    }
  },[selectedEvent])

  return (
      <Modal
        show={shouldShow}
        backdrop="static"
        keyboard={false}
        className="stackholder-modal"
        centered
      >
        {/* <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        > */}
          <Modal.Header>
            <h5 className="modal-title">
              Choose an event
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => onClose("reset")}
            >
              <span>
                <i className="fa fa-times"></i>
              </span>
            </button>
          </Modal.Header>
          <Modal.Body>
            {/* <div className="modal-body"> */}
              {/* <form onSubmit={handleSubmit(onSubmit)} noValidate> */}
            <div className="check-wrap">
              <div className="custom-check-container">
                <Controller
                  control={control}
                  name={"event"}
                  render={({ onChange, onBlur, value, name, ref }) => (
                  <RadioInput
                    name={name}
                    onChange={e=>{
                      onChange(e)
                      onEventChange(e)
                    }}
                    onBlur={onBlur}
                    value={value}
                    id={"event"}
                    inputRef={ref}
                    options={EVENT_OPTIONS}
                    error={errors.event}
                    // disabled={displayCheck}
                    // label=""
                  />
                  )}
                />
              </div>        
            </div>
            {/* <div className="event-radio">
              <input type="radio" name="event" id="myRadio0" value="1"/><label htmlFor="myRadio0"><p>Non-Profit</p></label>
              <input type="radio" name="event" id="myRadio1" value="2"/><label htmlFor="myRadio1"><p>Own Event</p></label>
            </div> */}
            {selectedEvent=="1" ?
              <form >
                <div className="mb-3">
                  <label htmlFor="" className="form-label">
                    Search a nonprofit
                  </label>
                  <input type="text" className="form-control" onChange={debouncedChangeHandler} />
                </div>
                <div className="o-search-modal-results">
                  <h3>Browse suggested nonprofits</h3>
                  <div className="m-charity-list ">
                    {
                      charityListSelector && charityListSelector.data && charityListSelector.data.length ? charityListSelector.data.map((item: any, index: any) => (
                        <div className="m-charity-row" key={index} onClick={() => onSubmit(item?.id)}>
                          {/* <input type="radio" name="charity_id" value={item.id} ref={register}/> */}
                          <div className="m-charity-info">
                            <div className="m-charity-info-logo">
                              {/* { item?.image?.id !== 0 ? */}
                                <img
                                src={item?.image?.thumb}
                                alt=""
                                />
                              {/* : null} */}
                            </div>
                            <div className="m-charity-text">
                              <h2>
                                <span>{item?.title}</span> <em>Society</em>{" "}
                                <i className="fa fa-check-circle" />
                              </h2>
                              <div className="m-charity-info-details-wrapper">
                                <div className="m-charity-detail">
                                  {item?.category}
                                </div>
                                <div className="m-charity-detail">
                                  {item?.city?.city_name}, OK
                                </div>
                                <div className="m-charity-detail">
                                  EIN: {item?.ein_number}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                      :
                      <>No result found</>
                    }
                  </div>
                  <div className="invalid-feedback">{errors.charity_id?.message}</div>
                </div>
              </form>
              :
              <div className="o-search-modal-results"></div>
            }
          {/* </div> */}
        </Modal.Body>

       
        {/* </div> */}
      </Modal>
  );
}
