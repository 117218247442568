import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useAppPetitionApi } from "src/_common/hooks/api/petition/appPetitionApiHook";
import { useAppPetitionDetailsSelector, useAppPetitionListSelector } from "src/_common/hooks/selectors/petition/petitionSelector";
import { CRYPTO_SECRET_KEY, getSignedTime, URLS } from "src/_config";
import Footer from "../../common/Footer";
import RightStickyBtn from "../../common/RightStickyBtn";
import GotoStartPetition from "../common/GotoStartPetition"
import Reply from "./Reply";
import ReportModal from "../common/ReportModal";
import RightPanelButtons from "../common/RightPanelButtons";
import SignPetition from "../sign-petition/SignPetition";
import WriteCommentModal from "./WriteCommentModal";
import NavTabs from "../my-petition-dashboard/NavTabs";
import { useAppUserDetailsSelector } from "src/_common/hooks/selectors/user/userSelector";
import NavbarMain from "src/components/common/navbar/NavbarMain";
import { useAppUserAction } from "src/_common/hooks/actions/user/appUserActionHook";
import { useAuthStatus } from "src/_common/hooks/authHook";
import { useToaster } from "src/_common/hooks/common/appToasterHook";
import MetaDecorator from "src/components/common/MetaDecorator";
import { Link } from "react-router-dom";

const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);
const queryString = require("query-string");

const PetitionComments = () => {

  const {slug} = useParams<any>();
  // const id = parseInt(cryptr.decrypt(petitionId.id));
  // const slug = petitionId.slug
  // console.log({ id });
  const [showWriteCommentModal, setShowWriteCommentModal] = useState<boolean>(false)
  const [showReportCommentModal, setShowReportCommentModal] = useState<boolean>(false)
  const [commentsList, setCommentsList] = useState<any>(null)
  const [replyDetails, setReplyDetails] = useState<any>()
  const [commentId, setCommentId] = useState<any>()
  const petitionDetails = useAppPetitionDetailsSelector();
  const userDetails = useAppUserDetailsSelector()
  const history = useHistory()
  const petitionApi = useAppPetitionApi()
  const userAction = useAppUserAction()
  const isAuth = useAuthStatus()
  const toast = useToaster()
  const scrollComments = useRef<any>()
  const commentsList2: any = []

  const setLink = () => {
    if(!isAuth)
    {
      userAction.setLink(`/petition-comments/${slug}`)
      return false
    } else {
      return true
    }
  }
  
  const openWriteCommentModal = () => {
    const checkLogin = setLink();
    if(checkLogin){
      setShowWriteCommentModal(true)
    } else {
      history.push(URLS.USER.LOGIN)
    }
  }
  
  const onCloseWriteCommentModal = () => {
    setShowWriteCommentModal(false)
  }
  
  const openReportCommentModal = (id:any) => {
    const checkLogin = setLink();
    if(checkLogin){
      setCommentId(id)
      setShowReportCommentModal(true)
    } else {
      history.push(URLS.USER.LOGIN)
    }
  }
    
  const onCloseReportCommentModal = () => {
    setShowReportCommentModal(false)
  }

  const getCommentsList = () => {
    let params = {
      slug
    }
    petitionApi.callGetPetitionCommentsList(params,
      (message: string, resp: any) => {
        // console.log({resp})
        setCommentsList(resp.data.data)
      }, (message: string) => {
      })
  }

  const onChangeReplyText = (e:any,comId:any) => {
    e.preventDefault()
    let x = {
      slug,
      comment_id: comId,
      body: e.target.value
    }
    setReplyDetails(x)
  }

  const replyToComment = (e:any,comId:any) => {
    e.preventDefault()
    const checkLogin = setLink();
    if(checkLogin){
      if(replyDetails && replyDetails.comment_id && replyDetails.comment_id==comId)
      {
        // console.log(replyDetails)
        petitionApi.callReplyToComment(replyDetails, (message: string, resp: any) => {
            // console.log({resp})
            getCommentsList()
          }, (message: string) => {
            toast.error(message)
          })
        setReplyDetails(null)
      }
    } else {
      history.push(URLS.USER.LOGIN)
    }
  }

  useEffect(()=>{
    getCommentsList()
  },[])

  useEffect(() => {
    if (slug && window.screen.width<=767) {
      scrollComments.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [slug])

  return (
    <>

      <MetaDecorator
        title={petitionDetails?.title+" : Petition Comments"}
        description={petitionDetails?.title+" : Petition Comments"}
      />

      <NavbarMain section={"petition"} />

      {/* <!--------- INNER BANNER START --------> */}
      <div className="innerpageBanner nobanner_image"></div>
      {/* <!--------- INNER BANNER END --------> */}

      <RightStickyBtn />
      
      <section className="inner-page adjusted-padding-top">
        <div className="container">
        {petitionDetails && userDetails && userDetails.id && userDetails.id==petitionDetails.user_id &&
          <div className="custom-tab-wrap">
            <NavTabs
              page={"comments"}
              // setPage={setPage}
            />
          </div>
        }
      {/* <!--------- CONTENT AREA START ----------> */}
      <div className="innerContentArea details-petition-wrap">
        <div className="">
          <div className="row reverse-order-mobile">
            <div className="col-lg-8 col-md-12">
              <div className="row ">
                <div className="col-lg-12 col-md-12" ref={scrollComments}>
                  <h3 className="section-title with-back">COMMENTS<span><Link to={`/petition/${slug}`}>Back</Link></span></h3>
                </div>
                <div className="col-lg-12 col-md-12 mb-2">
                  {
                    petitionDetails && petitionDetails.petition_comments && petitionDetails.petition_comments==1 ?
                    <div className="" onClick={openWriteCommentModal}>
                      <div className="write_commenticon">
                      <a style={{cursor:"pointer"}}><img src="/images/chatbubble-ellipses-outline.svg" />
                        WRITE YOUR COMMENT</a>
                      </div>
                      {/* <input
                        className="writecomment_input"
                        type="text"
                        placeholder="WRITE YOUR COMMENT"
                      /> */}
                    </div>
                    :null
                  }
                </div>
              </div>
              {/* <p>
                It is a long established fact that a reader will be distracted by the
                readable content of a page when looking at its layout. The point of
                using Lorem Ipsum is that it has a more-or-less normal distribution of
                letters, as opposed to using 'Content here, content here', making it
                look like readable English.
              </p> */}
              <div className="reasonsignin_list">
                <ul>
                {commentsList && commentsList.length ?
                  commentsList.map((comnt:any)=>
                    <li key={comnt.id}>
                      <div className="reasonsignin_listtop">
                        <div className="reasonsignin_listleft">
                          <div className="reasonsignin_listleftimg">
                            <img src={comnt?.user?.avatar?.thumb} alt="profile"/>
                          </div>
                          <div className="reasonsignin_listleftcont">
                            <h4>{comnt?.user?.full_name}</h4>
                            <h6>{getSignedTime(comnt?.comment_date)}</h6>
                          </div>
                        </div>
                        <div className="reasonsignin_listright" onClick={()=>openReportCommentModal(comnt?.id)} style={{cursor: "pointer"}}>
                          {/* <a href="#" className="like">
                            <img src="/images/like.jpg" /> 150
                          </a> */}
                          <a className="report">
                            Report
                          </a>
                        </div>
                      </div>
                      <p>
                        {comnt?.body}
                      </p>
                      <Reply
                        replyList={comnt?.comment_replies}
                        openReportCommentModal={openReportCommentModal}
                      />
                      <div className="comment_reply">
                        <span className="comment_icon">
                          <img src="/images/Group 26.svg" />
                        </span>
                        <input
                          className="reply_input"
                          type="text"
                          placeholder="Write a reply..."
                          onChange={e=>onChangeReplyText(e,comnt?.id)}
                          value={(comnt?.id==replyDetails?.comment_id)?replyDetails?.body:''}
                        />
                        <button className="reply_txtbtn" onClick={e=>replyToComment(e,comnt?.id)}>Reply</button>
                      </div>
                    </li>)
                    :
                    <>
                    <img src="/images/no-img-comments.jpg" alt="" />
                    </>
                  }
                </ul>
              </div>
              <GotoStartPetition/>
            </div>
            <div className="col-lg-4 col-md-12 mobm-30">
            <div className="sticky-right">
              <RightPanelButtons page="comments" />
              <SignPetition/>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!--------- CONTENT AREA END ----------> */}
      </div>
      </section>
      <Footer />

      {showWriteCommentModal && petitionDetails &&
        <WriteCommentModal
          onClose={onCloseWriteCommentModal} 
          shouldShow={showWriteCommentModal}
          petition_id={String(petitionDetails?.id)}
          getCommentsList={getCommentsList}
        />
      }
      {showReportCommentModal && petitionDetails &&
        <ReportModal
          onClose={onCloseReportCommentModal} 
          shouldShow={showReportCommentModal}
          petition_id={String(petitionDetails?.id)}
          entity_id={commentId}
          type="comment"
        />
      }
    </>
  );
};

export default PetitionComments;
