import { fork, take, takeLatest, all, takeEvery, takeLeading } from 'redux-saga/effects';
import { SAGA_ACTIONS } from '../_config';

import {
  petitionCategoryList,
  petitionList,
  petitionFeaturedTopicList,
  createPetition,
  petitionCommentsList,
  petitionUpdatesList,
  petitionDeatils,
  petitionAddComment,
  replyToComment,
  report,
  petitionReasonsList,
  getPetitionTips,
  likeReason,
  petitionSign,
  myPetitions,
  increasePetitionViewCount,
  deleteMyPetitions,
  petitionAddUpdate,
  petitionDeclareVictory,
  editPetition,
  topThreePetitionList, csvExport, chipIn, chipInList
} from './petitionSaga';

import {
  changePassword,
  deactivateAccount,
  login,
  register,
  updateUserInfo,
  profilePicUpload, socialLogin, profilePicDelete, forgotPassword, setPassword
} from './userSaga';

import { cityList, deleteImage, popupSetting, cmsContent, shareCount, stateList, homepageCounting, globalSearch, countryList, reportCategoryList, cmsLinks } from './commonSaga';

import { 
  fundraisingCategoryList, 
  fundraisingCharityList, 
  fundraisingDetails, 
  fundraisingList, 
  startFundraising,
  fundraisingCommentsList,
  viewAllDonors,
  viewTopDonors,
  getFundraisingTips,
  makeFundraisingDonation,
  myFundraisingList, editMyFundraising, createUpdate, getUpdatesList, removeUpdate, inviteSupporters, getInviteSupportersList, addBankAccount, getBankAccountDetails, deleteBankAccount, connectWithStripe, connectWithStripeSuccess, topDonatedFundraiser, fundCsvExport, deleteFundraiser, fundraiserSettings, updateFundraiserSettings, requestForPayout, closeFundraiser
} from './fundraisingSaga';
import { addEditQuestion, answerTypeList, createSurvey, getSurveyDetails, setTitleFooter, mySurveyList, surveyList, duplicateSurveyQuestion, surveyQuestionDelete, surveyComplete, backgroundImageUpload, backgroundImageDelete, deleteSurvey, duplicateSurvey, updateSurveyStatus, startUserSurvey, deleteSurveyPageBreak, addSurveyPageBreak, editSurvey, answerFileUpload, checkEmailExist, topFourSurvey, surveyReport, verifySurvey, associateUserSurvey } from './surveySaga';

export function* sagas() {
  yield all([

    //Petition Saga
    takeLatest(SAGA_ACTIONS.PETITION.CATEGORY_LIST, petitionCategoryList),
    takeLatest(SAGA_ACTIONS.PETITION.LIST, petitionList),
    takeLatest(SAGA_ACTIONS.PETITION.FEATURED_TOPIC_LIST, petitionFeaturedTopicList),
    takeLatest(SAGA_ACTIONS.PETITION.COMMENT_LIST, petitionCommentsList),
    takeLatest(SAGA_ACTIONS.PETITION.UPDATES_LIST, petitionUpdatesList),
    takeLatest(SAGA_ACTIONS.PETITION.REASONS_LIST, petitionReasonsList),
    takeLatest(SAGA_ACTIONS.PETITION.PETITION_DETAILS, petitionDeatils),
    takeLatest(SAGA_ACTIONS.PETITION.CREATE_PETITION, createPetition),
    takeLatest(SAGA_ACTIONS.PETITION.ADD_COMMENT, petitionAddComment),
    takeLatest(SAGA_ACTIONS.PETITION.REPLY_COMMENT, replyToComment),
    takeLatest(SAGA_ACTIONS.PETITION.REPORT, report),
    takeLatest(SAGA_ACTIONS.PETITION.SIGN_PETITION, petitionSign),
    takeLatest(SAGA_ACTIONS.PETITION.TIPS, getPetitionTips),
    takeLatest(SAGA_ACTIONS.PETITION.LIKE_REASON, likeReason),
    takeLatest(SAGA_ACTIONS.PETITION.MYPETITIONS, myPetitions),
    takeLatest(SAGA_ACTIONS.PETITION.DELETE_MYPETITIONS, deleteMyPetitions),
    takeLatest(SAGA_ACTIONS.PETITION.PETITION_VIEW_COUNT, increasePetitionViewCount),
    takeLatest(SAGA_ACTIONS.PETITION.ADD_UPDATE, petitionAddUpdate),
    takeLatest(SAGA_ACTIONS.PETITION.PETITION_DECLARE_VICTORY, petitionDeclareVictory),
    takeLatest(SAGA_ACTIONS.PETITION.EDIT_PETITION, editPetition),
    takeLatest(SAGA_ACTIONS.PETITION.PETITION_TOP_THREE_LIST, topThreePetitionList),
    takeLatest(SAGA_ACTIONS.PETITION.CSV_EXPORT, csvExport),
    takeLatest(SAGA_ACTIONS.PETITION.CHIP_IN, chipIn),
    takeLatest(SAGA_ACTIONS.PETITION.CHIP_IN_LIST, chipInList),

    //Fundraising Saga
    takeLatest(SAGA_ACTIONS.FUNDRAISING.CATEGORY_LIST, fundraisingCategoryList),
    takeLatest(SAGA_ACTIONS.FUNDRAISING.START_FUNDRAISING, startFundraising),
    takeLatest(SAGA_ACTIONS.FUNDRAISING.LISTING, fundraisingList),
    takeLatest(SAGA_ACTIONS.FUNDRAISING.DETAILS, fundraisingDetails),
    takeLatest(SAGA_ACTIONS.FUNDRAISING.CHARITY_LIST, fundraisingCharityList),
    takeLatest(SAGA_ACTIONS.FUNDRAISING.COMMENT_LIST, fundraisingCommentsList),
    takeLatest(SAGA_ACTIONS.FUNDRAISING.VIEW_ALL_DONORS, viewAllDonors),
    takeLatest(SAGA_ACTIONS.FUNDRAISING.VIEW_TOP_DONORS, viewTopDonors),
    takeLatest(SAGA_ACTIONS.FUNDRAISING.TIPS, getFundraisingTips),
    takeLatest(SAGA_ACTIONS.FUNDRAISING.MAKE_DONATION, makeFundraisingDonation),    
    takeLatest(SAGA_ACTIONS.FUNDRAISING.MY_FUNDRAISERS_LIST, myFundraisingList),
    takeLatest(SAGA_ACTIONS.FUNDRAISING.MY_FUNDRAISER_EDIT, editMyFundraising),
    takeLatest(SAGA_ACTIONS.FUNDRAISING.CREATE_UPDATE, createUpdate),
    takeLatest(SAGA_ACTIONS.FUNDRAISING.UPDATES_LIST, getUpdatesList),
    takeLatest(SAGA_ACTIONS.FUNDRAISING.REMOVE_UPDATE, removeUpdate),
    takeLatest(SAGA_ACTIONS.FUNDRAISING.INVITE_SUPPORTER, inviteSupporters),
    takeLatest(SAGA_ACTIONS.FUNDRAISING.INVITED_SUPPORTERS_LIST, getInviteSupportersList),
    takeLatest(SAGA_ACTIONS.FUNDRAISING.BANK_ACCOUNT, addBankAccount),
    takeLatest(SAGA_ACTIONS.FUNDRAISING.BANK_ACCOUNT_DETAILS, getBankAccountDetails),
    takeLatest(SAGA_ACTIONS.FUNDRAISING.BANK_ACCOUNT_DELETE, deleteBankAccount),
    takeLatest(SAGA_ACTIONS.FUNDRAISING.CONNECT_WITH_STRIPE, connectWithStripe),
    takeLatest(SAGA_ACTIONS.FUNDRAISING.CONNECT_WITH_STRIPE_SUCCESS, connectWithStripeSuccess),
    takeLatest(SAGA_ACTIONS.FUNDRAISING.TOP_DONATED_FUNDRAISER, topDonatedFundraiser),
    takeLatest(SAGA_ACTIONS.FUNDRAISING.CSV_EXPORT, fundCsvExport),
    takeLatest(SAGA_ACTIONS.FUNDRAISING.DELETE_FUNDRAISER, deleteFundraiser),
    takeLatest(SAGA_ACTIONS.FUNDRAISING.FUNDRAISING_SETTINGS, fundraiserSettings),
    takeLatest(SAGA_ACTIONS.FUNDRAISING.UPDATE_FUNDRAISING_SETTINGS, updateFundraiserSettings),
    takeLatest(SAGA_ACTIONS.FUNDRAISING.REQUEST_FOR_PAYOUT, requestForPayout),
    takeLatest(SAGA_ACTIONS.FUNDRAISING.CLOSE_FUNDRAISER, closeFundraiser),

    //Survey Saga
    takeLatest(SAGA_ACTIONS.SURVEY.GET_ANSWER_TYPE_LIST, answerTypeList),
    takeLatest(SAGA_ACTIONS.SURVEY.CREATE_SURVEY, createSurvey),
    takeLatest(SAGA_ACTIONS.SURVEY.SURVEY_DETAILS, getSurveyDetails),
    takeLatest(SAGA_ACTIONS.SURVEY.TITLE_FOOTER, setTitleFooter),
    takeLatest(SAGA_ACTIONS.SURVEY.ADD_EDIT_QUESTION, addEditQuestion),
    takeLatest(SAGA_ACTIONS.SURVEY.MY_SURVEY_LIST, mySurveyList),
    takeLatest(SAGA_ACTIONS.SURVEY.SURVEY_LIST, surveyList),
    takeLatest(SAGA_ACTIONS.SURVEY.DUPLICATE_SURVEY_QUESTION, duplicateSurveyQuestion),
    takeLatest(SAGA_ACTIONS.SURVEY.SURVEY_QUESTION_DELETE, surveyQuestionDelete),
    takeLatest(SAGA_ACTIONS.SURVEY.SURVEY_COMPLETE, surveyComplete),
    takeLatest(SAGA_ACTIONS.SURVEY.BACKGROUND_IMAGE_UPLOAD, backgroundImageUpload),
    takeLatest(SAGA_ACTIONS.SURVEY.BACKGROUND_IMAGE_DELETE, backgroundImageDelete),
    takeLatest(SAGA_ACTIONS.SURVEY.DELETE_SURVEY, deleteSurvey),
    takeLatest(SAGA_ACTIONS.SURVEY.DUPLICATE_SURVEY, duplicateSurvey),
    takeLatest(SAGA_ACTIONS.SURVEY.UPDATE_SURVEY_STATUS, updateSurveyStatus),
    takeLatest(SAGA_ACTIONS.SURVEY.EDIT_SURVEY, editSurvey),
    takeLatest(SAGA_ACTIONS.SURVEY.ADD_SURVEY_PAGE_BREAK, addSurveyPageBreak),
    takeLatest(SAGA_ACTIONS.SURVEY.DELETE_SURVEY_PAGE_BREAK, deleteSurveyPageBreak),
    takeLatest(SAGA_ACTIONS.SURVEY.START_USER_SURVEY, startUserSurvey),
    takeLatest(SAGA_ACTIONS.SURVEY.ANSWER_FILE_UPLOAD, answerFileUpload),
    takeLatest(SAGA_ACTIONS.SURVEY.CHECK_EMAIL_EXIST, checkEmailExist),
    takeLatest(SAGA_ACTIONS.SURVEY.TOP_FOUR_SURVEY, topFourSurvey),
    takeLatest(SAGA_ACTIONS.SURVEY.SURVEY_REPORT, surveyReport),
    takeLatest(SAGA_ACTIONS.SURVEY.VERIFY_SURVEY, verifySurvey),
    takeLatest(SAGA_ACTIONS.SURVEY.ASSOCIATE_USER_SURVEY, associateUserSurvey),
        
    //User Saga
    takeLatest(SAGA_ACTIONS.USER.LOGIN, login),
    takeLatest(SAGA_ACTIONS.USER.REGISTER, register),
    takeLatest(SAGA_ACTIONS.USER.UPDATE_USER_INFO, updateUserInfo),
    takeLatest(SAGA_ACTIONS.USER.CHANGE_PASSWORD, changePassword),
    takeLatest(SAGA_ACTIONS.USER.DEACTIVATE_ACCOUNT, deactivateAccount),
    takeLatest(SAGA_ACTIONS.USER.PROFILE_PIC_UPLOAD, profilePicUpload),
    takeLatest(SAGA_ACTIONS.USER.PROFILE_PIC_DELETE, profilePicDelete),
    takeLatest(SAGA_ACTIONS.USER.SOCIAL_LOGIN, socialLogin),
    takeLatest(SAGA_ACTIONS.USER.FORGOT_PASSWORD, forgotPassword),
    takeLatest(SAGA_ACTIONS.USER.SET_PASSWORD, setPassword),

    //Common Saga
    takeLatest(SAGA_ACTIONS.COMMON.COUNTRY_LIST, countryList),
    takeLatest(SAGA_ACTIONS.COMMON.STATE_LIST, stateList),
    takeLatest(SAGA_ACTIONS.COMMON.CITY_LIST, cityList),
    takeLatest(SAGA_ACTIONS.COMMON.SHARE_COUNT, shareCount),
    takeLatest(SAGA_ACTIONS.COMMON.DELETE_IMAGE, deleteImage),
    takeLatest(SAGA_ACTIONS.COMMON.POPUP_SETTING, popupSetting),
    takeLatest(SAGA_ACTIONS.COMMON.CMS_CONTENT, cmsContent),
    takeLatest(SAGA_ACTIONS.COMMON.CMS_LINKS, cmsLinks),
    takeLatest(SAGA_ACTIONS.COMMON.HOME_PAGE_COUNTING, homepageCounting),
    takeLatest(SAGA_ACTIONS.COMMON.GLOBAL_SEARCH, globalSearch),
    takeLatest(SAGA_ACTIONS.COMMON.REPORT_CATEGORY_LIST, reportCategoryList),

  ]);
}