import { useSelector } from 'react-redux'
import { StateExtended } from '../../../interfaces/StateExtended'


export function useAppFundraisingCategoryListSelector() {
  const fundraisingCategoryList: any = useSelector((state: StateExtended) => state.fundraising.fundraisingCategoryList)
  return fundraisingCategoryList
}

export function useAppStartFundraisingSelector() {
  const startFundraising: any = useSelector((state: StateExtended) => state.fundraising.startFundraising)
  return startFundraising
}

export function useAppFundraisingListSelector() {
  const fundraisingList: any = useSelector((state: StateExtended) => state.fundraising.fundraisingList)
  return fundraisingList
}

export function useAppFundraisingCharityListSelector() {
  const fundraisingCharityList: any = useSelector((state: StateExtended) => state.fundraising.fundraisingCharityList)
  return fundraisingCharityList
}

export function useAppFundraisingDetailsSelector() {
  const fundraisingDetails: any = useSelector((state: StateExtended) => state.fundraising.fundraisingDetails)
  return fundraisingDetails
}

export function useAppFundraisingTipsSelector() {
  const tips: any = useSelector((state: StateExtended) => state.fundraising.tips)
  return tips
}

export function useAppMyFundraisingListSelector() {
  const myFundraisingList: any = useSelector((state: StateExtended) => state.fundraising.myFundraisingList)
  return myFundraisingList
}

export function useAppBankAccountDetailSelector() {
  const bankAccountDetails: any = useSelector((state: StateExtended) => state.fundraising.bankAccountDetails)
  return bankAccountDetails
}