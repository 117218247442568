import React, { useEffect } from "react"
import { useHistory, useParams } from "react-router";

interface NavTabsProps {
  page: string;
  // setPage: Function
}

const NavTabs = ({page}: NavTabsProps) => {

  const history = useHistory()
  const {slug} = useParams<any>();

  const gotoDetailsPage = () => {
    // setPage("details")
    history.push(`/fundraising/${slug}`);
  }

  const gotoDashboardPage = () => {
    // setPage("dashboard")
    history.push(`/my-fundraising-dashboard/${slug}`);
  }

  const gotoEditPage = () => {
    // setPage("edit")
    history.push(`/edit-fundraising/${slug}`);
  }

  useEffect(()=>{
    // console.log(page)
  },[page])

  return (
    <ul
      className="nav nav-tabs my-petition-tab"
      id="myTab"
      role="tablist"
    >
      <li className="nav-item" role="presentation">
        <button
          className={"nav-link "+(page=="dashboard"?"active":"")}
          id="Dashboard-tab"
          data-bs-toggle="tab"
          data-bs-target="#Dashboard"
          type="button"
          role="tab"
          aria-controls="Dashboard"
          aria-selected="true"
          onClick={gotoDashboardPage} 
        >
          Dashboard
        </button>
      </li>
      <li className="nav-item" role="presentation">
        <button
          className={"nav-link "+(page=="details"?"active":"")}
          id="Petition-tab"
          data-bs-toggle="tab"
          data-bs-target="#Petition"
          type="button"
          role="tab"
          aria-controls="Petition"
          aria-selected="false"
          onClick={gotoDetailsPage} 
        >
          Fundraising
        </button>
      </li>
      <li className="nav-item" role="presentation">
        <button
          className={"nav-link "+(page=="edit"?"active":"")}
          id="Edit-tab"
          data-bs-toggle="tab"
          data-bs-target="#Edit"
          type="button"
          role="tab"
          aria-controls="Edit"
          aria-selected="false"
          onClick={gotoEditPage} 
        >
          Edit
        </button>
      </li>
    </ul>
  )
}

export default NavTabs