import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { CRYPTO_SECRET_KEY, URLS } from 'src/_config'
import ShareModal from './ShareModal'


const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

const RightStickyBtn = () => {
  const [showId, setShowId] = useState<any>('')
  const [showSection, setShowSection] = useState<string>('')

  const { slug } = useParams<any>();
  // const id = (petitionId.id!==null || petitionId.id!==undefined) ? parseInt(cryptr.decrypt(petitionId.id)) : '';
  // console.log(typeof(petitionId.id))

  // useEffect(()=>{
  //   if(typeof(petitionId.slug) === 'string') {
  //     const id = petitionId.slug
  //     setShowId(id)
  //   }
  //   console.log('entered')
  // },[])

  useEffect(()=> {
    // console.log(typeof(showId))
    let x=window.location.pathname
    let y=x.substring(0,x.indexOf('/',1))
    Object.values(URLS.PETITION).map((p:any)=>
      { 
        if(p.includes(y)) {
          setShowSection("petition")
          console.log("pett")
        }
      }
    )
    Object.values(URLS.FUNDRAISING).map((f:any)=>
      { 
        if(f.includes(y)) {
          setShowSection("fundraising")
          console.log("fund")
        }
      }
    )
    Object.values(URLS.SURVEY).map((s:any)=>
      { 
        if(s.includes(y)) {
          setShowSection("survey")
          console.log("surv")
        }
      }
    )
    // console.log(window.location.pathname,window.location.pathname.indexOf('/',1))
    // if(window.location.toString().includes('petition')) {
    //   setShowSection('petition')
    // } else if(window.location.toString().includes('fundraising')) {
    //   setShowSection('fundraising')
    // } else if(window.location.toString().includes('survey')) {
    //   setShowSection('survey')
    // }
  })

  const [showShareModal, setShowShareModal] = useState<boolean>(false)

  const openShareModal = () => {
    setShowShareModal(true)
  }

  const closeShareModal = () => {
    setShowShareModal(false)
  }

  return (
    <div className="rightsticky_btn">
      <ul>
        <li>
          <Link to={URLS.FUNDRAISING.BROWSE_FUNDRAISING}>
            <div className="rightsticky_icon"></div>
            Fundraisers
          </Link>
        </li>
        <li>
          <Link to={URLS.PETITION.LANDINGPETITION}>
            <div className="rightsticky_icon"></div>
            Petitions
          </Link>
        </li>
        <li>
          <Link to={URLS.SURVEY.SURVEY_LANDING}>
            <div className="rightsticky_icon"></div>
            Surveys
          </Link>
        </li>
        <li onClick={openShareModal}>
          <a style={{cursor: "pointer"}}>
            <div className="rightsticky_icon"></div>
            Share
          </a>
          {/* <div className="share_social">
            <Link to="#"><span></span></Link>
            <Link to="#"><span></span></Link>
            <Link to="#"><span></span></Link>
            <Link to="#"><span></span></Link>
          </div> */}
        </li>
      </ul>
      {showShareModal &&
        <ShareModal
          onClose={closeShareModal}
          shouldShow={showShareModal}
          slug={slug}
          url={slug ? '' : `${window.location.href}`}
          section={showSection}
        />
      } 
    </div>
  )
}

export default RightStickyBtn