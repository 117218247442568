import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import AuthSignPetitionForm from './AuthSignPetitionForm'
import { useAuthStatus } from 'src/_common/hooks/authHook';
import NoAuthSignPetitionForm from './NoAuthSignPetitionForm';
import { useAppPetitionApi } from 'src/_common/hooks/api/petition/appPetitionApiHook';
import { any } from 'prop-types';
import { useToaster } from 'src/_common/hooks/common/appToasterHook';
import { allowNumberOnly, CRYPTO_SECRET_KEY } from 'src/_config';
import { useHistory, useParams } from 'react-router';
import DeleteDuplicateConfirmationModal from '../../common/DeleteDuplicateConfirmationModal';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import FormTextInput from 'src/_common/components/form-elements/textinput/formTextInput';
import FormMaskedInput from 'src/_common/components/form-elements/maskedInput/maskedInput';
import { toast } from 'react-toastify';
import { useAppPetitionDetailsSelector, useAppPetitionSignPetitionSelector } from 'src/_common/hooks/selectors/petition/petitionSelector';
import CheckboxInput from 'src/_common/components/form-elements/checkboxinput/checkboxInput';
import HomePageModal from 'src/components/home/HomePageModal';

const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);
const queryString = require("query-string");

interface ChipInModalProps {
  onClose: () => void;
  shouldShow: boolean;
}

interface ChipInFormValues {
  auth: string;
  amount: string;
  // card_id: string,
  card_name: string;
  card_number: string;
  card_exp: string;
  // card_exp_month: string,
  // card_exp_year: string,
  card_cvc: string;
  // save_card_info: string,
  email: string;
  anonymous_donation: boolean;
}

const ChipInFormSchema = yup.object().shape({
  auth: yup
    .string(),
  amount: yup
    .string()
    .required("Amount is required"),
  card_name: yup
    .string()
    .required("Name on card is required"),
  card_number: yup
    .string()
    .required("Card number is required"),
  card_exp: yup
    .string()
    .required("Card expiry is required"),
  card_cvc: yup
    .string()
    .required("CVV is required"),
  email: yup
    .string(),
    // .when('auth', {
    //   is: '0',
    //   then: yup
    //       .string()
    //       .email("Provide a valid email")
    //       .required("Email is required")
    //   }),
});

export default function ChipInModal({ onClose, shouldShow, }: ChipInModalProps) {

  const petitionId = useParams<any>();
  // const id = parseInt(cryptr.decrypt(petitionId.id));
  const slug = petitionId.slug
  const petitionApi = useAppPetitionApi()
  const toast = useToaster()
  const petitionDetails = useAppPetitionDetailsSelector()
  const isAuth = useAuthStatus()
  const [anonymous, setAnonymous] = useState<any>("1")
  const signPetitionDetails = useAppPetitionSignPetitionSelector()
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const [popupSetting, setPopupSetting] = useState<any>()
  const history = useHistory()

  const { register, control, setValue, watch, handleSubmit, errors } = useForm<ChipInFormValues>({
      resolver: yupResolver(ChipInFormSchema),
      defaultValues: {
        amount: "",
        card_name: "",
        card_number: "",
        card_exp: "",
        card_cvc: "",
        email: "",
      },
    });
  
  const handleAnonymousChange = (e:any) => {
    console.log(e)
    if(e) {
      setAnonymous("1")
    }
    else {
      setAnonymous("0")
    }
  }

  const openPopup = () => {
    setShowPopup(true)
  }

  const closePopup = () => {
    setShowPopup(false)
    // history.push(`/fundraising-details/${id}`)
  }

  const onSubmit = (values: any) => {
    if(values.amount==0) {
      toast.error("Please enter a valid amount")
      return
    }
    if(isNaN(values.card_exp.split("/")[0]) || isNaN(values.card_exp.split("/")[1])) {
      toast.error("Please enter valid card expiry date")
      return
    }
    let params:any = {
      petition_id: petitionDetails?.id,
      amount: values.amount,
      card_name: values.card_name,
      card_number: (values.card_number).split(" ").join(""),
      card_exp_month: (values.card_exp).split("/")[0],
      card_exp_year: (values.card_exp).split("/")[1],
      card_cvc: values.card_cvc,
      anonymous_donation: anonymous,
      save_card_info: "0"
    }
    if(signPetitionDetails && signPetitionDetails.email) {
      params["email"] = signPetitionDetails.email
    }
    console.log({ params })
    petitionApi.callChipIn(
      params,
      (message: string, resp: any)=>{
        let x = {
          show_popup_option: 3,
          time: 5,
          title: 'Success',
          description: message,
          // popup_image: [
          //   {
          //     original: '/images/success-icon.png'
          //   }
          // ]
        }
        setPopupSetting(x)
        openPopup()
        // toast.success(message)
        onClose()
      },
      (message: string)=>{
        toast.error(message)
      },
    )
  }

  const handleKeyPress = (event: any) => { 
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  }

  useEffect(()=>{
    console.log({petitionDetails})
    if(petitionDetails && petitionDetails.chip_in_value && shouldShow) {
      setValue("amount", petitionDetails.chip_in_value)
    }
  },[petitionDetails, shouldShow])

  useEffect(()=>{
    if(isAuth) {
      setAnonymous("0")
    }
  },[isAuth])

  return (
    <React.Fragment>
      <Modal
        show={shouldShow}
        backdrop="static"
        keyboard={false}
        className="stackholder-modal"
        centered
      >
        <Modal.Header>
          {/* <img src="/images/pop-hand-left.svg" alt="" /> */}
          <h5 className="modal-title"><strong>Chip In</strong></h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => onClose()}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <input ref={register} name="auth" value={isAuth?"1":"0"} hidden/>
          <div className="card-setup-donation-amt">
            <h5>Enter your donation<span style={{color: 'red'}}>*</span></h5>
            <div className="card-setup-donation-amt-inner">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5>$</h5>
                  <h6>USD</h6>
                </div>
                <div className="donation-amt-fld">
                  <Controller
                    control={control}
                    name="amount"
                    render={({
                      onChange,
                      onBlur,
                      value,
                      name,
                      ref,
                    }) => (
                      <FormTextInput
                        name={name}
                        onChange={e=>{
                          onChange(e)
                          allowNumberOnly(e,name,setValue)
                        }}
                        onBlur={onBlur}
                        // value={Math.abs(Number(value))}
                        value={value}
                        // maxLength={MAX_LEN}
                        // min={0}
                        // onKeyPress={(event: any) => handleKeyPress(event)}
                        inputRef={ref}
                        type="tel"
                        // pattern=" 0+\.[0-9]*[1-9][0-9]*$"
                        error={errors.amount}
                        placeholder="0"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <h6>
              We protect your donation with the Rural America In Action
              Giving Guarantee
            </h6>
          </div>
          <div className="payment-method-pan-outer pt-4 pb-4">
            <h3>Payment method</h3>
            <div className="payment-method-pan">
              {/* {!isAuth && 
                <div className="mb-3">
                  <Controller
                    control={control}
                    name="email"
                    className="form-control"
                    render={({
                      onChange,
                      onBlur,
                      value,
                      name,
                      ref,
                    }) => (
                      <FormTextInput
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        inputRef={ref}
                        type="text"
                        error={errors.email}
                        placeholder="Email Address*"
                      />
                    )}
                  />
                </div>
              } */}
              <div className="row mb-3">
                <div className="col-sm-12 card-no-fld">
                  <Controller
                    control={control}
                    name="card_number"
                    className="form-control"
                    render={({
                      onChange,
                      onBlur,
                      value,
                      name,
                      ref,
                    }) => (
                      <FormMaskedInput
                        name={name}
                        mask="9999 9999 9999 9999"
                        maskPlaceholder="4242 4242 4242 4242"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        inputRef={ref}
                        type="tel"
                        error={errors.card_number}
                        placeholder="Card Number*"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-6 card-year-fld">
                  <Controller
                    control={control}
                    name="card_exp"
                    className="form-control"
                    render={({
                      onChange,
                      onBlur,
                      value,
                      name,
                      ref,
                    }) => (
                      <FormMaskedInput
                        name={name}
                        mask="99/99"
                        maskPlaceholder="MM/YY"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        inputRef={ref}
                        type="tel"
                        error={errors.card_exp}
                        placeholder="MM/YY*"
                      />
                    )}
                  />
                </div>
                <div className="col-sm-6">
                  <Controller
                    control={control}
                    name="card_cvc"
                    className="form-control"
                    render={({
                      onChange,
                      onBlur,
                      value,
                      name,
                      ref,
                    }) => (
                      <FormTextInput
                        name={name}
                        onChange={e=>{
                          onChange(e)
                          allowNumberOnly(e,name,setValue)
                        }}
                        onBlur={onBlur}
                        value={value}
                        inputRef={ref}
                        type="tel"
                        maxLength={4}
                        // onKeyPress={(event: any) => handleKeyPress(event)}
                        error={errors.card_cvc}
                        placeholder="CVV*"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="mb-3">
                <Controller
                  control={control}
                  name="card_name"
                  className="form-control"
                  render={({
                    onChange,
                    onBlur,
                    value,
                    name,
                    ref,
                  }) => (
                    <FormTextInput
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      inputRef={ref}
                      type="text"
                      error={errors.card_name}
                      placeholder="Name on card*"
                    />
                  )}
                />
              </div>
              {isAuth && 
                <div className="mb-3 form-check">
                  <Controller
                    control={control}
                    name="anonymous_donation"
                    className="form-check-input"
                    render={({
                      onChange,
                      onBlur,
                      value,
                      name,
                      ref,
                    }) => (
                      <CheckboxInput
                        name={name}
                        onChange={e=>{
                          onChange(e)
                          handleAnonymousChange(e)
                        }}
                        onBlur={onBlur}
                        value={value}
                        inputRef={ref}
                        // error={errors.anonymous_donation}
                        // disabled={anonymousCheck}
                        label="Anonymous Donation"
                      />
                    )}
                  />
                </div>
              }
            </div>
          </div>
          <div className="payment-donate-btn d-flex align-items-center">
            <button
              className="btn btn-primary setup-btn"
              onClick={handleSubmit(onSubmit)}
            >
              Donate Now
            </button>
            <p className="ms-3">
              <i className="fa fa-lock" aria-hidden="true" /> Secure
              donation
            </p>
          </div>
        </Modal.Body>
        {/* <Modal.Footer /> */}
      </Modal>
      <HomePageModal
        shouldShow={showPopup}
        onClose={closePopup}
        popupSetting={popupSetting}
      />
    </React.Fragment>
  )
}