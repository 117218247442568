import React from 'react';

const LazyPetitionUpdates = React.lazy(() =>
  import(/* webpackChunkName: "PetitionUpdatesPage" */ './petitionupdatespage')
);

const PetitionUpdatesPage = (props: Record<string, any>) => (
  <React.Suspense fallback={<div className="loader"></div>}>
    <LazyPetitionUpdates {...props} />
  </React.Suspense>
);

export default PetitionUpdatesPage