import React from "react";
import { Link } from "react-router-dom";
import StartPetitionButton from "src/components/petition/common/StartPetitionButton";
import { useAppUserAction } from "src/_common/hooks/actions/user/appUserActionHook";
import { useAuthStatus } from "src/_common/hooks/authHook";
import { URLS } from "src/_config";

const NavbarPetitionMidSec = () => {

  const userAction = useAppUserAction()
  const isAuth = useAuthStatus()

  const setLink = () => {
    if(!isAuth)
    {
      userAction.setLink(URLS.PETITION.MYPETITIONS)
    }
  }

  return (
    <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll" >
      <li className="nav-item">
        <Link className="nav-link" to={URLS.HOME}>
          Home
        </Link>
      </li>
      <li className="nav-item">
        <StartPetitionButton className={"nav-link"} noImage={true}/>
      </li>
      <li className="nav-item">
        <Link className={"nav-link"+(window.location.pathname==URLS.PETITION.MYPETITIONS ?" active":"")} to={URLS.PETITION.MYPETITIONS} onClick={setLink}>
          My petitions
        </Link>
      </li>
      <li className="nav-item">
        <Link className={"nav-link"+(window.location.pathname==URLS.PETITION.BROWSEPETITION?" active":"")} to={URLS.PETITION.BROWSEPETITION} aria-disabled="true">
          Browse
        </Link>
      </li>
    </ul>
  );
};

export default NavbarPetitionMidSec;
