import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import moment from 'moment'
import { Controller, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormTextAreaInput from 'src/_common/components/form-elements/textarea/textareaInput'
import { useAppPetitionApi } from 'src/_common/hooks/api/petition/appPetitionApiHook';
import FormTextInput from 'src/_common/components/form-elements/textinput/formTextInput';
import { useToaster } from 'src/_common/hooks/common/appToasterHook';
import { useAppFundraisingApi } from 'src/_common/hooks/api/fundraising/appFundraisingApiHook';
import { useAppFundraisingDetailsSelector } from 'src/_common/hooks/selectors/fundraising/fundraisingSelector';

interface AddUpdateModalProps {
   onClose: () => void;
   shouldShow: boolean;
   getUpdatesList?: Function
}

interface AddUpdateFormValues {
    title: string,
    description: string,
}

const updateSchema = yup.object().shape({
    title: yup
        .string()
        .required('Title is required'),
    description: yup
        .string(),
})

export default function AddUpdateModal({ onClose, shouldShow, getUpdatesList }: AddUpdateModalProps) {

    const fundraisingApi = useAppFundraisingApi()
    const toast = useToaster()
    const fundraisingDetails = useAppFundraisingDetailsSelector()?.details
    const [imageFile, setImageFile] = useState<any>()
    const [source, setSource] = useState("")

    const { register, control, setValue, handleSubmit, errors } = useForm<AddUpdateFormValues>({
        resolver: yupResolver(updateSchema),
        defaultValues: {
            title: '',
            description: '',
        },
    })

    const handleImageSelect = (target: any) => {
        if (target.files) {
            if (target.files.length !== 0) {
                const file = target.files[0];
                setImageFile(file)
                const newUrl = URL.createObjectURL(file);
                setSource(newUrl);
            }
            else {
                setSource("");
            }
        }
    }

    const handleImageRemoveClick = () => {
        setSource('');
        setImageFile(null);
        toast.success("Image has been successfully deleted.")
    }

    const onSubmit = (values: AddUpdateFormValues) => {
        // let params = {
        //     fundraiser_id: fundraisingDetails.id,
        //     title: values.title,
        //     description: values.description
        // }
        let formData: any = new FormData()
        formData.append("fundraiser_id", fundraisingDetails.id)
        formData.append("title", values.title)
        formData.append("description", values.description)
        if (imageFile) {
            formData.append('image', imageFile)
        }
        fundraisingApi.callCreateUpdate(formData, (message: string, resp: any) => {
            onClose()
            toast.success(message)
            if(getUpdatesList) {
                getUpdatesList()
            }
          }, (message: string) => {
            toast.error(message)
          })
    }

   return (
      <React.Fragment>
         <Modal
            show={shouldShow}
            backdrop="static"
            keyboard={false}
            className="stackholder-modal"
            centered
         >
            <Modal.Header>
               {/* <img src="/images/pop-hand-left.svg" alt="" /> */}
               <h5 className="modal-title"><strong>Add an Update</strong></h5>
               <button type="button" className="close" data-dismiss="modal" aria-label="Close"  onClick={()=>onClose()}>
                  <span><i className="fa fa-times" aria-hidden="true"></i></span>
               </button>
            </Modal.Header>
            <Modal.Body>
                {/* <div className="container-fluid text-center p-4 border rounded"> */}
                <div>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <div className="form-wrap">
                            <div className="row">
                                <div className="col mb-3">
                                    <Controller
                                    control={control}
                                    name="title"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <FormTextInput
                                        name={name}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        inputRef={ref}
                                        type="text"
                                        error={errors.title}
                                        placeholder="Enter the title here..."
                                        />
                                    )}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col mb-3">
                                    <div className="form-group">
                                        <Controller
                                            control={control}
                                            name="description"
                                            render={({ onChange, onBlur, value, name, ref }) => (
                                                <FormTextAreaInput
                                                    name={name}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    rows={3}
                                                    type="text"
                                                    error={errors.description}
                                                    placeholder="Enter description here..."
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                                <div className="row">
                                    <div className="col mb-3">
                                        <div className="upload-box">
                                            <div className="upload-btn-wrapper">
                                                {source ?
                                                    <>
                                                        <a className="remove-phhoto" onClick={handleImageRemoveClick}>
                                                            ✖
                                                        </a>
                                                        <img src={source} alt="" />
                                                    </>
                                                    :
                                                    <button className="btn-upload">
                                                        Upload a file
                                                        <img
                                                            src={"/images/upload.svg"}
                                                            alt="upload"
                                                        />
                                                        <input type="file" name="file" ref={register} accept="image/*" onChange={(e) => handleImageSelect(e.target)} />
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <div className="btn-wrap">
                                <button type="submit" className="btn btn-primary mt-4">
                                    Submit
                                    <img className="fa" src={"/images/arrow-forward-outline.svg"} alt="forward"/>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal.Body>
            {/* <Modal.Footer /> */}
         </Modal>
      </React.Fragment>
   )
}