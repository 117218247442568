import React, { useState } from "react";
// import { Link, useHistory } from "react-router-dom";
import { useUserApi } from "../../_common/hooks/api/user/appUserApiHook";
import * as Yup from "yup";
// import { URLS } from "../../_config";
import FormTextInput from "src/_common/components/form-elements/textinput/formTextInput";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToaster } from "../../_common/hooks/common/appToasterHook";
import MetaDecorator from "../common/MetaDecorator";
import { useAppLinkSelector } from "src/_common/hooks/selectors/user/userSelector";
import { useHistory } from "react-router";
import { useAppUserAction } from "src/_common/hooks/actions/user/appUserActionHook";

interface LoginFormValues {
  email: string;
  password: string;
}

interface RegisterFormValues {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  password_confirmation: string;
}

const validationSchema = Yup.object().shape({
  first_name: Yup.string().max(20, "First Name can be of maximum 20 characters").required("First Name is required"),
  last_name: Yup.string().max(20, "Last Name can be of maximum 20 characters").required("Last Name is required"),
  email: Yup.string().required("Email is required").email("Email is invalid"),
  password: Yup.string().required("Password is required").min(6, "Password must be at least 6 characters"),
  password_confirmation: Yup.string().required("Confirm Password is required").min(6).oneOf([Yup.ref('password'), ''], 'Passwords must match'),
});

const Signup = ({ setShowLogin, setLoggedin, page }: { setShowLogin: Function, setLoggedin: Function, page?: any }) => {
  const { control, errors, handleSubmit } = useForm<RegisterFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      password_confirmation: "",
    },
  });

  const toast = useToaster();
  const userApi = useUserApi();
  const history = useHistory();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const link = useAppLinkSelector()
  const userAction = useAppUserAction()

  const onSubmitLogin = (values: LoginFormValues) => {
    // e.preventDefault()
    const params = {
      email: values.email?.trim(),
      password: values.password?.trim(),
    }
    userApi.callUserLogin(params, (message: string, resp: any) => {
      if (resp) {
        // console.log({ resp });
        if (link) {
          history.push(link)
          userAction.setLink(null)
        }
        setLoggedin(false)
        toast.success(message)
      } else {
        toast.error(message)
      }
    }, (message: string) => {
      toast.error(message)
    })
  }

  const onSubmitRegister = (values: RegisterFormValues) => {
    const params = {
      first_name: values.first_name?.trim(),
      last_name: values.last_name?.trim(),
      email: values.email?.trim(),
      password: values.password?.trim(),
      password_confirmation: values.password_confirmation?.trim(),
    };
    userApi.callUserRegister(
      params,
      (message: string, resp: any) => {
        if (resp) {
          if(page) {
            onSubmitLogin({email: params.email, password: params.password})
          } else {
            setShowLogin(true);
          }
          toast.success(message)
        } else {
          toast.error(message);
        }
      },
      (messages: any) => {
        messages.forEach((message: any) => toast.error(message));
      }
    );
  };

  return (
    <div className="form-wrap">

      {!page &&
        <MetaDecorator
          title={"Sign Up"}
          description={"Sign Up to Rural America In Action"}
        />
      }

      <div className="mb-3">
        <label className="form-label">First Name<span style={{ color: 'red' }}>*</span></label>
        {/* <input
          name="firstName"
          value={firstName}
          type="text"
          className="form-control"
          placeholder="First Name"
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
        /> */}
        <Controller
          control={control}
          name="first_name"
          render={({ onChange, onBlur, value, name, ref }) => (
            <FormTextInput
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              inputRef={ref}
              type="text"
              error={errors.first_name}
              placeholder="First Name"
            />
          )}
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Last Name<span style={{ color: 'red' }}>*</span></label>
        {/* <input
          name="lastName"
          value={lastName}
          type="text"
          className="form-control"
          placeholder="Last Name"
          onChange={(e) => {
            setLastName(e.target.value);
          }}
        /> */}
        <Controller
          control={control}
          name="last_name"
          render={({ onChange, onBlur, value, name, ref }) => (
            <FormTextInput
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              inputRef={ref}
              type="text"
              error={errors.last_name}
              placeholder="Last Name"
            />
          )}
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Email address<span style={{ color: 'red' }}>*</span></label>
        {/* <input
          name="email"
          value={email}
          type="email"
          className="form-control"
          placeholder="Email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        /> */}
        <Controller
          control={control}
          name="email"
          render={({ onChange, onBlur, value, name, ref }) => (
            <FormTextInput
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              inputRef={ref}
              type="text"
              error={errors.email}
              placeholder="Email"
            />
          )}
        />
      </div>
      <div className="mb-3 password-field-show">
        <label className="form-label">Password<span style={{ color: 'red' }}>*</span></label>
        {/* <input
          name="password"
          value={password}
          type="password"
          className="form-control"
          placeholder="Password"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        /> */}
        <Controller
          control={control}
          name="password"
          render={({ onChange, onBlur, value, name, ref }) => (
            <FormTextInput
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              inputRef={ref}
              type={showPassword == false ? "password": "text"}
              error={errors.password}
              placeholder="Password"
            />
          )}
        />
        {
          showPassword == false ?
            <i className="fa fa-eye-slash" aria-hidden="true" onClick={() => setShowPassword(!showPassword)}></i>
            :
            <i className="fa fa-eye" aria-hidden="true" onClick={() => setShowPassword(!showPassword)}></i>
        }
      </div>
      <div className="mb-3 password-field-show">
        <label className="form-label">Confirm Password<span style={{ color: 'red' }}>*</span></label>
        {/* <input
          name="confirmPassword"
          value={confirmPassword}
          type="password"
          className="form-control"
          placeholder="Confirm Password"
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
        /> */}
        <Controller
          control={control}
          name="password_confirmation"
          render={({ onChange, onBlur, value, name, ref }) => (
            <FormTextInput
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              inputRef={ref}
              type={showConfirmPassword == false ? "password": "text"}
              error={errors.password_confirmation}
              placeholder="Confirm Password"
            />
          )}
        />
        {
          showConfirmPassword == false ?
            <i className="fa fa-eye-slash" aria-hidden="true" onClick={() => setShowConfirmPassword(!showConfirmPassword)}></i>
            :
            <i className="fa fa-eye" aria-hidden="true" onClick={() => setShowConfirmPassword(!showConfirmPassword)}></i>
        }
      </div>
      <div className="btn-wrap">
        {/* <!-- <a href="javascript:void(0);" className="btn btn-secondary"><span className="back-icon"></span>Previous</a> --> */}
        {/* <!-- ADD *disabled* className when the field is empty --> */}
        {/* <Link
          to="#"
          className="btn btn-primary"
          onClick={(e) => onSubmitRegister(e)}
        > */}
        <button
          type="submit"
          className="btn btn-primary"
          onClick={handleSubmit(onSubmitRegister)}
        >
          Signup{" "}
          <img
            className="fa"
            src={"/images/arrow-forward-outline.svg"}
            alt="forward"
          />
        </button>
        {/* </Link> */}
      </div>
      <div className="or-wrap">
        <span>or</span>
      </div>
      
    </div>
  );
};

export default Signup;
