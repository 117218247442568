import React from 'react'
import CreateSurvey from 'src/components/survey/create-survey/CreateSurvey'

export default function CreateSurveyPage() {
  return (
    <React.Fragment>
       <CreateSurvey />
    </React.Fragment>
  )
}
