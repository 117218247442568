import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Footer from "src/components/common/Footer";
import NavbarMain from "src/components/common/navbar/NavbarMain";
import RightStickyBtn from "src/components/common/RightStickyBtn";
import ShareModal from "src/components/common/ShareModal";
import { useAppFundraisingApi } from "src/_common/hooks/api/fundraising/appFundraisingApiHook";
import { useToaster } from "src/_common/hooks/common/appToasterHook";
import ShareDonateButtons from "../common/ShareAndDonateButtons";
import RightDetailsComponent from "../fundraising-details/RightDetailsComponent";
import { getSignedTime } from "src/_config/functions";
import { CRYPTO_SECRET_KEY } from "src/_config";
import MetaDecorator from "src/components/common/MetaDecorator";
import { useAppFundraisingDetailsSelector } from "src/_common/hooks/selectors/fundraising/fundraisingSelector";
import { Link } from "react-router-dom";

const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

const WordsOfSupport = () => {

    const toast = useToaster();
    const fundraisingApi = useAppFundraisingApi();
    const {slug} = useParams<any>();
    const [showShareModal, setShowShareModal] = useState<boolean>(false)
    const fundraisingDetails = useAppFundraisingDetailsSelector();

    const [commentsList, setCommentsList] = useState<any>(null)

    const openShareModal = () => {
      setShowShareModal(true)
    }
  
    const closeShareModal = () => {
      setShowShareModal(false)
    }

    const getCommentsList = () => {
        let params = {
            // crowdfunding_id: fundraisingDetails?.details?.id
            slug
          }
          fundraisingApi.callGetFundraisingCommentsList(params,
            (message: string, resp: any) => {
              // console.log({resp})
              setCommentsList(resp.data.data)
            }, (message: string) => {
            })
    }

    useEffect(()=>{
        getCommentsList()
    },[])

  return (
    <>

      <MetaDecorator
        title={fundraisingDetails?.details?.title+" : Words of Support"}
        description={fundraisingDetails?.details?.title+" : Words of Support"}
        imageUrl={fundraisingDetails?.details?.fundraiser_image?.thumb}
      />

      <NavbarMain section={"fundraising"} />

      <RightStickyBtn />
      <section className="inner-page">
        <div className="container">
          <div className="innerContentArea ">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-8">
                  <h3 className="section-title with-back">WORDS OF SUPPORT ({commentsList && commentsList.length})<span><Link to={`/fundraising/${slug}`}>Back</Link></span></h3>

                  <p>Please donate to share words of support.</p>
                  <div className="reasonsignin_list">
                    <ul>
                      { commentsList && commentsList.length ? commentsList.map((item: any , index: any) => (
                        <li key={index}>
                          <div className="reasonsignin_listtop">
                            <div className="reasonsignin_listleft">
                              <div className="reasonsignin_listleftimg">
                                  {/* {item.user && item.user.avatar.id != 0 ? */}
                                      <img src={item?.user?.avatar?.thumb} alt=""/>
                                      {/* : null
                                  } */}
                                </div>
                                <div className="reasonsignin_listleftcont">
                                  <h4>{item?.anonymous_donation == '1' ? "Anonymous" : item?.user?.full_name}</h4>
                                  <h6>
                                  <span>${item?.amount.toLocaleString()}</span> {getSignedTime(item?.comment_date)}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <p>
                              {item?.body}
                            </p>
                          </li>
                        ))
                        :
                        <div className="col-lg-12 col-md-8">
                          <h1 className="alert-danger alert">No Donations done yet</h1>
                        </div>
                      }
                    </ul>
                  </div>
                  {/* <div className="loadmore mb-5">
                    <span>
                    <a href="#">Load More</a>
                    </span>
                  </div> */}
                  <ShareDonateButtons className="two-btns-wrap" openShareModal={openShareModal}/>
                </div>
                {fundraisingDetails && <RightDetailsComponent openShareModal={openShareModal}/>}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      {showShareModal &&
        <ShareModal
          shouldShow={showShareModal}
          onClose={closeShareModal}
          slug={slug}
          section="fundraising"
        />
      }
    </>
  );
};

export default WordsOfSupport;
