import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router";
import { useAppFundraisingApi } from "src/_common/hooks/api/fundraising/appFundraisingApiHook";
import { useToaster } from "src/_common/hooks/common/appToasterHook";
import { CRYPTO_SECRET_KEY, getSignedTime } from "src/_config";
import { useAppFundraisingDetailsSelector } from "src/_common/hooks/selectors/fundraising/fundraisingSelector";

const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);
const queryString = require("query-string");

interface DonorsListModalProps {
  onClose: () => void;
  shouldShow: boolean;
  donorsListType: string;
  setDonorsListType: Function;
}

export default function DonorsListModal({
  onClose,
  shouldShow,
  donorsListType,
  setDonorsListType,
}: DonorsListModalProps) {
  
  const {slug} = useParams<any>()
  // const id = cryptr.decrypt(fundraisingId.id)
  // const slug = fundraisingId.slug
  const toast = useToaster()
  const fundraisingApi = useAppFundraisingApi()
  const fundraisingDetails = useAppFundraisingDetailsSelector()

  const [donorsList, setDonorsList] = useState<any>([])

  const getAllDonors = () => {
    var params = { slug };
    fundraisingApi.callGetAllDonors(
      params,
      (message: string, resp: any) => {
        // console.log({ resp });
        setDonorsList(resp.data.list.data);
        // console.log(donorsList);
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  const getTopDonors = () => {
    var params = { slug };
    console.log({params})
    fundraisingApi.callGetTopDonors(
      params,
      (message: string, resp: any) => {
        // console.log({ resp });
        setDonorsList(resp.data.list.data);
        // console.log(donorsList);
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  useEffect(() => {
    if (donorsListType) {
      if (donorsListType == "all") {
        getAllDonors();
      }
      if (donorsListType == "top") {
        getTopDonors();
      }
    }
  }, [donorsListType]);

  return (
    <Modal
      show={shouldShow}
      backdrop="static"
      keyboard={false}
      className="stackholder-modal"
      centered
    >
      <Modal.Header>
        <h5 className="modal-title donor-title">
          <strong>{donorsListType == "top" && "Top "}Donations</strong>
          <span className="see-top">
          {donorsListType == "top" ? (
            <a onClick={() => setDonorsListType("all")}>See all donations</a>
          ) : (
            <a onClick={() => setDonorsListType("top")}>
              {/* <img src="/images/star-icon.svg" alt="" /> */}
              See top donations
            </a>
          )}
        </span>
        </h5>
        
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => onClose()}
        >
          <span>
            <i className="fa fa-times"></i>
          </span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          {fundraisingDetails?.settings?.allow_fundraiser_donation == 1 && fundraisingDetails?.details?.status == 1 ?
            <Link
              to={"/fundraising-donate/" + slug}
              className="btn btn-primary w-100 donatenowbtn"
            >
              Donate now <img className="fa" src="/images/rodeo_hover.svg" />
            </Link>
            : null
          }
        </div>
        <div className="o-search-modal-results">
          <div className="m-charity-list ">
            {donorsList &&
              donorsList.length ?
              donorsList.map((item: any, index: any) => (
                <div className="m-charity-row" key={index}>
                  <div className="m-charity-info">
                    <div className="m-charity-info-logo">
                      {/* {item.user.avatar.id !== 0 ? ( */}
                        <img src={item?.user?.avatar?.thumb} alt="" />
                      {/* ) : null} */}
                    </div>
                    <div className="m-charity-text">
                      <h2>
                        <span>{item?.anonymous_donation == '1' ? "Anonymous" : item?.user?.full_name}</span>
                      </h2>
                      <div className="m-charity-info-details-wrapper">
                        <div className="m-charity-detail">${item?.amount.toLocaleString()}</div>
                        <div className="m-charity-detail">
                          {getSignedTime(item?.created_at)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )) : 
                <div>
                  <h4 className="alert-danger alert">No Donations Yet</h4>
                </div>
              }
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
