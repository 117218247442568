import React from 'react'
import SetPasswordComponent from '../../components/login-signup/SetPassword'

export default function SetPassword() {

  return (
    <div>
      <SetPasswordComponent />
    </div>
  );
}
