import React, { useCallback, useEffect, useRef, useState } from 'react'
import Cropper from "react-cropper";
import { Modal } from 'react-bootstrap'
import "cropperjs/dist/cropper.css";
import './imagePicker.css'
import { useToaster } from 'src/_common/hooks/common/appToasterHook'
import { zoomScale } from 'src/_config';


interface ImagePickerProps {
  onUploadImage: (...args: any) => void;
  fieldName?: string;
  setImgSrc: Function;
}

function ImagePickerNew({ onUploadImage, fieldName, setImgSrc }: ImagePickerProps) {

  const [shouldShowCropper, setShouldShowCropper] = useState(false)
  const [fileObj, setFileObj] = useState<any>({});
  const [newFile, setNewFile] = useState<any>({});
  const [imgBase64, setImgBase64] = useState<any>();

  const toast = useToaster();
  const fileInput: any = useRef();
  const cropper: any = useRef();


  const handleChange = (e: any) => {
    const file = fileInput.current.files[0];

    let imgTypes = ["image/jpeg", "image/png", "image/jpg", "image/gif", "image/svg", "image/svg+xml"]
    if (file && file.type && imgTypes.includes(file.type)) {
      if (file.size < 5242880) {
        loadImage(file, URL.createObjectURL(file), e)
      }
      else {
        toast.error("The image must not be greater than 5 MB")
        return
      }
    }
    else if (file && file.type && !imgTypes.includes(file.type)) {
      toast.error("The image must be of type: jpeg, png, jpg, gif, svg")
      return
    }
    else {
      toast.error("Please select only image file...")
      return
    }
  };

  const loadImage = (file: any, imageSrc: any, e: any) => {
    convertBlobToBase64(e)

    const img = new Image();
    img.src = imageSrc;
    const { name, size, type } = file;

    img.onload = () => {
      if (img.width < 720) {
        toast.error("Please select an image of minimum width 720px...")
      }
      else {
        setShouldShowCropper(true)
        setNewFile(file)
        setFileObj({
          name,
          size,
          type,
          imageSrc,
          croppedImgSrc: null
        });
      }
    };
  };

  const convertBlobToBase64 = async (event: any) => {
    const selectedfile = event.target.files;
    if (selectedfile.length > 0) {
      const [imageFile] = selectedfile;
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const srcData = fileReader.result;
        setImgBase64(srcData)
      };
      fileReader.readAsDataURL(imageFile);

    }
  }


  const handleCropChange = () => {
    const croppedImgData = cropper.current.cropper
      .getCroppedCanvas()
      .toDataURL();
    // console.log("## cropped !", cropper.current.cropper.g);
    // setFileObj({ ...fileObj, croppedImgSrc: croppedImgData });
    setFileObj((prev: any) => {
      return { ...prev, croppedImgSrc: croppedImgData }
    });
  };

  const handleRotateLeft = () => {
    cropper.current.cropper.rotate(-45);
    handleCropChange();
  };

  const handleRotateRight = () => {
    cropper.current.cropper.rotate(45);
    handleCropChange();
  };

  const closeCropperModal = () => {
    setShouldShowCropper(false)
    if (fileInput?.current?.value) {
      fileInput.current.value = ''
    }
  }

  const uploadImage = async () => {
    handleCropChange()
    console.log({ org: imgBase64, cropped: fileObj.croppedImgSrc })
    setImgSrc(fileObj.croppedImgSrc ? fileObj.croppedImgSrc : imgBase64)

    onUploadImage(newFile, () => {
      closeCropperModal()
    }, () => {
      closeCropperModal()
    })
  }

  const handleZoom = (flag: string) => {
    const operator = (flag == 'in') ? '+' : '-';
    const val = operator + zoomScale;
    cropper.current.cropper.zoom(val);
    handleCropChange();
  };

  return (
    <React.Fragment>
      Upload Photo
      <img
        src={"/images/upload.svg"}
        alt="upload"
      />
      <input type="file" accept=".jpeg,.png,.jpg,.gif,.svg" className="text-center center-block file-upload" ref={fileInput}
        onChange={(e) => handleChange(e)} />

      <Modal
        show={shouldShowCropper}
        size="lg"
        backdrop="static"
        keyboard={false}
        className="ne-modal-wrapper"
      >
        <Modal.Header>
          <h5 className="modal-title">
            Crop Image
          </h5>
          <button type="button" className="close" onClick={closeCropperModal}>
            <img src="/images/close-btn-icon.png" alt="" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="image-upload-modal-body">
            <div className="profile-image-modal-crop-area">
              <Cropper
                style={{ maxWidth: "600px", height: "400px" }}
                ref={cropper}
                src={fileObj.imageSrc}
                aspectRatio={16 / 9}
                cropend={() => handleCropChange()}
              />
            </div>
            <div className="profile-image-modal-slider-container">
              <div className="profile-image-modal-slider-rotate">
                <button onClick={() => handleRotateLeft()}><i className="fa fa-solid fa-rotate-left"></i></button>
                <button onClick={() => handleRotateRight()}><i className="fa fa-solid fa-rotate-right"></i></button>
              </div>
              <div className="profile-image-modal-slider-rotate zoom">
                <button onClick={() => handleZoom('out')}><i className="fa fa-solid fa-minus"></i></button>
                <button onClick={() => handleZoom('in')}><i className="fa fa-solid fa-plus"></i></button>
              </div>
            </div>
            <div className="profile-image-modal-bottom-actions">
              <button onClick={closeCropperModal} className="btn theme-btn btn-danger waves-effect">Cancel</button>
              <button onClick={uploadImage} className="btn theme-btn btn-primary waves-effect waves-light">Crop &amp; Upload</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default ImagePickerNew
