import { useDispatch } from 'react-redux'
import { ACTIONS } from '../../../../_config'

export function useAppSurveyAction() {

  const dispatch = useDispatch()

  const createSurvey = (data: any) => {
    dispatch({
      type: ACTIONS.SURVEY.CREATE_SURVEY,
      payload: data
    })
  }

  const resetCreateSurvey = () => {
    dispatch({
      type: ACTIONS.SURVEY.RESET_CREATE_SURVEY
    })
  }

  const createSurveyPage = (data: any) => {
    dispatch({
      type: ACTIONS.SURVEY.CREATE_SURVEY_PAGE,
      payload: data
    })
  }

  const resetCreateSurveyPage = () => {
    dispatch({
      type: ACTIONS.SURVEY.RESET_CREATE_SURVEY_PAGE
    })
  }

  const resetSurveyList= () => {
    dispatch({
      type: ACTIONS.SURVEY.RESET_SURVEY_LIST
    })
  }

  const resetMySurveyList= () => {
    dispatch({
      type: ACTIONS.SURVEY.RESET_MY_SURVEY_LIST
    })
  }

  const resetSurveyDetails= () => {
    dispatch({
      type: ACTIONS.SURVEY.RESET_SURVEY_DETAILS
    })
  }

  const startSurvey = (data: any) => {
    dispatch({
      type: ACTIONS.SURVEY.START_SURVEY,
      payload: data
    })
  }

  const resetStartSurvey = () => {
    dispatch({
      type: ACTIONS.SURVEY.RESET_START_SURVEY
    })
  }

  return {
    createSurvey,
    resetCreateSurvey,
    createSurveyPage,
    resetCreateSurveyPage,
    resetSurveyList,
    resetSurveyDetails,
    resetMySurveyList,
    startSurvey,
    resetStartSurvey,
  }
}