import React, { useEffect, useState } from "react";
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { Link } from "react-router-dom";

interface SocialLoginsProps {
    clientId: string;
    onSuccess: (...args:any)=>void;
    onFailure: (...args:any)=>void;
    responseFacebook: (...args:any)=>void;
}

const SocialLogins = ({clientId, onSuccess, onFailure, responseFacebook}: SocialLoginsProps) => {

    return (
        <>
            <div className="social-logins">
                <a className="google">
                {/* <span>Continue with Google</span> */}
                <GoogleLogin
                    clientId={clientId}
                    buttonText="Continue With Google"
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                    cookiePolicy={'single_host_origin'}
                    isSignedIn={false}
                />
                </a>
                <a className="facebook">
                {/* <span>Continue with Facebook</span> */}
                <FacebookLogin
                    // appId="1123824818512127"
                    appId="1136429477246907"   //live
                    autoLoad={false}
                    fields="name,email"
                    scope="public_profile,email"
                    callback={responseFacebook}
                    icon="fa-facebook" 
                    textButton="Continue with Facebook" 
                />
                </a>
            </div>
            <div className="terms-wrap">
                By joining or logging in, you accept ruralamericainaction.com's{" "}
                <Link to="/cms/terms-of-use">Terms of Service</Link> and{" "}
                <Link to="/cms/privacy-policy">Privacy Policy</Link>.
            </div>
        </>
    )
}

export default SocialLogins