let apiBaseUrl = ''

let appStage = process.env.REACT_APP_STAGE

if (appStage == 'dev') {
  apiBaseUrl = 'https://westernjustice-backend-dev.dreamztesting.com/api/v1/'
  // apiBaseUrl = 'https://westernjustice-backend.dreamztesting.com/api/v1/'
  // apiBaseUrl = 'https://backend.ruralamericainaction.com/api/v1/'
} else if (appStage == 'stage') {
  apiBaseUrl = ''
} else if (appStage == 'uat') {
  apiBaseUrl = 'https://westernjustice-backend.dreamztesting.com/api/v1/'
} else if (appStage == 'prod') {
  apiBaseUrl = 'https://backend.ruralamericainaction.com/api/v1/'
}

export const API_BASE_URL = apiBaseUrl