import React from "react";
import { Link } from "react-router-dom";
import { URLS } from "src/_config";

interface NavbarLeftProps {
  section: string;
}

const NavbarLeftLogo = ({ section }: NavbarLeftProps) => {
  return (
    <>
      <Link className="navbar-brand" to={URLS.HOME}>
        {/* <img src={"/images/LOGO.png"} alt="logo" /> */}
        <img src={"/images/LOGO_NEW.png"} alt="logo" />
      </Link>
      {section!="login" && <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarScroll"
        aria-controls="navbarScroll"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>}
    </>
  );
};

export default NavbarLeftLogo;