import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import { useAppPetitionAction } from "src/_common/hooks/actions/petition/appPetitionActionHook";
import { useAppUserAction } from "src/_common/hooks/actions/user/appUserActionHook";
import { useAuthStatus } from "src/_common/hooks/authHook";
import { URLS } from "src/_config";

const NavbarRightLoginButton = () => {

  const isAuth: any = useAuthStatus()
  const userAction = useAppUserAction()
  const history = useHistory()
  const petitionAction = useAppPetitionAction()
  const toast = useToaster()

  const logout = () => {
    userAction.logout()
    toast.success("Logged out successfully")
    petitionAction.resetCreatePetition()
    history.push(URLS.USER.HOME)
  }
  
  return (
    <>
      <a href={"https://www.wjustice.org"} className="wjlogo" target="_blank">
        <img src={"/images/Mask Group 27.png"} alt='mask'/>
      </a>
      {isAuth ? 
        <a className="nav_logout">
          <div className="dropdown custom-top-drop">
            <a
              className="nav_logout dropdown-toggle"
              href="#"
              id="navbarScrollingDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span>
                <img src={isAuth.avatar.thumb} alt='avatar'/>
              </span>{" "}
              <em>{isAuth.first_name && isAuth.first_name}</em>
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarScrollingDropdown" style={{cursor: "pointer"}}>
              <li>
                <Link to={URLS.USER.MY_PROFILE} className="dropdown-item">
                  <img src="/images/user-icon.svg" alt="" /> My Profile
                </Link>
              </li>
              <li>
                <Link to={URLS.USER.SETTINGS} className="dropdown-item">
                  <img src="/images/settings-icon.svg" alt="" /> Settings
                </Link>
              </li>
              <li>
                <a className="dropdown-item" onClick={logout}>
                  <img src="/images/switch-icon.svg" alt="" /> Logout
                </a>
              </li>
            </ul>
          </div>
        </a>
        :
        <Link className="nav_logout" to={URLS.USER.LOGIN}>
          <div className="dropdown custom-top-drop">
              <a className="nav_logout" >
                <span>
                  <img src="/images/log-out-outline.png" alt='avatar'/>
                </span>{" "}
                <em>Login</em>
              </a>
            </div>
        </Link>
      }
    </>
  );
};

export default NavbarRightLoginButton;
