import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import AuthSignPetitionForm from './AuthSignPetitionForm'
import { useAuthStatus } from 'src/_common/hooks/authHook';
import NoAuthSignPetitionForm from './NoAuthSignPetitionForm';
import { useAppPetitionApi } from 'src/_common/hooks/api/petition/appPetitionApiHook';
import { any } from 'prop-types';
import { useToaster } from 'src/_common/hooks/common/appToasterHook';
import { CRYPTO_SECRET_KEY } from 'src/_config';
import { useParams } from 'react-router';
import DeleteDuplicateConfirmationModal from '../../common/DeleteDuplicateConfirmationModal';
import ChipInModal from './ChipInModal';
import ChipinConfirmationModal from './ChipinConfirmationModal';

const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);
const queryString = require("query-string");

interface SignPetitionModalProps {
  onClose: () => void;
  shouldShow: boolean;
  petitionDetails: any;
}

export default function SignPetitionModal({ petitionDetails, onClose, shouldShow, }: SignPetitionModalProps) {

  const isAuth = useAuthStatus()
  const petitionApi = useAppPetitionApi()
  const toast = useToaster()
  const [params, setParams] = useState<any>()
  const [showChipinModal, setShowChipinModal] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)

  const petitionId = useParams<any>()
  // const id = parseInt(cryptr.decrypt(petitionId.id))
  const slug = petitionId.slug

  const closeModal = () => {
    console.log("3")
    setShowModal(false)
  }

  const openModal = () => {
    console.log("2")
    setShowModal(true)
  }

  const closeChipinModal = () => {
    setShowChipinModal(false)
  }

  const openChipinModal = () => {
    // setShowModal(false)
    setShowChipinModal(true)
  }

  const getPetitionDetails = () => {
    const params = {
      slug,
    };
    petitionApi.callgetPetitionDetails(
      params,
      (message: string, resp: any) => {
        console.log({ resp });
        if(resp?.data?.petition_details?.chip_in==1) {
          // setShowChipinModal(true)
          console.log("1")
          openModal()
          onClose()
        }
      },
      (message: string) => {
        toast.error(message)
      }
    );
  };

  useEffect(() => {
    if (params) {
      petitionApi.callPetitionSign(
        params,
        (message: string, resp: any) => {
          // console.log({ resp })
          toast.success(message)
          getPetitionDetails()
          // onClose()
        }, (message: string) => {
          toast.error(message)
        })
    }
  }, [params])

  useEffect(()=>{
    console.log({showChipinModal})
  },[showChipinModal])

  useEffect(()=>{
    console.log({showModal})
  },[showModal])

  return (
    <React.Fragment>
      <Modal
        show={shouldShow}
        backdrop="static"
        keyboard={false}
        className="stackholder-modal"
        centered
      >
        <Modal.Header>
          {/* <img src="/images/pop-hand-left.svg" alt="" /> */}
          <h5 className="modal-title"><strong>Sign The Petition</strong></h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => onClose()}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          {isAuth ?
            <AuthSignPetitionForm setParams={setParams} onClose={onClose} petitionDetails={petitionDetails}/>
            :
            <NoAuthSignPetitionForm setParams={setParams} onClose={onClose} petitionDetails={petitionDetails}/>
          }
        </Modal.Body>
        {/* <Modal.Footer /> */}
      </Modal>
      <ChipinConfirmationModal
        shouldShow={showModal}
        onClose={closeModal}
        handleConfirm={openChipinModal}
      />
      <ChipInModal
        shouldShow={showChipinModal}
        onClose={closeChipinModal}
      />
    </React.Fragment>
  )
}