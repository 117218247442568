import React, { useEffect, useState } from "react";
import { EmailIcon, EmailShareButton, FacebookShareButton, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";
import { Modal } from "react-bootstrap";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import { useCommonApi } from "src/_common/hooks/api/common/appCommonApiHook";
import { CRYPTO_SECRET_KEY } from "src/_config/site_statics";
import { useAuthStatus } from "src/_common/hooks/authHook";
import { useAppPetitionApi } from "src/_common/hooks/api/petition/appPetitionApiHook";
import { useAppFundraisingApi } from "src/_common/hooks/api/fundraising/appFundraisingApiHook";
import { useAppSurveyApi } from "src/_common/hooks/api/survey/appSurveyApiHook";
import HelmetMetaData from "src/_common/components/elements/react-helmet/Helmet";
import { Helmet } from "react-helmet-async"
import { FB_SHARE, URLS } from "src/_config";
import { SHARE_URL } from "src/_config/share_urls";

// const fbShareLink = process.env.REACT_APP_FB_SHARE_PROD

const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

interface ShareModalProps {
  onClose: () => void;
  shouldShow: boolean;
  slug?: string;
  section?: string;
  url?: string;
}

const ShareModal = ({ onClose, shouldShow, slug, section, url }: ShareModalProps) => {

  const toast = useToaster();
  const commonApi = useCommonApi();
  const isAuth = useAuthStatus()

  const [isCopied, setIsCopied] = useState<boolean>(false)
  const [shareLink, setShareLink] = useState<any>()
  const [sectionDetails, setSectionDetails] = useState<any>()
  const [shareID, setShareID] = useState<any>(null)
  const fundraisingApi = useAppFundraisingApi()
  const petitionApi = useAppPetitionApi()
  const surveyApi = useAppSurveyApi()

  useEffect(() => {
    if (isCopied) {
      toast.success('Link copied successfully');
      // setIsCopied(false)
    }
  }, [isCopied])

  useEffect(() => {
    if (section && section != "survey" && shouldShow && sectionDetails && isAuth) {
      // console.log(id, cryptr.decrypt(id))
      let params = {
        entity_type: section,
        entity_id: sectionDetails?.id,
      }
      commonApi.callShareCount(params, (message: string, resp: any) => {
        // console.log("called sharecount")
      },
        (message: string) => {
          // toast.error(message)
        })
    }
    setShareLink('')
  }, [shouldShow, sectionDetails])

  useEffect(() => {
    if (section) {
      // let link = `${window.location.origin}/`
      if (section == 'survey') {
        setShareLink(`${window.location.origin}/start-${section}/${slug}`)
      }
      else {
        setShareLink(`${window.location.origin}/${section}/${slug}`)
      }
    }
  }, [section])

  useEffect(() => {
    if (slug) {
      // setShareID(cryptr.decrypt(id))
      // console.log(id, cryptr.decrypt(id))
      if (section == "fundraising") {
        console.log(section)
        const params = {
          slug,
        }
        fundraisingApi.callGetFundraisingDetails(
          params,
          (message: string, resp: any) => {
            // console.log(resp.data.details)
            setSectionDetails(resp.data.details)
            setShareID(resp.data.details.id)
          },
          (message: string) => {
            // toast.error(message)
          }
        )
      }
      if (section == "petition") {
        console.log(section)
        const params = {
          slug,
        }
        petitionApi.callgetPetitionDetails(
          params,
          (message: string, resp: any) => {
            // console.log(resp)
            setSectionDetails(resp.data.petition_details)
            setShareID(resp.data.petition_details.id)
          },
          (message: string) => {
            // toast.error(message)
          }
        )
      }
      if (section == "survey") {
        console.log(section)
        let params = {
          slug
        }
        surveyApi.callGetSurveyDetails(
          params,
          (message: string, resp: any) => {
            // console.log(resp.data.survey_details)
            setSectionDetails(resp.data.survey_details)
            setShareID(resp.data.survey_details.id)
          },
          (message: string) => {
            // toast.error(message)
          }
        )
      }
    }
  }, [slug])

  useEffect(() => {
    console.log({ sectionDetails })
    // if(sectionDetails) {
    //   let x = document.getElementById("fb-title")
    //   if(x)
    //     x.setAttribute('content',sectionDetails.title)
    // }
  }, [sectionDetails])

  return (
    <Modal
      show={shouldShow}
      backdrop="static"
      keyboard={false}
      className="stackholder-modal"
      centered
    >
      <Modal.Header>
        <h5 className="modal-title">
          <strong>Share Link</strong>
        </h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => { onClose(); setIsCopied(false); }}
        >
          <span>
            <i className="fa fa-times"></i>
          </span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-share">
          {/* <>{console.log(`${SHARE_URL}${btoa(`id=${shareID}&type=${section}&cryptId=${id}&baseUrl=${window.location.origin}/`)}`)}</> */}
          <FacebookShareButton
            quote={sectionDetails?.title}
            // url={url ? url : shareID ? `${SHARE_URL}${`type=${section}&cryptId=${cryptr.encrypt(shareID)}&baseUrl=${window.location.origin}/`}` : URLS.HOME}
            url={url ? url : shareID ? `${SHARE_URL}${`type=${section}&s=${slug}`}` : URLS.HOME}
            className="Demo__some-network__share-button"
          >
            <FacebookIcon size={32} round /> Facebook share
          </FacebookShareButton>
          
          <TwitterShareButton
            title={sectionDetails?.title}
            // url={url ? url : shareID ? `${SHARE_URL}${btoa(`id=${shareID}&type=${section}&cryptId=${cryptr.encrypt(shareID)}&baseUrl=${window.location.origin}/&timestamp=${Date.now()}`)}` : URLS.HOME}
            url={url ? url : shareID ? `${SHARE_URL}${`type=${section}&s=${slug}`}` : URLS.HOME}
          >
            <TwitterIcon size={32} round />
            Twitter share
          </TwitterShareButton>

          <WhatsappShareButton
            title={sectionDetails?.title}
            // url={url ? url : shareID ? `${SHARE_URL}${btoa(`id=${shareID}&type=${section}&cryptId=${cryptr.encrypt(shareID)}&baseUrl=${window.location.origin}/`)}` : URLS.HOME}
            url={url ? url : shareID ? `${SHARE_URL}${`type=${section}&s=${slug}`}` : URLS.HOME}
          >
            <WhatsappIcon size={32} round />
            Whatsapp share
          </WhatsappShareButton>

          <EmailShareButton
            body={sectionDetails?.title}
            // url={url ? url : shareID ? `${SHARE_URL}${btoa(`id=${shareID}&type=${section}&cryptId=${cryptr.encrypt(shareID)}&baseUrl=${window.location.origin}/`)}` : URLS.HOME}
            url={url ? url : shareID ? `${SHARE_URL}${`type=${section}&s=${slug}`}` : URLS.HOME}
          >
            <EmailIcon size={32} round />
            Email share
          </EmailShareButton>


          <CopyToClipboard
            // text={url ? url : shareID ? `${SHARE_URL}${btoa(`id=${shareID}&type=${section}&cryptId=${cryptr.encrypt(shareID)}&baseUrl=${window.location.origin}/`)}` : URLS.HOME}
            text={url ? url : shareID ? `${SHARE_URL}${`type=${section}&s=${slug}`}` : URLS.HOME}
            onCopy={() => setIsCopied(true)}
          >
            <button><i className="fa fa-copy"></i>Copy Link</button>
          </CopyToClipboard>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShareModal;
