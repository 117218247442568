import React, { useEffect, useState } from 'react'
import Pagination from 'react-js-pagination'
import { useHistory } from 'react-router-dom'
import Footer from 'src/components/common/Footer'
import MetaDecorator from 'src/components/common/MetaDecorator'
import NavbarMain from 'src/components/common/navbar/NavbarMain'
import RightStickyBtn from 'src/components/common/RightStickyBtn'
import ShareModal from 'src/components/common/ShareModal'
import { useAppGlobalAction } from 'src/_common/hooks/actions/common/appGlobalActionHook'
import { useAppSurveyAction } from 'src/_common/hooks/actions/survey/appSurveyActionHook'
import { useAppSurveyApi } from 'src/_common/hooks/api/survey/appSurveyApiHook'
import { useToaster } from 'src/_common/hooks/common/appToasterHook'
import { useAppSearchKeywordSelector } from 'src/_common/hooks/selectors/common/commonSelector'
import { useAppSurveyListSelector } from 'src/_common/hooks/selectors/survey/surveySelector'
import { CRYPTO_SECRET_KEY } from 'src/_config'
import StartSurveyButton from '../common/StartSurveyButton'

const Cryptr = require("cryptr");
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

const BrowseSurvey = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [surveyList, setSurveyList] = useState<any>([])
    const [surveySearchValue, setSurveySearchValue] = useState<any>('')
    const [showShareModal, setShowShareModal] = useState<boolean>(false)
    // const [currentSurveyId, setCurrentSurveyId ] = useState<any>()
    const [currentSurveySlug, setCurrentSurveySlug ] = useState<any>()

    const toast = useToaster()
    const surveyListState = useAppSurveyListSelector()
    // console.log(mySurveyListState)
    const surveyAction = useAppSurveyAction()
    const surveyApi = useAppSurveyApi()
    const history = useHistory()
    const searchKeyword = useAppSearchKeywordSelector()
    const globalAction = useAppGlobalAction()

    const openShareModal = (slug:any) => {
        setCurrentSurveySlug(slug)
        setShowShareModal(true)
    }

    const closeShareModal = () => {
        setShowShareModal(false)
    }

    const loadMore = () => {
        let newPage = currentPage + 1
        getSurveyList({ page: newPage })
        setCurrentPage(newPage)
    }

    const changeSearch = () => {
        surveyAction.resetSurveyList()
        setCurrentPage(1)
        getSurveyList({ front_search: searchKeyword ? searchKeyword : surveySearchValue})
        // getSurveyList({ front_search: surveySearchValue })
    }

    const handleSearchKeyPress = (e:any) => {
        if (e.key === "Enter") {
            changeSearch()
        }
    }

    const handleReset = () => {
        surveyAction.resetSurveyList()
        setCurrentPage(1)
        setSurveySearchValue('')
        getSurveyList()
    }

    const getSurveyList = (params?: any) => {
        if (params?.page && surveySearchValue && surveySearchValue != '') {
            params["front_search"] = surveySearchValue
        }
        surveyApi.callGetSurveyList(
            {...params,type:"browse"},
            (message: string, resp: any) => {
                // console.log(resp.data)
                let x: any = []
                if (params?.page) {
                    x = surveyList
                }
                x = x.concat(resp.data.list.data)
                setSurveyList(x)
            },
            (message: string) => {
                // toast.error(message)
            }
        );
    }

    const handleStartSurvey = (slug: any) => {
        history.push(`/start-survey/${slug}`)
    }

    useEffect(() => {
        getSurveyList()
    }, [])

    useEffect(() => {
        if(searchKeyword) {
            console.log({searchKeyword})
            setSurveySearchValue(searchKeyword)
            changeSearch()
            // setTimeout(()=>{
            // },500)
            globalAction.resetSearchKeyword()
        }
    }, [searchKeyword])

    return (
        <>

            <MetaDecorator
                title={"Browse Survey"}
                description={"Browse Survey"}
            />

            <NavbarMain section={"survey"} />

            {/*------- INNER BANNER START ------*/}
            <div className="innerpageBanner survey-listing-banner survey-create-banner">
                <div className="container">
                    <h2>GET SURVEY RESPONSES FROM RURAL AMERICA IN MINUTES</h2>
                </div>
            </div>
            {/*------- INNER BANNER END ------*/}

            {/*------- CONTENT AREA START --------*/}
            <div className="innerContentArea survey-section-wrap">
                <div className="container">
                    {/* <div className="welcome-bar">Welcome, lostlarson@gmail.com!</div> */}
                    <div className="survey-listing-section">
                        <div className="survey-header-wrap">
                            <h2>BROWSE SURVEY LIST</h2>
                            <div className="browse-survey-right-search">
                                <div className="search-box-left">
                                    {/* <h3>SEARCH BY:</h3> */}
                                    <div className="nav_search">
                                        <input className="search_input" type="text" placeholder="Search by title..." value={surveySearchValue} onChange={(e) => setSurveySearchValue(e.target.value)} onKeyPress={handleSearchKeyPress}/>
                                        <button className="nav_searchbtn" onClick={changeSearch} ></button>
                                    </div>
                                </div>
                                <div>
                                    <button type="button" className="btn btn-outline-primary" onClick={handleReset}><img src="/images/reset-icon.svg" alt="" /></button>
                                </div>
                                {/* <a href="create-survey-1.html" className="btn btn-primary">
                                    Create Survey{" "}
                                    <img className="fa" src="images/arrow-forward-outline.svg" />
                                </a> */}
                                <StartSurveyButton className={"btn btn-primary"} noImage={false} text={"Create Survey"} />
                            </div>
                        </div>
                        <div className="survey-listing-wrap new-servey">
                            {
                                surveyList && surveyList.length > 0 ?
                                    surveyList.map((item: any, index: any) => (
                                        <div className={
                                            (item?.is_complete == 1 && item.status == 0) ? "survey-list-box status-unpublish" : (item?.user_status == 1 && item.status == 1) ? "survey-list-box status-publish status-none" : (item?.user_status == 0 && item.status == 1) ? "survey-list-box status-unpublish" : "survey-list-box status-draft"
                                        }
                                            key={index}>
                                            <div className="survey-box-left">
                                                <h2>{item?.title}</h2>
                                                <div className="list-date-time-wrap">
                                                    <div className="created-box">
                                                        <div className="created-box-icon">
                                                            <img src="/images/man.svg" alt="" />
                                                        </div>
                                                        <div className="created-data">
                                                            <h3>Creator Name:</h3>
                                                            <h4>{item?.user?.full_name}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="created-box">
                                                        <div className="created-box-icon">
                                                            <img src="/images/clock-icon.svg" alt="" />
                                                        </div>
                                                        <div className="created-data">
                                                            <h3>Created Date:</h3>
                                                            <h4>{item?.date_created}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="survey-box-right">
                                                <div className="question-box">
                                                    <span>{item?.questions && item?.questions.length}</span>
                                                    <em>Questions</em>
                                                </div>
                                                <div className="collector-box new-servey">
                                                    <a
                                                        className="btn-share-details share-btn"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={()=>openShareModal(item?.slug)}
                                                    >
                                                        <img src="images/share-social-outline.svg" alt="social" /> Share
                                                    </a>
                                                    <hr />
                                                    <a className="btn btn-primary" onClick={() => handleStartSurvey(item?.slug)}>Start survey</a>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    <div className="col-lg-12 col-md-8">
                                        <h1 className="alert-danger alert">No Survey Added Yet</h1>
                                    </div>
                            }
                            {currentSurveySlug && showShareModal &&
                                <ShareModal
                                    onClose={closeShareModal}
                                    shouldShow={showShareModal}
                                    slug={currentSurveySlug}
                                    section="survey"
                                />
                            }
                        </div>
                        <div className="survey-footer-wrap survey-footer-margin">
                            {surveyListState?.last_page != currentPage ?
                                <div style={{ cursor: "pointer" }} className="loadmore" onClick={() => loadMore()}>
                                    <span>
                                        <a>Load More</a>
                                    </span>
                                </div>
                                : null
                            }
                            <StartSurveyButton className={"btn btn-primary"} noImage={false} text={"Create Survey"} />
                        </div>
                    </div>
                </div>
            </div>

            {/*------- CONTENT AREA END --------*/}

            <RightStickyBtn />

            <Footer />
        </>

    )
}

export default BrowseSurvey